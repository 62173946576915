export const CORE_REFRESH_TOKEN = "CORE_REFRESH_TOKEN";

/**
 * Sets the refreshToken token to storage.
 *
 * @param {string} token - The refreshToken token to be set.
 */
export const setRefreshTokenToStorage = (token: string) => {
  localStorage.setItem(CORE_REFRESH_TOKEN, token);
};

/**
 * Retrieves the refreshToken token from storage.
 *
 */
export const getRefreshTokenFromStorage = () => {
  return localStorage.getItem(CORE_REFRESH_TOKEN);
};
