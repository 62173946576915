interface IGetPlaceDetails {
  place?: google.maps.places.PlaceResult;
}

export const getPlaceDetails = ({ place }: IGetPlaceDetails) => {
  if (!place || (place && !place.address_components)) {
    return {};
  }

  const address = place?.address_components?.reduce<Record<string, string>>((acc, c) => {
    acc[c?.types?.[0]] = c?.short_name;
    return acc;
  }, {});

  const state = (
    address?.administrative_area_level_1 || address?.administrative_area_level_2
  )?.trim();
  const street = [address?.street_number, address?.route].join(" ")?.trim();
  const city = (address?.city || address?.locality)?.trim();
  const zip = address?.postal_code?.trim();

  return {
    state,
    street,
    city,
    zip,
  };
};
