import {
  FilterChangesType,
  ITableFilter,
  NewButton,
  NewTable,
  toastNotification,
} from "@helium10/re-ui-components";
import { getRefreshTokenFromStorage } from "@walmart10/container/helpers/refreshToken";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { useTranslation } from "../../../i18n/useTranslation";
import { clearSession } from "../requests";
import { useUserSettingsStore } from "../store";
import { CardBody, CardHeader, ColumnLink, SettingBox } from "./Common";

const tableColumn = [
  {
    field: "dateTime",
    title: "Date & time",
  },
  {
    field: "userName",
    title: "Username",
  },
  {
    field: "ip",
    title: "IP",
  },
  {
    field: "country",
    title: "Country",
  },
];
interface TableDateType {
  dateTime: string;
  userName: string;
  ip: string;
  id: string;
  country: string;
  clientId: string;
  userId: string;
  loginTime: string;
}

function formatDateTime(dateTimeStr: string) {
  const date = new Date(dateTimeStr);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
}

export const SessionsBox = memo(() => {
  const { t } = useTranslation();
  for (let i = 0; i < tableColumn.length; i++) {
    tableColumn[i].title = t(`userSettings.sessionTable.${tableColumn[i].field}`);
  }
  const {
    useStore,
    getUserListParamsSelector,
    loginRecordsSelector,
    useLoginRecordsParamsController,
  } = useUserSettingsStore();
  const LoginRecords = useStore(loginRecordsSelector);
  const userListParams = useStore(getUserListParamsSelector);
  const loginRecordsController = useLoginRecordsParamsController();
  const [tableDate, setTableDate] = useState<TableDateType[]>([]);
  const [total, setTotal] = useState(0);
  const [showLast60, setShowLast60] = useState({
    last60: false,
    gagination: false,
  });
  useEffect(() => {
    const records = LoginRecords.data?.data;
    if (Array.isArray(records)) {
      const data = records.map((i) => {
        return {
          ...i,
          dateTime: formatDateTime(i.loginTime),
          id: i.clientId,
        };
      });
      setTableDate(data);
      const _total = LoginRecords.data?.pageInfo.totalCount || 0;
      setTotal(_total);
      if (!showLast60.gagination) {
        if (_total > data.length) {
          setShowLast60((old) => {
            return { last60: true, gagination: old.gagination };
          });
        }
      }
    } else {
      setTableDate([]);
      setTotal(0);
    }
  }, [LoginRecords]);

  const behavior = useMemo(
    () => ({
      // noHeader: true,
      noFooter: !showLast60.gagination,
      // noPagination: false,
      itemsPerPage: [10, 20, 30],
    }),
    [showLast60],
  );

  const filter = useMemo(() => {
    return {
      haveInitial: true,
      defaultValue: {
        count: behavior.itemsPerPage[0],
        page: 1,
        search: "",
        sortField: "",
        sortDirection: "ASC",
      } as ITableFilter,
      value: {
        count: userListParams.pageSize,
        page: userListParams.pageIndex,
        search: "",
        sortField: "",
        sortDirection: "ASC",
      } as ITableFilter,
    };
  }, [userListParams, behavior]);

  const onFilter = useCallback(
    (filterOption: ITableFilter, _changeType: FilterChangesType) => {
      useLoginRecordsParamsController.action({
        page: {
          pageSize: filterOption.count,
          pageIndex: filterOption.page,
        },
      });
    },
    [useLoginRecordsParamsController],
  );

  const last60Click = () => {
    setShowLast60({ last60: false, gagination: true });
  };

  const [loading, setLoading] = useState(false);
  const clearSessionClick = async () => {
    setLoading(true);
    try {
      const refreshToken = getRefreshTokenFromStorage() || "";
      const res = await clearSession({
        refreshToken,
      });
      if (res.code === 200) {
        toastNotification.success(t("userSettings.clearSession"), { autoClose: false });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SettingBox>
      <CardHeader>
        {t("userSettings.sessions")}
        <MyButton onClick={clearSessionClick} loading={loading}>
          {t("userSettings.clearAllSessions")}
        </MyButton>
      </CardHeader>
      <CardBody bordercolor="#62cb31" display="block">
        <NewTable
          status={loginRecordsController.status}
          data={tableDate}
          columns={tableColumn}
          name="user-settings"
          total={total}
          behavior={behavior}
          selected={{ haveCheckbox: false }}
          filter={filter}
          onFilter={onFilter}
        />
        {showLast60.last60 && (
          <ColumnLink
            style={{ marginTop: "10px" }}
            fontSize="13px"
            underline={true}
            fontWeight="400"
            float="right"
            onClick={last60Click}
          >
            {t("userSettings.showLast60")}
          </ColumnLink>
        )}
      </CardBody>
    </SettingBox>
  );
});

const MyButton = styled(NewButton)`
  float: right;
`;
