import { faCamera, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICategoricalTagsSelectOption, NewCheckbox } from "@helium10/re-ui-components";
import { useWickAccountsStore } from "@walmart10/container/core/store/wickAccountsStore";
import { useWickUserInfoStore } from "@walmart10/container/core/store/wickUserInfoStore";
import { format, set } from "date-fns";
import { ChangeEvent, memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { useRedirect } from "@/core/hooks/useRedirect";

import { appRoutes } from "../../../core/links";
import { useTranslation } from "../../../i18n/useTranslation";
import { useUserSettingsStore } from "../store";
import {
  CardBody,
  CardHeader,
  ColumnLink,
  getFilenameWithoutExtension,
  getIconPath,
  MyBaseSingleSelect,
  ProfileInfo,
  SettingBox,
} from "./Common";
import { IconModal } from "./IconModal";
import { ProfileModal } from "./ProfileModal";
import { Spinner } from "./Spinner";

const DATE_FORMAT = "MMM dd, yyyy";
const startMoment = { hours: 0, minutes: 0, seconds: 0 };
const modules: Record<string, any> = import.meta.glob("../../../static/animals/*.svg", {
  eager: true,
});
const iconList = Object.keys(modules).map((key) => {
  const path = modules[key].default;
  const name = getFilenameWithoutExtension(key);
  return { path, name };
});

export const ProfileBox = memo(() => {
  const {
    useStore,
    profileInfoSelector,
    useProfileInfoParamsController,
    isReceiveController,
    useReceiveSwagParamsController,
    isReceiveSelector,
    useProfileModalController,
    useSendUserActiveEmailsController,
    useUserInfoParamsController,
    isEmailValidatedSelector,
    useChangeDefaultAccountController,
  } = useUserSettingsStore();

  const userInfoController = useUserInfoParamsController();
  const emailController = useSendUserActiveEmailsController();
  const receiveController = useReceiveSwagParamsController();
  const changeDefaultAccountController = useChangeDefaultAccountController();
  const [profileOpen] = useProfileModalController();
  const userInfo = useWickUserInfoStore((s) => s.userInfo);
  const accounts = useWickAccountsStore((s) => s.availableAccounts);
  const adminInfo = accounts.filter((account) => account.isSuperAdmin)[0] || {};
  const profile = useStore(profileInfoSelector);
  const emailValidatedType = useStore(isEmailValidatedSelector);
  const { getPath, redirect } = useRedirect();
  const [iconsOpen, setIconsOpen] = useState(false);

  const isReceive = useStore(isReceiveSelector);

  const giftsChange = (event: ChangeEvent<{ checked: boolean }>) => {
    const isopen = event.target.checked;
    if (isopen && !profile.results?.addressInfo.name) {
      isReceiveController.patch({
        needUpdate: true,
      });
      // 如果没有 Address 信息，则弹出设置框
      useProfileModalController.open();
    } else {
      useReceiveSwagParamsController.action(isopen);
    }
  };

  const [accountValue, setAccountValue] = useState<string | number>("");
  const [accountOptions, setAccountOptions] = useState<ICategoricalTagsSelectOption[]>([]);

  /** 邮箱验证函数 */
  const emailConfirmClick = async () => {
    useSendUserActiveEmailsController.action({
      email: userInfo?.mail || "",
      userId: userInfo?.userId || 0,
      type: 7,
      website: "Wick",
    });
  };
  const handleEmailChange = useCallback(() => {
    redirect(getPath(appRoutes.codeVerification), {
      from: "settings",
      e: encodeURIComponent(userInfo?.mail || ""),
    });
  }, [redirect, getPath, userInfo]);
  const handlePWDChange = useCallback(() => {
    redirect(getPath(appRoutes.changePassword), {
      p: encodeURIComponent(userInfo?.mail || ""),
    });
  }, [redirect, getPath, userInfo]);

  useEffect(() => {
    if (!accounts) {
      setAccountOptions([]);
      setAccountValue("");
    } else {
      const map = [];
      for (let i = 0; i < accounts.length; i++) {
        const user = accounts[i];
        const item = {
          label: user.name,
          value: user.userId,
        };
        if (user.isDefaultAccount) {
          setAccountValue(item.value);
        }
        map.push(item);
      }
      setAccountOptions(map);
    }
  }, [accounts]);

  const setIconName = (avatar: string) => {
    profile.results &&
      useProfileInfoParamsController.set({
        results: {
          ...profile.results,
          avatar,
        },
      });
  };

  const getInfoDate = useCallback(() => {
    if (!userInfo?.addTime) {
      return "--";
    }
    return format(set(new Date(userInfo.addTime), startMoment), DATE_FORMAT);
  }, [userInfo]);

  const changeDefaultAccount = useCallback(async (value: string | number) => {
    if (value) {
      useChangeDefaultAccountController.action({ UserId: value });
    }
  }, []);

  useEffect(() => {
    const accountBeenSaved = changeDefaultAccountController.payload?.UserId;
    if (accountBeenSaved && changeDefaultAccountController.status === "loaded") {
      setAccountValue(accountBeenSaved);
    }
  }, [changeDefaultAccountController.status]);

  const { t } = useTranslation();
  return (
    <SettingBox>
      <CardHeader>{t("userSettings.profile")}</CardHeader>
      <CardBody>
        <AccountSwitcher>
          {accountOptions.length > 1 ? (
            <>
              <MySelectTitle>{t("userSettings.changeAccount")}</MySelectTitle>
              <MyBaseSingleSelect
                disabled={changeDefaultAccountController.status === "loading"}
                value={accountValue}
                options={accountOptions}
                dropdownAutoWidth={true}
                onChange={(value) => changeDefaultAccount(value.value)}
              ></MyBaseSingleSelect>
            </>
          ) : null}
        </AccountSwitcher>
        <ProfileHead>
          <ProfilePhoto>
            <ProfilePhotoImg
              src={getIconPath(iconList, profile.results?.avatar || "profile-default")?.path}
            />
            <ProfilePhotoHovered onClick={() => setIconsOpen(true)}>
              <FontAwesomeIcon icon={faCamera} />
            </ProfilePhotoHovered>
          </ProfilePhoto>
          <ProfileNameInfo>
            <ProfileName>{adminInfo.name}</ProfileName>
            <ProfileSince>
              {t("userSettings.memberSince")}
              {getInfoDate()}
            </ProfileSince>
          </ProfileNameInfo>
        </ProfileHead>
        <ProfileInfo height="max-content">
          <Column>
            <ColumnHeading>
              {t("userSettings.addressPhone")} (
              <ColumnLink onClick={() => useProfileModalController.open()}>
                {!profile.results?.addressInfo.name
                  ? t("userSettings.clickHere")
                  : t("userSettings.change")}
              </ColumnLink>
              )
            </ColumnHeading>
            {!profile.results?.addressInfo.name ? (
              <ColumnSub>{t("userSettings.notset")}</ColumnSub>
            ) : (
              <>
                <ColumnSub>{profile.results?.addressInfo.name}</ColumnSub>
                <ColumnSub>{profile.results?.addressInfo.address}</ColumnSub>
                <ColumnSub>{profile.results?.addressInfo.district}</ColumnSub>
                <ColumnSub>{profile.results?.addressInfo.province}</ColumnSub>
                <ColumnSub>{profile.results?.addressInfo.post}</ColumnSub>
                <ColumnSub>{profile.results?.addressInfo.nation}</ColumnSub>
                <ColumnSub>{profile.results?.addressInfo.phone}</ColumnSub>
              </>
            )}

            <GiftsCheckbox
              checked={isReceive.value}
              onChange={giftsChange}
              disabled={receiveController.status === "loading"}
            >
              {t("userSettings.receiveGifts")}
            </GiftsCheckbox>
          </Column>
          <Column>
            <ColumnHeading>
              {t("userSettings.primaryEmail")} (
              <ColumnLink style={{ color: "#337ab7" }} onClick={handleEmailChange}>
                {t("userSettings.change")}
              </ColumnLink>
              )
            </ColumnHeading>
            {userInfo?.mail && (
              <ColumnSub>
                {userInfo.mail}
                {emailValidatedType?.isEmailValidated ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "#63E6BE", verticalAlign: "middle", marginLeft: "6px" }}
                  />
                ) : emailController.status === "loading" ||
                  userInfoController.status === "loading" ? (
                  <Spinner style={{ marginLeft: "6px" }} />
                ) : (
                  <>
                    {"("}
                    <ColumnLink style={{ color: "#337ab7" }} onClick={emailConfirmClick}>
                      {t("userSettings.confirm")}
                    </ColumnLink>
                    {")"}
                  </>
                )}
              </ColumnSub>
            )}
          </Column>
          <Column>
            <ColumnHeading>
              {t("userSettings.password")} (
              <ColumnLink style={{ color: "#337ab7" }} onClick={handlePWDChange}>
                {t("userSettings.change")}
              </ColumnLink>
              )
            </ColumnHeading>
            <ColumnSub>**********</ColumnSub>
          </Column>
        </ProfileInfo>
      </CardBody>
      <ProfileModal
        isOpen={profileOpen}
        onClose={useProfileModalController.close}
        profile={profile}
      ></ProfileModal>
      <IconModal
        isOpen={iconsOpen}
        onClose={() => setIconsOpen(false)}
        userIcon={profile.results?.avatar || "profile-default"}
        setIconName={setIconName}
        iconList={iconList}
      ></IconModal>
    </SettingBox>
  );
});

const MySelectTitle = styled.span`
  padding: 0 32px 0 8px;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
`;

const GiftsCheckbox = styled(NewCheckbox)`
  height: 13px;
  line-height: 13px;
  margin-top: 0.5rem;
  > span:nth-child(2) {
    height: 13px;
    width: 13px;
    min-width: 13px;
    margin-top: 0;
    > svg {
      transform: scale(0.6);
    }
  }
  > span:nth-child(3) {
    font-size: 13px;
    font-weight: 700;
    color: #6a6c6f;
    line-height: 13px;
  }
`;

const ColumnSub = styled.p`
  margin-bottom: 0;
  margin-top: 0;
  font-size: 13px;
`;

const ColumnHeading = styled.h5`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
`;

const Column = styled.div`
  padding-bottom: 0.5rem;
  padding: 0 15px;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  min-height: 80.59px;
  height: max-content;
  &:not(:last-child) {
    border-right: 1px solid;
  }
`;

const ProfileSince = styled.div`
  margin: 0;
  font-size: 13px;
  font-weight: 400;
`;

const ProfileName = styled.h2`
  font-size: 30px;
  font-weight: 400;
  margin: 5px 0 0;
  min-height: 41px;
`;

const ProfileNameInfo = styled.div``;

const ProfilePhotoHovered = styled.div.attrs({
  className: "profile-photo-hovered",
})`
  width: 100%;
  height: 40%;
  position: absolute;
  top: 100%;
  transition: transform 150ms ease;
  color: #ddd;
  font-size: 22px;
  text-align: center;
  padding-top: 5px;
  background-color: rgba(0, 0, 0, 0.65);
`;

const ProfilePhotoImg = styled.img`
  width: 100px;
  height: 100px;
`;

const ProfilePhoto = styled.div`
  height: 100px;
  width: 100px;
  overflow: hidden;
  margin-right: 24px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  &:hover {
    .profile-photo-hovered {
      transform: translateY(-40px);
    }
  }
`;

const ProfileHead = styled.div`
  margin-bottom: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const AccountSwitcher = styled.div`
  float: right;
`;
