import { datadogRum } from "@datadog/browser-rum";
import { declareAction, declareRequest, map, membersLink, useRouterStore } from "@helium10/re-core";
import { add } from "date-fns";
import { cloneDeep } from "lodash-es";
import queryString from "query-string";
import { create } from "zustand";

import { sendSegmentEventRequest } from "@/core/gtm/gtmStore";
import { deleteCookie, setCookie } from "@/core/helpers/cookies";
import type { IRejected } from "@/core/rejectReaction";
import { rejectReaction } from "@/core/rejectReaction";
import type {
  IAdtomicCustomer,
  IAdtomicCustomerProperties,
  IAdtomicCustomerResponse,
} from "@/requests/customers/getCustomerAdtomic";
import { getAdtomicCustomer } from "@/requests/customers/getCustomerAdtomic";
import type {
  ICustomer,
  ICustomerHelium10Response,
  IInvoice,
} from "@/requests/customers/getCustomerHelium10";
import { getCustomerHelium10, initialCustomer } from "@/requests/customers/getCustomerHelium10";
import type {
  IExtendedUpdateCustomerProperties,
  IUpdateCustomerProperties,
} from "@/requests/customers/updateCustomer";
import { updateCustomer } from "@/requests/customers/updateCustomer";

import { accessRequest, isVoidResult } from "./accessStore";
import { accountAtom } from "./accountsStore";
import {
  getStartedStateSelector,
  setAcceptance,
  subscriptionsRequest,
  useSubscriptionsStore,
} from "./subscriptionsStore";

export const customerRequest = declareRequest<string, ICustomerHelium10Response>(
  "customer",
  getCustomerHelium10,
  {
    fulfilledReaction: ({ params: accountId, result: { results } }, { dispatch }) => {
      useCustomerStore.getState().setHeliumCustomer(results);
      dispatch(adtomicCustomerRequest.actions.pending(accountId));
      if (
        ["production", "stage", "pvu-stage", "pvu-prod"].includes(process.env.REACT_APP_STATE || "")
      ) {
        datadogRum.addRumGlobalContext("usr", {
          id: results.id,
          email: results.email,
        });
      }
    },
    initialStatus: "loading",
  },
);

export const customerSelector = map(
  "customerSelector",
  customerRequest.atom,
  ({ content }): ICustomer => ({
    ...(content?.results || initialCustomer),
  }),
);

export const customerSelectorInvoice = map(
  "customerSelectorInvoice",
  customerSelector,
  (
    content,
  ):
    | (Omit<IInvoice, "gracePeriodBy" | "invoiceDueDate"> & {
        gracePeriodBy: Date;
        invoiceDueDate: Date;
      })
    | null => {
    const invoice: IInvoice | undefined = content.invoices
      .reverse()
      .find((inv) => inv.invoiceStatus === "open");
    return invoice
      ? {
          ...invoice,
          gracePeriodBy: new Date(invoice["gracePeriodBy"] * 1000),
          invoiceDueDate: new Date(invoice["invoiceDueDate"] * 1000),
        }
      : null;
  },
);

export const updateCustomerActions =
  declareAction<Partial<IAdtomicCustomerProperties>>("updateCustomerActions");

export enum BILLING_TERMS_ACCEPTANCE_STATE {
  ACCEPTED = "ACCEPTED",
  DEFERRED = "DEFERRED",
  NEVER_PRESENTED = "NEVER_PRESENTED",
}

export type BILLING_TERMS_ACCEPTANCE_STATES_TYPE =
  | BILLING_TERMS_ACCEPTANCE_STATE.ACCEPTED
  | BILLING_TERMS_ACCEPTANCE_STATE.DEFERRED
  | BILLING_TERMS_ACCEPTANCE_STATE.NEVER_PRESENTED;

export const adtomicCustomerRequest = declareRequest<string, IAdtomicCustomerResponse>(
  "adtomicCustomer",
  async () => {
    const result = await getAdtomicCustomer();

    const advanced_features = overrideAdvancedFeatures(
      result.data?.advanced_features,
      result.data?.hasPacvueProfiles,
    );

    return {
      ...result,
      data: {
        ...result.data,
        advanced_features,
      },
    };
  },
  {
    fulfilledReaction: ({ params: accountId, result }, { getState, dispatch }) => {
      setCookie("engine", "pvu", { expires: add(new Date(), { years: 5 }), "max-age": 36000000 });
      const accessResult = getState(accessRequest.atom);
      if (
        result?.data.access_level !== CustomerAccessLevels.Full ||
        (!Object.values(result?.data.tokenInfo || {}).includes(true) &&
          (accessResult?.status === "error" || isVoidResult(accessResult.content?.results)))
      )
        return false;

      dispatch(subscriptionsRequest.actions.pending(accountId));

      const link = window.location.pathname;

      if (process.env.NODE_ENV === "production") {
        if (
          link.match(/\d+\/campaigns/) &&
          (!result.data?.advanced_features?.properties.isReactCampaignsAvailable ||
            !result.data?.advanced_features?.properties.isReactCampaignsEnabled)
        ) {
          deleteCookie("react_app_campaigns");
          window.location.reload();
        } else if (
          (link.includes("analytics") ||
            link.includes("dashboard") ||
            link.includes("changelog")) &&
          ["production", "stage"].includes(process.env.REACT_APP_STATE || "") &&
          !result.data?.advanced_features?.properties.isReactEnabled
        ) {
          if (!result?.data.hasPacvueProfiles) {
            deleteCookie("react_app");
            window.location.reload();
          }
        } else if (
          link.includes("suggestions") &&
          !result.data?.advanced_features?.properties.isReactSuggestionsEnabled
        ) {
          deleteCookie("react_app_suggestions");
          window.location.reload();
        } else if (
          link.includes("campaign-builder") &&
          !result.data?.advanced_features?.properties.isReactBuilderEnabled
        ) {
          deleteCookie("react_app_builder");
          window.location.reload();
        }
      }
    },
    rejectedReaction: (payload, store) => {
      // store.dispatch(someErrorModalStore.open());
      rejectReaction(payload, store);
    },
    reducers: (on) => [
      on(updateCustomerActions, (data, options) => {
        if (data.content) {
          return {
            ...data,
            content: {
              ...data.content,
              data: {
                ...data.content.data,
                advanced_features: {
                  ...data.content.data.advanced_features,
                  properties: { ...data.content.data.advanced_features.properties, ...options },
                },
              },
            },
          };
        } else {
          return { ...data };
        }
      }),
    ],
    initialStatus: "loading",
  },
);

export const CustomerAccessLevels = {
  Full: "full",
  LimitedDashboard: "limited_dashboard",
  None: "none",
};

export type ICustomerAccessLevelsType =
  (typeof CustomerAccessLevels)[keyof typeof CustomerAccessLevels];

export const accessSelector = map("accessSelector", adtomicCustomerRequest.atom, ({ content }) => {
  return content?.data.access_level || CustomerAccessLevels.None;
});

export const adtomicCustomerSelector = map(
  "adtomicCustomerSelector",
  adtomicCustomerRequest.atom,
  ({ content }): IAdtomicCustomer => ({
    ...(content?.data || initialAdtomicCustomer),
  }),
);

export const enableAdtomicBreakAction = {
  [CustomerAccessLevels.Full]: declareAction<BILLING_TERMS_ACCEPTANCE_STATES_TYPE>(
    "enableAdtomicActionFull",
    async (status, { dispatch }) => {
      dispatch(setAcceptance.actions.pending({ status }));
    },
  ),
  [CustomerAccessLevels.LimitedDashboard]: declareAction<BILLING_TERMS_ACCEPTANCE_STATES_TYPE>(
    "enableAdtomicActionLimited",
    async (_, { dispatch }) => {
      dispatch(
        sendSegmentEventRequest.actions.pending({
          event_name: "Adtomic Platinum Upsell",
        }),
      );
    },
  ),
  [CustomerAccessLevels.None]: declareAction<BILLING_TERMS_ACCEPTANCE_STATES_TYPE>(
    "enableAdtomicActionNone",
    async (_, { dispatch }) => {
      dispatch(
        sendSegmentEventRequest.actions.pending({
          event_name: "Adtomic Free Upsell",
        }),
      );
    },
  ),
};

export const enableAdtomicAction = declareAction<BILLING_TERMS_ACCEPTANCE_STATES_TYPE>(
  "enableAdtomicAction",
  async (status, { getState, dispatch }) => {
    const accessLevel = getState(accessSelector);
    const action = enableAdtomicBreakAction[accessLevel];
    dispatch(action?.(status));
    dispatch(subscribeGetStartedAction());
  },
);

export const subscribeGetStartedAction = declareAction(
  "subscribeGetStartedAction",
  (_, { subscribe, getState, dispatch }) => {
    const { accountId } = getState(accountAtom);
    const navigate = useRouterStore.getState().navigate;
    const accessLevel = getState(accessSelector);
    const unsubscribe = subscribe(getStartedStateSelector, (state) => {
      if (state) {
        accessLevel === CustomerAccessLevels.None
          ? (window.location.href = `${membersLink}/subscribe?accountId=${accountId}`)
          : navigate?.(`/panel/${accountId}`);
        dispatch(subscriptionsRequest.actions.pending(accountId));
        window.location.reload();
        unsubscribe();
      }
    });
  },
);

export const updateReactEnableRequest = declareRequest<
  IExtendedUpdateCustomerProperties,
  IRejected
>(
  "updateReactEnableRequest",
  async (params: IExtendedUpdateCustomerProperties) => {
    return updateCustomer(params);
  },
  {
    initialStatus: "init",
    fulfilledReaction: (payload, { dispatch }) => {
      const navigate = useRouterStore.getState().navigate;

      if (payload.params.isReactEnabled === "false") {
        navigate?.(
          queryString.stringifyUrl({
            url: window.location.pathname,
          }),
        );
        deleteCookie("react_app");
        window.location.reload();
      }

      if (payload.params.isReactCampaignsEnabled === "false") {
        navigate?.(
          queryString.stringifyUrl({
            url: window.location.pathname,
          }),
        );
        deleteCookie("react_app_campaigns");
        window.location.reload();
      } else if (payload.params.isReactSuggestionsEnabled === "false") {
        navigate?.(
          queryString.stringifyUrl({
            url: window.location.pathname,
          }),
        );
        deleteCookie("react_app_suggestions");
        window.location.reload();
      } else if (payload.params.isReactBuilderEnabled === "false") {
        navigate?.(
          queryString.stringifyUrl({
            url: window.location.pathname,
          }),
        );
        deleteCookie("react_app_builder");
        window.location.reload();
      }
      const { close, atom } = useSubscriptionsStore.getState().noActivePPCAccountsModal;
      atom.isOpen && close();
      dispatch(updateCustomerActions(convertParamsToProperties(payload.params)));
    },
    rejectedReaction: (payload, store) => {
      rejectReaction(payload, store);
    },
  },
);

export const convertParamsToProperties = (
  params: IUpdateCustomerProperties,
): Partial<IAdtomicCustomerProperties> => {
  const properties: Partial<IAdtomicCustomerProperties> = {};
  (Object.keys(params) as (keyof IUpdateCustomerProperties)[]).forEach((key) => {
    properties[key] = params[key] === "true";
  });
  return properties;
};

interface ICustomerState {
  heliumCustomer: ICustomer;
  setHeliumCustomer: (value: ICustomer) => void;
}

export const useCustomerStore = create<ICustomerState>((set) => ({
  heliumCustomer: initialCustomer,
  setHeliumCustomer: (heliumCustomer) => set({ heliumCustomer }),
}));

// export const showForNewUserSelector = map(
//   adtomicCustomerRequest.atom,
//   ({ content, status }): boolean => {
//     const billingTime = content?.data.billing_terms_acceptance_time;
//     const amazonExperienceFrom = content?.data.amazon_advertising_experience_track_from;
//     const isValid =
//       billingTime && amazonExperienceFrom && new Date(billingTime) > new Date(amazonExperienceFrom);
//
//     return !!isValid && status === "loaded";
//   },
// );

const removeUrlParam = (sourceURL: string, key: string) => {
  let rtn = sourceURL.split("?")[0],
    param,
    params_arr = [];
  const queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (let i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
};

const overrideAdvancedFeatures = (
  properties: {
    access_level: ICustomerAccessLevelsType;
    tester: boolean;
    properties: IAdtomicCustomerProperties;
  },
  hasPacvueProfiles: boolean,
) => {
  const clonedProperties = cloneDeep(properties);
  if (hasPacvueProfiles) {
    const { properties } = clonedProperties;
    clonedProperties.properties = {
      ...properties,
      isReactEnabled: true,
      isReactBuilderEnabled: true,
      isReactCampaignsEnabled: true,
      isReactSuggestionsEnabled: true,
    };
  }
  return clonedProperties;
};

export const initialAdtomicCustomer: IAdtomicCustomer = {
  advanced_features: {
    access_level: CustomerAccessLevels.Full,
    tester: false,
    properties: {
      isReactAvailable: false,
      isReactEnabled: false,
      isReactDisableAlertShown: false,
      isReactAvailableAlertShown: false,
      isReactCampaignsAvailable: false,
      isReactCampaignsEnabled: false,
      isReactCampaignsWelcomeShown: false,
      isReactCampaignsTooltipShown: false,
      isReactSuggestionsAvailable: false,
      isReactSuggestionsEnabled: false,
      isReactSuggestionsWelcomeShown: false,
      isReactSuggestionsTooltipShown: false,
      isReactBuilderAvailable: false,
      isReactBuilderEnabled: false,
      isReactBuilderWelcomeShown: false,
      isReactBuilderAlertShown: false,
      isBulkActionsAvailable: false,
      isReactOnly: null,
      isSchedulesAvailable: false,
      isOnboardingAdManagerBuilderTooltipShown: false,
      isOnboardingAdManagerRuleTooltipShown: false,
      isOnboardingBuilderCreationTooltipShown: false,
      isOnboardingRuleCreationTooltipShown: false,
      isOnboardingBannerShown: false,
      showQuickActionsWarning: false,
      isPortfolioManageable: false,
      isAdtomicOnboardingEnabled: true,
    },
  },
  access_level: CustomerAccessLevels.Full,
  tester: false,
  created_at: "",
  email: "",
  id: 0,
  last_selected_profile_id: "",
  last_visit_at: 0,
  store_name: "",
  updated_at: "",
  username: "",
  refund_available: false,
  billing_terms_acceptance_state: BILLING_TERMS_ACCEPTANCE_STATE?.ACCEPTED,
  billing_terms_acceptance_time: "",
  amazon_advertising_experience_track_from: "",
  hasPacvueProfiles: false,
  tokenInfo: {
    amazonAvailable: false,
    googleAvailable: false,
    walmartAvailable: false,
  },
};
