import { combineStatuses } from "@helium10/re-core";
import { StatusSwitcher } from "@helium10/re-ui-components";
import { useWickAccountsController } from "@walmart10/container/core/store/wickAccountsStore";
import { useWickUserInfoController } from "@walmart10/container/core/store/wickUserInfoStore";
import { memo, useEffect } from "react";

import { useLoginAction } from "../../core/hooks/useLoginAction";
import { AuthenticationBox } from "./components/AuthenticationBox";
import { Content } from "./components/Content";
import { ProfileBox } from "./components/ProfileBox";
import { SessionsBox } from "./components/SessionsBox";
import { useDefault, UserSettingsProvider, useUserSettingsStore } from "./store";
const Context = () => {
  const wickAccountsController = useWickAccountsController();
  const wickUserInfoController = useWickUserInfoController();
  useLoginAction();
  const {
    useProfileInfoParamsController,
    useForceStatusParamsController,
    isEmailValidatedController,
  } = useUserSettingsStore();
  useEffect(() => {
    if (wickAccountsController.status === "loaded" && wickUserInfoController.status === "loaded") {
      useProfileInfoParamsController.action({});
      useForceStatusParamsController.action({});
      isEmailValidatedController.action({});
    }
  }, [wickAccountsController.status, wickUserInfoController.status]);
  const combineStatus = combineStatuses([
    wickAccountsController.status,
    wickUserInfoController.status,
  ]);
  return (
    <StatusSwitcher status={combineStatus}>
      <Content>
        <ProfileBox />
        <AuthenticationBox />
        <SessionsBox />
      </Content>
    </StatusSwitcher>
  );
};

export const UserSettings = memo(() => {
  const value = useDefault();
  return (
    <>
      <UserSettingsProvider value={value}>
        <Context />
      </UserSettingsProvider>
    </>
  );
});
