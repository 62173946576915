import { IFeatureCreator, leitenFeature, leitenRecord } from "@helium10/re-core";
import _ from "lodash-es";
import { create } from "zustand";

interface IDemoDashBoard {
  name: string;
}

interface IState {
  userInfo: {
    name: string;
    age: number;
  };
}

export type IDemoDashBoardFeature = IFeatureCreator<IDemoDashBoard>;

export const [useDemoDashBoard, DemoDashBoardProvider] = leitenFeature(
  ({ useProps }: IDemoDashBoardFeature) => {
    const useStore = create<IState>(() => ({
      userInfo: {
        name: useProps.getState().name,
        age: 1,
      },
    }));

    const editController = leitenRecord(useStore, "userInfo");

    const infoSelector = (state: IState) => {
      return {
        age: state.userInfo.age,
        name: state.userInfo.name,
        nickname: state.userInfo.name + state.userInfo.age,
      };
    };

    return {
      editController,
      infoSelector,
      useStore,
      useProps,
    };
  },
);
