import styled from "styled-components";

import type { IPaymentMethods } from "../../../common/types/IPaymentMethods";
import { translateErrors } from "../../../common/utils/translateErrors";
import { useTranslation } from "../../../i18n/useTranslation";
import { Errors } from "../Styles";

interface IErrorMessages {
  type: IPaymentMethods;
  message: string;
}

export const ErrorMessages = ({ type, message }: IErrorMessages) => {
  const { t } = useTranslation();
  const { [type]: ErrorNode } = {
    card: CardErrors,
    alipay: AlipayErrors,
    apple_pay: Errors,
  };

  return <ErrorNode>{translateErrors(t, message)}</ErrorNode>;
};

const CardErrors = styled(Errors)`
  margin: ${(p) => `${p.theme.spacing.sp24} 0 0`};
`;

const AlipayErrors = styled(Errors)`
  margin: ${(p) => `${p.theme.spacing.sp24} 0`};
`;
