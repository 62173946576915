import { InputErrorMessage, NewButton, NewInput } from "@helium10/re-ui-components";
import { memo, useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";

import { useRedirect } from "@/core/hooks/useRedirect";

import { appRoutes } from "../../../core/links";
import { useTranslation } from "../../../i18n/useTranslation";
import walmartlogoSrc from "../../../static/icons/walmartlogo.svg";
import { IForgotPassword } from "../requests/index";
import { RecoveryProvider, useRecoveryStore } from "../store/index";

export const PasswordRecoveryForm = memo(() => {
  const value = {
    email: "",
  };

  return (
    <RecoveryProvider value={value}>
      <Context />
    </RecoveryProvider>
  );
});

export const Context = memo(() => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPassword>();

  const { getPath, redirect } = useRedirect();
  const { useRecoveryController } = useRecoveryStore();
  const controller = useRecoveryController();

  const handleRouteChange = useCallback(() => {
    redirect(getPath(appRoutes.signIn));
  }, [redirect, getPath]);

  const JumpToSignIn = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    handleRouteChange();
  };

  // 添加状态变量
  const [messageVisible, setMessageVisible] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState("");

  const onSubmit: SubmitHandler<IForgotPassword> = (data) => {
    useRecoveryController.action(data);
  };

  useEffect(() => {
    if (controller.status === "loaded") {
      setMessageVisible(true);
      setSubmittedEmail(controller.payload?.email || "");
    }
  }, [controller.status]);

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <SignUpFormWrapperTitle>{t("passwordRecovery.title")}</SignUpFormWrapperTitle>
      <ContainerLogo href="https://www.helium10.com/">
        <img src={walmartlogoSrc} alt="Helium 10" />
      </ContainerLogo>
      {!messageVisible && (
        <>
          <FormGroup>
            <SignUpFormLabel htmlFor="email">{t("common.email")}</SignUpFormLabel>
            <NewInput
              id="email"
              size={"400"}
              placeholder={t("passwordRecovery.emailPlaceholder")}
              {...register("email", {
                required: t("common.emailCheck"),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i,
                  message: t("common.emailCheck2"),
                },
              })}
            />
            {errors.email && <InputErrorMessage>{String(errors.email.message)}</InputErrorMessage>}
          </FormGroup>
          <NewButtonWrapper
            size="400"
            buttonType={"primary"}
            type="submit"
            loading={controller.status === "loading"}
          >
            <NewButtonText>{t("passwordRecovery.resetPassword")}</NewButtonText>
          </NewButtonWrapper>
          <NewButtonWrapper1 size="400" buttonType={"secondary"} onClick={JumpToSignIn}>
            <NewButtonText>{t("common.login")}</NewButtonText>
          </NewButtonWrapper1>
        </>
      )}

      {/* 显示消息 */}
      {messageVisible && (
        <LoginFormMessage>
          {t("passwordRecovery.sendEmail")}{" "}
          <LoginFormMessageBold>{submittedEmail}</LoginFormMessageBold>.{" "}
          {t("passwordRecovery.checkEmail")}
        </LoginFormMessage>
      )}
    </FormWrapper>
  );
});

const FormWrapper = styled.form`
  width: 410px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.sp20};
`;

const ContainerLogo = styled.a`
  width: 200px;
  margin-bottom: 24px;

  & > img {
    display: block;
    width: 100%;
  }
`;

const SignUpFormWrapperTitle = styled.h3`
  /* font-family: "Neuton", serif; */
  /* font-size: 44px; */
  font-size: 38px;
  font-weight: 400;
  letter-spacing: 2px;
  text-align: center;
  color: #003873;
  margin: 0px auto 24px;
  white-space: nowrap;
`;

const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const SignUpFormLabel = styled.label`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.375;
  color: #646464;
  margin-bottom: 8px;
  display: inline-block;
`;

const NewButtonWrapper = styled(NewButton)`
  width: 410px;
  margin-top: 16px;
  color: #ede8e4;
`;

const NewButtonWrapper1 = styled(NewButtonWrapper)`
  color: #003873;

  &:hover {
    color: #ede8e4;
    background-color: #0081ff;
  }
`;

const NewButtonText = styled.span`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.375;
`;

const LoginFormMessage = styled.div`
  padding: 28px 18px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #343a40;
  border-radius: 8px;
  background-color: #ede8e4;
`;

const LoginFormMessageBold = styled.b`
  font-weight: bolder;
`;
