import { baseFetch } from "@helium10/re-core";

import { segmentEventLink } from "../consts/constants";

// import { segmentEventLink, testDomain } from "./constants";

interface SegmentEventDataRequest {
  properties?: Record<string, string | string[]>;
  name: string;
}

interface SegmentEventData {
  properties?: Record<string, string | string[]>;
  name: string;
}

export const sendSegmentEvent = (data: SegmentEventDataRequest) => {
  // url: testDomain + segmentEventLink,
  return baseFetch<SegmentEventData, void>({
    method: "POST",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
    url: segmentEventLink,
    params: {
      name: data.name,
      properties: data?.properties,
    },
  });
};
