import { Skeleton } from "@helium10/re-ui-components";
import styled from "styled-components";

import { Container } from "../../Styles";

export const SkeletonDescription = () => {
  return (
    <Container>
      <Description />
    </Container>
  );
};

const Description = styled(Skeleton)`
  height: 40px;
  width: 200px;
`;
