import type { ICreateRequest } from "@helium10/re-core";
import { createSlice, declareRequest, useRouterStore } from "@helium10/re-core";
import { toastNotification } from "@helium10/re-ui-components";
import TagManager from "react-gtm-module";
import type { Location } from "react-router-dom";
import { create } from "zustand";

import { useTranslationStore } from "@/i18n/translationStore";
import type { IGTMResponse } from "@/requests/log/getGTMInfo";
import { getGTMInfo } from "@/requests/log/getGTMInfo";
import type { SegmentEvent } from "@/requests/log/segmentEvent";
import { sendSegmentEvent } from "@/requests/log/segmentEvent";

interface IGTMLStore {
  GTMInfoRequest: ICreateRequest<void, IGTMResponse | null>;
}

export const useGTMStore = create<IGTMLStore>((set, get) => ({
  ...createSlice(
    set,
    get,
    "GTMInfoRequest",
    async (_: void) => {
      const data = await getGTMInfo();
      const results = data?.results;

      if (results) {
        const { firstName, email, createdAt, plan, ...rest } = results;
        return {
          ...rest,
          name: firstName,
          emailAddress: email,
          joinDate: createdAt,
          subscriptionLevel: plan,
          userId: String(rest.userId),
          lastLogin: String(rest.lastLogin),
          chromeExtensionLastSeenAt: Number(rest.chromeExtensionLastSeenAt),
        } as IGTMResponse;
      } else {
        return null;
      }
    },
    {
      fulfilledReaction: (result) => {
        if (result) {
          TagManager.initialize({
            gtmId: "GTM-5QPG5B",
            dataLayer: result,
          });
          const { location } = useRouterStore.getState();
          const userId = result.userId;
          if (location) {
            TagManager.dataLayer({
              dataLayer: {
                event: "Page View",
                userId,
                "content-name": location.pathname + location.search,
              },
            });
          }
        }
      },
    },
  ),
}));

export const setTagManager = (location: Location) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "Page View",
      userId: useGTMStore.getState().GTMInfoRequest.atom.content?.userId,
      "content-name": location.pathname + location.search,
    },
  });
};

export const sendSegmentEventRequest = declareRequest<SegmentEvent, void>(
  "sendSegmentEventRequest",
  (event: SegmentEvent) => sendSegmentEvent(event),
  {
    rejectedReaction: () => {
      const translate = useTranslationStore.getState().translation;
      toastNotification.error(translate?.t("errors.internalServer"));
    },
    initialStatus: "init",
  },
);
