import { cssDisplay200Bold, cssDisplay400Bold, media } from "@helium10/re-ui-components";
import styled from "styled-components";

import h10logo from "../../static/images/h10_logo.svg";
import icon from "../../static/images/thumbs-up-light-blue.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img.attrs({ src: icon })`
  display: flex;
  width: 70px;
  margin: ${(p) => `0 auto ${p.theme.spacing.sp16}`};

  ${media.desktop} {
    width: 100px;
  }
`;

export const LogoImage = styled.img.attrs({ src: h10logo })`
  display: flex;
  width: 192px;
  height: 70px;
  margin: ${(p) => `0 auto ${p.theme.spacing.sp8}`};
`;

export const Title = styled.h2`
  ${cssDisplay200Bold};
  text-align: center;

  ${media.desktop} {
    ${cssDisplay400Bold};
  }

  span {
    color: ${(p) => p.theme.systemColors.black};
  }
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  margin: 0;

  & + & {
    margin: ${(p) => `${p.theme.spacing.sp24} 0 0`};
  }
`;
