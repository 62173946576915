import { formatPrice, reCaptchaV2Callback } from "@helium10/re-core";
import { cssDisplay50, cssDisplay50Bold, media } from "@helium10/re-ui-components";
import { ReCaptchaV2 } from "recaptcha-v2v3";
import styled from "styled-components";

import { useTranslation } from "../../../../i18n/useTranslation";
import { Checkout } from "../../../checkout/Checkout";
import { Container } from "../../Styles";
import { useAlipay } from "../hooks/useAlipay";
import { useCard } from "../hooks/useCard";
import {
  paymentEditingController,
  paymentModeController,
  useCheckoutModalStore,
} from "../store/useCheckoutModalStore";
import { ActionsWithCard } from "./ActionsWithCard";
import { ActionsWithoutCard } from "./ActionsWithoutCard";
import { Disclaimer } from "./Disclaimer";
import { SecurePayment } from "./SecurePayment";

export const Content = () => {
  const { t } = useTranslation();

  const [isComplete, paymentMode, stripe, type, plan, paymentMethods, specifics] =
    useCheckoutModalStore((state) => [
      state.isComplete,
      state.paymentMode,
      state.stripe,
      state.data?.type,
      state.data?.plan,
      state.data?.paymentMethods,
      state.data?.specifics,
    ]);

  const isWithCard = ["subscribeModal", "subscribeModalCard"].includes(type as string);

  return (
    <Container>
      {isWithCard && (
        <Plan>
          {plan?.title && <Name>{plan?.title}</Name>}
          <Price>
            {plan?.amount && (
              <Amount>{formatPrice({ amount: plan?.amount, isRounded: true })}</Amount>
            )}
            {plan?.interval && <Period>/{t(`intervalShort.${plan?.interval}`)}</Period>}
          </Price>
        </Plan>
      )}
      <Options>
        <Checkout
          isCardAvailable={isWithCard}
          isModal
          data={{
            stripe,
            paymentMode,
            paymentMethods,
            paymentModeController,
            paymentEditingController,
          }}
          useCard={useCard}
          useAlipay={useAlipay}
          securePayment={<SecurePayment />}
          recaptchaV2={<ReCaptchaV2 callback={reCaptchaV2Callback} />}
          disclaimer={<Disclaimer />}
          modalText={specifics?.mainParagraph}
          actions={
            isWithCard ? (
              <ActionsWithCard
                isComplete={isComplete}
                buttonDefaultText={specifics?.ctaTitle}
                buttonSubmittingText={specifics?.processingTitle}
                buttonCompleteText={specifics?.successfulTitle}
              />
            ) : (
              <ActionsWithoutCard
                isComplete={isComplete}
                buttonDefaultText={specifics?.ctaTitle}
                buttonSubmittingText={specifics?.processingTitle}
                buttonCompleteText={specifics?.successfulTitle}
                buttonCancelText={specifics?.cancelTitle}
              />
            )
          }
        />
      </Options>
    </Container>
  );
};

const Plan = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${(p) => p.theme.systemColors.grey200};
  gap: ${(p) => p.theme.spacing.sp16};
  padding: ${(p) => p.theme.spacing.sp16};
  margin: ${(p) => `0 0 ${p.theme.spacing.sp24}`};

  ${media.desktop} {
    flex-direction: row;
  }
`;

const Name = styled.h4`
  ${cssDisplay50Bold};
`;

const Price = styled.div`
  ${cssDisplay50};
`;

const Amount = styled.span``;

const Period = styled.span``;

const Options = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
