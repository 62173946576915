import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DialogModal, NewButton, NewRadio } from "@helium10/re-ui-components";
import { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { useRedirect } from "@/core/hooks/useRedirect";

import { appRoutes } from "../../../core/links";
import { useTranslation } from "../../../i18n/useTranslation";
import { useUserSettingsStore } from "../store";
import { IModalProps } from "./Common";
import { CardBody, CardHeader, ProfileInfo, SettingBox } from "./Common";
enum FORCE_TYPE {
  MFA = "mfa",
  EMAIL = "email",
  DISABLE = "",
}

export const AuthenticationBox = memo(() => {
  const [nextOpen, setNextOpen] = useState(false);
  const [alreadyOpen, setAlready] = useState(false);
  const [factorType, setFactorType] = useState("");
  const { useStore, forceStatusSelector } = useUserSettingsStore();
  const forceStatus = useStore(forceStatusSelector);
  const { getPath, redirect } = useRedirect();
  const handleChange = useCallback(
    (from: string) => {
      redirect(getPath(appRoutes.confirmMethod), { from });
    },
    [redirect, getPath],
  );
  // const handleAuthenticator = useCallback(() => {
  //   redirect(getPath(appRoutes.authenticator));
  // }, [redirect, getPath]);
  useEffect(() => {
    setFactorType(forceStatus.data?.forceType || "");
  }, [forceStatus]);
  const factorTypeClick = () => {
    if (factorType === (forceStatus.data?.forceType || "")) {
      setAlready(true);
      return;
    }
    if (factorType === FORCE_TYPE.DISABLE) {
      setNextOpen(true);
    } else if (factorType === FORCE_TYPE.MFA) {
      handleChange("tfa");
    } else if (factorType === FORCE_TYPE.EMAIL) {
      handleChange("tfe");
    }
  };

  const { t } = useTranslation();

  return (
    <SettingBox>
      <CardHeader>{t("userSettings.twoFactor")}</CardHeader>
      <CardBody bordercolor="#62cb31" display="flex">
        <ProfileInfo marginbottom="0">
          <Column>
            <InfoRow>{t("userSettings.twoFactorText")}</InfoRow>
            <InfoRow>{t("userSettings.twoFactorText2")}</InfoRow>
          </Column>
          <Column>
            <FactorForm>
              <FormTitle>{t("userSettings.twoFactorMethod")}</FormTitle>
              {forceStatus.data?.isForce === true && (
                <FormRadio
                  checked={factorType === FORCE_TYPE.DISABLE}
                  name={"group0"}
                  value={FORCE_TYPE.DISABLE}
                  id="group1_0"
                  onChange={() => setFactorType(FORCE_TYPE.DISABLE)}
                >
                  {t("userSettings.disable")}
                </FormRadio>
              )}
              {/* 不支持，暂时注释 */}
              {/* <FormRadio
                checked={factorType === "1"}
                name={"group1"}
                value={"1"}
                id="group1_1"
                onChange={(e) => setFactorType(e.target.value)}
              >
                Text Message
              </FormRadio> */}
              <FormRadio
                checked={factorType === FORCE_TYPE.EMAIL}
                name={"group1"}
                value={FORCE_TYPE.EMAIL}
                id="group1_2"
                onChange={() => setFactorType(FORCE_TYPE.EMAIL)}
              >
                {t("userSettings.email")}
              </FormRadio>
              <FormRadio
                checked={factorType === FORCE_TYPE.MFA}
                name={"group1"}
                value={FORCE_TYPE.MFA}
                id="group1_3"
                onChange={() => setFactorType(FORCE_TYPE.MFA)}
              >
                {t("userSettings.authenticatorApp")}
                {/* <FontAwesomeIcon
                  onClick={toAuthFactor}
                  icon={faGear}
                  style={{ verticalAlign: "middle", marginLeft: "4px", cursor: "pointer" }}
                /> */}
              </FormRadio>
              <FromButton onClick={() => factorTypeClick()}>{t("userSettings.save")}</FromButton>
            </FactorForm>
          </Column>
        </ProfileInfo>
      </CardBody>
      <NextModal
        isOpen={nextOpen}
        onClose={() => setNextOpen(false)}
        handleChange={() => handleChange("tfd")}
      ></NextModal>
      <AlreadyModal isOpen={alreadyOpen} onClose={() => setAlready(false)}></AlreadyModal>
    </SettingBox>
  );
});

const NextModal = ({ isOpen, onClose, handleChange }: IModalProps) => {
  const { t } = useTranslation();
  return (
    <DialogModal
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <Footer>
          <NewButton buttonType="secondary" onClick={onClose} size="200">
            {t("userSettings.cancel")}
          </NewButton>
          <NewButton buttonType="primary" onClick={handleChange} size="200">
            {t("userSettings.yes")}
          </NewButton>
        </Footer>
      }
    >
      <NextText>
        <FontAwesomeIcon
          icon={faCircleExclamation}
          style={{ color: "#FFD43B", fontSize: "80px" }}
        />
      </NextText>
      <NextTitle>{t("userSettings.sure")}</NextTitle>
      <NextText>{t("userSettings.areYouSure")}</NextText>
    </DialogModal>
  );
};

const AlreadyModal = ({ isOpen, onClose }: IModalProps) => {
  const { t } = useTranslation();
  return (
    <DialogModal
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <Footer>
          <NewButton buttonType="primary" onClick={onClose} size="200">
            {t("userSettings.ok")}
          </NewButton>
        </Footer>
      }
    >
      <NextText>
        <FontAwesomeIcon
          icon={faCircleExclamation}
          style={{ color: "#C9DAE1", fontSize: "80px" }}
        />
      </NextText>
      <NextTitle>{t("userSettings.already")}</NextTitle>
    </DialogModal>
  );
};

const Footer = styled.div`
  text-align: center;
`;

const NextTitle = styled.h2`
  font-size: 26px;
  color: #575757;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  text-transform: none;
  position: relative;
  margin: 25px 0;
  padding: 0;
  line-height: 40px;
  display: block;
`;

const NextText = styled.p`
  text-align: center;
`;

const FromButton = styled(NewButton)`
  background-color: #62cb31;
  border-color: #62cb31;
  margin-top: 20px;
  &:hover {
    background-color: #95df70;
    border-color: #95df70;
  }
`;

const FormRadio = styled(NewRadio)`
  margin-bottom: 0.5rem;
  height: 26px;
  line-height: 26px;
  > div:nth-child(2) {
    height: 16px;
    width: 16px;
  }
  > span {
    font-size: 13px;
  }
`;

const FactorForm = styled.div.attrs({
  id: "change-2fa-method-form",
  action: "",
  method: "post",
})``;

const FormTitle = styled.h5`
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #333;
  margin: 0 0 15px;
`;

const InfoRow = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Nunito Sans", "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #6a6c6f;
  font-weight: 400;
  line-height: 1.5;
`;

const Column = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  @media (min-width: 1200px) {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    &:nth-child(even) {
      border-left: 1px #e5e5e5 solid;
    }
  }
`;
