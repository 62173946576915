import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCircleCheck } from "@fortawesome/pro-duotone-svg-icons";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reCaptchaV2Callback } from "@helium10/re-core";
import { InputErrorMessage, NewButton, NewCheckbox, NewInput } from "@helium10/re-ui-components";
import { userloginController } from "@walmart10/container/core/store/loginStore";
import { memo, useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ReCaptchaV2 } from "recaptcha-v2v3";
import styled from "styled-components";

import { useRedirect } from "@/core/hooks/useRedirect";

import { appRoutes } from "../../../core/links";
import { useTranslation } from "../../../i18n/useTranslation";
import walmartlogoSrc from "../../../static/icons/walmartlogo.svg";
import { IUserRegistration } from "../requests/index";
import { SignUpProvider, useSignUpStore } from "../store/index";

export const SignUpFormWrapper = memo(() => {
  const { getPath, redirect } = useRedirect();
  const value = {
    name: "",
    email: "",
    password: "",
    agree: false,
  };

  return (
    <SignUpProvider value={{ ...value, getPath, redirect }}>
      <Context />
    </SignUpProvider>
  );
});

export const Context = memo(() => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IUserRegistration>();

  const { getPath, redirect } = useRedirect();

  const handleRouteChange = useCallback(() => {
    redirect(getPath(appRoutes.signIn));
  }, [redirect, getPath]);

  const JumpToSignIn = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    handleRouteChange();
  };

  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    mixture: false,
    notEmail: false,
  });

  const [reCaptchaError, setReCaptchaError] = useState(false);
  const [reCaptchaValidated, setReCaptchaValidated] = useState(false);

  const { useRegisterController } = useSignUpStore();
  const controller = useRegisterController();
  const loginController = userloginController();

  const onSubmit: SubmitHandler<IUserRegistration> = async (data) => {
    if (!reCaptchaValidated) {
      setReCaptchaError(true);
      return;
    }
    useRegisterController.action(data);
  };

  const password = watch("password", "");
  const email = watch("email", "");

  useEffect(() => {
    setPasswordValidation({
      length: password.length >= 8,
      mixture:
        /[A-Za-z]/.test(password) && /[0-9]/.test(password) && /[^A-Za-z0-9\s]/.test(password),
      notEmail: email.indexOf(password) === -1,
    });
  }, [password, email]);

  const handleReCaptchaCallback = (response: string | false | Error) => {
    reCaptchaV2Callback(response);
    setReCaptchaValidated(!!response);
    setReCaptchaError(!response);
  };

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <SignUpFormWrapperTitle>{t("signUp.title")}</SignUpFormWrapperTitle>
      <ContainerLogo href="https://www.helium10.com/">
        <img src={walmartlogoSrc} alt="Helium 10" />
      </ContainerLogo>
      <FormGroup>
        <SignUpFormLabel htmlFor="name">{t("signUp.name")}</SignUpFormLabel>
        <NewInput
          id="name"
          size={"400"}
          error={errors.name && t("signUp.nameCheck")}
          placeholder={t("signUp.namePlaceholder")}
          {...register("name", { required: true })}
        />
      </FormGroup>
      <FormGroup>
        <SignUpFormLabel htmlFor="email">{t("common.email")}</SignUpFormLabel>
        <NewInput
          id="email"
          size={"400"}
          error={errors.email && String(errors.email.message)}
          placeholder={t("signUp.emailPlaceholder")}
          {...register("email", {
            required: t("common.emailCheck"),
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i,
              message: t("common.emailCheck2"),
            },
          })}
        />
      </FormGroup>
      <FormGroup>
        <SignUpFormLabel htmlFor="password">{t("common.password")}</SignUpFormLabel>
        <NewInput
          id="password"
          type="password"
          size={"400"}
          error={errors.password && t("common.passwordCheck")}
          placeholder={t("signUp.passwordPlaceholder")}
          {...register("password", { required: true, minLength: 8 })}
        />
      </FormGroup>
      <PasswordValidation>
        <PasswordValidationItem>
          {passwordValidation.length ? <CircleCheckIcon /> : <TimesCircleIcon />}
          {t("common.passwordCheck2")}
        </PasswordValidationItem>
        <PasswordValidationItem>
          {passwordValidation.mixture ? <CircleCheckIcon /> : <TimesCircleIcon />}
          {t("common.passwordCheck3")}
        </PasswordValidationItem>
        <PasswordValidationItem>
          {passwordValidation.notEmail ? <CircleCheckIcon /> : <TimesCircleIcon />}
          {t("common.passwordCheck4")}
        </PasswordValidationItem>
      </PasswordValidation>
      {/* <FormGroup>
        <SignUpFormLabel htmlFor="phone">{t("signUp.phone")}</SignUpFormLabel>
        <PhoneOptionalText>({t("signUp.optional")})</PhoneOptionalText>
        <NewInput
          id="phone"
          size={"400"}
          placeholder={t("signUp.phonePlaceholder")}
          {...register("phone")}
        />
      </FormGroup> */}
      <FormGroup>
        <CaptchaWrapper>
          <ReCaptchaV2 callback={handleReCaptchaCallback} />
          {reCaptchaError && (
            <InputErrorMessage>{t("common.reCaptchaVerification")}</InputErrorMessage>
          )}
        </CaptchaWrapper>
      </FormGroup>
      <FormGroup>
        <NewCheckbox {...register("agree", { required: true })}>
          <CheckboxTextWrapper>
            {t("signUp.agree")}{" "}
            <CheckboxTextLink
              href="https://www.helium10.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("signUp.privacyPolicy")}
            </CheckboxTextLink>{" "}
            {t("signUp.and")}{" "}
            <CheckboxTextLink
              href="https://www.helium10.com/terms-and-conditions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("signUp.terms")}
            </CheckboxTextLink>
          </CheckboxTextWrapper>
        </NewCheckbox>
        {errors.agree && <InputErrorMessage>{t("signUp.agreeCheck")}</InputErrorMessage>}
      </FormGroup>
      <NewButtonWrapper
        size="400"
        buttonType={"primary"}
        type="submit"
        loading={controller.status === "loading" || loginController.status === "loading"}
      >
        <NewButtonText>{t("signUp.signUp")}</NewButtonText>
      </NewButtonWrapper>
      <LogInWrapper>
        {t("signUp.haveAccout")}{" "}
        <LogInTextLink onClick={JumpToSignIn}>{t("common.login")}</LogInTextLink>
      </LogInWrapper>
    </FormWrapper>
  );
});

const FormWrapper = styled.form`
  width: 410px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.sp20};
`;

const ContainerLogo = styled.a`
  width: 200px;
  margin-bottom: 24px;

  & > img {
    display: block;
    width: 100%;
  }
`;

const SignUpFormWrapperTitle = styled.h3`
  /* font-family: "Neuton", serif; */
  /* font-size: 44px; */
  font-size: 38px;
  font-weight: 400;
  letter-spacing: 2px;
  text-align: center;
  color: #003873;
  margin: 0px auto 24px;
  white-space: nowrap;
`;

const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const SignUpFormLabel = styled.label`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.375;
  color: #646464;
  margin-bottom: 8px;
  display: inline-block;
`;

const PhoneOptionalText = styled.span`
  margin-left: 4px;
  font-size: 14px;
  color: "#6A6C6F";
`;

const PasswordValidation = styled.ul`
  width: 100%;
  margin-top: 0;
  margin-bottom: 1rem;
  list-style-type: none;
  text-align: left;
  padding-left: 0;
  font-size: 14px;
  color: #646464;
  display: block;
`;

const PasswordValidationItem = styled.li`
  display: list-item;
  text-align: -webkit-match-parent;
  unicode-bidi: isolate;
`;

const TimesCircleIcon = styled(FontAwesomeIcon).attrs({ icon: faTimesCircle })<{ icon?: IconProp }>`
  color: #dae0e9;
  font-size: 16px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const CircleCheckIcon = styled(FontAwesomeIcon).attrs({ icon: faCircleCheck })<{ icon?: IconProp }>`
  color: #0bcf97;
  font-size: 16px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const CheckboxTextWrapper = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #646464;
  letter-spacing: 0.5px;
  line-height: 1.375;
`;

const CheckboxTextLink = styled.a`
  color: #337ab7;
  text-decoration: underline;
`;

const NewButtonWrapper = styled(NewButton)`
  width: 410px;
`;

const NewButtonText = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #ede8e4;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.375;
`;

const LogInWrapper = styled.div`
  margin-top: 1rem;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.45px;
  color: #646464;
`;

const LogInTextLink = styled.a`
  color: #337ab7;
  text-decoration: none;
  cursor: pointer;
`;

const CaptchaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
