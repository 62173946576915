import type { Helium10Plans } from "@helium10/re-core";
import { baseFetch, getUrlWithSearchParams } from "@helium10/re-core";

import type { IPaymentMethods } from "../../../../common/types/IPaymentMethods";
import { apiIntent } from "../consts/links";
import type { ICardTypes } from "./getData";

export type IIntent = "setup" | "payment";

export interface IIntentRequest {
  intent: IIntent;
  captchaTokenV3?: string | null;
  captchaTokenV2?: string | null;
  email?: string | null;
  method?: string | null;
  plan?: keyof Helium10Plans;
  coupon?: string | null;
  amount?: string | number | null;
  name?: string | null;
}

export interface IIntentResponse {
  data: {
    status: "success" | "error" | "captcha";
    message?: string;
    messageType?: string;
    invoiceUrl?: string;
    url?: string;
    paymentIntent?: {
      id: string;
      object: string;
      amount: number;
      amount_capturable: number;
      amount_details: { tip: [] };
      amount_received: number;
      application: string | null;
      application_fee_amount: number | null;
      automatic_payment_methods: string | null;
      canceled_at: null;
      cancellation_reason: null;
      capture_method: string;
      charges: {
        object: string;
        data: [];
        has_more: boolean;
        total_count: number;
        url: string;
      };
      client_secret: string;
      confirmation_method: string;
      created: number;
      currency: string;
      customer: null;
      description: null;
      invoice: null;
      last_payment_error: null;
      latest_charge: null;
      livemode: boolean;
      metadata: {
        amount: string;
        cacheKey: string;
        coupon: string;
        email: string;
        isUpgrade: string;
        method: string;
        name: string;
        plan: string;
        planItems: string;
        prorationDate: string;
        source: string;
      };
      next_action: null;
      on_behalf_of: null;
      payment_method: null;
      payment_method_options: { "": [] };
      payment_method_types: string[];
      processing: null;
      receipt_email: null;
      review: null;
      setup_future_usage: null;
      shipping: null;
      source: null;
      statement_descriptor: null;
      statement_descriptor_suffix: null;
      status: string;
      transfer_data: null;
      transfer_group: null;
    };
    setupIntent?: {
      id: string;
      object: string;
      application: string | null;
      automatic_payment_methods: string | null;
      cancellation_reason: string | null;
      client_secret: string;
      created: number;
      customer: string | null;
      description: string | null;
      flow_directions: string | null;
      last_setup_error: string | null;
      latest_attempt: string | null;
      livemode: boolean;
      mandate: string | null;
      metadata: {
        email: string;
      };
      next_action: string | null;
      on_behalf_of: string | null;
      payment_method: string | null;
      payment_method_options: {
        card: {
          mandate_options: string | null;
          network: string | null;
          request_three_d_secure: string;
        };
      };
      payment_method_types: IPaymentMethods[];
      single_use_mandate: string | null;
      status: string;
      usage: string;
    };
    checkZipAndCountry?: boolean;
    cardInfo?: {
      brand: ICardTypes;
      last4: string | null;
    };
    stripePublishableKey?: string;
  };
}

export const getIntent = async (
  params: IIntentRequest,
  signal?: AbortSignal,
): Promise<IIntentResponse> => {
  return await baseFetch<IIntentRequest, IIntentResponse>({
    url: getUrlWithSearchParams(apiIntent, {}),
    withCredentials: true,
    params,
    signal,
  });
};
