import { leitenPrimitive } from "@helium10/re-core";
import { create } from "zustand";

import type { useTranslation } from "./useTranslation";

type ITranslationState = ReturnType<typeof useTranslation>;

export const useTranslationStore = create<{
  translation: ITranslationState | null;
}>(() => ({
  translation: null,
}));

export const translationController = leitenPrimitive(useTranslationStore, "translation");
