import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NewButton } from "@helium10/re-ui-components";
import styled, { css } from "styled-components";

interface IActions {
  isLoading?: boolean;
  isComplete?: boolean;
  isSubmitting?: boolean;
  buttonDefaultText?: string;
  buttonSubmittingText?: string;
  buttonCompleteText?: string;
}

export const ActionsWithCard = ({
  isLoading,
  isComplete,
  isSubmitting,
  buttonDefaultText,
  buttonSubmittingText,
  buttonCompleteText,
}: IActions) => {
  const isSubmittingText = isSubmitting && buttonSubmittingText;
  const isCompleteText = isComplete && buttonCompleteText;

  return (
    <Container>
      <ProcessButton
        $size="400"
        $isComplete={!!isComplete}
        $buttonType={isComplete ? "secondary" : "primary"}
        icon={isLoading && <FontAwesomeIcon spin icon={faSpinner} />}
        disabled={isLoading || isSubmitting || isComplete}
      >
        {isLoading && !isSubmitting
          ? ""
          : isCompleteText || isSubmittingText || buttonDefaultText || ""}
      </ProcessButton>
    </Container>
  );
};

const Container = styled.div`
  order: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(p) => `${p.theme.spacing.sp16} 0 0 0`};
`;

const ProcessButton = styled(NewButton)<{ $isComplete: boolean }>`
  min-width: 224px;

  ${(p) =>
    p.$isComplete &&
    css`
      cursor: initial;
      background: #def8e6;
      border-color: #def8e6;
      color: #217c49;

      &:hover,
      &:focus,
      &:active,
      &:disabled {
        border-color: #def8e6;
        color: #217c49;
        opacity: 1;
      }
    `}
`;
