import { translateErrors } from "../../../common/utils/translateErrors";
import type { ITOnlyValue } from "../../../i18n/useTranslation";

export const getCardNameRules = (t: ITOnlyValue) => ({
  required: t("checkout.errors.required", {
    field: t("checkout.card.form.name.label"),
  }),
});

export const getCardNumberRules = (t: ITOnlyValue) => {
  const required = t("checkout.errors.required", {
    field: t("checkout.card.form.number.label"),
  });

  return {
    required,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validate: (field: any) => {
      return field?.empty ? required : translateErrors(t, field?.error?.message);
    },
  };
};

export const getCardExpiryRules = (t: ITOnlyValue) => {
  const required = t("checkout.errors.required", {
    field: t("checkout.card.form.expiry.label"),
  });

  return {
    required,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validate: (field: any) => {
      return field?.empty ? required : translateErrors(t, field?.error?.message);
    },
  };
};

export const getCardCvcRules = (t: ITOnlyValue) => {
  const required = t("checkout.errors.required", {
    field: t("checkout.card.form.cvc.label"),
  });

  return {
    required,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validate: (field: any) => {
      return field?.empty ? required : translateErrors(t, field?.error?.message);
    },
  };
};

export const getCountryRules = (t: ITOnlyValue) => ({
  required: t("checkout.errors.required", {
    field: t("checkout.card.form.country.label"),
  }),
});

export const getStreetRules = (t: ITOnlyValue) => ({
  required: t("checkout.errors.required", {
    field: t("checkout.card.form.street.label"),
  }),
});

export const getCityRules = (t: ITOnlyValue) => ({
  required: t("checkout.errors.required", {
    field: t("checkout.card.form.city.label"),
  }),
});

export const getStateRules = (t: ITOnlyValue) => ({
  required: t("checkout.errors.required", {
    field: t("checkout.card.form.state.label"),
  }),
});

export const getZipRules = (t: ITOnlyValue) => ({
  required: t("checkout.errors.required", {
    field: t("checkout.card.form.zip.label"),
  }),
});
