import { memo } from "react";
import styled from "styled-components";

import { useTranslation } from "../../../i18n/useTranslation";
import frameQuoteImageSrc from "../../../static/icons/frame-quote.svg";
import frameImageSrc from "../../../static/icons/login-illustration.svg";

export const FrameWrapper = memo(() => {
  const { t } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <FrameWrapperHeader>
          <img src={frameImageSrc} alt="Sign Up for Helium 10" />
        </FrameWrapperHeader>
        <FrameWrapperBody>
          <IconWrapper>
            <img src={frameQuoteImageSrc} />
          </IconWrapper>
          <FrameWrapperTitle>{t("signUp.introduction")}</FrameWrapperTitle>
        </FrameWrapperBody>
        <FrameWrapperFooter>
          <FrameWrapperFooterBold>Reda A,</FrameWrapperFooterBold> {t("signUp.introduction1")}
        </FrameWrapperFooter>
      </Wrapper>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 475px;
  height: 800px;
  margin-left: ${({ theme }) => theme.spacing.sp80};
  padding: 40px;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.44;
  color: #13191f;
  background: #fff;
  box-shadow: 0 3px 10px rgba(72, 94, 117, 0.15);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const FrameWrapperHeader = styled.div`
  margin-bottom: 40px;
`;

const FrameWrapperBody = styled.div`
  padding: 0px 16px 18px;
  position: relative;
`;

const IconWrapper = styled.div`
  height: 48px;
  margin-bottom: 16px;
`;

const FrameWrapperTitle = styled.h3`
  font-size: 28px;
  font-weight: 800;
  line-height: 36px;
  color: #485e75;
  margin: 0 0 16px;
`;

const FrameWrapperFooter = styled.span`
  padding: 0 16px;
  margin-top: auto;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #13191f;
`;

const FrameWrapperFooterBold = styled.span`
  font-weight: 800;
`;
