import React, { useCallback } from "react";

import { useTranslation } from "../../../../../i18n/useTranslation";
import { useAccessManagementStore } from "../../../store";

export const useUserInfoParams = () => {
  const { t } = useTranslation();

  return {
    formItems: [
      {
        label: t("accessManagement.modal.userPermission.formEmail"),
        name: "email",
        placeHolder: t("accessManagement.modal.userPermission.emailPlaceHolder"),
        maxLength: 100,
        rules: {
          required: "Email is required",
          pattern: {
            value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
            message: "Invalid email address",
          },
        },
      },
      {
        label: t("accessManagement.modal.userPermission.formName"),
        name: "name",
        maxLength: 100,
        placeHolder: t("accessManagement.modal.userPermission.namePlaceHolder"),
        rules: {
          required: "Name is required",
        },
      },
    ],
  };
};

export const useFeatureTree = () => {
  const { useStore, allMenusSelector } = useAccessManagementStore();
  const allMenus = useStore(allMenusSelector);
  const { t } = useTranslation();

  return React.useMemo(() => {
    return {
      label: t("accessManagement.modal.userPermission.selectAll"),
      id: "_root",
      children: (allMenus || []).map((rec) => {
        return {
          id: String(rec.menuId),
          label: rec.i18N,
          children: (rec.children || []).map((child) => {
            return {
              id: String(child.menuId),
              label: child.i18N,
            };
          }),
        };
      }),
    };
  }, [allMenus, t]);
};

export const useFeatureNodes = () => {
  const { useStore, allMenusSelector } = useAccessManagementStore();
  const allMenus = useStore(allMenusSelector);

  return useCallback(
    (enableIds: string[]) => {
      const ids: {
        menuId: string;
        permissionType: 0 | 2;
      }[] = [];
      allMenus.forEach((menu) => {
        ids.push({
          menuId: String(menu.menuId),
          permissionType: enableIds.includes(String(menu.menuId)) ? 2 : 0,
        });
        (menu.children || []).forEach((child) => {
          ids.push({
            menuId: String(child.menuId),
            permissionType: enableIds.includes(String(child.menuId)) ? 2 : 0,
          });
        });
      });
      return ids;
    },
    [allMenus],
  );
};
