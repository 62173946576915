// import your api domain here
// export const amazonApiDomain = process.env.REACT_APP_PROFITS_AMAZON_DOMAIN;

// add your api Path here
// export const getUserInfo = amazonApiDomain + "/v1/getUserInfo";

const WickWalmartBase = process.env.REACT_APP_MEMBERS_DOMAIN;
const WickUserBase = process.env.REACT_APP_WICK_USER_DOMAIN;
const WickAdminBase = process.env.REACT_APP_WICK_ADMIN_DOMAIN;

export const userSystem = {
  signup: WickWalmartBase + "/api/v1/users/signup",
  userLogin: WickWalmartBase + "/api/v1/users/login",
};

export const wickUserAPI = {
  getUserInfo: WickUserBase + "/api/wick/userInfo",
  getAvailableAccounts: WickUserBase + "/api/wick/accounts",
  getUsesPermissions: WickAdminBase + "/wick/user/menus",
};
