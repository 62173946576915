import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { media, Skeleton } from "@helium10/re-ui-components";
import styled from "styled-components";

export const FilterGrid8Columns = styled.div`
  padding-left: 30px;
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing.sp8};
  align-items: flex-end;
  grid-template-columns: 1fr;

  ${media.mobile} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${media.laptop} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${media.largeDesktop} {
    grid-template-columns: repeat(8, 1fr);
  }
`;

export const UpArrow = styled(FontAwesomeIcon).attrs({ icon: faAngleUp })<{
  $reverse?: boolean;
  icon?: IconProp;
}>`
  transform: ${({ $reverse: reverse }) => (reverse ? "rotate(360deg)" : "rotate(90deg)")};
`;

const SkeletonWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const LineSkeleton = styled(Skeleton)`
  max-width: 200px;
  min-width: 100px;
`;

const RectSkeleton = styled(Skeleton)`
  width: 20px;
  height: 20px;
  padding-left: 8px;
`;

export const CheckBoxSkeleton = () => {
  return (
    <SkeletonWrapper>
      <RectSkeleton />
      <LineSkeleton />
    </SkeletonWrapper>
  );
};
