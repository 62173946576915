import type { RumPublicApi } from "@datadog/browser-rum-core";
import { getCurrentAccountId } from "@helium10/re-core";

interface IProps extends Window {
  DD_RUM?: RumPublicApi;
}

export const getHeaderHelper = (hasAccountId = true) => {
  const uiView = (window as IProps).DD_RUM?.getInternalContext()?.view?.id;
  const headers: Record<string, string> = {
    "x-ui-view-id": uiView || "",
  };
  if (hasAccountId) headers["helium10-api-accountid"] = getCurrentAccountId() || "";
  return headers;
};
