import { membersLink, transformHelium10Domain } from "@helium10/re-core";

export const helium = transformHelium10Domain("https://www.helium10.com");

export const consumerTermAndConditionLink = helium + "/terms-and-conditions/";
export const consumerPrivacyPolicyLink = helium + "/privacy-policy/";

export const apiData = membersLink + "/api/v1/customers/checkout/data";
export const apiIntent = membersLink + "/api/v1/subscribe/create-intent";
export const apiSubscribeProcess = membersLink + "/api/v1/subscribe/process";
export const apiSubscribeCheck = membersLink + "/api/v1/subscribe/check-subscription";
export const apiUpgrade = membersLink + "/api/v1/upgrade/process";
export const apiALaCarteUpdate = membersLink + "/api/v1/update/process";
export const apiALaCarteDowngrade = membersLink + "/api/v1/downgrade/process";

export const apiConfirmation = `${window.location.origin}/subscribe/confirmation`;
