import { Loader } from "@googlemaps/js-api-loader";
import { media } from "@helium10/re-ui-components";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { GOOGLEMAPS_KEY } from "../const/params";
import { GoogleAutocompleteStyle, Input } from "../Styles";
import { getPlaceDetails } from "../utils/getPlaceDetails";

interface IStreetInput {
  size: "50" | "100" | "200" | "400";
  label: string;
  value: string;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  onBlur: () => void;
  onChange: () => void;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setValue: any;
}

export const StreetInput = ({ setValue, ...restProps }: IStreetInput) => {
  const [googlePlaces, setGooglePlaces] = useState<google.maps.PlacesLibrary | null>(null);
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    new Loader({ apiKey: GOOGLEMAPS_KEY, libraries: ["places"] })
      .importLibrary("places")
      .then(setGooglePlaces);
  }, [setGooglePlaces]);

  useEffect(() => {
    if (inputRef.current && googlePlaces) {
      const Autocomplete = googlePlaces?.Autocomplete;

      autoCompleteRef.current = new Autocomplete(inputRef.current, { types: ["geocode"] });

      if (autoCompleteRef.current) {
        autoCompleteRef.current.setFields(["address_component"]);
        autoCompleteRef.current.addListener("place_changed", async () => {
          const place = await autoCompleteRef.current?.getPlace();
          const placeOptions = getPlaceDetails({ place });

          for (const [key, value] of Object.entries(placeOptions)) {
            setValue(key, value ?? "", { shouldValidate: true });
          }
        });
      }
    }
  }, [googlePlaces, setValue]);

  return (
    <>
      <StyledInput {...restProps} ref={inputRef} />
      <GoogleAutocompleteStyle />
    </>
  );
};

const StyledInput = styled(Input)`
  position: relative;

  ${media.desktopBefore} {
    min-width: 100%;
  }
`;
