import type { ConfirmAlipayPaymentData, PaymentIntent, StripeError } from "@stripe/stripe-js";

import type { IStripe } from "../../store/useCheckoutModalStore";

type PaymentIntentResultWithAlipayRedirect = PaymentIntent & {
  next_action: PaymentIntent.NextAction & {
    alipay_handle_redirect?: PaymentIntent.NextAction.RedirectToUrl;
  };
};

export interface IStripeConfirmAlipayRequest {
  stripe: IStripe;
  clientSecret: string;
  data?: ConfirmAlipayPaymentData;
}

export interface IStripeConfirmAlipayResponse {
  paymentIntent?: PaymentIntentResultWithAlipayRedirect;
  error?: StripeError;
}

export const stripeConfirmAlipay = ({
  stripe,
  clientSecret,
  data,
}: IStripeConfirmAlipayRequest): Promise<IStripeConfirmAlipayResponse> => {
  return stripe?.confirmAlipayPayment(clientSecret, data, { handleActions: false });
};
