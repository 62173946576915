import type { Helium10Plans } from "@helium10/re-core";
import { combineStatuses, useRecaptchaStore } from "@helium10/re-core";
import { CardNumberElement, useElements } from "@stripe/react-stripe-js";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { shallow } from "zustand/shallow";

import { sendSegmentEvent } from "../../../../common/segmentEvents/sendSegmentEvent";
import type { IPaymentMethods } from "../../../../common/types/IPaymentMethods";
import type { ICardTypes, IModalTypes } from "../requests/getData";
import {
  dataController,
  formController,
  intentController,
  paymentEditingController,
  stripeConfirmIntentController,
  stripeRetrieveIntentController,
  subscribeProcessController,
  upgradeProcessController,
  useCheckoutModalStore,
} from "../store/useCheckoutModalStore";

export type IStripeValue = {
  brand: ICardTypes | "unknown";
  complete: boolean;
  elementType: "cardNumber" | "cardExpiry" | "cardCvc";
  empty: boolean;
  error?: {
    message: string;
  };
  value?: string;
};

interface IFormValues {
  cardNumber: string & IStripeValue;
  cardName: string;
  cardExpiry: string & IStripeValue;
  cardCvc: string & IStripeValue;
  country: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

export const useCard = () => {
  const elements = useElements();
  const dataStatus = dataController((state) => state.status);
  const intentStatus = intentController((state) => state.status);
  const stripeConfirmStatus = stripeConfirmIntentController((state) => state.status);
  const stripeRetrieveStatus = stripeRetrieveIntentController((state) => state.status);
  const stripeSubscribeStatus = subscribeProcessController((state) => state.status);
  const stripeUpgradeStatus = upgradeProcessController((state) => state.status);
  const captchaTokenV2 = useRecaptchaStore((state) => state.captchaTokenV2);

  const [
    isEditing,
    location,
    data,
    intent,
    paymentMode,
    stripeConfirmIntent,
    stripeRetrieveIntent,
    subscribeProcess,
    upgradeProcess,
  ] = useCheckoutModalStore(
    (state) => [
      state.isEditing,
      state.initialData?.location,
      state.data,
      state.intent,
      state.paymentMode,
      state.stripeConfirmIntent,
      state.stripeRetrieveIntent,
      state.subscribeProcess,
      state.upgradeProcess,
    ],
    shallow,
  );

  const { watch, control, formState, setValue, setError, clearErrors, handleSubmit } =
    useForm<IFormValues>({
      mode: "onChange",
      defaultValues: {
        cardNumber: "",
        cardName: "",
        cardExpiry: "",
        cardCvc: "",
        country: data?.address?.countryIsoCode || data?.address?.currentCountry || "",
        street: data?.address?.street || "",
        city: data?.address?.city || "",
        state: data?.address?.state || "",
        zip: data?.address?.zipCode || "",
      },
    });

  const country = watch("country");

  const isLoading =
    combineStatuses(
      dataStatus,
      intentStatus,
      stripeConfirmStatus,
      stripeRetrieveStatus,
      stripeSubscribeStatus,
      stripeUpgradeStatus,
    ) === "loading";

  const isSubmitting = isLoading && formState?.isSubmitted;

  const isCardAvailable = data?.card?.brand && !isEditing;

  const isApplePayAvailable = useMemo(
    () => data?.paymentMethods?.includes("apple_pay"),
    [data?.paymentMethods],
  );

  const countries = useMemo(() => {
    return Object.keys(data?.countries || {})?.map((countryId) => ({
      label: data?.countries?.[countryId],
      searchString: data?.countries?.[countryId]?.toLowerCase(),
      value: countryId,
    }));
  }, [data?.countries]);

  const states = useMemo(() => {
    return Object.keys(data?.states || {})?.map((stateId) => ({
      label: data?.states?.[stateId],
      searchString: data?.states?.[stateId]?.toLowerCase(),
      value: stateId,
    }));
  }, [data?.states]);

  const isEnabledRecaptchaV2 =
    intent?.data?.status === "error" && intent?.data?.message === "Insecure request";

  const handleIntent = useCallback(
    async ({ captchaTokenV2 }: { captchaTokenV2?: string | null }) => {
      intentController?.action({ captchaTokenV2, intent: "setup" });
    },
    [],
  );

  const onSubmitCard = useCallback(
    async (formData: IFormValues) => {
      formController.patch({ ...formData });

      const card = elements?.getElement?.(CardNumberElement);
      if (card) {
        stripeConfirmIntentController.action({ cardName: formData?.cardName, card });
      } else {
        data?.type === "upgradeModal"
          ? upgradeProcessController?.action({})
          : subscribeProcessController?.action({});
      }
    },
    [elements, data],
  );

  useEffect(() => {
    handleIntent({});

    return () => {
      intentController.clear();
      stripeConfirmIntentController.clear();
      stripeRetrieveIntentController.clear();
      subscribeProcessController.clear();
      upgradeProcessController.clear();
      paymentEditingController.clear();
    };
  }, [handleIntent]);

  useEffect(() => {
    if (isEnabledRecaptchaV2 && captchaTokenV2) handleIntent({ captchaTokenV2 });
  }, [isEnabledRecaptchaV2, captchaTokenV2, handleIntent]);

  useEffect(() => {
    if (
      intent?.data?.status === "error" ||
      stripeConfirmIntent?.error ||
      stripeRetrieveIntent?.error ||
      subscribeProcess?.data?.status === "error" ||
      upgradeProcess?.data?.status === "error"
    ) {
      setError("root.stripe", {
        type: "stripe",
        message:
          intent?.data?.message ||
          stripeConfirmIntent?.error?.message ||
          stripeRetrieveIntent?.error?.message ||
          subscribeProcess?.data?.message ||
          upgradeProcess?.data?.message,
      });
    } else {
      clearErrors("root.stripe");
    }
  }, [
    intent,
    stripeConfirmIntent,
    stripeRetrieveIntent,
    subscribeProcess,
    upgradeProcess,
    setError,
    clearErrors,
  ]);

  useEffect(() => {
    location &&
      data &&
      sendSegmentEvent({
        name: "Subscription Modal Viewed",
        properties: {
          location,
          newPlan: data.plan.key as Helium10Plans,
          checkoutModalType: modalTypesMap[data.type as IModalTypes],
          paymentMethodsShown: data.paymentMethods.map(
            (method) => paymentMethodsMap[method as IPaymentMethods],
          ),
        },
      });
  }, [data, location, paymentMode, isApplePayAvailable]);

  return {
    isEditing,
    isLoading,
    isSubmitting,
    isCardAvailable,
    isApplePayAvailable,
    amount: data?.amount,
    nextAmount: data?.nextAmount,
    card: data?.card,
    location,
    control,
    country,
    countries,
    formState,
    states,
    setValue,
    handleSubmit,
    onSubmitCard,
    isEnabledRecaptchaV2,
  };
};

const modalTypesMap: Record<IModalTypes, string> = {
  subscribeModal: "purchaseNoCc",
  subscribeModalCard: "purchaseWithCc",
  upgradeModal: "upgrade",
  updateModal: "updateModal",
  downgradeModal: "downgradeModal",
};

const paymentMethodsMap: Record<IPaymentMethods, string> = {
  card: "card",
  alipay: "aliPay",
  apple_pay: "applePay",
};
