import {
  InputErrorMessage,
  NewButton,
  NewCheckbox,
  NewInput,
  toastNotification,
} from "@helium10/re-ui-components";
import { userloginController } from "@walmart10/container/core/store/loginStore";
import { memo, useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { useRedirect } from "@/core/hooks/useRedirect";

import { useLoginAction } from "../../core/hooks/useLoginAction";
import { appRoutes } from "../../core/links";
import { useTranslation } from "../../i18n/useTranslation";
import logoSrc from "../../static/icons/2-colors-light-bg.svg";
import walmartlogoSrc from "../../static/icons/walmartlogo.svg";
import { bindForceEmail } from "../userSettings/requests";
import { sendChangeEmailCode, verifyEmail } from "./hooks";
import { CodeVerificationProvider, useCodeVerificationStore } from "./store/index";

export type FromType = "signinapp" | "signemail" | "settings" | "changeemail" | "factoremail";
interface UserInfo {
  e: string;
  p: string;
  c: string;
  r: number;
}

interface ICodeVerification {
  rememberMe: boolean;
  code: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function maskEmail(email: string) {
  // 分割邮箱地址为用户名和域名部分
  const [username, domain] = email.split("@");
  if (!username || !domain) return email; // 如果格式不正确，直接返回原邮箱地址

  // 处理用户名部分，从第三个字符开始到末尾替换成*
  const maskedUsername =
    username.length > 2 ? username.substring(0, 2) + "*".repeat(username.length - 2) : username;

  // 处理域名部分，从@之后的第二个字符开始到.之前的字符替换成*
  const domainParts = domain.split(".");
  const domainName = domainParts[0];
  const maskedDomainName =
    domainName.length > 2
      ? domainName.substring(0, 1) + "*".repeat(domainName.length - 1)
      : domainName;
  const maskedDomain = [maskedDomainName, ...domainParts.slice(1)].join(".");

  return `${maskedUsername}@${maskedDomain}`;
}
export const CodeVerification = memo(() => {
  useLoginAction();
  const { getPath, redirect } = useRedirect();
  const value = {
    email: "",
    password: "",
    rememberMe: 1 as 0 | 1,
    code: "",
    validateCode: "",
  };

  return (
    <CodeVerificationProvider value={{ ...value, getPath, redirect }}>
      <Context />
    </CodeVerificationProvider>
  );
});

const Context = memo(() => {
  const query = useQuery();
  const fromType = (query.get("from") as FromType) ?? "settings";
  const email = decodeURIComponent(query.get("e") ?? "");
  const code = decodeURIComponent(query.get("p") ?? "");
  const u = decodeURIComponent(query.get("u") ?? "");
  const userInfo = u ? JSON.parse(u) : {};
  return (
    <>
      <ContainerLogo href="https://www.helium10.com/">
        <img src={logoSrc} alt="Helium 10" />
      </ContainerLogo>
      <Container>
        <Wrapper>
          <ChangeFormWrapper fromType={fromType} code={code} email={email} userInfo={userInfo} />
        </Wrapper>
      </Container>
    </>
  );
});

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 104px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
`;

const ChangeFormWrapper = memo(
  ({
    fromType,
    code,
    email,
    userInfo,
  }: {
    fromType: FromType;
    code: string;
    email: string;
    userInfo: UserInfo;
  }) => {
    const { getPath, redirect } = useRedirect();

    const { t } = useTranslation();

    const { useVerifyLoginController } = useCodeVerificationStore();

    /** 路由参数信息 */
    const [fromTypeInfo, setFromTypeInfo] = useState({
      isSettings: fromType === "settings",
      isSigninapp: fromType === "signinapp",
      isSignemail: fromType === "signemail",
      isChangeEmail: fromType === "changeemail",
      isFactoremail: fromType === "factoremail",
    });
    useEffect(() => {
      setFromTypeInfo({
        isSettings: fromType === "settings",
        isSigninapp: fromType === "signinapp",
        isSignemail: fromType === "signemail",
        isChangeEmail: fromType === "changeemail",
        isFactoremail: fromType === "factoremail",
      });
    }, [fromType]);

    const [userInfoState, setUserInfoState] = useState({
      email: userInfo.e,
      password: userInfo.p,
      code: userInfo.c,
      rememberMe: userInfo.r,
    });
    useEffect(() => {
      setUserInfoState({
        email: userInfo.e,
        password: userInfo.p,
        code: userInfo.c,
        rememberMe: userInfo.r,
      });
    }, [userInfo]);
    /** 路由参数信息 */

    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
    } = useForm<ICodeVerification>();

    const verifyLoginController = useVerifyLoginController();

    /** 邮件发送倒计时功能逻辑 */
    const maxSecond = 180;
    const [disabled, setDisabled] = useState(false);
    const [seconds, setSeconds] = useState(maxSecond);
    const [showRemeber, setShowRemeber] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    const [emailCode, setEmailCode] = useState<string | undefined>("");
    useEffect(() => {
      setShowEmail(fromTypeInfo.isSettings || fromTypeInfo.isSignemail);
      setShowRemeber(!fromTypeInfo.isFactoremail && !fromTypeInfo.isSettings);
    }, []);
    async function sendEmail(needSend: boolean = true) {
      if (disabled) return;
      if (fromType === "settings") {
        const code = await sendChangeEmailCode({ fromType });
        setEmailCode(code);
        if (code) setDisabled(true);
      } else if (fromType === "signemail" && needSend) {
        userloginController.action({
          email: userInfoState.email,
          password: userInfoState.password,
          rememberMe: userInfoState.rememberMe ? 1 : 0,
        });
      }
      if (fromType === "signemail") setDisabled(true);
      const times = setInterval(() => {
        setSeconds((val) => {
          if (val === 0) {
            clearInterval(times);
            setDisabled(false);
            return maxSecond;
          } else {
            return val - 1;
          }
        });
      }, 1000);
    }
    useEffect(() => {
      if (fromTypeInfo.isSigninapp) {
        return;
      } else if (fromTypeInfo.isSignemail) {
        sendEmail(false);
        return;
      }
      sendEmail();
    }, []);
    /** 邮件发送倒计时功能逻辑 */

    const handleSettings = useCallback(() => {
      redirect(getPath(appRoutes.settings));
    }, [redirect, getPath]);
    const handleChangeEmail = useCallback(() => {
      redirect(getPath(appRoutes.changeEmail));
    }, [redirect, getPath]);
    const onCancel = () => {
      handleSettings();
    };

    const onSubmit: SubmitHandler<ICodeVerification> = async (data) => {
      console.log(data);
      if (fromType === "signemail" || fromType === "signinapp") {
        useVerifyLoginController.action({
          email: userInfoState.email,
          password: userInfoState.password,
          rememberMe: data.rememberMe ? 1 : 0,
          code: userInfoState.code,
          validateCode: data.code,
        });
      } else if (fromType === "factoremail") {
        try {
          const res = await bindForceEmail({ code, validateCode: data.code });
          if (res.code === 250) {
            toastNotification.error(t("mfa.errorMessage"), { autoClose: false });
          } else if (res.code === 240) {
            toastNotification.error(t("mfa.errorMessage2"), {
              autoClose: false,
            });
          } else {
            handleSettings();
          }
        } catch (error) {
          console.log(error);
        }
      } else if (fromType === "settings") {
        verifyEmail({ handleChangeEmail, data, code: emailCode, setDisabled });
      }
    };

    return (
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <SignUpFormWrapperTitle>{t("mfa.title")}</SignUpFormWrapperTitle>
        <ContainerWalmartLogo href="https://www.helium10.com/">
          <img src={walmartlogoSrc} alt="Helium 10" />
        </ContainerWalmartLogo>
        <FormGroup>
          <SignUpFormLabel htmlFor="code">
            {fromTypeInfo.isSigninapp ? t("mfa.verifyApp") : t("mfa.verifyEmail")}
          </SignUpFormLabel>
          {showEmail ? (
            <SignUpFormLabel htmlFor="code">
              {maskEmail(email || userInfoState.email)}
            </SignUpFormLabel>
          ) : null}
          <NewInput
            id="code"
            type="password"
            size={"400"}
            placeholder={t("mfa.codePlaceholder")}
            {...register("code", { required: true })}
          />
          {errors.code && <InputErrorMessage>{t("mfa.codeCheck")}</InputErrorMessage>}
        </FormGroup>
        {showRemeber && (
          <FormGroup>
            <NewCheckbox checked {...register("rememberMe")}>
              <CheckboxTextWrapper>{t("mfa.rememberMe")}</CheckboxTextWrapper>
            </NewCheckbox>
            <TipWrapper>({t("mfa.rememberMeText")})</TipWrapper>
          </FormGroup>
        )}
        <NewButtonWrapper
          size="400"
          buttonType={"primary"}
          type="submit"
          loading={verifyLoginController.status === "loading"}
          style={{
            marginTop: fromTypeInfo.isFactoremail ? "15px" : "0",
          }}
        >
          {fromTypeInfo.isSettings ? "Confirm" : "Log in"}
        </NewButtonWrapper>
        {fromTypeInfo.isSigninapp ? null : fromTypeInfo.isFactoremail ? (
          <NewButtonWrapper
            onClick={onCancel}
            buttonType={"secondary"}
            size="400"
            type="button"
            style={{ marginTop: "15px" }}
          >
            {t("mfa.cancel")}
          </NewButtonWrapper>
        ) : (
          <NewButtonWrapper
            onClick={() => sendEmail()}
            disabled={disabled}
            size="400"
            buttonType={"primary"}
            type="button"
            style={{ marginTop: "15px" }}
          >
            {!disabled
              ? t("mfa.resend")
              : t("mfa.resend") + ` (${seconds}) ` + t("mfa.resendSeconds")}
          </NewButtonWrapper>
        )}
      </FormWrapper>
    );
  },
);

const TipWrapper = styled.div`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 300;
  color: #343a40;
`;

const FormWrapper = styled.form`
  width: 410px;
  height: 835px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.sp20};
`;

const ContainerLogo = styled.a`
  width: 200px;
  height: 33px;
  margin-top: 40px;
  padding: 0 0 0 60px;

  & > img {
    display: block;
    width: 200px;
  }
`;

const ContainerWalmartLogo = styled.a`
  width: 200px;
  margin-bottom: 24px;

  & > img {
    display: block;
    width: 100%;
  }
`;

const SignUpFormWrapperTitle = styled.h3`
  /* font-family: "Neuton", serif; */
  /* font-size: 44px; */
  font-size: 38px;
  font-weight: 400;
  letter-spacing: 2px;
  text-align: center;
  color: #003873;
  margin: 0px auto 24px;
  white-space: nowrap;
`;

const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const SignUpFormLabel = styled.label`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.375;
  color: #646464;
  margin-bottom: 8px;
  display: block;
`;

const NewButtonWrapper = styled(NewButton)`
  width: 410px;
  text-transform: uppercase;
  margin-top: 20px;
`;

const CheckboxTextWrapper = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #343a40;
  letter-spacing: 0.5px;
  line-height: 1.375;
`;
