import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { media, NewButton } from "@helium10/re-ui-components";
import styled, { css } from "styled-components";

import { useCheckoutModal } from "../CheckoutModal";

interface IActions {
  isLoading?: boolean;
  isComplete?: boolean;
  isSubmitting?: boolean;
  buttonCancelText?: string;
  buttonDefaultText?: string;
  buttonSubmittingText?: string;
  buttonCompleteText?: string;
}

export const ActionsWithoutCard = ({
  isLoading,
  isComplete,
  isSubmitting,
  buttonCancelText,
  buttonDefaultText,
  buttonSubmittingText,
  buttonCompleteText,
}: IActions) => {
  const isSubmittingText = isSubmitting && buttonSubmittingText;
  const isCompleteText = isComplete && buttonCompleteText;

  return (
    <Container>
      <Controls>
        <NewButton
          size="200"
          buttonType="text"
          $buttonType={isLoading || isSubmitting || isComplete ? "text" : "secondary"}
          disabled={isLoading || isSubmitting || isComplete}
          onClick={useCheckoutModal.close}
        >
          {buttonCancelText}
        </NewButton>
        <ProcessButton
          size="200"
          $isComplete={!!isComplete}
          $buttonType={isComplete ? "secondary" : "primary"}
          icon={isLoading && <FontAwesomeIcon spin icon={faSpinner} />}
          disabled={isLoading || isSubmitting || isComplete}
        >
          {isLoading && !isSubmitting
            ? ""
            : isCompleteText || isSubmittingText || buttonDefaultText || ""}
        </ProcessButton>
      </Controls>
    </Container>
  );
};

const Container = styled.div`
  order: 3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${(p) => `${p.theme.spacing.sp24} 0`};

  ${NewButton} + ${NewButton} {
    margin: ${(p) => `${p.theme.spacing.sp24} 0 0 0`};
  }

  ${media.desktop} {
    flex-direction: row;

    ${NewButton} + ${NewButton} {
      margin: ${(p) => `0 0 0 ${p.theme.spacing.sp24}`};
    }
  }
`;

const ProcessButton = styled(NewButton)<{ $isComplete: boolean }>`
  min-width: 224px;

  ${(p) =>
    p.$isComplete &&
    css`
      cursor: initial;
      background: #def8e6;
      border-color: #def8e6;
      color: #217c49;

      &:hover,
      &:focus,
      &:active,
      &:disabled {
        border-color: #def8e6;
        color: #217c49;
        opacity: 1;
      }
    `}
`;
