import type { Reaction } from "@helium10/re-core";
import {
  declareAction,
  declareAtom,
  declareModal,
  map,
  setBearerToken,
  setCurrentAccountId,
} from "@helium10/re-core";

import { useCommonStore } from "../data/commonStore";

export interface ICredentialsStore {
  accountId: string;
  expiredTokenAttempts: number;
}

const initialValues: ICredentialsStore = {
  expiredTokenAttempts: 0,
  accountId: "",
};

export const setAccountIdAction = declareAction<string>("setAccountIdAction", (accountId) => {
  setCurrentAccountId(accountId);
  useCommonStore.getState().setAccountId(accountId);
});

export const setTokenAction = declareAction<string | null>("setTokenAction");
export const incrementTokenAttemptsAction = declareAction("incrementTokenAttemptsAction");
export const clearTokenAttemptsAction = declareAction("clearTokenAttemptsAction");

export const someErrorModalStore = declareModal<void>("attemptsModal", undefined);

export const handleExpiredTokenAction: Reaction<number> = (status, { getState, dispatch }) => {
  const { expiredTokenAttempts, accountId } = getState(credentialsAtom);
  if (status === 403 || status === 401) {
    if (expiredTokenAttempts < 2) {
      dispatch(incrementTokenAttemptsAction());
      dispatch(setTokenAction(null));
      accountId && setBearerToken(accountId, "");
    } else {
      dispatch(someErrorModalStore.open());
    }
  } else {
    dispatch(clearTokenAttemptsAction());
  }
};

export const credentialsAtom = declareAtom<ICredentialsStore>("mainAtom", initialValues, (on) => [
  on(setAccountIdAction, (store, accountId) => {
    useCommonStore.getState().setAccountId(accountId);
    return { ...store, accountId };
  }),
  on(incrementTokenAttemptsAction, (store) => ({
    ...store,
    expiredTokenAttempts: store.expiredTokenAttempts + 1,
  })),
  on(clearTokenAttemptsAction, (store) => ({ ...store, expiredTokenAttempts: 0 })),
]);

export const getAccountIdSelector = map(credentialsAtom, ({ accountId }) => accountId || "");
