import { IFeatureCreator, leitenFeature, leitenRequest } from "@helium10/re-core";
import { toastNotification } from "@helium10/re-ui-components";
import { create } from "zustand";

import { IResetOwnPasswordResponse, resetOwnPassword } from "../../userSettings/requests";

interface IState {
  resetOwnPasswordResponse: IResetOwnPasswordResponse;
}

export type ICodeVerificationFeature = IFeatureCreator<{
  handleSignOut: () => void;
}>;

export const [useChangePasswordStore, ChangePasswordProvider] = leitenFeature(
  ({ useProps }: ICodeVerificationFeature) => {
    const { handleSignOut } = useProps.getState();
    const useStore = create<IState>(() => ({
      resetOwnPasswordResponse: {
        code: 0,
        msg: "",
      },
    }));
    const useSendMFAEmailController = leitenRequest(
      useStore,
      "resetOwnPasswordResponse",
      resetOwnPassword,
      {
        fulfilled: () => {
          handleSignOut();
        },
        rejected: (res) => {
          const error = res.error || res.fetchError?.result?.msg;
          toastNotification.error(error, { autoClose: false });
        },
      },
    );

    return {
      useStore,
      useProps,
      useSendMFAEmailController,
    };
  },
);
