import { media } from "@helium10/re-ui-components";
import styled from "styled-components";

import { Input, Label } from "../Styles";

interface ILabeledName {
  placeholder?: string;
  label: string;
  id: string;
  value: string;
  error?: string;
  disabled?: boolean;
  onBlur: () => void;
  onChange: () => void;
}

export const LabeledName = ({ placeholder, label, id, ...rest }: ILabeledName) => {
  return (
    <Container>
      <Label htmlFor={id}>{label}</Label>
      <Input {...rest} id={id} placeholder={placeholder} size="200" />
    </Container>
  );
};

const Container = styled.div`
  min-width: 100%;
  font-size: 0;

  ${media.desktop} {
    min-width: 230px;
  }
`;
