// import your api domain here
// export const amazonApiDomain = process.env.REACT_APP_PROFITS_AMAZON_DOMAIN;

// add your api Path here
// export const getUserInfo = amazonApiDomain + "/v1/getUserInfo";

// add your route Path here
export const baseRoute = "/new-user-sys";

export const moduleRoute = {
  home: "/home",
  demoDashBoard: "/demoDashBoard",
  demoDetailPage: "/demoDetailPage",
  setSubUserPassword: "/set-subuser-pwd",
  accessManagement: "/access-management",
  settings: "/settings",
  setPassword: "/set-pwd",
  changePassword: "/change-pwd",
  recovery: "/recovery",
  changeEmail: "/changeEmail",
  confirmMethod: "/confirmMFAMethod",
  authenticator: "/authenticator",
  codeVerification: "/codeMFAVerification",
  signIn: "/sign-in",
  signUp: "/sign-up",
  confirmEmail: "/confirm-email",
  activateNewEmail: "/activate-new-email",
};

export const appRoutes = {
  home: baseRoute + moduleRoute.home,
  demoDashBoard: baseRoute + moduleRoute.demoDashBoard,
  demoDetailPage: baseRoute + moduleRoute.demoDetailPage,
  setSubUserPassword: baseRoute + moduleRoute.setSubUserPassword,
  accessManagement: baseRoute + moduleRoute.accessManagement,
  settings: baseRoute + moduleRoute.settings,
  setPassword: baseRoute + moduleRoute.setPassword,
  changePassword: baseRoute + moduleRoute.changePassword,
  recovery: baseRoute + moduleRoute.recovery,
  changeEmail: baseRoute + moduleRoute.changeEmail,
  confirmMethod: baseRoute + moduleRoute.confirmMethod,
  authenticator: baseRoute + moduleRoute.authenticator,
  codeVerification: baseRoute + moduleRoute.codeVerification,
  signIn: baseRoute + moduleRoute.signIn,
  signUp: baseRoute + moduleRoute.signUp,
};

const userSystemDomain = process.env.REACT_APP_MEMBERS_DOMAIN;
const pacvueAdminDomain = process.env.REACT_APP_WICK_ADMIN_DOMAIN;
const pacvueUserDomain = process.env.REACT_APP_WICK_USER_DOMAIN;

const WickApiBase = process.env.REACT_APP_WICK_ADMIN_DOMAIN;

export const userSystem = {
  signup: userSystemDomain + "/api/v1/users/signup",
  forgotPassword: pacvueAdminDomain + "/wick/user/forgotPassword",
  sendMFAEmail: pacvueAdminDomain + "/wick/user/sendMFAEmail",
  resetPassword: pacvueAdminDomain + "/wick/user/activateUser",
  userAccountList: WickApiBase + "/wick/user/searchAll",
  deleteUserAccount: WickApiBase + "/wick/user/delete/:userId",
  getUserInfoById: WickApiBase + "/roleMenu/getByUserId/:userId",
  addNewUser: WickApiBase + "/wick/user/invite",
  saveUserInfo: WickApiBase + "/wick/user/edit",
  getUserInfo: pacvueUserDomain + "/api/wick/userInfo",
  getAccounts: pacvueUserDomain + "/api/wick/accounts",
  getAllMenus: WickApiBase + "/wick/user/allMenus",
  getProfileInfo: userSystemDomain + "/api/v1/profile/index",
  changeAvatar: userSystemDomain + "/api/v1/profile/change-avatar",
  changeAddress: userSystemDomain + "/api/v1/profile/change-address",
  receiveSwag: userSystemDomain + "/api/v1/users/receive-swag",
  sendUserActiveEmails: WickApiBase + "/mail/sendUserActiveEmails",
  userLogin: userSystemDomain + "/api/v1/users/login",
  userVerifyLogin: userSystemDomain + "/api/v1/users/verify-login",
  forceStatus: pacvueUserDomain + "/api/wick/forceStatus",
  disableForce: pacvueUserDomain + "/api/wick/disableForce",
  forceApp: pacvueUserDomain + "/api/wick/mfa",
  forceEmail: pacvueUserDomain + "/api/wick/forceEmail",
  bindMFA: pacvueUserDomain + "/api/wick/bindmfa",
  bindForceEmail: pacvueUserDomain + "/api/wick/bindForceEmail",
  updateUserEmail: WickApiBase + "/userManager/updateUserEmail",
  resetOwnPassword: WickApiBase + "/userManager/resetOwnPassword",
  loginRecords: pacvueUserDomain + "/api/wick/loginRecords",
  clearSession: pacvueUserDomain + "/api/Wick/clearSession",
  activateUser: WickApiBase + "/wick/user/activateUser",
  sendEmailCode: pacvueUserDomain + "/api/wick/sendEmailCode",
  verifyEmailCode: pacvueUserDomain + "/api/wick/verifyEmailCode",
  changeDefaultAccount: pacvueUserDomain + "/api/wick/setDefaultAccount",
  userInfoRealTime: pacvueUserDomain + "/api/wick/userInfoRealTime",
};
