import {
  InputErrorMessage,
  NewButton,
  NewInput,
  toastNotification,
} from "@helium10/re-ui-components";
import QRCode from "qrcode.react";
import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { useRedirect } from "@/core/hooks/useRedirect";

import { useLoginAction } from "../../core/hooks/useLoginAction";
import { appRoutes } from "../../core/links";
import { useTranslation } from "../../i18n/useTranslation";
import { bindMFA } from "../userSettings/requests";
// import logoSrc from "../../static/icons/2-colors-light-bg.svg";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function extractSecret(uri: string): string | null {
  const secretMatch = uri.match(/secret=([^&]+)/);
  return secretMatch ? secretMatch[1] : null;
}

export const Authenticator = memo(() => {
  useLoginAction();
  const { t } = useTranslation();
  const query = useQuery();
  const [codeOption, setCodeOption] = useState({ code: "", keyUri: "" });
  useEffect(() => {
    const codeparam = query.get("p") as string;
    const val = JSON.parse(decodeURIComponent(codeparam)) as { code: string; keyUri: string };
    setCodeOption(val);
  }, []);
  const [showTip, setShowTip] = useState(false);
  return (
    <>
      <Container>
        <Wrapper>
          <BarCode>
            <BarTitle>{t("authenticator.title")}</BarTitle>
            <BarTextOl>
              <BarTextLi>{t("authenticator.title2")}</BarTextLi>
              <BarTextLi>{t("authenticator.searchText")}</BarTextLi>
              <BarTextLi>{t("authenticator.searchText2")}</BarTextLi>
            </BarTextOl>
            <BarTitle>{t("authenticator.title3")}</BarTitle>
            <BarTextOl>
              <BarTextLi>{t("authenticator.selectText")}</BarTextLi>
              <BarTextLi>{t("authenticator.selectText2")}</BarTextLi>
              <BarTextLi>{t("authenticator.selectText3")}</BarTextLi>
            </BarTextOl>
            {/* <QrCode src={logoSrc} /> */}
            <QRCodeComponent value={codeOption.keyUri}></QRCodeComponent>
            <CannotScan onClick={() => setShowTip(!showTip)}>
              {t("authenticator.selectText4")}
            </CannotScan>
            {showTip && (
              <CannotScanSettings>
                <ScanSettingsOl>
                  <ScanSettingsLi>{t("authenticator.cannotsetting1")}</ScanSettingsLi>
                  <ScanSettingsLi>{t("authenticator.cannotsetting2")}</ScanSettingsLi>
                  <ScanSettingsLi>{t("authenticator.cannotsetting3")}</ScanSettingsLi>
                  <ScanSettingsLi>{t("authenticator.cannotsetting4")}</ScanSettingsLi>
                  <ScanSettingsLi>{t("authenticator.cannotsetting5")}</ScanSettingsLi>
                </ScanSettingsOl>
                <StyledLabel>{t("authenticator.secretKey")}</StyledLabel>
                <BaseMarginBottom>
                  <strong>{extractSecret(codeOption.keyUri)}</strong>
                </BaseMarginBottom>
                <Subtext>{t("authenticator.authenticationAppText")}</Subtext>
              </CannotScanSettings>
            )}
          </BarCode>
          <ChangeFormWrapper code={codeOption.code} />
        </Wrapper>
      </Container>
    </>
  );
});

const Subtext = styled.span`
  font-size: 0.846em;
  line-height: 1.455em;
  color: #667881;
  font-weight: normal;
  display: block;
`;

const BaseMarginBottom = styled.div`
  margin-top: 0;
  margin-bottom: 1rem;
`;

const StyledLabel = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
`;

const ScanSettingsOl = styled.ol`
  margin-top: 0;
  margin-bottom: 1rem;
  list-style-type: decimal;
`;
const ScanSettingsLi = styled.li`
  display: list-item;
  list-style-type: decimal;
  unicode-bidi: isolate;
`;

const CannotScanSettings = styled.div`
  background: #f5f7f8;
  padding: 1.231em;
  border: 1px solid #b0bec5;
  position: relative;
  border-radius: 0.308em 0.308em 0.308em 0.308em;
  box-sizing: border-box;
  font-size: 13px;
  color: #6a6c6f;
`;

const QRCodeComponent = ({ value }: { value: string }) => {
  return (
    <div>
      <QRCode value={value} size={200} bgColor={"#ffffff"} fgColor={"#000000"} level={"L"} />
    </div>
  );
};

const BarCode = styled.div`
  width: 450px;
  margin-right: 50px;
`;

const CannotScan = styled.a.attrs({ href: "#" })`
  margin-top: 10px;
  font-size: 13px;
  color: #34495e;
  text-decoration: none;
  font-weight: 400;
  line-height: 1.5;
`;

const BarTitle = styled.p`
  margin-bottom: 1rem;
  font-size: 13px;
  color: #6a6c6f;
  font-weight: bolder;
`;

const QrCode = styled.img`
  height: 200px;
  width: 200px;
  margin-right: 250px;
`;

const BarTextOl = styled.ol`
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 13px;
  color: #6a6c6f;
  font-weight: 400;
  line-height: 1.5;
  display: block;
`;

const BarTextLi = styled.li`
  display: list-item;
  text-align: -webkit-match-parent;
  unicode-bidi: isolate;
`;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 104px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  padding-top: 7%;
`;

const ChangeFormWrapper = memo(({ code }: { code: string }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const res = await bindMFA({ code, validateCode: data.password });
      if (res.code === 250) {
        toastNotification.error(t("authenticator.250"), { autoClose: false });
      } else if (res.code === 240) {
        toastNotification.error(t("authenticator.240"), {
          autoClose: false,
        });
      } else {
        handleSettings();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  /* const password = watch("password", "");
  const email = watch("email", "");

  useEffect(() => {
    setPasswordValidation({
      length: password.length >= 6,
      mixture:
        /[A-Za-z]/.test(password) && /[0-9]/.test(password) && /[^A-Za-z0-9\s]/.test(password),
      notEmail: email.indexOf(password) === -1,
    });
  }, [password, email]); */

  const { getPath, redirect } = useRedirect();
  const handleSettings = useCallback(() => {
    redirect(getPath(appRoutes.settings));
  }, [redirect, getPath]);

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <SignUpFormLabel htmlFor="password">{t("authenticator.formTitle")}</SignUpFormLabel>
        <NewInput
          id="password"
          type="password"
          size={"400"}
          {...register("password", { required: true, minLength: 6 })}
        />
        {errors.password && <InputErrorMessage>{t("common.passwordCheck")}</InputErrorMessage>}
      </FormGroup>
      <NewButtonWrapper size="400" buttonType={"primary"} type="submit" loading={loading}>
        {t("authenticator.submitText")}
      </NewButtonWrapper>
      <NewButtonWrapper
        size="400"
        buttonType={"secondary"}
        onClick={handleSettings}
        disabled={loading}
      >
        {t("userSettings.cancel")}
      </NewButtonWrapper>
    </FormWrapper>
  );
});

const FormWrapper = styled.form`
  width: 410px;
  height: 835px;
  display: flex;
  flex-direction: column;
  margin-right: ${({ theme }) => theme.spacing.sp20};
  margin-top: 1rem;
`;

const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const SignUpFormLabel = styled.label`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.375;
  color: #646464;
  margin-bottom: 8px;
  display: inline-block;
`;

const NewButtonWrapper = styled(NewButton)`
  width: 410px;
  text-transform: uppercase;
  margin-top: 35px;
  font-size: 16px;
  &:nth-of-type(2) {
    margin-top: 15px;
  }
`;
