import { membersLink, transformHelium10Domain } from "@helium10/re-core";

export const adtomicDomain = transformHelium10Domain(process.env.REACT_APP_ADTOMIC_DOMAIN || "");
export const walmartAssetsLink = adtomicDomain + "/v1/assets/";
const membersDomain = membersLink;
const wickAdminDomain = process.env.REACT_APP_WICK_ADMIN_DOMAIN || "";
const wickUserDomain = process.env.REACT_APP_WICK_USER_DOMAIN || "";

export const landingControlLink =
  membersDomain + "/api/v1/testing/experiment/new-adtomic-add-on/variation";
export const keywordsLink = adtomicDomain + "/v1/keywords";
export const walmartKeywordsLink = adtomicDomain + "/v1/walmart/keywords";
export const walmartProductsLink = adtomicDomain + "/v1/walmart/items";
export const targetsLink = adtomicDomain + "/v1/targets";
export const accountsLink = wickUserDomain + "/api/wick/accounts";
export const accountPermissionLink = wickAdminDomain + "/roleMenu/getByUserId/";
export const gtmLink = membersDomain + "/api/v1/customers/segment-traits";
export const maintenanceLink = adtomicDomain + "/v1/maintenance";
export const updateCustomerLink = adtomicDomain + "/v1/customer/me/properties";
export const customerHelium10Link = membersDomain + "/api/v1/customers/me/ads";
export const upsellInfoLink = wickAdminDomain + "/wick/user/upsell-info";
export const subscriptionsLink = adtomicDomain + "/v1/profiles/subscription-status";
export const subscriptionsLinkV2 = adtomicDomain + "/v2/profiles/subscription-status";
export const updateSubscriptionsLink = adtomicDomain + "/v1/profiles/subscriptions";
export const customerAdtomicLink = adtomicDomain + "/v1/customer/me";
export const exportDataLink = adtomicDomain + "/v1/export";
export const addAdGroupNegativeKeywordsLink = keywordsLink + "/adgroup-negative";
export const segmentLink = adtomicDomain + "/v1/segment";
export const billingTermsLink = adtomicDomain + "/v1/billing-terms/acceptance";
export const productTitleLink = adtomicDomain + "/v1/products/asins/titles";
export const portfolioTitleLink = adtomicDomain + "/v1/portfolios/titles";
export const adgroupTitleLink = adtomicDomain + "/v1/adgroups/titles";

export const adgroupTitleLinks = {
  adgroup: adtomicDomain + "/v1/adgroups/titles/list",
  campaign: adtomicDomain + "/v1/adgroups/titles/list/campaign",
  product: adtomicDomain + "/v1/adgroups/titles/list/product",
  portfolio: adtomicDomain + "/v1/adgroups/titles/list/portfolio",
};

export const suggestionCountLink = adtomicDomain + "/v1/automation/suggestions/count";

export const campaignTitleLink = {
  campaign: adtomicDomain + "/v1/campaigns/titles/list",
  product: adtomicDomain + "/v1/campaigns/titles/list/product",
  portfolio: adtomicDomain + "/v1/campaigns/titles/list/portfolio",
};

export const analyticsLink = {
  portfolioChart: adtomicDomain + "/v1/portfolios/chart",
  campaignChart: adtomicDomain + "/v1/campaigns/chart",
  keywordChart: adtomicDomain + "/v1/analytics/targets/chart",
  productChart: adtomicDomain + "/v1/products/chart",
  adgroupChart: adtomicDomain + "/v1/adgroups/chart",
  searchTermChart: adtomicDomain + "/v1/search-terms/chart",
  adGroup: adtomicDomain + "/v1/adgroups/breakdown",
  campaign: adtomicDomain + "/v1/campaigns/breakdown",
  keyword: adtomicDomain + "/v1/keywords/breakdown",
  negativeKeyword: adtomicDomain + "/v1/negativeKeywords/breakdown",
  portfolio: adtomicDomain + "/v1/portfolios/breakdown",
  product: adtomicDomain + "/v1/products/breakdown",
  productTotalSales: adtomicDomain + "/v1/products/total-sales",
  productBulkTotalSales: adtomicDomain + "/v1/products/bulk-total-sales",
  productInfo: membersDomain + "/api/v1/common/products/info",
  searchTerm: adtomicDomain + "/v1/search-terms/breakdown",
  searchTermsNegative: adtomicDomain + "/v1/search-terms/negative",
  put: {
    adGroups: adtomicDomain + "/v1/adgroups/batch",
  },
};

export const portfolios = {
  create: adtomicDomain + "/v1/portfolios",
};

export const changelogLink = {
  keywordChanges: adtomicDomain + "/v1/changelog",
  bulkActions: "/v1/...", // TODO - add right URL for bulkActions
};

export const accountSettingsLinks = {
  chart: membersDomain + "/account/adtomic-widget-data",
  billing: adtomicDomain + "/v1/profiles/billing",
  syncData: adtomicDomain + "/v1/synchronize/info",
  put: {
    syncData: adtomicDomain + "/v1/data/synchronize",
  },
  accessState: adtomicDomain + "/v1/profiles/access-state",
  accessStateV2: adtomicDomain + "/v2/profiles/access-state",
};

export const suggestionsLink = {
  allBids: adtomicDomain + "/v1/automation/suggestions/all/bids",
  targetBid: adtomicDomain + "/v1/automation/suggestions/target/bids",
  allBudget: adtomicDomain + "/v1/automation/suggestions/budget",
  bidSettings: adtomicDomain + "/v1/automation/suggestions/bid-settings",
  bidsKeywordsApply: adtomicDomain + "/v1/keywords",
  bidsTargetsApply: adtomicDomain + "/v1/targets",
  budgetApply: adtomicDomain + "/v1/budget",
  keywords: adtomicDomain + "/v1/automation/suggestions/keywords",
  negativeKeywords: adtomicDomain + "/v1/automation/suggestions/negative-keywords",
  keywordsApply: adtomicDomain + "/v1/automation/suggestions/keywords",
  defaultRuleConditions: adtomicDomain + "/v1/automation/rules/settings",
};

export const dashboardLink = {
  totals: adtomicDomain + "/v1/dashboard/totals",
  campaignsTop: adtomicDomain + "/v1/campaigns/top",
  keywordsTop: keywordsLink + "/top",
  chart: adtomicDomain + "/v1/campaigns/chart",
  ppcPerformance: adtomicDomain + "/v1/dashboard/performance",
  createProfile: adtomicDomain + "/v1/walmart/profiles/import",
  chartMarketplaceDetails: membersDomain + "/api/v1/profits/marketplace/details",
  brandList: `${adtomicDomain}/v1/account/brandMetrics`,
  categoryList: `${adtomicDomain}/v1/account/brandMetrics/category`,
  reportList: `${adtomicDomain}/v1/account/brandMetrics/report`,
};

export const adManagerLink = {
  suggestedBidRange: adtomicDomain + "/v1/campaign-builder/suggested-bid-range",
  productDetails: adtomicDomain + "/v1/products/details",
  validateProductGroupName: adtomicDomain + "/v1/product-groups/validate",
  validateCampaignName: adtomicDomain + "/v1/campaigns/validate",
  mainChart: adtomicDomain + "/v1/campaigns/chart",
  groupChart: adtomicDomain + "/v1/adgroups/chart",
  keywordChart: keywordsLink + "/chart",
  targetChart: adtomicDomain + "/v1/targets/chart",
  productChart: adtomicDomain + "/v1/products/chart",
  createAdGroup: adtomicDomain + "/v1/adgroups",
  product: adtomicDomain + "/v1/products/breakdown",
  brandToAdvertise: adtomicDomain + "/v1/brands/list",
  mainTableList: adtomicDomain + "/v1/campaigns/list",
  searchTermsTableList: adtomicDomain + "/v1/search-terms/list",
  productsTableList: adtomicDomain + "/v1/products/list",
  negativeKeywordsCampaignTableList: keywordsLink + "/campaign-negative",
  negativeKeywordsGroupTableList: keywordsLink + "/adgroup-negative",
  negativeTargetsGroup: targetsLink + "/negative",
  targetsTableList: keywordsLink + "/list",
  targetsIsPatTableList: adtomicDomain + "/v1/targets/list",
  adGroupsList: adtomicDomain + "/v1/adgroups/list",
  campaignBudgetUsage: adtomicDomain + "/v1/campaigns/budget-usage",
  adGroupDetailsLink: {
    getDetails: adtomicDomain + "/v1/sd/creative",
    getPreview: adtomicDomain + "/v1/sd/creative/preview",
  },
  put: {
    campaigns: adtomicDomain + "/v1/campaigns",
    products: adtomicDomain + "/v1/products",
    walmartProducts: walmartProductsLink,
    targets: adtomicDomain + "/v1/targets",
    keywords: keywordsLink,
    walmartKeywords: walmartKeywordsLink,
    adGroups: adtomicDomain + "/v1/adgroups",
  },
};

export const rulesLink = {
  rulesInfo: adtomicDomain + "/v1/automation/rules/",
  ruleInfo: adtomicDomain + "/v1/automation/rules/rule/",
  ruleCriteria: adtomicDomain + "/v1/rule/template",
  rulesRemove: adtomicDomain + "/v1/automation/rules/remove",
  put: {
    rules: adtomicDomain + "/v1/automation/rules/rule/bulkActions",
  },
};

export const campaignBuilderLink = {
  productSelection: membersDomain + "/api/v1/customers/products/inventory",
  uploadFolder: membersDomain + "/keyword-manager/upload/get-upload-folders",
  uploadFolderKeywords: membersDomain + "/keyword-manager/upload/upload-folder",
  campaignDetails: (id: string) => adtomicDomain + "/v1/campaigns/" + id,
  // get assets api
  assetsSearch: adtomicDomain + "/v1/search-assets",
  assetsUpload: adtomicDomain + "/v1/upload-asset",
  aiAsset: {
    themeList: adtomicDomain + "/v1/ai-asset/theme",
    AIImage: adtomicDomain + "/v1/ai-asset",
  },
  categoryList: adtomicDomain + "/v1/category/recommendations",
  negativeBrands: adtomicDomain + "/v1/negative/brands/list",

  amazon: {
    addCampaigns: adtomicDomain + "/v2/campaign-builder/structure-setup",
    eligibilityProduct: adtomicDomain + "/v1/products/eligibility",
  },
  walmart: {
    walmartProductSelection: adtomicDomain + "/v1/walmart/product/list",
    addWalmartCampaigns: adtomicDomain + "/v1/walmart/campaign-builder/structure-setup",
    walmartCampaigns: adtomicDomain + "/v1/walmart/campaigns",
    walmartCampaignBrandLogo: adtomicDomain + "/v1/walmart/campaign/asset",
  },
  google: {
    addGoogleCampaigns: adtomicDomain + "/v1/google/campaign-builder/structure-setup",
    calloutSuggestions: adtomicDomain + "/v1/callout/suggestions",
  },
};

export const searchTermLink = {
  chart: adtomicDomain + "/v1/search-terms/chart",
  breakdown: adtomicDomain + "/v1/search-terms/breakdown",
  breakdownChart: adtomicDomain + "/v1/search-terms/breakdown/chart",
  keywords: adtomicDomain + "/v1/search-terms/list",
};

export const entityLink = {
  automationSuggestionsBidsHistory: adtomicDomain + "/v1/automation/suggestions/bids/history",
  automationSuggestionsBidsTargetsHistory:
    adtomicDomain + "/v1/automation/suggestions/targets/bids/history",
};

export const keywordsChartLink = adtomicDomain + "/v1/keywords/chart";
export const targetsChartLink = adtomicDomain + "/v1/targets/chart";

export const keywordLink = {
  summary: membersDomain + "/api/v1/keywords/history/summary",
  searchVolumeBatch: membersDomain + "/api/v1/keywords/search-volume/chart-batch",
  rank: adtomicDomain + "/v1/keywords/history/ranks",
  addKeyword: membersDomain + "/api/v1/keywordtracker/product",
  importKeywords: membersDomain + "/api/v1/keywordtracker/import-keywords",
  keywordOrganicSparkline: membersDomain + "/api/v1/keywords/history/organic",
  keywordSponsoredSparkline: membersDomain + "/api/v1/keywords/history/sponsored",
  products: adtomicDomain + "/v1/products/asins",
  limit: membersDomain + "/api/v1/keywordtracker/limits",
  addNegativeToCampaign: keywordsLink + "/campaign-negative",
  addNegativeToGroup: keywordsLink + "/adgroup-negative",
  negativeCount: adtomicDomain + "/v1/search-terms/adgroup-negative/count",
};

export const accountOverviewLinks = {
  performance: `${adtomicDomain}/v1/account/performance`,
  chart: `${adtomicDomain}/v1/dashboard/executive/chart`,
  totals: `${adtomicDomain}/v1/account/totals`,
};

export const scheduleLink = {
  main: adtomicDomain + "/v1/schedules",
  campaigns: adtomicDomain + "/v1/campaigns/list",
  couples: adtomicDomain + "/v1/schedules/entities",
  remove: adtomicDomain + "/v1/schedules/entity/remove",
  entityView: adtomicDomain + "/v1/schedules/entity-view",
  titles: adtomicDomain + "/v1/schedules/titles",
  validateName: adtomicDomain + "/v1/schedules/validate",
  // portfolio: adtomicDomain + "/v1/portfolios/breakdown",
  // product: adtomicDomain + "/v1/products/list",
};

export const streamLink = {
  aggregatedChart: adtomicDomain + "/v1/stream/aggregated",
  aggregatedStartTime: adtomicDomain + "/v1/stream/aggregated/start-time",
};

export const reportTimeLink = {
  reportTime: adtomicDomain + "/v1/analytics/latestReport",
};

export const filterPresetsLink = {
  main: adtomicDomain + "/v1/filter/preset",
};
