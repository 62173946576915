import type { IUltraSelectSingleProps, IUSBaseOption } from "@helium10/re-ui-components";
import { BaseSingleSelect, media } from "@helium10/re-ui-components";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { Label, StyledInputErrorMessage } from "../Styles";

interface ILabelSelect extends Omit<IUltraSelectSingleProps, "error"> {
  label: string;
  error?: string;
}

export const LabeledSelect = ({
  appendTo,
  label,
  value,
  error,
  options,
  placeholder,
  disabled,
  onChange,
}: ILabelSelect) => {
  const [search, setSearch] = useState<string>("");

  const filteredOptions = useMemo(
    () => options?.filter((option) => option?.searchString?.includes(search?.toLowerCase())),
    [options, search],
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleChange = useCallback((e: IUSBaseOption) => onChange?.(e?.value), [onChange]);

  return (
    <Select>
      <Label>{label}</Label>
      <BaseSingleSelect
        size="200"
        autocomplete
        dropdownAutoWidth
        appendTo={appendTo}
        value={value}
        error={!!error}
        disabled={disabled}
        options={filteredOptions}
        placeholder={placeholder}
        onChange={handleChange}
        onSearch={setSearch}
      />
      {error && <StyledInputErrorMessage>{error}</StyledInputErrorMessage>}
    </Select>
  );
};

const Select = styled.div`
  flex: 1;
  display: inline-flex;
  flex-direction: column;

  ${media.desktop} {
    max-width: 230px;
  }
`;
