import "./helpers/fixDate";

import {
  context,
  createStore,
  getCaptchaV2Key,
  getCaptchaV3Key,
  recaptchaLanguages,
  setErrorPage,
} from "@helium10/re-core";
import { FeaturesTranslateProvider } from "@helium10/re-features";
import { MiscellaneousTranslateProvider } from "@helium10/re-miscellaneous";
import {
  FontsStyles,
  GlobalStyle as UIComponentsGlobalStyle,
  scrollStylesCSS,
  styledTheme,
  UiTranslateProvider,
} from "@helium10/re-ui-components";
import { WidgetsTranslateProvider } from "@helium10/re-widgets";
import {
  CheckoutModal,
  FeaturesTranslateProvider as InnerFeaturesTranslateProvider,
} from "@walmart10/features";
import { PropsWithChildren, useMemo } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { ReCaptchaProvider } from "recaptcha-v2v3";
import { createGlobalStyle, ThemeProvider } from "styled-components";

import { Root } from "./App";
import { GTM } from "./gtm/GTM";
import { ContainerTranslateProvider, useTranslation } from "./i18n/useTranslation";

const store = createStore();
window.setError = setErrorPage.patch;

const helmetContext = {};

const GlobalStyle = createGlobalStyle`
  html, body, #re-container {
    height: 100%;
  }
  body, div, ul {
    ${scrollStylesCSS};
  }
  .intercom-lightweight-app {
      z-index: 999 !important;
  }
`;

if (process.env.NODE_ENV === "production") {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.packageVersions = packageVersions;
}

const ContainerReCaptchaProvider = ({ children }: PropsWithChildren) => {
  const { language } = useTranslation();
  const recaptchaV2Key = useMemo(getCaptchaV2Key, []);
  const recaptchaV3Key = useMemo(getCaptchaV3Key, []);
  const { [language]: reCaptchaLanguage } = recaptchaLanguages;

  return (
    <ReCaptchaProvider
      hideV3Badge={true}
      siteKeyV2={recaptchaV2Key}
      siteKeyV3={recaptchaV3Key}
      langCode={reCaptchaLanguage}
    >
      {children}
    </ReCaptchaProvider>
  );
};

const ConnectExternalScripts = () => {
  return <>{/* <GTM /> */}</>;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById("re-container")!);
root.render(
  // <StrictMode>
  <>
    <ConnectExternalScripts />
    <context.Provider value={store}>
      <HelmetProvider context={helmetContext}>
        <BrowserRouter>
          <ContainerTranslateProvider>
            <ThemeProvider theme={styledTheme}>
              <UiTranslateProvider>
                <FeaturesTranslateProvider>
                  <MiscellaneousTranslateProvider>
                    <InnerFeaturesTranslateProvider>
                      <WidgetsTranslateProvider>
                        <ContainerReCaptchaProvider>
                          <Root />
                          <CheckoutModal />
                        </ContainerReCaptchaProvider>
                      </WidgetsTranslateProvider>
                    </InnerFeaturesTranslateProvider>
                  </MiscellaneousTranslateProvider>
                </FeaturesTranslateProvider>
              </UiTranslateProvider>
              <UIComponentsGlobalStyle />
              <GlobalStyle />
              <FontsStyles />
            </ThemeProvider>
          </ContainerTranslateProvider>
        </BrowserRouter>
      </HelmetProvider>
    </context.Provider>
  </>,
  // </StrictMode>
);
