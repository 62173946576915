import { baseFetch } from "@helium10/re-core";

import { userSystem } from "../../../core/links";

// register
export interface IResetPassword {
  secretKey: string;
  password: string;
}
export interface IResetPasswordResponse {
  data: {
    results?: string;
    code?: number;
    message?: string;
    name?: string;
    status?: number;
  } | null;
  msg?: string;
}

export const resetPassword = async (params: IResetPassword) => {
  return baseFetch<IResetPassword, IResetPasswordResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: false,
    url: userSystem.resetPassword,
    params: params,
  });
};
