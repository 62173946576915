import { cssDisplay400Bold } from "@helium10/re-ui-components";
import { memo } from "react";
import styled from "styled-components";

import { useTranslation } from "../../i18n/useTranslation";
import helium10logo from "../../static/icons/helium10logo.svg";

export const DemoDetailPage = memo(() => {
  const { t } = useTranslation();
  return (
    <>
      <Wrapper>
        <H1>
          <Logo />
          {t("demo.detailPage")}
        </H1>
      </Wrapper>
    </>
  );
});

const Wrapper = styled.main`
  padding: ${({ theme }) => `${theme.spacing.sp24} ${theme.spacing.sp48}`};
`;

const H1 = styled.h1`
  ${cssDisplay400Bold}
  margin-bottom: ${({ theme }) => theme.spacing.sp24};
`;

const Logo = styled.img.attrs({ src: helium10logo })`
  margin-right: ${({ theme }) => theme.spacing.sp8};
  width: ${({ theme }) => theme.spacing.sp24};
`;
