import {
  declareAction,
  declareAtom,
  declareRequest,
  membersLink,
  useRouterStore,
} from "@helium10/re-core";

import { getRedirectDashboardRoute } from "@/core/link/link";
import type { IAccount, IUserReponse } from "@/requests/getAccountsList";
import { getAccounts } from "@/requests/getAccountsList";

import { setAccountIdAction } from "./credentialsStore";

const ADMIN_PERMISSIONS = [
  "blackbox",
  "trendster",
  "magnet",
  "cerebro",
  "keywordprocessor",
  "scribbles2",
  "_5kchecker",
  "keywordtracker",
  "alerts",
  "refundgenie",
  "misspellinator",
  "profits",
  "inventory-management",
  "followup",
  "ppc",
  "markettracker",
  "portals",
  "notification",
  "keyword-manager",
  "listing-analyzer",
  "mobile-product-search",
  "mobile-keyword-search",
  "listing-builder",
  "mt360",
  "dashboard",
  "subscribe",
];

export const accountsRequest = declareRequest<string | undefined, IUserReponse>(
  "accounts",
  (initialAccountId) => getAccounts(initialAccountId),
  {
    rejectedReaction: () => {
      if (process.env.NODE_ENV === "production") {
        window.location.assign(membersLink);
      }
    },
    fulfilledReaction: async ({ params: initialAccountId, result: { data } }, { dispatch }) => {
      let validAccountId: string | null = null;

      const accountIds = data?.map((item) => String(item.clientId));

      if (initialAccountId && accountIds.includes(initialAccountId as string)) {
        validAccountId = initialAccountId as string;
      } else {
        const mainAccount = data?.find((item) => item?.isSuperAdmin === 1);
        if (mainAccount) {
          validAccountId = String(mainAccount?.clientId);
        }
      }

      if (validAccountId) {
        const account = data?.find((item) => String(item.clientId) === validAccountId);
        if (account) {
          // const permissionsResult = await getAccountPermission(account?.userId);
          dispatch(
            setAccountAction({
              accountName: account.name,
              accountId: String(account?.clientId),
              role: account?.isSuperAdmin,
              permissions: ADMIN_PERMISSIONS,
            }),
          );
          dispatch(setAccountIdAction(validAccountId));
          if (validAccountId !== String(initialAccountId)) {
            useRouterStore.getState().navigate?.(getRedirectDashboardRoute(validAccountId), {
              replace: true,
            });
          }
        }
      } else {
        window.location.assign(membersLink);
      }
    },
    initialStatus: "init",
  },
);

const initAccount: IAccount = {
  accountId: "",
  accountName: "",
  active: true,
  permissions: [],
  role: -1,
};

export const setAccountAction = declareAction<IAccount>("setAccountAction");

export const accountAtom = declareAtom<IAccount>("accountAtom", initAccount, (on) => [
  on(setAccountAction, (_, account) => ({ ...account })),
]);

// export const isOwnerSelector = map(
//   accountAtom,
//   (state) => state.role === 1 && state.permissions.includes("subscribe"),
// );
