import { BannerContainer, StatusSwitcher, ToastContainer } from "@helium10/re-ui-components";
import { memo, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";

import { useRedirect } from "../../core/hooks/useRedirect";
import { moduleRoute } from "./core/links";
import { AccessManagement } from "./features/accessManagement";
import { ActivateUser } from "./features/activateUser";
import { Authenticator } from "./features/authenticator";
import { ChangeEmail } from "./features/changeEmail";
import { ChangePassword } from "./features/changePassword";
import { CodeVerification } from "./features/codeVerification";
import { ConfirmMethod } from "./features/confirmMethod";
import { DemoDashBoard } from "./features/demoDashBoard";
import { DemoDetailPage } from "./features/demoDetailPage";
import { PasswordRecovery } from "./features/passwordRecovery";
import { SetPassword } from "./features/setPassword";
import { SignIn } from "./features/signIn";
import { SignUp } from "./features/signUp";
import { UserSettings } from "./features/userSettings";
import {
  translationController,
  UserSystemTranslateProvider,
  useTranslation,
} from "./i18n/useTranslation";

export const UserSystem = memo(() => {
  return (
    <>
      <Helmet>
        <title>User System</title>
        <meta name="description" content="User System" />
      </Helmet>
      <ToastContainer position="bottom-left" autoClose={5000} closeOnClick={false} limit={3} />
      <UserSystemTranslateProvider>
        <Layout>
          <UserSystemRoutes />
        </Layout>
      </UserSystemTranslateProvider>
    </>
  );
});

export const useWithOutCredentialList = () => {
  return useMemo(() => {
    return [
      moduleRoute.setPassword,
      moduleRoute.changePassword,
      moduleRoute.signUp,
      moduleRoute.signIn,
      moduleRoute.recovery,
      moduleRoute.changeEmail,
      moduleRoute.confirmMethod,
      moduleRoute.setSubUserPassword,
      moduleRoute.authenticator,
      moduleRoute.codeVerification,
      moduleRoute.confirmEmail,
    ];
  }, []);
};

const InitTranslation = () => {
  const translation = useTranslation();

  useEffect(() => {
    translationController.set(translation);
  }, [translation]);

  return null;
};

const UserSystemRoutes = () => {
  const { getPath } = useRedirect();

  return (
    <>
      <BannerContainer limit={4} containerId={"userSystem-main"} />
      <InitTranslation />
      <StatusSwitcher status={"loaded"}>
        <Routes>
          <Route path={moduleRoute.settings} element={<UserSettings />} />
          <Route path={moduleRoute.setPassword} element={<SetPassword />} />
          <Route path={moduleRoute.changePassword} element={<ChangePassword />} />
          <Route path={moduleRoute.recovery} element={<PasswordRecovery />} />
          <Route path={moduleRoute.changeEmail} element={<ChangeEmail />} />
          <Route path={moduleRoute.confirmMethod} element={<ConfirmMethod />} />
          <Route path={moduleRoute.authenticator} element={<Authenticator />} />
          <Route path={moduleRoute.codeVerification} element={<CodeVerification />} />
          <Route path={moduleRoute.demoDashBoard} element={<DemoDashBoard />} />
          <Route path={moduleRoute.demoDetailPage} element={<DemoDetailPage />} />
          <Route path={moduleRoute.setSubUserPassword} element={<SetPassword />} />
          <Route path={moduleRoute.accessManagement} element={<AccessManagement />} />
          <Route path={moduleRoute.signUp} element={<SignUp />} />
          <Route path={moduleRoute.signIn} element={<SignIn />} />
          <Route path={moduleRoute.confirmEmail} element={<ActivateUser />} />
          <Route path={moduleRoute.activateNewEmail} element={<ActivateUser />} />
          <Route
            path={moduleRoute.home}
            element={<Navigate to={getPath("/dashboard")} replace />}
          />
          <Route path={`/*`} element={<Navigate to={getPath("/dashboard")} replace />} />
        </Routes>
      </StatusSwitcher>
    </>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
