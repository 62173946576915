import { reCaptchaV2Callback } from "@helium10/re-core";
import { InputErrorMessage, NewButton, NewCheckbox, NewInput } from "@helium10/re-ui-components";
import { userloginController } from "@walmart10/container/core/store/loginStore";
import { memo, useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ReCaptchaV2 } from "recaptcha-v2v3";
import styled from "styled-components";

import { useRedirect } from "@/core/hooks/useRedirect";

import { appRoutes } from "../../../core/links";
import { useTranslation } from "../../../i18n/useTranslation";
import walmartlogoSrc from "../../../static/icons/walmartlogo.svg";
export interface ISignInParams {
  email: string;
  password: string;
  rememberMe: boolean;
}

export const SignInFormWrapper = memo(() => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignInParams>();

  const { getPath, redirect } = useRedirect();
  const [captchaEnable, setCaptchaEnable] = useState(true);
  const key = "CaptchaEnable";
  function setCaptchaEnableFunc(enable: boolean = true) {
    localStorage.setItem(key, "" + enable);
    setCaptchaEnable(enable);
    setReCaptchaValidated(true);
  }
  function getCaptchaEnableFunc() {
    const captcha = localStorage.getItem(key);
    if (captcha !== null && !JSON.parse(captcha)) {
      setCaptchaEnable(JSON.parse(captcha));
      setReCaptchaValidated(true);
    }
  }
  useEffect(() => {
    window.setCaptchaEnable = setCaptchaEnableFunc;
    getCaptchaEnableFunc();
  }, []);

  const handleRouteChangeToSignUp = useCallback(() => {
    redirect(getPath(appRoutes.signUp));
  }, [redirect, getPath]);

  const handleRouteChangeToRecovery = useCallback(() => {
    redirect(getPath(appRoutes.recovery));
  }, [redirect, getPath]);

  const JumpToSignUp = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    handleRouteChangeToSignUp();
  };

  const JumpToRecovery = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    handleRouteChangeToRecovery();
  };

  const [reCaptchaError, setReCaptchaError] = useState(false);
  const [reCaptchaValidated, setReCaptchaValidated] = useState(false);

  const handleReCaptchaCallback = (response: string | false | Error) => {
    reCaptchaV2Callback(response);
    setReCaptchaValidated(!!response);
    setReCaptchaError(!response);
  };

  const loginController = userloginController();

  const onSubmit: SubmitHandler<ISignInParams> = (data) => {
    if (!reCaptchaValidated) {
      setReCaptchaError(true);
      return;
    }
    console.log(data);
    userloginController.action({
      email: data.email,
      password: data.password,
      rememberMe: data.rememberMe ? 1 : 0,
    });
  };

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <SignUpFormWrapperTitle>{t("signIn.title")}</SignUpFormWrapperTitle>
      <ContainerLogo href="https://www.helium10.com/">
        <img src={walmartlogoSrc} alt="Helium 10" />
      </ContainerLogo>
      <FormGroup>
        <SignUpFormLabel htmlFor="email">{t("common.email")}</SignUpFormLabel>
        <NewInput
          id="email"
          size={"400"}
          placeholder={t("signIn.emailPlaceholder")}
          {...register("email", {
            required: t("common.emailCheck"),
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i,
              message: t("common.emailCheck2"),
            },
          })}
        />
        {errors.email && <InputErrorMessage>{String(errors.email.message)}</InputErrorMessage>}
      </FormGroup>
      <FormGroup>
        <SignUpFormLabel htmlFor="password">{t("common.password")}</SignUpFormLabel>
        <NewInput
          id="password"
          type="password"
          size={"400"}
          placeholder={t("signIn.passwordPlaceholder")}
          {...register("password", { required: true })}
        />
        {errors.password && <InputErrorMessage>{t("common.passwordCheck")}</InputErrorMessage>}
        <ForgotPasswordLinkWrapper>
          <ForgotPasswordLink onClick={JumpToRecovery}>
            {t("signIn.forgotPassword")}
          </ForgotPasswordLink>
        </ForgotPasswordLinkWrapper>
      </FormGroup>
      <FormGroup>
        {captchaEnable && (
          <CaptchaWrapper>
            <ReCaptchaV2 callback={handleReCaptchaCallback} />
            {reCaptchaError && (
              <InputErrorMessage>{t("common.reCaptchaVerification")}</InputErrorMessage>
            )}
          </CaptchaWrapper>
        )}
      </FormGroup>
      <FormGroup>
        <NewCheckbox {...register("rememberMe")}>
          <CheckboxTextWrapper>{t("signIn.rememberMe")}</CheckboxTextWrapper>
        </NewCheckbox>
        <TipWrapper>({t("signIn.rememberMeText")})</TipWrapper>
      </FormGroup>
      <NewButtonWrapper
        size="400"
        buttonType={"primary"}
        type="submit"
        loading={loginController.status === "loading"}
      >
        <NewButtonText>{t("common.login")}</NewButtonText>
      </NewButtonWrapper>
      <SignUpWrapper>
        {t("signIn.noAccount")}{" "}
        <SignUpTextLink onClick={JumpToSignUp}>{t("signIn.signUp")}</SignUpTextLink>
      </SignUpWrapper>
      <ContackWrapper>
        {t("signIn.questions")}{" "}
        <SignUpTextLink href="https://helium10.zendesk.com/hc/en-us/requests/new" target="_blank">
          {t("signIn.contact")}
        </SignUpTextLink>
      </ContackWrapper>
    </FormWrapper>
  );
});

const FormWrapper = styled.form`
  width: 410px;
  height: 835px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.sp20};
`;

const ContainerLogo = styled.a`
  width: 200px;
  margin-bottom: 24px;

  & > img {
    display: block;
    width: 100%;
  }
`;

const SignUpFormWrapperTitle = styled.h3`
  /* font-family: "Neuton", serif; */
  /* font-size: 44px; */
  font-size: 38px;
  font-weight: 400;
  letter-spacing: 2px;
  text-align: center;
  color: #003873;
  margin: 0px auto 24px;
  white-space: nowrap;
`;

const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const SignUpFormLabel = styled.label`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.375;
  color: #646464;
  margin-bottom: 8px;
  display: inline-block;
`;

const ForgotPasswordLinkWrapper = styled.div`
  text-align: right;
  margin-top: 8px;
`;

const ForgotPasswordLink = styled.a`
  font-size: 16px;
  font-weight: 600;
  color: #0081ff;
  text-decoration: none;
  line-height: 1.375;
  cursor: pointer;
`;

const CheckboxTextWrapper = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #343a40;
  letter-spacing: 0.5px;
  line-height: 1.375;
`;

const TipWrapper = styled.div`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 300;
  color: #343a40;
`;

const NewButtonWrapper = styled(NewButton)`
  width: 410px;
`;

const NewButtonText = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #ede8e4;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.375;
`;

const SignUpWrapper = styled.div`
  margin-top: 1rem;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.45px;
  color: #343a40;
`;

const SignUpTextLink = styled.a`
  color: #337ab7;
  text-decoration: none;
  cursor: pointer;
`;

const ContackWrapper = styled(SignUpWrapper)`
  margin-top: 10px;
`;

const CaptchaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
