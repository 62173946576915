import { baseFetch, getCurrentAccountId, getUrlWithSearchParams } from "@helium10/re-core";

import { updateCustomerLink } from "@/core/link/apiLink";
import type { IRejected } from "@/core/rejectReaction";

export const updateCustomer = async (params: IUpdateCustomerProperties) => {
  return baseFetch<IUpdateCustomerProperties, IRejected>({
    method: "PUT",
    withCredentials: true,
    params,
    url: getUrlWithSearchParams(updateCustomerLink, { accountId: getCurrentAccountId() }),
    useSessionAccountId: true,
  });
};

export interface IUpdateCustomerProperties {
  isReactAvailable?: "true" | "false";
  isReactEnabled?: "true" | "false";
  isReactDisableAlertShown?: "true" | "false";
  isReactAvailableAlertShown?: "true" | "false";
  isDaypartingTooltipShown?: "true" | "false";

  isReactCampaignsAvailable?: "true" | "false";
  isReactCampaignsEnabled?: "true" | "false";
  isReactCampaignsWelcomeShown?: "true" | "false";
  isReactCampaignsTooltipShown?: "true" | "false";

  isReactSuggestionsAvailable?: "true" | "false";
  isReactSuggestionsEnabled?: "true" | "false";
  isReactSuggestionsWelcomeShown?: "true" | "false";
  isReactSuggestionsTooltipShown?: "true" | "false";

  isReactBuilderEnabled?: "true" | "false";
  isReactBuilderAvailable?: "true" | "false";
  isReactBuilderWelcomeShown?: "true" | "false";
  isReactBuilderAlertShown?: "true" | "false";

  isOnboardingAdManagerBuilderTooltipShown?: "true" | "false";
  isOnboardingAdManagerRuleTooltipShown?: "true" | "false";
  isOnboardingBuilderCreationTooltipShown?: "true" | "false";
  isOnboardingRuleCreationTooltipShown?: "true" | "false";
  isOnboardingBannerShown?: "true" | "false";

  isAdtomicOnboardingEnabled?: "true" | "false";
  showQuickActionsWarning?: "true" | "false";
}

export interface IExtendedUpdateCustomerProperties extends IUpdateCustomerProperties {
  adtomicCancellation?: { reason: string[]; comment: string };
}
