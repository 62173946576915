import type { ILeitenPrimitive } from "@helium10/re-core";
import { media, SelectionCard } from "@helium10/re-ui-components";
import { Elements } from "@stripe/react-stripe-js";
import type { Stripe } from "@stripe/stripe-js";
import type { ReactElement } from "react";
import { useCallback } from "react";
import styled from "styled-components";

import { Image } from "../../common/components/Image";
import type { IPaymentMethods } from "../../common/types/IPaymentMethods";
import { useTranslation } from "../../i18n/useTranslation";
import alipayImage from "../../static/images/alipay.svg";
import alipayMobImage from "../../static/images/alipay-mob.svg";
import creditCardImage from "../../static/images/credit-card.svg";
import creditCardMobImage from "../../static/images/credit-card-mob.svg";
import { PaymentAlipay } from "./components/PaymentAlipay";
import { PaymentCard } from "./components/PaymentCard";
import { Title } from "./Styles";

type IData = {
  paymentMode: IPaymentMethods;
  paymentMethods?: IPaymentMethods[];
  paymentModeController: ILeitenPrimitive<IPaymentMethods>;
  paymentEditingController: ILeitenPrimitive<boolean>;
  stripe: Stripe | null;
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export type IUseCard = any;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export type IUseAlipay = any;

export interface IPaymentProps {
  isModal?: boolean;
  isWithoutTitle?: boolean;
  isCardAvailable?: boolean;
  data: IData;
  modalText?: string;
  actions: ReactElement;
  disclaimer?: ReactElement;
  securePayment?: ReactElement;
  recaptchaV2?: ReactElement;
  useAlipay?: () => IUseCard;
  useCard: () => IUseAlipay;
}

export const Checkout = ({
  isModal,
  isWithoutTitle,
  isCardAvailable,
  data,
  actions,
  modalText,
  disclaimer,
  securePayment,
  recaptchaV2,
  useAlipay,
  useCard,
}: IPaymentProps) => {
  const { t } = useTranslation();
  const { paymentMode, paymentMethods, stripe, paymentModeController, paymentEditingController } =
    data || {};

  const handleAlipayMethod = useCallback(() => {
    paymentModeController?.set("alipay");
  }, [paymentModeController]);

  const handleCardMethod = useCallback(() => {
    paymentModeController?.set("card");
  }, [paymentModeController]);

  const handleEditCard = useCallback(() => {
    paymentModeController?.set("card");
    paymentEditingController?.set(true);
  }, [paymentModeController, paymentEditingController]);

  const isModalCardAvailable = isModal && isCardAvailable;
  const filteredPaymentMethods = paymentMethods?.filter((method) => method !== "apple_pay") ?? [];
  const isMultiplePaymentMethods = !isModalCardAvailable && filteredPaymentMethods?.length > 1;

  const optionsNode = isMultiplePaymentMethods && (
    <>
      <Title>{t("checkout.method.selectTitle")}</Title>
      <SelectMethod>
        <Option $isModal={isModal} active={paymentMode === "alipay"} onClick={handleAlipayMethod}>
          <Image image={alipayImage} smallImage={alipayMobImage} />
        </Option>
        <Option $isModal={isModal} active={paymentMode === "card"} onClick={handleCardMethod}>
          {t("checkout.method.secureCreditCard")}
          <Image image={creditCardImage} smallImage={creditCardMobImage} />
        </Option>
      </SelectMethod>
    </>
  );

  const { [paymentMode]: paymentNode } = {
    alipay: (
      <PaymentAlipay
        useAlipay={useAlipay}
        recaptchaV2={recaptchaV2}
        disclaimer={disclaimer}
        actions={actions}
      />
    ),
    card: (
      <PaymentCard
        isWithoutTitle={isWithoutTitle}
        isModal={isModal}
        useCard={useCard}
        modalText={modalText}
        recaptchaV2={recaptchaV2}
        handleEditCard={handleEditCard}
        securePayment={securePayment}
        disclaimer={disclaimer}
        actions={actions}
      />
    ),
    apple_pay: null,
  };

  return (
    <Elements stripe={stripe}>
      {optionsNode}
      {paymentNode}
    </Elements>
  );
};

const SelectMethod = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
`;

const Option = styled(SelectionCard)<{ $isModal?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  color: ${(p) => p.theme.systemColors.black};
  height: ${({ $isModal }) => ($isModal ? "72px" : "96px")};
  padding: 0;
  gap: 8px;

  ${media.laptopBefore} {
    min-width: 100%;
  }

  ${media.desktopBefore} {
    flex: 1;
  }

  ${media.desktop} {
    width: 160px;
  }
`;
