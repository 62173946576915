import { IFeatureCreator, leitenFeature, leitenPrimitive, leitenRequest } from "@helium10/re-core";
import { toastNotification, ToastTitle } from "@helium10/re-ui-components";
import { create } from "zustand";

import { forgotPassword, IForgotPasswordResponse } from "../requests";

interface IRecovery {
  email: string;
}

interface IState {
  forgotPasswordResponse: IForgotPasswordResponse;
  getRecoveryParams: {
    email: string;
  };
}

export type IRecoveryFeature = IFeatureCreator<IRecovery>;

export const [useRecoveryStore, RecoveryProvider] = leitenFeature(
  ({ useProps }: IRecoveryFeature) => {
    const props = useProps.getState();

    const useStore = create<IState>(() => ({
      forgotPasswordResponse: {
        code: 200,
        data: null,
        msg: null,
      },
      getRecoveryParams: {
        email: props.email,
      },
    }));

    const useRecoveryParamsController = leitenPrimitive(useStore, "getRecoveryParams");

    const getRecoveryParamsSelector = (state: IState) => {
      return { ...state.getRecoveryParams };
    };

    const useRecoveryController = leitenRequest(
      useStore,
      "forgotPasswordResponse",
      forgotPassword,
      {
        fulfilled: ({ payload, result }) => {
          useRecoveryParamsController.set({
            email: payload.email,
          });
          console.log(result);
        },
        rejected: ({ fetchError }) => {
          console.log(fetchError?.result?.msg);
          const errorMessage = fetchError?.result?.msg;
          toastNotification.error(<ToastTitle>{errorMessage}</ToastTitle>);
        },
      },
    );

    return {
      useStore,
      useProps,
      useRecoveryController,
      useRecoveryParamsController,
      getRecoveryParamsSelector,
    };
  },
);
