import type { ILoadingStatus } from "@helium10/re-core";
import { combine, declareRequest, map } from "@helium10/re-core";

import type {
  IAccountState,
  IGetAccountAccessStateResponse,
} from "@/requests/settings/getProfilesAccessState";
import { getProfilesAccessState } from "@/requests/settings/getProfilesAccessState";

import { useAccessesStore } from "../accesses/accessesStore";

export const accessRequest = declareRequest<void, IGetAccountAccessStateResponse | null>(
  "access",
  getProfilesAccessState,
  {
    fulfilledReaction: ({ result }) => {
      useAccessesStore.setState({ accesses: result });
    },
    initialStatus: "init",
  },
);

export const accessStatusSelector = map(
  combine([accessRequest.atom]),
  ([accessRequest]): ILoadingStatus => {
    return accessRequest.status;
  },
);

export const isVoidResult = (result: Record<string, IAccountState> | undefined) => {
  const isNull = result === null || result === undefined;
  const isEmpty = !isNull && JSON.stringify(result) === "{}";
  return isNull || isEmpty;
};
