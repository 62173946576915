import { leitenRequest } from "@helium10/re-core";
import { create } from "zustand";

import { IUserLoginResponse, usersLogin } from "../requests/usersLogin";

export interface IUserLoginInfo {
  userLogin: IUserLoginResponse | null;
}

export const userWickUserLoginStore = create<IUserLoginInfo>(() => ({
  userLogin: null,
}));

export const userloginController = leitenRequest(userWickUserLoginStore, "userLogin", usersLogin);
