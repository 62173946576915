import { baseFetch } from "@helium10/re-core";

import { getHeaderHelper } from "@/core/helpers/getHeader.helper";
import { accountSettingsLinks } from "@/core/link/apiLink";

export const getProfilesAccessState = async () => {
  return baseFetch<void, IGetAccountAccessStateResponse>({
    method: "GET",
    withCredentials: true,
    headers: getHeaderHelper(),
    useSessionAccountId: true,
    url: accountSettingsLinks.accessStateV2,
  });
};

export type IAccountState = "SUBSCRIBED" | "UNSUBSCRIBED" | "DISABLED";
export interface IGetAccountAccessStateResponse {
  results: Record<string, IAccountState>;
}
