import { cssBody100, media } from "@helium10/re-ui-components";
import type { ReactElement } from "react";
import { cloneElement, useMemo } from "react";
import { Controller } from "react-hook-form";
import styled, { css } from "styled-components";

import { CreditCard } from "../../../common/components/CreditCard";
import { useTranslation } from "../../../i18n/useTranslation";
import stripePoweredImage from "../../../static/images/powered_by_stripe.png";
import type { IUseCard } from "../Checkout";
import { US_ISO_CODE } from "../const/params";
import { ApplePayButton, Content, Footer, Form, Input, Link, Row, SmallTitle } from "../Styles";
import {
  getCardCvcRules,
  getCardExpiryRules,
  getCardNameRules,
  getCardNumberRules,
  getCityRules,
  getCountryRules,
  getStateRules,
  getStreetRules,
  getZipRules,
} from "../utils/getFormRules";
import { ErrorMessages } from "./ErrorMessages";
import { LabeledName } from "./LabeledName";
import { LabeledSelect } from "./LabeledSelect";
import { LabeledStripe } from "./LabeledStripe";
import { StreetInput } from "./StreetInput";

interface IPaymentCard {
  isWithoutTitle?: boolean;
  isModal?: boolean;
  modalText?: string;
  recaptchaV2?: ReactElement;
  securePayment?: ReactElement;
  disclaimer?: ReactElement;
  actions: ReactElement;
  useCard: () => IUseCard;
  handleEditCard: () => void;
}

export const PaymentCard = ({
  isWithoutTitle,
  isModal,
  recaptchaV2,
  securePayment,
  disclaimer,
  modalText,
  actions,
  useCard,
  handleEditCard,
}: IPaymentCard) => {
  const { t } = useTranslation();
  const info = useCard();
  const rules = useMemo(
    () => ({
      cardName: getCardNameRules(t),
      cardNumber: getCardNumberRules(t),
      cardExpiry: getCardExpiryRules(t),
      cardCvc: getCardCvcRules(t),
      country: getCountryRules(t),
      street: getStreetRules(t),
      city: getCityRules(t),
      state: getStateRules(t),
      zip: getZipRules(t),
    }),
    [t],
  );

  const modalNode = (
    <ModalForm onSubmit={info?.handleSubmit(info?.onSubmitCard)}>
      <Content>{modalText}</Content>
      <Footer>
        {disclaimer}
        {recaptchaV2 && info?.isEnabledRecaptchaV2 && (
          <CaptchaWrapper>{recaptchaV2}</CaptchaWrapper>
        )}
        {info?.formState?.errors?.root?.stripe && (
          <ModalErrors>
            <ErrorMessages type="card" message={info?.formState?.errors?.root?.stripe?.message} />
          </ModalErrors>
        )}
        {cloneElement(actions, {
          isLoading: info?.isLoading,
          isSubmitting: info?.isSubmitting,
        })}
      </Footer>
    </ModalForm>
  );

  const editNode = (
    <Form onSubmit={info?.handleSubmit(info?.onSubmitCard)}>
      <EditTitle>{t("checkout.method.currentTitle")}</EditTitle>
      {info?.card?.brand && (
        <CurrentPaymentMethod>
          <CreditCard type={info?.card?.brand} />
          {t("checkout.method.currentCard", {
            brand: info?.card?.brand,
            last4: info?.card?.last4 || "",
          })}
        </CurrentPaymentMethod>
      )}
      <StyledLink onClick={handleEditCard}>{t("checkout.method.changePaymentMethod")}</StyledLink>
      <Footer>
        {disclaimer}
        {recaptchaV2 && info?.isEnabledRecaptchaV2 && (
          <CaptchaWrapper>{recaptchaV2}</CaptchaWrapper>
        )}
        {cloneElement(actions, {
          isLoading: info?.isLoading,
          isSubmitting: info?.isSubmitting,
        })}
      </Footer>
    </Form>
  );

  if (info?.isCardAvailable) {
    return isModal ? modalNode : editNode;
  }

  return (
    <>
      {info?.isApplePayAvailable && (
        <PaymentOptions>
          <ApplePayButton disabled={info?.isSubmitting} />
          <Separator>{t("checkout.method.separator")}</Separator>
        </PaymentOptions>
      )}
      {!isWithoutTitle && !info?.isApplePayAvailable && (
        <SmallTitle>{t("checkout.card.title")}</SmallTitle>
      )}
      <Form $isWithoutTitle={isWithoutTitle} onSubmit={info?.handleSubmit(info?.onSubmitCard)}>
        <Row>
          <Controller
            name="cardNumber"
            control={info?.control}
            rules={rules?.cardNumber}
            render={({ field }) => (
              <LabeledStripe
                id="cardNumber"
                type="cardNumber"
                label={t("checkout.card.form.number.label")}
                placeholder={t("checkout.card.form.number.placeholder")}
                error={info?.formState?.errors?.cardNumber?.message}
                disabled={info?.isSubmitting}
                onBlur={field?.onBlur}
                onChange={field?.onChange}
              />
            )}
          />
        </Row>
        <Row>
          <Controller
            name="cardName"
            control={info?.control}
            rules={rules?.cardName}
            render={({ field }) => (
              <LabeledName
                id="cardName"
                label={t("checkout.card.form.name.label")}
                placeholder={t("checkout.card.form.name.placeholder")}
                error={info?.formState?.errors?.cardName?.message}
                disabled={info?.isSubmitting}
                value={field?.value}
                onBlur={field?.onBlur}
                onChange={field?.onChange}
              />
            )}
          />
          <Controller
            name="cardExpiry"
            control={info?.control}
            rules={rules?.cardExpiry}
            render={({ field }) => (
              <LabeledStripe
                id="cardExpiry"
                type="cardExpiry"
                label={t("checkout.card.form.expiry.label")}
                placeholder={t("checkout.card.form.expiry.placeholder")}
                error={info?.formState?.errors?.cardExpiry?.message}
                disabled={info?.isSubmitting}
                onBlur={field?.onBlur}
                onChange={field?.onChange}
              />
            )}
          />
          <Controller
            name="cardCvc"
            control={info?.control}
            rules={rules?.cardCvc}
            render={({ field }) => (
              <LabeledStripe
                id="cardCvc"
                type="cardCvc"
                label={t("checkout.card.form.cvc.label")}
                placeholder={t("checkout.card.form.cvc.placeholder")}
                error={info?.formState?.errors?.cardCvc?.message}
                disabled={info?.isSubmitting}
                onBlur={field?.onBlur}
                onChange={field?.onChange}
              />
            )}
          />
        </Row>
        <Cards>
          <CardTypes>
            <CreditCard isGray type="Visa" />
            <CreditCard isGray type="Mastercard" />
            <CreditCard isGray type="Discover" />
            <CreditCard isGray type="Amex" />
            <CreditCard isGray type="Unionpay" />
          </CardTypes>
          <PoweredStripe />
        </Cards>
        <Additional $isModal={isModal}>
          <Row>
            <Controller
              name="country"
              control={info?.control}
              rules={rules?.country}
              render={({ field }) => (
                <CountrySelector
                  appendTo={document.body}
                  label={t("checkout.card.form.country.label")}
                  placeholder={t("checkout.card.form.country.placeholder")}
                  error={info?.formState?.errors?.country?.message}
                  disabled={info?.isSubmitting}
                  options={info?.countries}
                  value={field?.value}
                  onChange={field?.onChange}
                />
              )}
            />
          </Row>
          {info?.country === US_ISO_CODE && (
            <>
              <Row>
                <Controller
                  name="street"
                  control={info?.control}
                  rules={rules?.street}
                  render={({ field }) => (
                    <StreetInput
                      size="200"
                      label={t("checkout.card.form.street.label")}
                      placeholder={t("checkout.card.form.street.placeholder")}
                      error={info?.formState?.errors?.street?.message}
                      disabled={info?.isSubmitting}
                      value={field?.value}
                      onBlur={field?.onBlur}
                      onChange={field?.onChange}
                      setValue={info?.setValue}
                    />
                  )}
                />
              </Row>
              <Row>
                <Controller
                  name="city"
                  control={info?.control}
                  rules={rules?.city}
                  render={({ field }) => (
                    <CityInput
                      size="200"
                      label={t("checkout.card.form.city.label")}
                      placeholder={t("checkout.card.form.city.placeholder")}
                      error={info?.formState?.errors?.city?.message}
                      disabled={info?.isSubmitting}
                      value={field?.value}
                      onBlur={field?.onBlur}
                      onChange={field?.onChange}
                    />
                  )}
                />
                <Controller
                  name="state"
                  control={info?.control}
                  rules={rules?.state}
                  render={({ field }) => (
                    <LabeledSelect
                      appendTo="parent"
                      label={t("checkout.card.form.state.label")}
                      placeholder={t("checkout.card.form.state.placeholder")}
                      error={info?.formState?.errors?.state?.message}
                      disabled={info?.isSubmitting}
                      options={info?.states}
                      value={field?.value}
                      onChange={field?.onChange}
                    />
                  )}
                />
                <Controller
                  name="zip"
                  control={info?.control}
                  rules={rules?.zip}
                  render={({ field }) => (
                    <Input
                      size="200"
                      label={t("checkout.card.form.zip.label")}
                      placeholder={t("checkout.card.form.zip.placeholder")}
                      error={info?.formState?.errors?.zip?.message}
                      disabled={info?.isSubmitting}
                      value={field?.value}
                      onBlur={field?.onBlur}
                      onChange={field?.onChange}
                    />
                  )}
                />
              </Row>
            </>
          )}
        </Additional>
        {info?.formState?.errors?.root?.stripe && (
          <ErrorMessages type="card" message={info?.formState?.errors?.root?.stripe?.message} />
        )}
        <Footer>
          {securePayment}
          {disclaimer}
          {recaptchaV2 && info?.isEnabledRecaptchaV2 && (
            <CaptchaWrapper>{recaptchaV2}</CaptchaWrapper>
          )}
          {cloneElement(actions, {
            isEditing: info?.isEditing,
            isLoading: info?.isLoading,
            isSubmitting: info?.isSubmitting,
          })}
        </Footer>
      </Form>
    </>
  );
};

const ModalForm = styled(Form)`
  margin: ${(p) => `${p.theme.spacing.sp6} 0 0 0`};
`;

const ModalErrors = styled.div`
  display: flex;
  justify-content: center;
`;

const EditTitle = styled(SmallTitle)`
  color: rgb(72, 94, 117);
  font-weight: 400;
  margin: 0 0 12px 0;
`;

const CurrentPaymentMethod = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  gap: 3px;
  margin-bottom: ${(p) => p.theme.spacing.sp24};
`;

const StyledLink = styled(Link)`
  font-weight: 700;
  font-size: 14px;
  margin: ${(p) => `0 0 ${p.theme.spacing.sp16}`};
`;

const CityInput = styled(Input)`
  min-width: 100%;

  ${media.desktop} {
    min-width: 45%;
  }
`;

const CountrySelector = styled(LabeledSelect)`
  flex: 1;

  ${media.desktop} {
    width: 230px;
  }
`;

const Cards = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CardTypes = styled.div`
  display: flex;
`;

const PoweredStripe = styled.img.attrs({ src: stripePoweredImage, alt: "Stripe" })`
  user-select: none;
`;

const Additional = styled.div<{ $isModal?: boolean }>`
  flex-direction: column;
  margin: ${(p) => `${p.theme.spacing.sp16} 0 0`};

  ${(p) =>
    !p.$isModal &&
    css`
      border-top: 1px solid ${p.theme.systemColors.grey200};
      padding: ${p.theme.spacing.sp32} 0 0;
    `}
`;

const PaymentOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Separator = styled.div`
  ${cssBody100};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: ${(p) => `${p.theme.spacing.sp16} 0 0`};

  &:before,
  &:after {
    border-top: 1px solid ${(p) => p.theme.systemColors.grey200};
    content: "";
    flex: 1 1;
  }

  &:before {
    margin: ${(p) => `0 ${p.theme.spacing.sp16} 0 0`};
  }

  &:after {
    margin: ${(p) => `0 0 0 ${p.theme.spacing.sp16}`};
  }
`;

const CaptchaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: ${(p) => p.theme.spacing.sp16};
`;
