import { CORE_BEARER_TOKEN } from "@helium10/re-core";
import { CORE_REFRESH_TOKEN } from "@walmart10/container/helpers/refreshToken";
import { memo } from "react";
import styled from "styled-components";

import {
  accountIdSelector,
  useAccountStore,
  useCallbacksStore,
} from "../../../features/src/components/newMenu/store/callbacksStore";
import { ApiAccounts } from "../../../features/src/requests/accounts";
import { useLoginAction } from "../../core/hooks/useLoginAction";
import { appRoutes } from "../../core/links";
import logoSrc from "../../static/icons/2-colors-light-bg.svg";
import { SetPasswordForm } from "./components/SetPasswordForm";
import { ChangePasswordProvider } from "./store/index";

export const ChangePassword = memo(() => {
  useLoginAction();
  const accountId = useAccountStore(accountIdSelector);
  const callbacks = useCallbacksStore((state) => state.callbacks);
  const handleSignOut = () => {
    const logoutToken = useCallbacksStore.getState().callbacks?.logoutToken;
    if (callbacks.onLogout) {
      callbacks.onLogout?.(accountId);
    } else {
      ApiAccounts.signOut(logoutToken)?.finally(() => {
        localStorage.removeItem(CORE_REFRESH_TOKEN);
        localStorage.removeItem(CORE_BEARER_TOKEN);
        window.location.replace(appRoutes.signIn + "?to=settings");
      });
    }
  };
  return (
    <ChangePasswordProvider value={{ handleSignOut }}>
      <ContainerLogo href="https://www.helium10.com/">
        <img src={logoSrc} alt="Helium 10" />
      </ContainerLogo>
      <Container>
        <Wrapper>
          <SetPasswordForm />
        </Wrapper>
      </Container>
    </ChangePasswordProvider>
  );
});

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 73px);
  min-height: 835px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 410px;
  height: 835px;
  display: flex;
`;

const ContainerLogo = styled.a`
  width: 200px;
  height: 33px;
  margin-top: 40px;
  padding: 0 0 0 60px;

  & > img {
    display: block;
    width: 200px;
  }
`;
