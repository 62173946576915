import styled from "styled-components";

interface IPicture {
  smallImage?: string;
  image: string;
}

export const Image = ({ image, smallImage }: IPicture) => {
  return (
    <Picture>
      {smallImage && <source media="(max-width: 768px)" srcSet={smallImage} />}
      <Img src={image} />
    </Picture>
  );
};

const Picture = styled.picture`
  display: inline-flex;
  align-items: center;
`;

const Img = styled.img``;
