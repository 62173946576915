import { leitenRequest } from "@helium10/re-core";
import { create } from "zustand";

import { getWickUserInfo, IGetWickUserInfoResponse } from "../requests/userInfo";

export interface IUserInfo {
  userInfo: IGetWickUserInfoResponse["data"] | null;
}

export const useWickUserInfoStore = create<IUserInfo>(() => ({
  userInfo: null,
}));

export const useWickUserInfoController = leitenRequest(
  useWickUserInfoStore,
  "userInfo",
  async (_: void) => {
    const result = await getWickUserInfo();
    return result.data || {};
  },
);
