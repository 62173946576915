import { baseFetch } from "@helium10/re-core";

import { billingTermsLink } from "@/core/link/apiLink";
import type { IRejected } from "@/core/rejectReaction";
import type { BILLING_TERMS_ACCEPTANCE_STATES_TYPE } from "@/store/account/customerStore";

export const setBillingTerms = async (params: IAcceptanceParams) => {
  return baseFetch<IAcceptanceParams, IRejected>({
    method: "PUT",
    withCredentials: true,
    params,
    url: billingTermsLink,
    useSessionAccountId: true,
  });
};

export interface IAcceptanceParams {
  status: BILLING_TERMS_ACCEPTANCE_STATES_TYPE;
}
