import { create } from "zustand";

import type { IGetAccountAccessStateResponse } from "@/requests/settings/getProfilesAccessState";

interface IState {
  accesses: IGetAccountAccessStateResponse | null;
}

export const useAccessesStore = create<IState>(() => ({
  accesses: null,
}));
