import { BaseSingleSelect, IControlSize } from "@helium10/re-ui-components";
import styled from "styled-components";

import { IProfileInfoResponse } from "../requests";

export const SettingBox = styled.div`
  border: 1px solid #e4e5e7;
  background-color: #fff;
  box-shadow: none;
  margin: 0 auto;
  margin-bottom: 25px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 0.25rem;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  @media (min-width: 1200px) {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
`;

interface CardBodyProps {
  bordercolor?: string;
  display?: string;
  padding?: string;
}
export const CardBody = styled.div<CardBodyProps>`
  border-top: 2px solid #34495e;
  border-color: ${(props) => props.bordercolor || "#34495e"};
  background: #fff;
  border-radius: 2px;
  padding: ${(props) => props.padding || "20px"};
  position: relative;
  display: ${(props) => props.display || "block"};
`;

export const CardHeader = styled.div`
  background: #fff;
  padding: 10px 10px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 700;
`;

interface ProfileInfoProps {
  marginbottom?: string;
  height?: string;
}
export const ProfileInfo = styled.div<ProfileInfoProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: ${(props) => props.marginbottom || "1em"};
  flex: 1 0 100%;
  margin-left: -15px;
  margin-right: -15px;
  height: ${(props) => props.height};
`;

interface ColumnLinkType {
  underline?: boolean;
  fontSize?: string;
  fontWeight?: string;
  float?: string;
}
export const ColumnLink = styled.a<ColumnLinkType>`
  cursor: pointer;
  color: #337ab7;
  font-weight: ${(props) => props.fontWeight || "700"};
  text-transform: none;
  display: inline;
  font-size: ${(props) => props.fontSize};
  float: ${(props) => props.float};
  text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  &:not([href]) {
    color: inherit;
  }
`;

export const MyBaseSingleSelect = styled(BaseSingleSelect)`
  /* line-height: 19px;
  height: 19px;
  width: 48px; */
  > div {
    border: none;
    &[data-open="true"] {
      outline-color: transparent;
    }
    > div > div > div > span {
      font-weight: 600;
    }
  }
`;

type ProfileBoxProps = (() => void | false) | undefined;

export interface IModalProps {
  isOpen: boolean;
  onClose: ProfileBoxProps;
  handleChange?: () => void;
}

export interface IProfileModalProps extends IModalProps {
  isOpen: boolean;
  onClose: ProfileBoxProps;
  profile: IProfileInfoResponse;
}

export type AddressInfoType = {
  nation: string;
  phone: string;
  post: string;
  name: string;
  address: string;
  district: string;
  province: string;
};

export interface IconModalProps extends IModalProps {
  userIcon: string;
  setIconName: (name: string) => void;
  iconList: { name: string; path: string }[];
}

export const iconSize: IControlSize = "200";

export function getFilenameWithoutExtension(filePath: string) {
  const filenameWithExtension = filePath.split("/").pop();
  if (!filenameWithExtension) return "";
  const filenameWithoutExtension = filenameWithExtension.split(".").slice(0, -1).join(".");
  return filenameWithoutExtension;
}

export function getIconPath(iconList: { name: string; path: string }[], name: string) {
  return iconList.find((i) => i.name === name);
}
