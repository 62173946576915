import { csrfFetch, getCsrf, membersLink } from "@helium10/re-core";

import { domain } from "../components/newMenu/links/apiLinks";

export namespace ApiAccounts {
  const spaLink = membersLink + "/api/v1/site/logout";
  const link = domain + "/user/signout";

  export const signOut = (logoutToken?: string) => {
    if (logoutToken) {
      return csrfFetch<Record<string, string>, void>({
        url: spaLink,
        method: "POST",
        logoutToken,
        params: {
          token: logoutToken,
        },
        withCredentials: true,
      });
    }

    const csrf = getCsrf();
    if (csrf) {
      return csrfFetch<Record<string, string>, void>({
        url: link,
        method: "POST",
        params: {
          [csrf.key]: csrf.value,
        },
        withCredentials: true,
      });
    }
  };
}
