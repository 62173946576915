import { baseFetch } from "@helium10/re-core";

import { userSystem } from "../../../core/links";

// register
export interface IForgotPassword {
  email: string;
}
export interface IForgotPasswordResponse {
  code: number;
  data: null;
  msg: string | null;
}

export const forgotPassword = async (params: IForgotPassword) => {
  return baseFetch<IForgotPassword, IForgotPasswordResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: false,
    url: userSystem.forgotPassword,
    params: params,
  });
};
