import type { IModalCreator } from "@helium10/re-core";
import {
  combine,
  createModal,
  declareAction,
  declareRequest,
  map,
  useRouterStore,
} from "@helium10/re-core";
import type { DataTableType, IGroupOption, IOption } from "@helium10/re-ui-components";
import { create } from "zustand";
import { shallow } from "zustand/shallow";

import { useBaseModalStore } from "@/components/modals/modalStore";
import { sendSegmentEventRequest } from "@/core/gtm/gtmStore";
import type { IProfile } from "@/core/interfaces";
import type { ITableContent } from "@/core/interfaces/ITableContent";
import type { IRejected } from "@/core/rejectReaction";
import { rejectReaction } from "@/core/rejectReaction";
import type { IGroupProfiles, IGroupProfilesResponse } from "@/requests/getSubscriptions";
import { getSubscriptions, initialProfile } from "@/requests/getSubscriptions";
import type { IAcceptanceParams } from "@/requests/setBillingTerms";
import { setBillingTerms } from "@/requests/setBillingTerms";
import { updateSubscriptions } from "@/requests/updateSubscriptions";

import { useCommonStore } from "../data/commonStore";
import { accountAtom } from "./accountsStore";
import { adtomicCustomerRequest } from "./customerStore";
import { setProfileAction } from "./profileStore";

export const subscriptionsModalRequest = declareRequest<void, IGroupProfilesResponse>(
  "getSubscriptions",
  (_) => getSubscriptions(),
  {
    rejectedReaction: (_, { getState }) => {
      const navigate = useRouterStore.getState().navigate;
      const { accountId } = getState(accountAtom);
      navigate?.(`/panel/${accountId}/no-profiles`);
      // rejectReaction(payload, store);
    },
  },
);

export const subscriptionsRequest = declareRequest<string, IGroupProfilesResponse>(
  "getSubscriptions",
  async (_, { getState, dispatch }) => {
    const result = await getSubscriptions();
    const accounts = result.data.accounts;
    useCommonStore.getState().setGroupProfiles(accounts);
    useSubscriptions.getState().setSubscription(result);
    if (!accounts?.length) return result;

    const id = useCommonStore.getState().profile.id;
    const location = useRouterStore.getState().location;
    const query = new URLSearchParams(location?.search);

    let profile = findProfileById(id, accounts);

    const profileId = query.get("profileId");
    if (profileId) {
      const profileFromParams = findProfileById(profileId, accounts);
      if (profileFromParams.id) profile = profileFromParams;
    }
    if (!profile.id) {
      const adtomicCustomer = getState(adtomicCustomerRequest.atom);
      const lastProfileId = adtomicCustomer.content?.data.last_selected_profile_id;
      if (lastProfileId) {
        profile = findProfileById(lastProfileId, accounts);
      }
    }
    if (!profile.id) {
      const firstProfile = accounts[0].profiles[0];
      if (firstProfile) profile = firstProfile;
    }
    dispatch(
      setProfileAction({
        profile,
        subscribed: result.data.subscription_statuses[profile.id],
      }),
    );
    return result;
  },
  {
    rejectedReaction: (payload, store) => {
      rejectReaction(payload, store);
    },
    reducers: (on) => {
      on(subscriptionsModalRequest.actions.fulfilled, (_, { result }) => ({
        content: result,
        status: "loaded",
      }));
    },
    initialStatus: "init",
  },
);

export const subscriptionsUpdateRequest = declareRequest<
  { selections: Record<string, boolean> },
  IGroupProfilesResponse
>(
  "updateSubscriptions",
  ({ selections }) => {
    return updateSubscriptions(selections);
  },
  {
    fulfilledReaction: (payload, { dispatch, getState }) => {
      useBaseModalStore.getState().subscriptionsModal.close();
      dispatch(subscriptionsRequest.actions.pending(getState(accountAtom).accountId));
      if (!Object.values(payload.params.selections).some((e) => e)) {
        useSubscriptionsStore.getState().noActivePPCAccountsModal.open();
      }
    },
    rejectedReaction: (payload, store) => {
      rejectReaction(payload, store);
    },
    initialStatus: "init",
  },
);

export const setAcceptance = declareRequest<IAcceptanceParams, IRejected>(
  "setAcceptance",
  (params) => setBillingTerms(params),
  {
    rejectedReaction: (payload, store) => rejectReaction(payload, store),
    initialStatus: "init",
  },
);

export const enableCurrentSubscriptionAction = declareAction(
  "enableCurrentSubscription",
  (_: void, { dispatch }) => {
    const id = useCommonStore.getState().profile.id;
    dispatch(
      subscriptionsUpdateRequest.actions.pending({
        selections: { [id]: true },
      }),
    );
  },
);

export const profilesSelector = map(subscriptionsRequest.atom, (state): IGroupOption[] => {
  return (
    state.content?.data.accounts.map((account, index) => {
      const options: IOption[] = [];
      account.profiles.forEach((profile) => {
        options.push({ label: profile.domain, value: profile.id });
      });
      return { id: index + 1, label: account.brand_name || account.seller_string_id, options };
    }) || []
  );
});

type SubscriptionsType = ITableContent<DataTableType<IProfile>[]> & {
  statuses: Record<string, boolean>;
  disable: boolean;
  isLoading: boolean;
};

export const subscriptionsDataSelector = map(
  combine([accountAtom, subscriptionsModalRequest.atom, subscriptionsUpdateRequest.atom]),
  ([{ role, permissions }, { status, content }, { status: updateStatus }]): SubscriptionsType => {
    const data: DataTableType<IProfile>[] = [];

    content?.data.accounts.forEach((account) => {
      account.profiles.forEach((profile) => {
        data.push({
          ...profile,
          name: profile.custom_name || profile.brand_name || profile.seller_string_id,
        });
      });
    });
    const isLoading = status === "loading" || updateStatus === "loading";
    const disable = isLoading || (role !== 1 && !permissions.includes("subscribe"));

    return {
      content: data,
      status,
      statuses: content?.data.subscription_statuses || {},
      disable,
      isLoading,
    };
  },
);

export const getStartedStateSelector = map(
  combine([sendSegmentEventRequest.atom, subscriptionsUpdateRequest.atom, setAcceptance.atom]),
  ([{ status: segmentStatus }, { status: subscriptionStatus }, { status: acceptanceStatus }]) => {
    return !(
      segmentStatus === "loading" ||
      subscriptionStatus === "loading" ||
      acceptanceStatus === "loading"
    );
  },
);

interface ISubscriptionsState {
  noActivePPCAccountsModal: IModalCreator<undefined>;
}

export const useSubscriptionsStore = create<ISubscriptionsState>((set, get) => ({
  ...createModal(set, get, "noActivePPCAccountsModal", undefined),
}));

interface ISubscriptionState {
  subscription: IGroupProfilesResponse | null;
  setSubscription: (value: IGroupProfilesResponse) => void;
}

export const useSubscriptions = create<ISubscriptionState>((set, _) => ({
  subscription: null,
  setSubscription: (subscription) => {
    set({ subscription });
  },
}));

export const findProfileById = (id: string, profiles: IGroupProfiles[]): IProfile => {
  let profile: IProfile = initialProfile;
  profiles.forEach((accounts) => {
    const profileTemp = accounts.profiles.find((account) => {
      return account.id === id;
    });
    if (profileTemp) profile = profileTemp;
  });
  return profile;
};

export const getProfileSubscription = (id: string) => {
  const subscription = useSubscriptions.getState().subscription?.data;
  const profile = findProfileById(id, subscription?.accounts || []);
  const subscribed = subscription?.subscription_statuses[id];

  return { profile, subscribed };
};

export const useProfileSubscriptions = () => {
  const profile = useCommonStore((state) => state.profile);
  const subscribed = useSubscriptions(
    ({ subscription }) => subscription?.data.subscription_statuses[profile.id],
    shallow,
  );

  return { profile, subscribed, exist: profile.id && subscribed !== undefined };
};
