import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCircleCheck } from "@fortawesome/pro-duotone-svg-icons";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  InputErrorMessage,
  NewButton,
  NewInput,
  toastNotification,
} from "@helium10/re-ui-components";
import { useWickUserInfoStore } from "@walmart10/container/core/store/wickUserInfoStore";
import { memo, useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { useRedirect } from "@/core/hooks/useRedirect";

import { appRoutes } from "../../../core/links";
import { useTranslation } from "../../../i18n/useTranslation";
import walmartlogoSrc from "../../../static/icons/walmartlogo.svg";
import { useChangePasswordStore } from "../store/index";

type FormValues = { cpassword: string; password: string; confirmPassword: string };

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const SetPasswordForm = memo(() => {
  const { t } = useTranslation();
  const query = useQuery();
  const email = decodeURIComponent(query.get("p") as string);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>();

  const { useSendMFAEmailController } = useChangePasswordStore();
  const sendMFAEmailController = useSendMFAEmailController();

  const { getPath, redirect } = useRedirect();

  const handleSettings = useCallback(() => {
    redirect(getPath(appRoutes.settings));
  }, [redirect, getPath]);

  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    mixture: false,
    notEmail: false,
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    useSendMFAEmailController.action({
      oldPassword: data.cpassword,
      password: data.password,
      resetAssociatedUserPassword: true,
    });
  };

  const cpassword = watch("cpassword", "");
  const password = watch("password", "");
  const confirmPassword = watch("confirmPassword", "");
  const userInfoStore = useWickUserInfoStore();
  useEffect(() => {
    if (!email) return;
    setPasswordValidation({
      length: password.length >= 8,
      mixture:
        /[A-Za-z]/.test(password) && /[0-9]/.test(password) && /[^A-Za-z0-9\s]/.test(password),
      notEmail: email.indexOf(password) === -1,
    });
  }, [password, cpassword, confirmPassword, userInfoStore]);

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <SignUpFormWrapperTitle>{t("changePassword.title")}</SignUpFormWrapperTitle>
      <ContainerLogo href="https://www.helium10.com/">
        <img src={walmartlogoSrc} alt="Helium 10" />
      </ContainerLogo>
      <FormGroup>
        <SignUpFormLabel htmlFor="cpassword">{t("changePassword.currentPassword")}</SignUpFormLabel>
        <NewInput
          id="cpassword"
          type="password"
          size={"400"}
          placeholder={t("changePassword.placeholder1")}
          {...register("cpassword", { required: t("changeEmail.required1") })}
        />
        {errors.cpassword && (
          <InputErrorMessage>{String(errors.cpassword?.message)}</InputErrorMessage>
        )}
      </FormGroup>
      <FormGroup>
        <SignUpFormLabel htmlFor="password">{t("changePassword.newPassword")}</SignUpFormLabel>
        <NewInput
          id="password"
          type="password"
          size={"400"}
          placeholder={t("changePassword.placeholder2")}
          {...register("password", { required: t("changeEmail.required1") })}
        />
        {errors.password && (
          <InputErrorMessage>{String(errors.password?.message)}</InputErrorMessage>
        )}
      </FormGroup>
      <PasswordValidation>
        <PasswordValidationItem>
          {passwordValidation.length ? <CircleCheckIcon /> : <TimesCircleIcon />}
          {t("common.passwordCheck2")}
        </PasswordValidationItem>
        <PasswordValidationItem>
          {passwordValidation.mixture ? <CircleCheckIcon /> : <TimesCircleIcon />}
          {t("common.passwordCheck3")}
        </PasswordValidationItem>
        <PasswordValidationItem>
          {passwordValidation.notEmail ? <CircleCheckIcon /> : <TimesCircleIcon />}
          {t("common.passwordCheck4")}
        </PasswordValidationItem>
      </PasswordValidation>
      <FormGroup>
        <SignUpFormLabel htmlFor="confirmPassword">
          {t("changePassword.passwordAgain")}
        </SignUpFormLabel>
        <NewInput
          id="confirmPassword"
          type="password"
          size={"400"}
          placeholder={t("changePassword.placeholder3")}
          {...register("confirmPassword", {
            required: t("changeEmail.required1"),
            validate: (value) => value === password || t("changePassword.required2"),
          })}
        />
        {errors.confirmPassword && (
          <InputErrorMessage>{String(errors.confirmPassword?.message)}</InputErrorMessage>
        )}
      </FormGroup>
      <NewButtonWrapper
        size="400"
        buttonType={"primary"}
        type="submit"
        loading={sendMFAEmailController.status === "loading"}
      >
        <NewButtonText>{t("changePassword.setPassword")}</NewButtonText>
      </NewButtonWrapper>
      <NewButtonWrapper1
        size="400"
        buttonType={"secondary"}
        onClick={handleSettings}
        disabled={sendMFAEmailController.status === "loading"}
        type="button"
      >
        <NewButtonText>{t("userSettings.cancel")}</NewButtonText>
      </NewButtonWrapper1>
    </FormWrapper>
  );
});

const FormWrapper = styled.form`
  width: 410px;
  height: 835px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.sp20};
`;

const ContainerLogo = styled.a`
  width: 200px;
  margin-bottom: 24px;

  & > img {
    display: block;
    width: 100%;
  }
`;

const SignUpFormWrapperTitle = styled.h3`
  /* font-family: "Neuton", serif; */
  /* font-size: 44px; */
  font-size: 38px;
  font-weight: 400;
  letter-spacing: 2px;
  text-align: center;
  color: #003873;
  margin: 0px auto 24px;
  white-space: nowrap;
`;

const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const SignUpFormLabel = styled.label`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.375;
  color: #646464;
  margin-bottom: 8px;
  display: inline-block;
`;

const PasswordValidation = styled.ul`
  width: 100%;
  margin-top: 0;
  margin-bottom: 1rem;
  list-style-type: none;
  text-align: left;
  padding-left: 0;
  font-size: 14px;
  color: #6a6c6f;
  display: block;
`;

const PasswordValidationItem = styled.li`
  display: list-item;
  text-align: -webkit-match-parent;
  unicode-bidi: isolate;
`;

const TimesCircleIcon = styled(FontAwesomeIcon).attrs({ icon: faTimesCircle })<{ icon?: IconProp }>`
  color: #dae0e9;
  font-size: 16px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const CircleCheckIcon = styled(FontAwesomeIcon).attrs({ icon: faCircleCheck })<{ icon?: IconProp }>`
  color: #0bcf97;
  font-size: 16px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const NewButtonWrapper = styled(NewButton)`
  width: 410px;
  margin-top: 16px;
  color: #ede8e4;
`;

const NewButtonWrapper1 = styled(NewButtonWrapper)`
  color: #003873;

  &:hover {
    color: #ede8e4;
    background-color: #0081ff;
  }
`;

const NewButtonText = styled.span`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.375;
`;
