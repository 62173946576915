import { Skeleton } from "@helium10/re-ui-components";
import styled from "styled-components";

import { Container } from "../../Styles";

export const SkeletonContent = () => {
  return (
    <Container>
      <Content>
        <SkeletonPrices />
        <SkeletonDisclaimer />
      </Content>
      <Actions>
        <SkeletonSubmit />
      </Actions>
    </Container>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
`;

const SkeletonPrices = styled(Skeleton)`
  height: 40px;
  width: 632px;
`;

const SkeletonDisclaimer = styled(Skeleton)`
  height: 40px;
  width: 592px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(p) => `${p.theme.spacing.sp32} 0 ${p.theme.spacing.sp24}`};
  gap: 24px;
`;

const SkeletonSubmit = styled(Skeleton)`
  height: 40px;
  width: 170px;
`;
