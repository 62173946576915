import type { IMarketType, IShortPlatformType } from "@helium10/re-core";
import { getMarketInfo, getUrlWithSearchParams, membersLink } from "@helium10/re-core";
import queryString from "query-string";
import { generatePath } from "react-router-dom";

export const adtomicDomainUrl = process.env.REACT_APP_ADTOMIC_URL || "";
export const getStartedWithAdtomic =
  "https://calendly.com/d/ynj-yft-7hk/adtomic-success-onboarding";
export const supportLink = "https://kb.helium10.com/hc/en-us/requests/new";
export const bookDemoLink = "https://pages.helium10.com/adtomic-demo";
export const researchApiLink = process.env.REACT_APP_RESEARCH_BACKEND || "";

export const feedbackUrl = "https://survey.survicate.com/944ca29c5a06f1a5";

export const connectWalmartLink = "https://forms.office.com/r/SVnKPpCNnQ";

export const onboardingJoinNow = "https://pages.helium10.com/adtomic-blast-off-reg";
export const performanceQALink =
  "https://kb.helium10.com/hc/en-us/articles/1260806098990-Adtomic-FAQs";
export const appRoute = "/adtomic";

export const baseRoute = appRoute + "/panel/:accountId";
export const landing = baseRoute + "/landing";
export const connections = baseRoute + "/connections";

export const dashboardRoute = baseRoute + "/dashboard";

export const accountOverviewRoute = baseRoute + "/account-overview";
export const termsAndConditionRoute = baseRoute + "/termsAndCondition";
export const accountSettingsRoute = baseRoute + "/account-settings";

export const adManagerRoute = baseRoute + "/campaigns";
export const adManagerCampaignRoute = adManagerRoute + "/:campaign";
export const adManagerCampaignRouteTab = adManagerCampaignRoute + "/:tab";
export const adManagerCampaignAdGroupRoute = adManagerCampaignRoute + "/ad-group";
export const adManagerCampaignTargetsRoute = adManagerCampaignRoute + "/targets";
export const adManagerCampaignSearchTermsRoute = adManagerCampaignRoute + "/search-terms";
export const adManagerCampaignNegativeKeywordsRoute = adManagerCampaignRoute + "/negative-keywords";
export const adManagerCampaignProductsRoute = adManagerCampaignRoute + "/products";
export const adManagerCampaignDetailsRoute = adManagerCampaignRoute + "/details";
export const adManagerGroupRoute = adManagerCampaignRoute + "/group/:group";
export const adManagerGroupRouteTab = adManagerGroupRoute + "/:tab";
export const adManagerGroupDetailsRoute = adManagerGroupRoute + "/detail";
export const adManagerGroupTargetsRoute = adManagerGroupRoute + "/targets";
export const adManagerGroupSearchTermsRoute = adManagerGroupRoute + "/search-terms";
export const adManagerGroupNegativeTargetsRoute = adManagerGroupRoute + "/negative-targets";
export const adManagerGroupNegativeKeywordsRoute = adManagerGroupRoute + "/negative-keywords";
export const adManagerGroupProductsRoute = adManagerGroupRoute + "/products";

export const analyticsRoute = baseRoute + "/analytics";
// export const analyticsRouteTab = analyticsRoute + "/:tab";
export const analyticsRoutePortfolio = analyticsRoute + "/portfolio";
export const analyticsRouteCampaigns = analyticsRoute + "/campaigns";
export const analyticsRouteAdGroups = analyticsRoute + "/adgroups";
export const analyticsRouteTargets = analyticsRoute + "/targets";
export const analyticsRouteNegativeTargets = analyticsRoute + "/negative-targets";
// export const analyticsRouteKeywords = analyticsRoute + "/keywords";
export const analyticsRouteSearchTerms = analyticsRoute + "/search-terms";
export const analyticsRouteAsin = analyticsRoute + "/asin";

export const suggestionsRoute = baseRoute + "/suggestions";
// export const suggestionsRouteTab = suggestionsRoute + "/:tab";
export const suggestionsRouteAIBids = suggestionsRoute + "/AI-bids";
export const suggestionsRouteBids = suggestionsRoute + "/bids";
export const suggestionsRouteNewKeywords = suggestionsRoute + "/keywords";
export const suggestionsRouteNegativeKeywords = suggestionsRoute + "/negative-keywords";
export const suggestionsRouteBudget = suggestionsRoute + "/budget";

export const changeLogRoute = baseRoute + "/changelog";
// export const changeLogRouteBulkActions = changeLogRoute + "/bulk-actions";

// export const productsRoute = baseRoute + "/products";

export const campaignBuilderRoute = baseRoute + "/campaign-builder";
export const campaignBuilderRouteQuick = campaignBuilderRoute + "/quick";
export const campaignBuilderRouteSingle = campaignBuilderRoute + "/single";
export const campaignBuilderRouteGuided = campaignBuilderRoute + "/guided";
export const campaignBuilderRouteAds = campaignBuilderRoute + "/ads";
export const campaignBuilderRouteWalmart = campaignBuilderRoute + "/walmart";

export const searchTermsDetailsRoute = baseRoute + "/details/queries/:search";
// export const entityDetailsRoute = baseRoute + "/details/entity/:type/:id";

export const schedulesRoute = baseRoute + "/schedules";
export const scheduleBuilderCreateRoute = baseRoute + "/schedules/builder/create";
export const scheduleBuilderEditRoute = baseRoute + "/schedules/builder/edit/:id";
export const scheduleInfoRoute = baseRoute + "/schedules/info/:id";

export const ScheduleRule = baseRoute + "/schedule-rule";
export const scheduleRuleBuilderCreateRoute = ScheduleRule + "/builder/create";
export const scheduleRuleBuilderEditRoute = ScheduleRule + "/builder/edit/:id";
export const budgetScheduleRuleBuilderCreateRoute = ScheduleRule + "/budget/builder/create";
export const budgetScheduleRuleBuilderEditRoute = ScheduleRule + "/budget/builder/edit/:id";

export const rulesRoute = baseRoute + "/rules-automation";
export const rulesRouteAI = rulesRoute + "/AI-bid";
export const rulesRouteBid = rulesRoute + "/bid";
export const rulesRoutePostive = rulesRoute + "/keyword";
export const rulesRouteNagative = rulesRoute + "/negative";
export const rulesRouteBudget = rulesRoute + "/budget-rules";
export const rulesRouteSchedule = rulesRoute + "/schedule-rule";
export const rulesRouteBudgetSchedule = rulesRoute + "/budget-schedule";

export const ruleBuilderRoute = baseRoute + "/rule-builder";
export const bidRuleBuilderRoute = ruleBuilderRoute + "/bid/:id?";
export const budgetRuleBuilderRoute = ruleBuilderRoute + "/budget/:id?";
export const harvestRuleBuilderRoute = ruleBuilderRoute + "/harvest/:id?";
export const negativeRuleBuilderRoute = ruleBuilderRoute + "/negative/:id?";
export const budgetScheduleRuleBuilderRoute = ruleBuilderRoute + "/budgetSchedule/:id?";

export const connectAmazonBrand = "https://brandservices.amazon.com/";
// ---- get route ----

export const getRedirectDashboardRoute = (accountId: string) =>
  getUrlWithSearchParams(generatePath(dashboardRoute, { accountId }), {
    accountId,
  });

export const getRedirectAnalyticsRoute = (accountId: string) =>
  getUrlWithSearchParams(generatePath(analyticsRoute, { accountId }), {
    accountId,
  });

export const getBookDemoRoute = () =>
  queryString.stringifyUrl({
    url: bookDemoLink,
    query: {
      utm_source: "memberspage",
      utm_medium: "direct",
      utm_campaign: "adtomic",
      utm_content: "adtomic-demo-cta",
      lead_url: `${window.location.origin}${window.location.pathname}`,
    },
  });
export const getSchedulesBuilderRoute = (accountId: string) =>
  getUrlWithSearchParams(generatePath(scheduleRuleBuilderCreateRoute, { accountId }), {
    accountId,
  });

export const getRedirectLandingRoute = (accountId: string) =>
  getUrlWithSearchParams(generatePath(landing, { accountId }), {
    accountId,
  });
export const getRedirectConnectionsRoute = (accountId: string) =>
  getUrlWithSearchParams(generatePath(connections, { accountId }), {
    accountId,
  });

export const getEntityDetailTarget = (acc: string, id: string, isPat: boolean) =>
  adtomicDomainUrl +
  `/panel/${acc}/details/entity/${isPat ? "target" : "keyword"}/${id}?accountId=${acc}`;

export const getAdManagerRoute = (accountId: string) =>
  getUrlWithSearchParams(generatePath(adManagerRoute, { accountId }), {
    accountId,
  });

export const getAnalyticsRoute = (accountId: string) =>
  getUrlWithSearchParams(generatePath(analyticsRoute, { accountId }), {
    accountId,
  });

export const getSchedulesRoute = (accountId: string) =>
  getUrlWithSearchParams(generatePath(schedulesRoute, { accountId }), {
    accountId,
  });

export const getSettingsRoute = (accountId: string) =>
  getUrlWithSearchParams(generatePath(accountSettingsRoute, { accountId }), {
    accountId,
  });

export const getRulesAutomationRoutes = (accountId: string, ruleRoute: string) =>
  getUrlWithSearchParams(generatePath(ruleRoute, { accountId }), {
    accountId,
  });

export const getRulesAutomationRoute = (accountId: string) =>
  getUrlWithSearchParams(generatePath(rulesRoute, { accountId }), {
    accountId,
  });

export const getTokensManagerLink = (accountId: string) =>
  queryString.stringifyUrl({
    url: membersLink + "/account/tokens-manager",
    query: { accountId },
  });

export const getConnectAccountLink = (accountId: string) =>
  queryString.stringifyUrl({
    url: membersLink + "/new-account/tokens-manager-list",
    query: { accountId },
  });
export const getBillingAccountLink = (accountId: string) =>
  queryString.stringifyUrl({
    url: membersLink + "/account/billing",
    query: { accountId },
  });

export const getEditSubscriptionLink = (accountId: string) =>
  queryString.stringifyUrl({
    url: membersLink + "/subscribe/edit-subscription",
    query: { accountId },
  });

export const getSubscriptionLink = (accountId: string) =>
  queryString.stringifyUrl({
    url: membersLink + "/subscribe",
    query: { accountId },
  });

export const productLink = (platform: IShortPlatformType, domain: IMarketType, id: string) => {
  if (platform === "AM") {
    return `https://${domain || "amazon.com"}/dp/${id}`;
  } else {
    return `https://www.walmart.com/ip/name/${id}`;
  }
};

export const brandLink = (store: string, id: string) => {
  return `https://${getMarketInfo("AM", "store", store)?.domain || "amazon.com"}/stores/node/${id}`;
};
