import { isDevHost, membersLink } from "@helium10/re-core";
import queryString from "query-string";

export const WECHAT_COUNTER = "wechat-new-counter";
export const SEGMENT_EVENT_WECHAT_POPOVER = "WeChat Popover Shown";
export const SEGMENT_EVENT_FREEDOM_TICKET_CLICKED = "Freedom Ticket Clicked";
export const SEGMENT_EVENT_REFERSION_CLICKED = "Refersion Promo Clicked";
export const SEGMENT_EVENT_SEARCH_BAR_CLICKED = "Search Bar Clicked";
export const SEGMENT_EVENT_FACEBOOK_ICON_CLICKED = "Facebook Icon Clicked";
export const SEGMENT_EVENT_ACCOUNT_SWITCHER_CLICKED = "Account Switcher Clicked";
export const SEGMENT_EVENT_ACCOUNT_MENU_LINK_CLICKED = "Account Menu Link Clicked";

export const domain = isDevHost() ? "" : membersLink;
export const segmentEventLink = domain + "/api/v1/customers/segment-track";

export const isDashboard = ["/", "/dashboard"].includes(
  queryString.parseUrl(window.location.pathname)?.url ?? "",
);

export const isMembersPricing = ["/subscribe"].includes(
  queryString.parseUrl(window.location.pathname)?.url ?? "",
);

export const beamerUrl = "https://app.getbeamer.com/js/beamer-embed.js";
export const beamerId = "Nwesmoez43458";

export const localStorageBVSource = "BV_TOOL_SEGMENT_SOURCE";

export const testDomain =
  process.env.NODE_ENV === "test" ? "http://localhost.helium10-dev.com:1981" : "";
