import type { Helium10Plans } from "@helium10/re-core";
import { baseFetch, getUrlWithSearchParams } from "@helium10/re-core";

import { apiUpgrade } from "../consts/links";

export interface IUpgradeProcessParams {
  captchaTokenV3?: string | null;
  coupon?: string | null;
  plan?: keyof Helium10Plans;
  period?: "month" | "year";
  utm_campaign?: string | null;
  eventSource?: string | null;
  module?: string | null;
  items?: string[] | null;
  downgrade?: boolean;
  upgrade?: boolean;
}

export interface IUpgradeProcessRequest {
  captchaTokenV3: string | null;
  coupon: string;
  plan: keyof Helium10Plans | string;
  period?: "month" | "year";
  utm_campaign: string;
  eventSource: string;
  module: string;
  items: string;
}

export interface IUpgradeProcessResponse {
  data: {
    status: "success" | "error";
    accountId?: number;
    token?: string;
    message?: string;
    messageType?: string;
    invoiceUrl?: string;
  };
}

export const upgradeProcess = async (
  params: IUpgradeProcessParams,
  signal?: AbortSignal,
): Promise<IUpgradeProcessResponse> => {
  return await baseFetch<IUpgradeProcessRequest, IUpgradeProcessResponse>({
    url: getUrlWithSearchParams(apiUpgrade, {}),
    method: "POST",
    params: {
      ...(params?.downgrade && { downgrade: params?.downgrade }),
      ...(params?.upgrade && { upgrade: params?.upgrade }),
      ...(params?.period && { period: params?.period }),
      captchaTokenV3: params?.captchaTokenV3 ?? "",
      coupon: params?.coupon ?? "",
      plan: params?.plan ?? "",
      items: JSON.stringify(params?.items),
      utm_campaign: params?.utm_campaign ?? "",
      eventSource: params?.eventSource ?? "",
      module: params?.module ?? "",
    },
    signal,
  });
};
