import type { IModalCreator } from "@helium10/re-core";
import { createModal } from "@helium10/re-core";
import { create } from "zustand";

interface IBaseModalStore {
  lockWalmartModal: IModalCreator<undefined>;
  unlockModal: IModalCreator<string>;
  upgradeModal: IModalCreator<undefined>;
  upgradeReadOnlyModal: IModalCreator<undefined>;
  welcomeModal: IModalCreator<undefined>;
  apiAccessModal: IModalCreator<undefined>;
  subscriptionsModal: IModalCreator<undefined>;
  confirmArchiveModal: IModalCreator<{ onApply?: () => void; onCancel?: () => void }>;
}

export const useBaseModalStore = create<IBaseModalStore>((set, get) => ({
  ...createModal(set, get, "lockWalmartModal", undefined),
  ...createModal(set, get, "unlockModal", ""),
  ...createModal(set, get, "upgradeModal", undefined),
  ...createModal(set, get, "upgradeReadOnlyModal", undefined),
  ...createModal(set, get, "welcomeModal", undefined),
  ...createModal(set, get, "apiAccessModal", undefined),
  ...createModal(set, get, "subscriptionsModal", undefined),
  ...createModal(set, get, "confirmArchiveModal", {}),
}));
