import { getCurrentAccountId } from "@helium10/re-core";
import { useCallback } from "react";
import type { Params } from "react-router-dom";
import { generatePath } from "react-router-dom";

export const useLink = () => {
  const accountId = getCurrentAccountId();

  const appendAccountId = useCallback(
    <S extends string>(url: string, params?: Params<S>): string =>
      generatePath(url, { accountId, ...params }),
    [accountId],
  );

  return {
    appendAccountId,
  };
};
