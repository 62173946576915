import { leitenRequest } from "@helium10/re-core";
import { create } from "zustand";

import {
  getWickAvailableAccounts,
  IGetWickAvailableAccountsResponse,
} from "../requests/getAvailableAccounts";

export interface IAvailableAccounts {
  availableAccounts: IGetWickAvailableAccountsResponse["data"];
}

export const useWickAccountsStore = create<IAvailableAccounts>(() => ({
  availableAccounts: [],
}));

export const useWickAccountsController = leitenRequest(
  useWickAccountsStore,
  "availableAccounts",
  async (_: void) => {
    const result = await getWickAvailableAccounts();
    return result.data || [];
  },
);
