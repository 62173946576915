import type { Helium10Plans } from "@helium10/re-core";
import { baseFetch, getUrlWithSearchParams } from "@helium10/re-core";

import type { IPaymentMethods } from "../../../../common/types/IPaymentMethods";
import { apiData } from "../consts/links";

export type ICardTypes = "Visa" | "Mastercard" | "Discover" | "Amex" | "Unionpay";

export type IAlaCarteModalTypes = "updateModal" | "downgradeModal";

export type IModalTypes =
  | "subscribeModal"
  | "subscribeModalCard"
  | "upgradeModal"
  | "updateModal"
  | "downgradeModal";

type ICoupon = string;

export interface IData {
  name?: string;
  email?: string;
  amount: number;
  amountOff: number;
  nextAmount: number;
  coupon?: ICoupon;
  billingPeriodEndAt?: string;
  paymentMethods: IPaymentMethods[];
  countries: Record<string, string>;
  states: Record<string, string>;
  plan: {
    key: keyof Helium10Plans;
    title: string;
    amount: number;
    interval: "month" | "year";
  };
  card?: {
    brand: ICardTypes;
    last4: string | null;
  };
  address: {
    city?: string;
    countryIsoCode?: string;
    currentCountry?: string;
    state?: string;
    street?: string;
    zipCode?: string;
  };
  stripeData: {
    key: string;
    publicKey: string;
  };
  type: IModalTypes;
  specifics: {
    title: string;
    subTitle: string;
    mainParagraph?: string;
    security?: string;
    agreement: {
      text: string;
      termsAndConditionsText: string;
      termsAndConditionsUrl: string;
      privacyPolicyText: string;
      privacyPolicyUrl: string;
    };
    ctaTitle: string;
    successfulTitle: string;
    processingTitle: string;
    cancelTitle?: string;
    closeTitle: string;
  };
}

export interface IGetDataParams {
  offer?: string;
  coupon?: ICoupon | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items?: string[];
}

export interface IGetDataRequest {
  offer?: string;
  coupon?: ICoupon | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items?: string;
}

export interface IGetDataResponse {
  data: IData;
}

export const getData = async (params: IGetDataParams, signal?: AbortSignal): Promise<IData> => {
  return await baseFetch<IGetDataRequest, IGetDataResponse>({
    url: getUrlWithSearchParams(apiData, {}),
    params: {
      ...(params?.offer && { offer: params?.offer }),
      ...(params?.coupon && { coupon: params?.coupon }),
      ...(params?.items && { items: JSON.stringify(params?.items) }),
    },
    signal,
  }).then(transform);
};

const transform = (response: IGetDataResponse) => response?.data;
