import {
  faCcAmex,
  faCcDiscover,
  faCcMastercard,
  faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";

import unionPayImage from "../../static/images/union_pay.svg";

export type ICardTypes = "Visa" | "Mastercard" | "Discover" | "Amex" | "Unionpay";

interface IProps {
  isGray?: boolean;
  type: ICardTypes;
}

export const CreditCard = ({ isGray, type }: IProps) => {
  const { [type]: cardType } = {
    Visa: <CreditCardIcon $isGray={isGray} icon={faCcVisa} />,
    Mastercard: <CreditCardIcon $isGray={isGray} icon={faCcMastercard} />,
    Discover: <CreditCardIcon $isGray={isGray} icon={faCcDiscover} />,
    Amex: <CreditCardIcon $isGray={isGray} icon={faCcAmex} />,
    Unionpay: <CreditCardUnionPayIcon $isGray={isGray} />,
  };

  return cardType;
};

const cardStyle = css`
  user-select: none;
  font-size: 18px;
  height: 18px;
`;

export const CreditCardIcon = styled(FontAwesomeIcon)<{ $isGray?: boolean }>`
  ${cardStyle};

  ${(p) =>
    p?.$isGray &&
    css`
      color: #d8d8d8;
    `}
  & + & {
    margin-left: 10px;
  }
`;

export const CreditCardUnionPayIcon = styled.img.attrs({ src: unionPayImage, alt: "UnionPay" })<{
  $isGray?: boolean;
}>`
  ${cardStyle};
  width: 25px;
  margin-left: 10px;

  ${(p) =>
    p?.$isGray &&
    css`
      color: #d8d8d8;
    `}
`;
