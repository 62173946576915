import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faApplePay } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  cssBody200Bold,
  cssBody400,
  InputErrorMessage,
  NewButton,
  NewInput,
} from "@helium10/re-ui-components";
import styled, { createGlobalStyle, css } from "styled-components";

export const GoogleAutocompleteStyle = createGlobalStyle`
    .pac-container {
        z-index: 1000001;
    }
`;

const cssTitle = css`
  font-weight: 800;
  line-height: 1.33;
  color: ${(p) => p.theme.systemColors.black};
  margin-bottom: 2px;
`;

export const Title = styled.h3`
  ${cssTitle};
  font-size: 24px;
`;

export const SmallTitle = styled.h4`
  ${cssTitle};
  font-size: 20px;
  margin: ${(p) => `${p.theme.spacing.sp16} 0 0`};
`;

export const Form = styled.form<{ $isWithoutTitle?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: ${(p) => (p.$isWithoutTitle ? 0 : p.theme.spacing.sp16)};
`;

export const Content = styled.div`
  ${cssBody400};
  text-align: center;
`;

export const Label = styled.label`
  ${cssBody200Bold};
  user-select: none;
  margin-bottom: ${(p) => p.theme.spacing.sp6};
`;

export const Input = styled(NewInput)`
  flex: 1;

  ${NewInput.InputWrapper} {
    margin: ${(p) => `${p.theme.spacing.sp6} 0 0`};
  }

  ${NewInput.Input} {
    color: ${(p) => p.theme.systemColors.grey600};
    padding: ${(p) => `7px ${p.theme.spacing.sp12}`};
    cursor: initial;
  }

  & + & {
    margin-top: ${(p) => p.theme.spacing.sp16};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: ${(p) => p.theme.spacing.sp16};
  min-width: 100%;

  & + & {
    margin: ${(p) => `${p.theme.spacing.sp16} 0 0`};
  }

  ${Input} {
    margin: 0;
  }
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  user-select: none;
  color: ${(p) => p.theme.systemColors.grey600};
  margin: 0 ${(p) => p.theme.spacing.sp16} 0 0;

  a {
    cursor: pointer;
    min-height: auto;
    font-weight: 700;
    text-decoration: none;
    color: ${(p) => p.theme.systemColors.blue600};
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      color: ${(p) => p.theme.systemColors.blue800};
    }
  }
`;

export const Link = styled.a`
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
  color: ${(p) => p.theme.systemColors.blue600};

  & > * {
    margin-right: ${(p) => p.theme.spacing.sp8};
  }

  &:hover,
  &:active,
  &:focus {
    color: ${(p) => p.theme.systemColors.blue800};
  }
`;

export const StyledInputErrorMessage = styled(InputErrorMessage)`
  word-break: break-word;
`;

export const Errors = styled.div`
  text-align: left;
  line-height: 1.21;
  font-size: 14px;
  color: #c00;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
`;

const appleIconHoverCss = css`
  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    color: ${(p) => p.theme.systemColors.white};
  }
`;

const AppleIcon = styled(FontAwesomeIcon).attrs({ icon: faApplePay })<{ icon?: IconProp }>`
  font-size: 40px;
`;

export const ApplePayButton = styled(NewButton).attrs({ $buttonType: "text", icon: <AppleIcon /> })`
  ${appleIconHoverCss};
  display: flex;
  height: 56px;
  width: 100%;
  max-width: 304px;
  color: ${(p) => p.theme.systemColors.white};
  background: ${(p) => p.theme.systemColors.black};
  border-color: ${(p) => p.theme.systemColors.black};

  ${NewButton.LoaderWrapper} {
    ${appleIconHoverCss};
    color: ${(p) => p.theme.systemColors.white};
  }

  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.24;
    `};
`;
