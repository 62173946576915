import { baseFetch } from "@helium10/re-core";

import { userSystem } from "../../../core/links";

export interface IUserInfoResponse {
  code?: number;
  data: {
    mail: string;
    userName: string;
    userRole: string;
    userId: number;
    rootUserId: number;
    clientId: number;
    isSuperAdmin: boolean;
    isEmailValidated: boolean;
    byPacvuer: string;
    addTime: string;
  } | null;
}

export interface IAccountsResponse {
  code?: number;
  data:
    | [
        {
          name: string;
          userId: number;
          clientId: number;
          isSuperAdmin: number;
          isDefaultAccount: boolean;
        },
      ]
    | null;
}

export interface IProfileInfoResponse {
  results:
    | {
        name: string;
        joinDate: string;
        addressInfo: {
          name: string;
          address: string;
          district: string;
          province: string;
          post: string;
          nation: string;
          phone: string;
        };
        avatar: string;
        isReceive: boolean; //是否接受h10赠品
      }
    | null
    | undefined;
}

type LoginInfo = {
  data: {
    accessToken: string;
  };
};

export const login = async () => {
  return baseFetch<unknown, LoginInfo>({
    method: "GET",
    withCredentials: true,
    useSessionAccountId: true,
    url: "https://apiusertest.pacvue.com/api/Wick/login",
    params: {
      username: "Yushun.Gong@pacvue.com",
      password: "lijingxian01",
      sourceType: 0,
      douxing: 0,
    },
  });
};

export const getUserInfo = async () => {
  return baseFetch<undefined, IUserInfoResponse>({
    method: "GET",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.getUserInfo,
  });
};

export const getAccounts = async () => {
  return baseFetch<undefined, IAccountsResponse>({
    method: "GET",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.getAccounts,
  });
};

export const getProfileInfo = async () => {
  return baseFetch<undefined, IProfileInfoResponse>({
    method: "GET",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.getProfileInfo,
  });
};

export type IChangeResponse = {
  results?: "success";
  code?: number;
};
export const changeAvatar = async (avatar: string) => {
  return baseFetch<{ avatar: string }, IChangeResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.changeAvatar,
    params: {
      avatar,
    },
  });
};

export type IChangeAddressParams = {
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
};

export const changeAddress = async (params: IChangeAddressParams) => {
  return baseFetch<IChangeAddressParams, IChangeResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.changeAddress,
    params,
  });
};

type IReceiveSwagParams = {
  value: boolean; //true接收 false不接收
};
export const receiveSwag = async (value: boolean) => {
  return baseFetch<IReceiveSwagParams, IChangeResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.receiveSwag,
    params: { value },
  });
};

type UserActiveEmailsParams = {
  email: string;
  userId: number;
  type: number; //固定值7
  website: string;
};

export const sendUserActiveEmails = async (params: UserActiveEmailsParams) => {
  return baseFetch<UserActiveEmailsParams, IChangeResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.sendUserActiveEmails,
    params,
  });
};

export type ForceStatusResponse = {
  data: {
    forceType: string;
    isForce: boolean;
  } | null;
};
export const forceStatus = async () => {
  return baseFetch<undefined, ForceStatusResponse>({
    method: "GET",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.forceStatus,
  });
};

export type DisableForce = {
  password: string;
};

export type DisableForceResponse = {
  code: number;
  data: { msg: string; code?: string } | null;
};

export type AppForceResponse = {
  code: number;
  data: { code: string; keyUrl: string };
};
export const disableForce = async (password: string) => {
  return baseFetch<DisableForce, DisableForceResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.disableForce,
    params: { password },
  });
};

export const forceApp = async (password: string) => {
  return baseFetch<DisableForce, AppForceResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.forceApp,
    params: { password },
  });
};

export const forceEmail = async (password: string) => {
  return baseFetch<DisableForce, DisableForceResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.forceEmail,
    params: { password },
  });
};

type BindMFAParams = {
  code: string; // 设置接口返回的code
  validateCode: string; // 用户绑定auth app的6位验证码
};
export const bindMFA = async (params: BindMFAParams) => {
  return baseFetch<BindMFAParams, DisableForceResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.bindMFA,
    params,
  });
};

type BindForceEmailParams = {
  code: string; // 设置接口返回的code
  validateCode: string; // 用户绑定auth app的6位验证码
};
export const bindForceEmail = async (params: BindForceEmailParams) => {
  return baseFetch<BindForceEmailParams, DisableForceResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.bindForceEmail,
    params,
  });
};

type UpdateUserEmailParams = {
  password: string;
  mail: string;
  resetAssociatedUserEmail: boolean;
};
export type IUpdateUserEmailResponse = {
  code: number;
  msg: string;
};
export const updateUserEmail = async (params: UpdateUserEmailParams) => {
  return baseFetch<UpdateUserEmailParams, IUpdateUserEmailResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.updateUserEmail,
    params,
  });
};

type ResetOwnPasswordParams = {
  password: string;
  oldPassword: string;
  resetAssociatedUserPassword: boolean;
};
export type IResetOwnPasswordResponse = {
  code: number;
  msg: string;
};
export const resetOwnPassword = async (params: ResetOwnPasswordParams) => {
  return baseFetch<ResetOwnPasswordParams, IResetOwnPasswordResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.resetOwnPassword,
    params,
  });
};

type LoginRecordsParams = {
  page: {
    pageIndex: number;
    pageSize: number;
  };
};
export type SessionRecord = {
  clientId: string;
  userId: string;
  userName: string;
  country: string;
  ip: string;
  loginTime: string;
};
export type LoginRecordsResponse = {
  code?: number;
  data: {
    data: SessionRecord[];
    pageInfo: {
      pageIndex: number;
      pageSize: number;
      totalCount: number;
    };
  } | null;
};
export const loginRecords = async (params: LoginRecordsParams) => {
  return baseFetch<LoginRecordsParams, LoginRecordsResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.loginRecords,
    params,
  });
};

type ClearSessionParams = {
  refreshToken: string;
};
type ClearSessionResponse = {
  code: number;
  msg: string;
};
export const clearSession = async (params: ClearSessionParams) => {
  return baseFetch<ClearSessionParams, ClearSessionResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.clearSession,
    params,
  });
};
type ActivateUserParams = {
  secretKey: string;
  password?: string;
  useAssociateUserPwd: true;
  website: string;
};
export type IActivateUserResponse = {
  code: number;
  msg: string;
};
export const activateUser = async (params: ActivateUserParams) => {
  return baseFetch<ActivateUserParams, IActivateUserResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.activateUser,
    params,
  });
};

type ChangeDefaultAccountParams = {
  UserId: string | number;
};

export type IAChangeDefaultAccountResponse = {
  code: number;
  msg: string;
};

export const changeDefaultAccount = async (params: ChangeDefaultAccountParams) => {
  return baseFetch<ChangeDefaultAccountParams, IAChangeDefaultAccountResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.changeDefaultAccount,
    params,
  });
};

export type IUserInfoRealTimeResponse = {
  code: number;
  data: {
    isEmailValidated: boolean;
  } | null;
};

export const userInfoRealTime = async () => {
  return baseFetch<undefined, IUserInfoRealTimeResponse>({
    method: "GET",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.userInfoRealTime,
  });
};
