import { useErrorPage, useRouterStore, usesRemainingSingleton } from "@helium10/re-core";
import { Suspense, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ErrorPage } from "./components/ErrorPage";
import { useDataDogRum } from "./core/hooks/dataDog";
import { BaseRoutes } from "./Routes";

export function Root() {
  const { error } = useErrorPage();
  const navigate = useNavigate();
  const location = useLocation();
  usesRemainingSingleton.useSingleton();

  useDataDogRum();

  useEffect(() => {
    window.customNavigate = navigate;
    useRouterStore.getState().setRouter({ navigate, location });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navigate]);

  return <Suspense fallback={null}>{!error ? <BaseRoutes /> : <ErrorPage />}</Suspense>;
}
