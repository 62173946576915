import { baseFetch } from "@helium10/re-core";

import { wickUserAPI } from "../links";

// getWickUserInfo
export interface IGetWickUserInfoResponse {
  data: {
    mail: string;
    userName: string;
    userRole: string;
    userId: number;
    rootUserId: number;
    clientId: number;
    rootClientId: number;
    isSuperAdmin: boolean;
    isEmailValidated: boolean;
    byPacvuer: string;
    addTime: string;
  };
}

export const getWickUserInfo = async () => {
  return baseFetch<void, IGetWickUserInfoResponse>({
    method: "GET",
    withCredentials: true,
    useSessionAccountId: true,
    url: wickUserAPI.getUserInfo,
  });
};
