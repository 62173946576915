import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { leitenModal } from "@helium10/re-core";
import type { IDialogModalProps } from "@helium10/re-ui-components";
import {
  cssBody400Bold,
  cssDisplay200Bold,
  DialogModal,
  NewButton,
} from "@helium10/re-ui-components";
import { useEffect } from "react";
import styled from "styled-components";
import { create } from "zustand";

import { translateErrors } from "../../../common/utils/translateErrors";
import { useTranslation } from "../../../i18n/useTranslation";
import { Image, Text, Title } from "../Styles";
import { Content } from "./components/Content";
import { SkeletonContent } from "./components/SkeletonContent";
import { SkeletonDescription } from "./components/SkeletonDescription";
import { SkeletonTitle } from "./components/SkeletonTitle";
import type { IAlaCarteModalTypes } from "./requests/getData";
import type { IInitialData } from "./store/useCheckoutModalStore";
import {
  captchaController,
  clearSubscriptionCheck,
  completeController,
  dataController,
  initialDataController,
  intentController,
  modalErrorController,
  paymentEditingController,
  paymentModeController,
  stripeConfirmAlipayController,
  stripeConfirmIntentController,
  stripeController,
  stripeRetrieveIntentController,
  subscribeCheckController,
  subscribeProcessController,
  upgradeProcessController,
  useCheckoutModalStore,
} from "./store/useCheckoutModalStore";

export interface ICheckoutModalProps {
  modalProps?: IDialogModalProps;
}

const useStore = create<{ data: null }>(() => ({ data: null }));
export const useCheckoutModal = leitenModal(useStore, "data");

export const checkoutModal = {
  open: (params?: IInitialData) => {
    params && initialDataController.patch(params);
    useCheckoutModal.open();
  },
  close: useCheckoutModal.close,
};

export const CheckoutModal = ({ modalProps }: ICheckoutModalProps) => {
  const { t } = useTranslation();
  const [isCheckoutSubscribeModalOpen] = useCheckoutModal();
  const [isComplete, initialData, modalError, plan, type, specifics] = useCheckoutModalStore(
    (state) => [
      state.isComplete,
      state.initialData,
      state.modalError,
      state.data?.plan,
      state.data?.type,
      state.data?.specifics,
    ],
  );

  useEffect(() => {
    isComplete &&
      setTimeout(() => {
        initialData?.reload ? window.location.reload() : useCheckoutModal.close();
      }, 1500);
  }, [isComplete, initialData]);

  useEffect(() => {
    if (isCheckoutSubscribeModalOpen) {
      initialData?.onOpen?.();
      dataController.action();
    } else {
      initialData?.onClose?.();
      dataController.clear();
      intentController.clear();
      stripeController.clear();
      captchaController.clear();
      completeController.clear();
      paymentModeController.clear();
      paymentEditingController.clear();
      initialDataController.clear();
      stripeRetrieveIntentController.clear();
      stripeConfirmAlipayController.clear();
      stripeConfirmIntentController.clear();
      subscribeProcessController.clear();
      subscribeCheckController.clear();
      upgradeProcessController.clear();
      modalErrorController.clear();
      clearSubscriptionCheck();
    }
  }, [isCheckoutSubscribeModalOpen, initialData]);

  const { [type as IAlaCarteModalTypes]: ImageALaCarte } = {
    updateModal: <ExclamationIcon icon={faCircleExclamation} />,
    downgradeModal: <ExclamationIcon icon={faCircleExclamation} />,
  };

  const errorNode = <ErrorMessage>{translateErrors(t, modalError?.message)}</ErrorMessage>;
  const contentNode = plan ? <Content /> : <SkeletonContent />;
  const modalHeaderProps = modalError
    ? {
        footer: (
          <ErrorActions>
            <NewButton $size="200" onClick={useCheckoutModal.close}>
              {specifics?.closeTitle}
            </NewButton>
          </ErrorActions>
        ),
      }
    : {
        title: plan ? (
          <>
            {ImageALaCarte ?? <Image />}
            <CheckoutTitle as="span">{specifics?.title}</CheckoutTitle>
          </>
        ) : (
          <SkeletonTitle />
        ),
        subtitle: plan ? (
          <CheckoutDescription>{specifics?.subTitle}</CheckoutDescription>
        ) : (
          <SkeletonDescription />
        ),
      };

  return (
    <Modal
      {...modalProps}
      {...modalHeaderProps}
      isOpen={isCheckoutSubscribeModalOpen}
      onClose={useCheckoutModal.close}
      onClickOutside={() => false}
    >
      {modalError ? errorNode : contentNode}
    </Modal>
  );
};

const Modal = styled(DialogModal).attrs<IDialogModalProps>({ size: "100", titleAlign: "center" })``;

const ExclamationIcon = styled(FontAwesomeIcon)`
  width: 100px;
  height: 100px;
  display: flex;
  color: ${(p) => p.theme.systemColors.grey200};
  margin: ${(p) => `0 auto ${p.theme.spacing.sp16}`};
`;

const CheckoutTitle = styled(Title)`
  ${cssDisplay200Bold};
`;

const CheckoutDescription = styled(Text)`
  margin: ${(p) => `${p.theme.spacing.sp4} 0 0`};
`;

const ErrorActions = styled.div`
  justify-content: center;
  display: flex;
`;

const ErrorMessage = styled.div`
  justify-content: center;
  display: flex;
  ${cssBody400Bold};
`;
