import type { ICreateRequest } from "@helium10/re-core";
import { createSlice, getUrlWithSearchParams } from "@helium10/re-core";
import { create } from "zustand";

import { suggestionCountLink } from "@/core/link/apiLink";
import { getSuggestionsCount } from "@/requests/getSuggestionsCount";
import { useCommonStore } from "@/store/data/commonStore";

export interface ISuggestionCountType {
  aiBid: number;
  bid: number;
  negativeTarget: number;
  target: number;
  budget: number;
}

interface ITState {
  getSuggestionsCount: ICreateRequest<void, ISuggestionCountType>;
}

export const useSuggestionCount = create<ITState>((set, get) => ({
  ...createSlice(set, get, "getSuggestionsCount", async (_: void) => {
    const profileId = useCommonStore.getState().profile.id;

    const url = getUrlWithSearchParams(suggestionCountLink, { profileId });
    return getSuggestionsCount(url);
  }),
}));

export const totalCuntSelector = (state: ITState) => {
  const { status, content } = state.getSuggestionsCount.atom;

  return status === "loaded"
    ? (content?.aiBid || 0) +
        (content?.bid || 0) +
        (content?.target || 0) +
        (content?.negativeTarget || 0) +
        (content?.budget || 0)
    : undefined;
};
