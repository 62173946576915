import type { Helium10Plans } from "@helium10/re-core";
import { baseFetch, getUrlWithSearchParams } from "@helium10/re-core";
import type { PaymentIntent, SetupIntent } from "@stripe/stripe-js";

import { apiALaCarteDowngrade, apiALaCarteUpdate, apiSubscribeProcess } from "../consts/links";
import type { IAlaCarteModalTypes, IModalTypes } from "./getData";

export interface ISubscribeProcessParams {
  captchaTokenV3?: string | null;
  type?: IModalTypes;
  name?: string;
  email?: string;
  coupon?: string | null;
  plan?: keyof Helium10Plans;
  period?: "month" | "year";
  paymentIntentId?: PaymentIntent;
  setupIntent?: SetupIntent;
  country?: string;
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  items?: string[] | null;
}

export interface ISubscribeProcessRequest {
  captchaTokenV3: string | null;
  paymentIntentId?: string;
  setupIntent?: string;
  name: string;
  email: string;
  coupon: string;
  planId?: keyof Helium10Plans | string;
  plan?: keyof Helium10Plans | string;
  "address[country]": string;
  "address[street]": string;
  "address[city]": string;
  "address[state]": string;
  "address[zip]": string;
  items?: string;
}

export interface ISubscribeProcessResponse {
  data: {
    status: "success" | "error";
    accountId?: number;
    token?: string;
    message?: string;
    messageType?: string;
    invoiceUrl?: string;
  };
}

export const subscribeProcess = async (
  params: ISubscribeProcessParams,
  signal?: AbortSignal,
): Promise<ISubscribeProcessResponse> => {
  const paymentIntentId = params?.paymentIntentId;
  const setupIntent = params?.setupIntent;

  const { [params?.type as IAlaCarteModalTypes]: aLaCarteLink } = {
    updateModal: apiALaCarteUpdate,
    downgradeModal: apiALaCarteDowngrade,
  };

  // return mockSubscribeProcess;
  return await baseFetch<ISubscribeProcessRequest, ISubscribeProcessResponse>({
    url: getUrlWithSearchParams(aLaCarteLink ?? apiSubscribeProcess, {}),
    withCredentials: true,
    method: "POST",
    params: {
      ...(paymentIntentId && { paymentIntentId: JSON.stringify(params?.paymentIntentId) }),
      ...(setupIntent && { setupIntent: JSON.stringify(params?.setupIntent) }),
      ...(params?.period && { period: params?.period }),
      ...(params?.type === "downgradeModal"
        ? {
            planId: params?.plan ?? "",
          }
        : {
            plan: params?.plan ?? "",
          }),
      captchaTokenV3: params?.captchaTokenV3 ?? "",
      name: params?.name ?? "",
      email: params?.email ?? "",
      coupon: params?.coupon ?? "",
      items: JSON.stringify(params?.items),
      "address[country]": params?.country ?? "",
      "address[street]": params?.street ?? "",
      "address[city]": params?.city ?? "",
      "address[state]": params?.state ?? "",
      "address[zip]": params?.zip ?? "",
    },
    signal,
  });
};
