import { IFeatureCreator, leitenFeature, leitenPrimitive, leitenRequest } from "@helium10/re-core";
import { toastNotification, ToastTitle } from "@helium10/re-ui-components";
import { userloginController } from "@walmart10/container/core/store/loginStore";
import type { Params } from "react-router-dom";
import { create } from "zustand";

import { IUserRegistrationResponse, userRegistration } from "../requests";

interface ISignUp {
  name: string;
  email: string;
  password: string;
  phone?: number;
  agree: boolean;
  getPath: <S extends string>(
    url: string,
    query?: Record<string, string | number>,
    params?: Params<S>,
  ) => string;
  redirect: <S extends string>(
    url: string,
    query?: Record<string, string | number>,
    params?: Params<S>,
    force?: boolean,
  ) => void;
}

interface IState {
  userRegistrationResponse: IUserRegistrationResponse;
  getRegisterParams: {
    name: string;
    email: string;
    password: string;
    phone?: number;
    agree: boolean;
  };
}

export type ISignUpFeature = IFeatureCreator<ISignUp>;

export const [useSignUpStore, SignUpProvider] = leitenFeature(({ useProps }: ISignUpFeature) => {
  const props = useProps.getState();

  const useStore = create<IState>(() => ({
    userLoginResponse: null,
    userRegistrationResponse: {
      data: null,
    },
    getRegisterParams: {
      name: props.name,
      email: props.email,
      password: props.password,
      phone: props.phone,
      agree: props.agree,
    },
  }));

  const useRegisterParamsController = leitenPrimitive(useStore, "getRegisterParams");

  const userRegistrationSelector = (state: IState) => {
    return {
      data: state.userRegistrationResponse.data || {},
    };
  };
  const getUserRegistrationParamsSelector = (state: IState) => {
    return { ...state.getRegisterParams };
  };

  const useRegisterController = leitenRequest(
    useStore,
    "userRegistrationResponse",
    userRegistration,
    {
      fulfilled: ({ payload, result }) => {
        useRegisterParamsController.set({
          name: payload.name,
          email: payload.email,
          password: payload.password,
          phone: payload.phone,
          agree: payload.agree,
        });
        console.log(result);
        userloginController.action({
          email: payload.email,
          password: payload.password,
          rememberMe: 1,
        });
      },
      rejected: ({ error }) => {
        console.log(error);
        toastNotification.error(<ToastTitle>{error}</ToastTitle>);
      },
    },
  );

  return {
    useStore,
    useProps,
    useRegisterController,
    useRegisterParamsController,
    userRegistrationSelector,
    getUserRegistrationParamsSelector,
  };
});
