import { amazonMarketsData, getMarketInfo, walmartMarketsData } from "@helium10/re-core";
import { format, isValid, subDays } from "date-fns";
import { create } from "zustand";
import { shallow } from "zustand/shallow";

import { STORED_PERIOD } from "@/core/consts/period";
import type { IFilterCommon } from "@/core/helpers/getFilterString";
import type { IProfile } from "@/core/interfaces";
import type { IGroupProfiles } from "@/requests/getSubscriptions";
import { initialProfile } from "@/requests/getSubscriptions";

interface IStoredPeriod {
  from: { year: number; month: number; day: number };
  to: { year: number; month: number; day: number };
}

const defaultOffset = 1;

const defaultPeriod: [from: Date, to: Date] = [
  subDays(new Date(), defaultOffset + 7),
  subDays(new Date(), defaultOffset),
];

export const getPeriodFromQueryParams = (): [fromDate: Date, toDate: Date] | null => {
  const params = new URLSearchParams(window.location.search);

  try {
    const from = params.get("from") || "";
    const to = params.get("to") || "";

    const fromDate = new Date(from);
    const toDate = new Date(to);

    const isValidDateRange = isValid(fromDate) && isValid(toDate);

    return isValidDateRange ? [fromDate, toDate] : null;
  } catch (e) {
    return null;
  }
};

export const getSavedPeriod = (): [from: Date, to: Date] => {
  const saved: IStoredPeriod = JSON.parse(localStorage.getItem(STORED_PERIOD) || "{}");

  try {
    const fromDate = new Date(saved.from.year, saved.from.month, saved.from.day);
    const toDate = new Date(saved.to.year, saved.to.month, saved.to.day);

    if (isValid(fromDate) && isValid(toDate)) {
      return [fromDate, toDate];
    }

    return defaultPeriod;
  } catch (e) {
    return defaultPeriod;
  }
};

const STORED_PROFILE = "storedProfileAdtomic";
const stored = localStorage.getItem(STORED_PROFILE);
export const initialProfileState = stored ? JSON.parse(stored) : initialProfile;

interface ICommonState {
  accountId: string;
  setAccountId: (value: string) => void;
  profile: IProfile;
  setProfile: (value: IProfile) => void;
  groupProfiles: IGroupProfiles[];
  setGroupProfiles: (value: IGroupProfiles[]) => void;
  period: Date[];
  setPeriod: (value: Date[]) => void;
}

export const useCommonStore = create<ICommonState>((set) => ({
  accountId: "",
  setAccountId: (accountId: string) => {
    set(() => ({ accountId }));
  },
  period: getPeriodFromQueryParams() || getSavedPeriod(),
  setPeriod: (period: Date[]) => {
    if (period.every((d) => isValid(d))) {
      set((_) => ({ period }));
    }
  },
  profile: initialProfileState,
  setProfile: (profile: IProfile) => {
    set(() => ({ profile }));
  },
  groupProfiles: [],
  setGroupProfiles: (groupProfiles) => set(() => ({ groupProfiles })),
}));

export const getCommonSelector = (): Omit<IFilterCommon, "breakdowns"> => {
  const state = useCommonStore.getState();

  return {
    profile_id: state.profile.id,
    date_from: format(state.period[0], "dd-MM-yyyy"),
    date_to: format(state.period[1], "dd-MM-yyyy"),
  };
};

export const useCommonSelector = () => {
  const state = useCommonStore();

  return {
    profile_id: state.profile.id,
    date_from: format(state.period[0], "dd-MM-yyyy"),
    date_to: format(state.period[1], "dd-MM-yyyy"),
  };
};

export const useIsSchedulesAvailableMarketplaces = (): boolean => {
  const { code, amazon, walmart } = useCommonStore(
    (state) => ({
      code: state.profile.country_code,
      amazon: state.profile.data_provider_type === "AMAZON",
      walmart: state.profile.data_provider_type === "WALMART",
    }),
    shallow,
  );
  return (amazon && ["US", "CA", "MX"].includes(code)) || walmart;
};

export const useMarketplace = () => {
  const { marketplace_string_id, data_provider_type } = useCommonStore.getState().profile;
  switch (data_provider_type) {
    case "AMAZON":
      return getMarketInfo("AM", "store", marketplace_string_id) || amazonMarketsData.com;
    case "WALMART":
      return getMarketInfo("WALMART", "store", marketplace_string_id) || walmartMarketsData.com;
    default:
      return getMarketInfo("WALMART", "store", marketplace_string_id) || walmartMarketsData.com;
  }
};

export const amazonProfilesSelector = (state: ICommonState) => {
  return state.groupProfiles
    .filter((item) => item.profiles.some((p) => p.domain.includes("amazon")))
    .map((item) => ({
      ...item,
      profiles: item.profiles.filter((p) => p.domain.includes("amazon")),
    }));
};

export const getAccountTypeSelector = () => {
  const { account_type } = useCommonStore.getState().profile;
  return account_type;
};
