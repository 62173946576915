import { IFeatureCreator, leitenFeature, leitenRequest } from "@helium10/re-core";
import { toastNotification, ToastTitle } from "@helium10/re-ui-components";
import { setRefreshTokenToStorage } from "@walmart10/container/helpers/refreshToken";
import type { Params } from "react-router-dom";
import { create } from "zustand";

import { appRoutes } from "../../../core/links";
import {
  ISendMFAEmailResponse,
  IUserVerifyLoginResponse,
  IVerifyLoginAgainResult,
  IVerifyLoginFailedResult,
  IVerifyLoginSuccessResult,
  sendMFAEmail,
  userVerifyLogin,
} from "../requests";

interface ICodeVerification {
  email: string;
  password: string;
  rememberMe: 1 | 0;
  code: string;
  validateCode: string;
  getPath: <S extends string>(
    url: string,
    query?: Record<string, string | number>,
    params?: Params<S>,
  ) => string;
  redirect: <S extends string>(
    url: string,
    query?: Record<string, string | number>,
    params?: Params<S>,
    force?: boolean,
  ) => void;
}

interface IState {
  sendMFAEmailResponse: ISendMFAEmailResponse;
  userVerifyLoginResponse: IUserVerifyLoginResponse;
}

export type ICodeVerificationFeature = IFeatureCreator<ICodeVerification>;

export const [useCodeVerificationStore, CodeVerificationProvider] = leitenFeature(
  ({ useProps }: ICodeVerificationFeature) => {
    const { getPath, redirect } = useProps.getState();

    const useStore = create<IState>(() => ({
      sendMFAEmailResponse: {
        data: null,
      },
      userVerifyLoginResponse: {
        results: { code: 200, message: "" },
      },
    }));

    const useSendMFAEmailController = leitenRequest(
      useStore,
      "sendMFAEmailResponse",
      sendMFAEmail,
      {
        fulfilled: ({ payload, result }) => {
          console.log(payload, result);
        },
      },
    );

    const useVerifyLoginController = leitenRequest(
      useStore,
      "userVerifyLoginResponse",
      userVerifyLogin,
      {
        fulfilled: ({ payload, result }) => {
          console.log(payload, result);
          if (result.results.code === 0) {
            const results = result.results as IVerifyLoginAgainResult;
            toastNotification.error(<ToastTitle>{results.message}</ToastTitle>);
            // Need double login
          } else if (result.results.code === 200) {
            if ((result.results as { message?: string })?.message) {
              const results = result.results as IVerifyLoginFailedResult;
              toastNotification.error(<ToastTitle>{results.message}</ToastTitle>);
            } else {
              const results = result.results as IVerifyLoginSuccessResult;
              // TODO need save accessToken | refreshToken
              console.log("here, need to save accessToken | refreshToken");
              setRefreshTokenToStorage(results.refreshToken);
              redirect(getPath(appRoutes.home));
            }
          }
        },
      },
    );

    return {
      useStore,
      useProps,
      useSendMFAEmailController,
      useVerifyLoginController,
    };
  },
);
