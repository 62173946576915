import { DialogModal, NewButton } from "@helium10/re-ui-components";
import { memo } from "react";
import styled from "styled-components";

import { useTranslation } from "../../../i18n/useTranslation";
import { useAccessManagementStore } from "../store";

export const DeleteConfirmModal = memo(() => {
  const {
    useDeleteConfirmModal,
    useDeleteUserAccountController,
    useStore,
    deleteConfirmParamsSelector,
  } = useAccessManagementStore();
  const delParams = useStore(deleteConfirmParamsSelector);
  const controller = useDeleteUserAccountController();
  const [isOpen] = useDeleteConfirmModal();
  const { t } = useTranslation();

  return (
    <DialogModal
      title={t("accessManagement.modal.delete.title")}
      onClose={useDeleteConfirmModal.close}
      onClickOutside={() => false}
      onClickEscape={() => false}
      maxWidth={"504px"}
      footer={
        <Between>
          <NewButton buttonType={"cancel"} onClick={useDeleteConfirmModal.close} size={"200"}>
            {t("accessManagement.button.cancel")}
          </NewButton>
          <NewButton
            loading={controller.status === "loading"}
            onClick={() => {
              if (delParams) {
                useDeleteUserAccountController.action({ userId: delParams.id });
              }
            }}
            buttonType={"primary"}
            size={"200"}
          >
            {t("accessManagement.button.confirm")}
          </NewButton>
        </Between>
      }
      isOpen={isOpen}
    >
      {t("accessManagement.modal.delete.content")}
    </DialogModal>
  );
});

const Between = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
