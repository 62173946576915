import { Logo } from "@helium10/re-ui-components";
import { memo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { useRedirect } from "@/core/hooks/useRedirect";

import { useLoginAction } from "../../core/hooks/useLoginAction";
import { ActivateUserProvider, useActivateUserStore } from "./store";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ActivateUser = memo(() => {
  const { getPath, redirect } = useRedirect();
  return (
    <ActivateUserProvider value={{ getPath, redirect }}>
      <Container />
    </ActivateUserProvider>
  );
});

const Container = memo(() => {
  useLoginAction;
  const { useActivateUserController } = useActivateUserStore();
  const activateUserController = useActivateUserController();
  const query = useQuery();
  const secretKey = query.get("key") as string;
  const confirm = async () => {
    useActivateUserController.action({
      secretKey,
      useAssociateUserPwd: true,
      website: "Wick",
    });
  };
  useEffect(() => {
    confirm();
  }, []);
  return (
    <Wrapper>
      {activateUserController.status === "loading" && (
        <Logo style={{ height: "50px", width: "50px" }}></Logo>
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;
