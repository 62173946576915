import { IFeatureCreator, leitenFeature, leitenRequest } from "@helium10/re-core";
import { toastNotification, ToastTitle } from "@helium10/re-ui-components";
import type { Params } from "react-router-dom";
import { create } from "zustand";

import { appRoutes } from "../../../core/links";
import { IResetPasswordResponse, resetPassword } from "../requests";

interface IResetPassword {
  password: string;
  getPath: <S extends string>(
    url: string,
    query?: Record<string, string | number>,
    params?: Params<S>,
  ) => string;
  redirect: <S extends string>(
    url: string,
    query?: Record<string, string | number>,
    params?: Params<S>,
    force?: boolean,
  ) => void;
}

interface IState {
  resetPasswordResponse: IResetPasswordResponse;
}

export type IResetPasswordFeature = IFeatureCreator<IResetPassword>;

export const [useResetPasswordStore, ResetPasswordProvider] = leitenFeature(
  ({ useProps }: IResetPasswordFeature) => {
    const { getPath, redirect } = useProps.getState();

    const useStore = create<IState>(() => ({
      resetPasswordResponse: {
        data: null,
      },
    }));

    const useResetPasswordController = leitenRequest(
      useStore,
      "resetPasswordResponse",
      resetPassword,
      {
        fulfilled: ({ payload, result }) => {
          console.log(payload, result, window.location);
          redirect(getPath(appRoutes.signIn));
        },
        rejected: ({ fetchError }) => {
          toastNotification.error(<ToastTitle>{fetchError?.result?.msg || ""}</ToastTitle>);
        },
      },
    );

    return {
      useStore,
      useProps,
      useResetPasswordController,
    };
  },
);
