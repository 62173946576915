import { baseFetch } from "@helium10/re-core";

import { getHeaderHelper } from "@/core/helpers/getHeader.helper";
import type { ISuggestionCountType } from "@/store/account/suggestionsCount";

export const getSuggestionsCount = async (url: string) => {
  return baseFetch<void, ISuggestionCountType>({
    method: "GET",
    withCredentials: true,
    headers: getHeaderHelper(),
    url,
    useSessionAccountId: true,
  });
};
