import { LabelWithTooltip, NewInput, Tooltip } from "@helium10/re-ui-components";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import styled from "styled-components";

interface IProps {
  name: string;
  label: string;
  disabled?: boolean;
  placeHolder?: string;
  maxLength?: number;
  rules:
    | Omit<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">
    | undefined;
}

export const InputItem = (props: IProps) => {
  const { name, rules, label, disabled = false, ...rst } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, formState }) => (
        <GripWrapper>
          <LabelWithTooltip required label={label} />
          {!disabled && (
            <>
              <NewInput value={field.value} onChange={field.onChange} {...rst} />
              {formState.errors[name] && (
                <ErrorLine>{String(formState.errors[name]?.message)}</ErrorLine>
              )}
            </>
          )}
          {disabled && (
            <Tooltip maxWidth={1000} content={field.value} appendTo={document.body}>
              <DisableWrapper>{field.value}</DisableWrapper>
            </Tooltip>
          )}
        </GripWrapper>
      )}
    />
  );
};

const DisableWrapper = styled.div`
  background-color: rgb(217, 224, 232);
  color: rgb(43, 57, 72);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 30px;
  padding: 0 8px;
  border-radius: 6px;
`;

const ErrorLine = styled.p`
  color: red;
  margin: 0px;
  padding: 0px;
`;

const GripWrapper = styled.div`
  display: flex;
  grid-column: span 4;
  align-items: flex-start;
  flex-direction: column;
`;
