import {
  IFeatureCreator,
  leitenFeature,
  leitenModal,
  leitenPrimitive,
  leitenRequest,
} from "@helium10/re-core";
import { toastNotification, ToastTitle } from "@helium10/re-ui-components";
import { create } from "zustand";

import { useTranslationStore } from "../../../i18n/useTranslation";
import {
  addNewUser,
  deleteUserAccount,
  getAllMenus,
  getUserFeaturesById,
  getUserList,
  IAddNewUserResponse,
  IDeleteUserResponse,
  IGetAllMenusResponse,
  IGetUserFeaturesResponse,
  IGetUserListResponse,
  ISaveUserInfoResponse,
  saveUserInfo,
} from "../requests";

interface IAccessManagement {
  pageSize: number;
  pageIndex: number;
}

interface IState {
  userInfoModal: { id: number; email: string; name: string } | null;
  useDeleteConfirmModal: { id: string } | null;
  accountConfirmModal: null;
  userListResponse: IGetUserListResponse;
  deleteUserResponse: IDeleteUserResponse | null;
  getUserFeaturesByIdResponse: IGetUserFeaturesResponse;
  addNewUserResponse: IAddNewUserResponse | null;
  saveUserInfoResponse: ISaveUserInfoResponse;
  getAllMenusResponse: IGetAllMenusResponse;
  userAccountTableParams: { pageSize: number; pageIndex: number };
}

export type IAccessManagementFeature = IFeatureCreator<IAccessManagement>;

export const [useAccessManagementStore, AccessManagementProvider] = leitenFeature(
  ({ useProps }: IAccessManagementFeature) => {
    const props = useProps.getState();

    const useStore = create<IState>(() => ({
      userInfoModal: null,
      accountConfirmModal: null,
      useDeleteConfirmModal: null,
      userListResponse: { data: { list: [], totalCount: 0 } },
      deleteUserResponse: null,
      getUserFeaturesByIdResponse: { data: [] },
      saveUserInfoResponse: { data: null },
      getAllMenusResponse: { data: [] },
      addNewUserResponse: null,
      userAccountTableParams: {
        pageIndex: props.pageSize,
        pageSize: props.pageIndex,
      },
    }));

    const useUserAccountTableParamsController = leitenPrimitive(useStore, "userAccountTableParams");

    const useDeleteUserAccountController = leitenRequest(
      useStore,
      "deleteUserResponse",
      deleteUserAccount,
      {
        fulfilled: ({ result }) => {
          if (result.data) {
            const translate = useTranslationStore.getState().translation;
            const { pageSize } = useUserAccountTableParamsController.get();
            toastNotification.success(
              <ToastTitle>{translate?.t("toastTitle.remove.success")}</ToastTitle>,
            );
            useUserListController.action({ page: { pageSize, pageIndex: 1 } });
            useDeleteConfirmModal.close();
          }
        },
        rejected: ({ fetchError }) => {
          const translate = useTranslationStore.getState().translation;
          toastNotification.error(
            <ToastTitle>
              {fetchError?.result?.msg || translate?.t("toastTitle.remove.failed")}
            </ToastTitle>,
          );
        },
      },
    );

    const useGetUserFeaturesByIdController = leitenRequest(
      useStore,
      "getUserFeaturesByIdResponse",
      getUserFeaturesById,
    );

    const useEditUserInfoController = leitenRequest(
      useStore,
      "saveUserInfoResponse",
      saveUserInfo,
      {
        resolved: () => {
          useUserInfoModal.close();
          const { pageSize } = useUserAccountTableParamsController.get();
          useUserListController.action({
            page: { pageSize, pageIndex: 1 },
          });
          const translate = useTranslationStore.getState().translation;
          toastNotification.success(
            <ToastTitle>{translate?.t("toastTitle.edit.success")}</ToastTitle>,
          );
        },
        rejected: ({ fetchError }) => {
          const translate = useTranslationStore.getState().translation;
          toastNotification.error(
            <ToastTitle>
              {fetchError?.result?.msg || translate?.t("toastTitle.edit.failed")}
            </ToastTitle>,
          );
        },
      },
    );

    const useGetAllMenusController = leitenRequest(
      useStore,
      "getAllMenusResponse",
      async (_: void) => {
        return await getAllMenus();
      },
    );

    const useUserListController = leitenRequest(useStore, "userListResponse", getUserList, {
      fulfilled: ({ payload }) => {
        useUserAccountTableParamsController.set({
          pageSize: payload.page.pageSize,
          pageIndex: payload.page.pageIndex,
        });
      },
    });

    const useAddNewUserController = leitenRequest(useStore, "addNewUserResponse", addNewUser, {
      fulfilled: ({ result }) => {
        useUserInfoModal.close();
        const { pageSize } = useUserAccountTableParamsController.get();
        useUserListController.action({
          page: { pageSize, pageIndex: 1 },
        });
        if (result.data.newInviteUser) {
          setTimeout(() => {
            useAccountConfirmModal.open();
          }, 1000);
        } else {
          const translate = useTranslationStore.getState().translation;
          toastNotification.success(
            <ToastTitle>{translate?.t("toastTitle.addNewUser.success")}</ToastTitle>,
          );
        }
      },
      rejected: ({ fetchError }) => {
        const translate = useTranslationStore.getState().translation;
        toastNotification.error(
          <ToastTitle>
            {fetchError?.result?.msg || translate?.t("toastTitle.addNewUser.failed")}
          </ToastTitle>,
        );
      },
    });

    const userListSelector = (state: IState) => state.userListResponse.data;
    const deleteConfirmParamsSelector = (state: IState) => state.useDeleteConfirmModal;
    const getUserFeaturesByIdSelector = (state: IState) => state.getUserFeaturesByIdResponse.data;

    const allMenusSelector = (state: IState) => state.getAllMenusResponse.data;
    const permConfigDialogInputSelector = (state: IState) => state.userInfoModal;

    const useUserInfoModal = leitenModal(useStore, "userInfoModal", { clearOnClose: true });
    const useDeleteConfirmModal = leitenModal(useStore, "useDeleteConfirmModal");
    const useAccountConfirmModal = leitenModal(useStore, "accountConfirmModal");

    return {
      useStore,
      useProps,
      useUserInfoModal,
      useDeleteConfirmModal,
      useAccountConfirmModal,
      useUserListController,
      useDeleteUserAccountController,
      useGetUserFeaturesByIdController,
      useEditUserInfoController,
      useGetAllMenusController,
      useAddNewUserController,
      userListSelector,
      allMenusSelector,
      getUserFeaturesByIdSelector,
      deleteConfirmParamsSelector,
      permConfigDialogInputSelector,
    };
  },
);
