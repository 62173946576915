import {
  baseFetch,
  getCurrentAccountId,
  getUrlWithSearchParams,
  Helium10Plans,
} from "@helium10/re-core";

import { customerHelium10Link } from "@/core/link/apiLink";

export const getCustomerHelium10 = () =>
  baseFetch<void, ICustomerHelium10Response>({
    url: getUrlWithSearchParams(
      customerHelium10Link,
      { accountId: getCurrentAccountId() },
      { hasAccountId: false },
    ),
    withCredentials: true,
  });

export interface ICustomerHelium10Response {
  results: ICustomer;
}

export interface ICustomer {
  accountId: number;
  email: string;
  full_name: string;
  id: number;
  merchant_ids: Array<{
    seller_string_id: string;
    region: string;
  }>;
  plan: Helium10Plans;
  ppc_enabled: boolean;
  ppc_enabled_raw: boolean;
  ppc_nds_signed: boolean;
  has_ppc_token: boolean;
  subscription: {
    coupon: string;
    plan: Helium10Plans;
  };
  userId: number;
  tester: boolean;
  invoices: IInvoice[];
  hasAccess: boolean;
  hasGoogleAdsToken: boolean;
  hasEliteAccess: boolean;
  isActualPlan: boolean;
  isAccessIncluded: boolean;
  isAccessAdded: boolean;
  canGetAccess: boolean;
}

export const initialCustomer: ICustomer = {
  accountId: 0,
  email: "",
  full_name: "",
  id: 0,
  merchant_ids: [],
  plan: Helium10Plans.Free,
  ppc_enabled: true,
  ppc_enabled_raw: true,
  ppc_nds_signed: true,
  has_ppc_token: true,
  hasGoogleAdsToken: true,
  hasAccess: true,
  subscription: {
    coupon: "",
    plan: Helium10Plans.Free,
  },
  userId: 0,
  tester: false,
  invoices: [],
  hasEliteAccess: true,
  isActualPlan: true,
  isAccessIncluded: true,
  isAccessAdded: true,
  canGetAccess: true,
};

export interface IInvoice {
  amount: string;
  invoiceId: string;
  invoiceStatus: string;
  invoiceDueDate: number;
  invoiceUrl: string;
  periodStart: number;
  periodEnd: number;
  lastPaymentAttempt: number;
  lastPaymentAttemptError: null;
  adtomicUsage: {
    amount: number;
    adSpend: number;
    threshold: number;
    percentage: number;
  };
  gracePeriodBy: number;
}
