import { baseFetch } from "@helium10/re-core";

import { getHeaderHelper } from "@/core/helpers/getHeader.helper";
import type { DataProviderType, IProfile } from "@/core/interfaces";
import { subscriptionsLinkV2 } from "@/core/link/apiLink";
import type { IRejected } from "@/core/rejectReaction";

export const getSubscriptions = async () => {
  return baseFetch<void, IGroupProfilesResponse>({
    method: "GET",
    withCredentials: true,
    headers: getHeaderHelper(),
    url: subscriptionsLinkV2,
    useSessionAccountId: true,
  });
};

export type IGroupProfilesResponse = {
  data: {
    accounts: IGroupProfiles[];
    subscription_statuses: Record<string, boolean>;
  };
  meta: {
    count: number;
    current_profile: string;
    offset: number;
    perPage: number;
    totalCount: number;
  };
} & IRejected;

export interface IGroupProfiles {
  brand_name: string;
  custom_name: string;
  data_provider_type: DataProviderType;
  external_platform: DataProviderType;
  profiles: IProfile[];
  id: string;
  seller_string_id: string;
  has_amazon_brands: boolean;
}

export const initialProfile: IProfile = {
  account_sub_type: null,
  account_type: "seller",
  added_at: "",
  brand_name: "",
  country_code: "US",
  currency_code: "",
  custom_name: "",
  daily_budget: 0,
  domain: "",
  handling_policy: 0,
  has_mws: false,
  id: "",
  id_raw: "",
  is_disabled: false,
  is_valid: false,
  marketplace_string_id: "",
  ppc_token_id: 0,
  profits_enabled: 0,
  seller_custom_name: "",
  seller_string_id: "",
  site: "",
  sync_state: "none",
  target_acos: null,
  timezone: "",
  user_id: 0,
  account_id: "",
  created_date: "",
  external_id: "",
  seller_account_id: "",
  sync_event_id: "",
  data_provider_type: "WALMART",
};
