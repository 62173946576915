import type { SetupIntent, StripeError } from "@stripe/stripe-js";

import type { IStripe } from "../../store/useCheckoutModalStore";

export interface IStripeRetrieveIntentRequest {
  stripe: IStripe;
  clientSecret: string;
}

export interface IStripeRetrieveIntentResponse {
  setupIntent?: SetupIntent;
  error?: StripeError;
}

export const stripeRetrieveIntent = async ({
  stripe,
  clientSecret,
}: IStripeRetrieveIntentRequest): Promise<IStripeRetrieveIntentResponse> => {
  return await stripe.retrieveSetupIntent(clientSecret);
};
