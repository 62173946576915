import { IFeatureCreator, leitenFeature, leitenRequest } from "@helium10/re-core";
import { toastNotification } from "@helium10/re-ui-components";
import type { Params } from "react-router-dom";
import { create } from "zustand";

import { appRoutes } from "../../../core/links";
import { activateUser, IActivateUserResponse } from "../../userSettings/requests";

interface IState {
  activateUser: IActivateUserResponse;
}

export type ICodeVerificationFeature = IFeatureCreator<{
  getPath: <S extends string>(
    url: string,
    query?: Record<string, string | number>,
    params?: Params<S>,
  ) => string;
  redirect: <S extends string>(
    url: string,
    query?: Record<string, string | number>,
    params?: Params<S>,
    force?: boolean,
  ) => void;
}>;

export const [useActivateUserStore, ActivateUserProvider] = leitenFeature(
  ({ useProps }: ICodeVerificationFeature) => {
    const { getPath, redirect } = useProps.getState();
    const useStore = create<IState>(() => ({
      activateUser: {
        code: 0,
        msg: "",
      },
    }));
    const useActivateUserController = leitenRequest(useStore, "activateUser", activateUser, {
      fulfilled: (res) => {
        if (res.result.code === 200) {
          redirect(getPath(appRoutes.settings));
        } else {
          toastNotification.error(res.result.msg, { autoClose: false });
        }
      },
      rejected: (res) => {
        const error = res.error || res.fetchError?.result?.msg;
        toastNotification.error(error, { autoClose: false });
      },
    });

    return {
      useStore,
      useProps,
      useActivateUserController,
    };
  },
);
