import { getUrlWithSearchParams, isDevHost, leitenPrimitive, membersLink } from "@helium10/re-core";
import { create } from "zustand";

import type { IAccountId } from "../../../common/types/IAccountId";
import type { INewMenuProps } from "../NewMenu";

type ICallback = Pick<INewMenuProps, "onAccountChange" | "onLogout" | "logoutToken">;
export interface ICallbacksState {
  callbacks: ICallback;
}

export const useCallbacksStore = create<ICallbacksState>(() => ({
  callbacks: {
    onAccountChange: (accountId) => {
      window.location.assign(
        getUrlWithSearchParams(
          isDevHost?.() ? window.location.origin : membersLink,
          { accountId },
          { hasAccountId: false },
        ),
      );
    },
  },
}));

export const setCallbacks = (payload: ICallback) => {
  useCallbacksStore.setState((state) => ({ callbacks: { ...state.callbacks, ...payload } }));
};

export interface IAccountAtomState {
  number: IAccountId;
}
export const useAccountStore = create<IAccountAtomState>(() => ({
  number: -1,
}));

export const useSetAccountIdController = leitenPrimitive(useAccountStore, "number");

export const accountIdSelector = (state: IAccountAtomState) => {
  return state.number;
};
