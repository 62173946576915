import { cssDisplay400Bold, NewTable } from "@helium10/re-ui-components";
import { memo, useEffect } from "react";
import styled from "styled-components";

import { useTranslation } from "../../i18n/useTranslation";
import { AccountConfirmModal } from "./components/AccountConfirmModal";
import { DeleteConfirmModal } from "./components/DeleteConfirmModal";
import { UserInfoModal } from "./components/UserInfoModal";
import { useDefault, useTableProps } from "./hooks";
import { AccessManagementProvider, useAccessManagementStore } from "./store";

export const AccessManagement = memo(() => {
  const value = useDefault();

  return (
    <AccessManagementProvider value={value}>
      <Context />
    </AccessManagementProvider>
  );
});

const Header = memo(() => {
  const { t } = useTranslation();

  return (
    <Title>
      <MainTitle>{t("accessManagement.title.main")}</MainTitle>
      <SubTitle>{t("accessManagement.title.sub")}</SubTitle>
    </Title>
  );
});

const Context = () => {
  const { useStore, userListSelector, useUserListController, useGetAllMenusController } =
    useAccessManagementStore();
  const userListController = useUserListController();
  const { list, totalCount } = useStore(userListSelector);
  const tableProps = useTableProps(list);

  useEffect(() => {
    useGetAllMenusController.action();
  }, []);

  return (
    <>
      <Wrapper>
        <Header />
        <AccountTable
          name={"access-management"}
          status={userListController.status}
          data={list}
          total={totalCount}
          {...tableProps}
        />
        <UserInfoModal />
        <DeleteConfirmModal />
        <AccountConfirmModal />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.main`
  padding: 0px ${({ theme }) => theme.spacing.sp48};
`;

const Title = styled.div`
  margin: 36px 0px 32px 0px;
`;

const MainTitle = styled.h1`
  ${cssDisplay400Bold}
`;

const SubTitle = styled.span`
  font-size: 16px;
  line-height: 28px;
`;

const AccountTable = styled(NewTable)`
  border: 0px;

  & > div:first-child {
    border: none;
    padding: 0px 0px 24px;
  }
`;
