import { NewButton, NewCheckbox } from "@helium10/re-ui-components";
import _ from "lodash-es";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { CheckBoxSkeleton, FilterGrid8Columns, UpArrow } from "./Styled";

interface ITreeLeaf {
  label: string;
  id: string;
}

interface ITreeNode {
  label: string;
  id: string;
  children: ITreeLeaf[];
}

interface ICheckTree {
  checkIds: string[];
  onChange: (checkIds: string[]) => void;
  data: {
    label: string;
    id: string;
    children: ITreeNode[] | null;
  };
  isLoading: boolean;
}

const removeElement = (arr: string[], e: string) => {
  if (arr.includes(e)) {
    const index = arr.indexOf(e);
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
  }
  return [...arr];
};
const addElement = (arr: string[], e: string) => (arr.includes(e) ? [...arr] : [...arr, e]);

const getAllLeafIds = (nodes: { label: string; id: string }[]) => nodes.map((node) => node.id);

export const CheckTree = (props: ICheckTree) => {
  const { checkIds, data, onChange, isLoading = false } = props;
  const [expandGroups, setExpandGroups] = useState<string[]>(
    (data.children || []).map((node) => node.id),
  );

  const { pairs, filledData, allLeafs } = useMemo(() => {
    const pairs = new Map<string, string[]>();
    const filledData = _.clone(data);
    const allLeafs = (filledData.children || []).reduce((previousValue: string[], currentValue) => {
      if (currentValue.children && currentValue.children.length !== 0) {
        pairs.set(currentValue.id, getAllLeafIds(currentValue.children));
        return [...previousValue, ...getAllLeafIds(currentValue.children)];
      } else {
        return [...previousValue, currentValue.id];
      }
    }, []);
    pairs.set(filledData.id, allLeafs);

    return {
      pairs,
      filledData,
      allLeafs,
    };
  }, [data]);

  const handleOnChange = useCallback(
    (ids: string[]) => {
      (filledData.children || []).forEach((subTree) => {
        const found = subTree.children.find((leaf) => {
          return ids.includes(leaf.id);
        });
        if (found) {
          ids.push(subTree.id);
        }
      });

      onChange(ids);
    },
    [onChange],
  );

  const checkedLeafIds = _.intersection(allLeafs, checkIds);

  return (
    <>
      <RootCheckBoxLine>
        {!isLoading ? (
          <NewCheckbox
            indeterminate={_.xor(pairs.get(filledData.id), checkedLeafIds).length !== 0}
            checked={checkedLeafIds.length !== 0}
            id={filledData.id}
            onChange={() => {
              const allLeafIds = pairs.get(filledData.id) || [];
              const values = checkedLeafIds.length !== 0 ? [] : [...allLeafIds];
              handleOnChange(values);
            }}
          >
            <CheckBoxLabel>{filledData.label}</CheckBoxLabel>
          </NewCheckbox>
        ) : (
          <CheckBoxSkeleton />
        )}
      </RootCheckBoxLine>
      {(filledData.children || []).map((group) => {
        if (group.children && group.children.length !== 0) {
          const groupIds = pairs.get(group.id) || [];
          const ownBranchChecked = _.intersection(checkedLeafIds, groupIds);
          return (
            <SubTreeWrapper key={group.id}>
              <NodeCheckBoxLine>
                {!isLoading ? (
                  <NewCheckbox
                    id={group.id}
                    indeterminate={_.xor(groupIds, ownBranchChecked).length !== 0}
                    checked={ownBranchChecked.length !== 0}
                    onChange={() => {
                      const diff = _.difference(checkedLeafIds, groupIds);
                      const values = ownBranchChecked.length === 0 ? [...diff, ...groupIds] : diff;
                      handleOnChange(values);
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <NewButton
                        size="50"
                        buttonType="icon"
                        icon={<UpArrow $reverse={expandGroups.includes(group.id)} />}
                        onClick={(e) => {
                          e.preventDefault();
                          const includes = expandGroups.includes(group.id);
                          const ids = includes
                            ? removeElement(expandGroups, group.id)
                            : addElement(expandGroups, group.id);
                          setExpandGroups(ids);
                        }}
                      />
                      <CheckBoxLabel>{group.label}</CheckBoxLabel>
                    </div>
                  </NewCheckbox>
                ) : (
                  <CheckBoxSkeleton />
                )}
              </NodeCheckBoxLine>
              <LeafCheckBoxLine open={expandGroups.includes(group.id)}>
                <FilterGrid8Columns>
                  {(group.children || []).map((child) => {
                    return (
                      <WideCell key={child.id}>
                        {!isLoading ? (
                          <NewCheckbox
                            id={child.id}
                            checked={checkedLeafIds.includes(child.id)}
                            onChange={() => {
                              const includes = checkedLeafIds.includes(child.id);
                              const ids = includes
                                ? removeElement(checkedLeafIds, child.id)
                                : addElement(checkedLeafIds, child.id);
                              handleOnChange(ids);
                            }}
                          >
                            <CheckBoxLabel>{child.label}</CheckBoxLabel>
                          </NewCheckbox>
                        ) : (
                          <CheckBoxSkeleton />
                        )}
                      </WideCell>
                    );
                  })}
                </FilterGrid8Columns>
              </LeafCheckBoxLine>
            </SubTreeWrapper>
          );
        } else {
          return (
            <SubTreeWrapper key={group.id}>
              <NodeCheckBoxLine>
                {!isLoading ? (
                  <NewCheckbox
                    id={group.id}
                    checked={checkedLeafIds.includes(group.id)}
                    onChange={() => {
                      const includes = checkedLeafIds.includes(group.id);
                      const ids = includes
                        ? removeElement(checkedLeafIds, group.id)
                        : addElement(checkedLeafIds, group.id);
                      handleOnChange(ids);
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <CheckBoxLabel>{group.label}</CheckBoxLabel>
                    </div>
                  </NewCheckbox>
                ) : (
                  <CheckBoxSkeleton />
                )}
              </NodeCheckBoxLine>
            </SubTreeWrapper>
          );
        }
      })}
    </>
  );
};

const WideCell = styled.div`
  grid-column: span 2;
`;

const RootCheckBoxLine = styled.div`
  margin-bottom: 16px;
`;

const NodeCheckBoxLine = styled.div`
  margin-bottom: 12px;
`;

const LeafCheckBoxLine = styled.div<{ open: boolean }>`
  margin-bottom: 12px;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.1s ease-in-out;
  overflow: hidden;

  transform: scaleY(${({ open }) => (open ? 1 : 0)});
  height: ${({ open }) => (open ? "auto" : 0)};
`;

const CheckBoxLabel = styled.span`
  color: #485e75;
`;

const SubTreeWrapper = styled.div`
  margin-bottom: 16px;
`;
