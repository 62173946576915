import type { RumInitConfiguration } from "@datadog/browser-rum";
import { datadogRum } from "@datadog/browser-rum";
import { useCredentialsStore } from "@helium10/re-core";
import { useEffect } from "react";

export const initDataDogRum = () => {
  const config: RumInitConfiguration = {
    applicationId: "faf4d57a-8917-4287-b1a2-d3032bd0be0c",
    clientToken: "pub22f15ef7eb117d6f2138e10b1e533949",
    site: "datadoghq.com",
    service: "re-walmart-container",
    env: getEnvTag(),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    version: packageVersions.profits,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    beforeSend: (event) => {
      if (
        event.type === "error" &&
        event.error.message.includes("ResizeObserver loop limit exceeded")
      ) {
        return false;
      }
    },
  };
  datadogRum.init(config);
  datadogRum.startSessionReplayRecording();
};

const getEnvTag = () => {
  switch (process.env.REACT_APP_STATE) {
    case "prod":
      return "prd";
    case "stage":
      return "stg";
    case "dev":
      return "dev";
    default:
      return "unknown";
  }
};

export const useDataDogRum = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      initDataDogRum();
      return useCredentialsStore.subscribe((s, p) => {
        const { userId, accountId } = s.main;
        const { userId: prevUserId, accountId: prevAccountId } = p.main;

        if (userId && accountId && userId !== prevUserId && accountId !== prevAccountId) {
          datadogRum.setUser({
            id: accountId,
            userId,
          });
        }
      });
    }
  }, []);
};
