import { combineStatuses, useRecaptchaStore } from "@helium10/re-core";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { shallow } from "zustand/shallow";

import { sendSegmentEvent } from "../../../../common/segmentEvents/sendSegmentEvent";
import {
  clearSubscriptionCheck,
  dataController,
  intentController,
  stripeConfirmAlipayController,
  subscribeCheckController,
  subscriptionCheck,
  useCheckoutModalStore,
} from "../store/useCheckoutModalStore";

export const useAlipay = () => {
  const dataStatus = dataController((state) => state.status);
  const intentStatus = intentController((state) => state.status);
  const stripeConfirmAlipayStatus = stripeConfirmAlipayController((state) => state.status);
  const captchaTokenV2 = useRecaptchaStore((state) => state.captchaTokenV2);

  const [intent, paymentMode, stripeConfirmAlipay, subscribeCheck] = useCheckoutModalStore(
    (state) => [
      state?.intent,
      state?.paymentMode,
      state?.stripeConfirmAlipay,
      state?.subscribeCheck,
    ],
    shallow,
  );

  const { formState, setError, clearErrors, handleSubmit } = useForm({ mode: "onChange" });

  const isLoading =
    !!subscriptionCheck ||
    combineStatuses(dataStatus, intentStatus, stripeConfirmAlipayStatus) === "loading";

  const isSubmitting = isLoading && formState?.isSubmitted;

  const isEnabledRecaptchaV2 =
    intent?.data?.status === "error" && intent?.data?.message === "Insecure request";

  const handleIntent = useCallback(
    async ({ captchaTokenV2 }: { captchaTokenV2?: string | null }) => {
      intentController?.action({ captchaTokenV2, intent: "payment" });
    },
    [],
  );

  const onSubmitAlipay = useCallback(() => {
    stripeConfirmAlipayController?.action({});
  }, []);

  useEffect(() => {
    handleIntent({});

    return () => {
      intentController.clear();
      stripeConfirmAlipayController.clear();
      subscribeCheckController.clear();
      clearSubscriptionCheck();
    };
  }, [handleIntent]);

  useEffect(() => {
    if (isEnabledRecaptchaV2 && captchaTokenV2) handleIntent({ captchaTokenV2 });
  }, [isEnabledRecaptchaV2, captchaTokenV2, handleIntent]);

  useEffect(() => {
    sendSegmentEvent({ name: "Subscription Modal Viewed" });
  }, [paymentMode]);

  useEffect(() => {
    if (
      intent?.data?.status === "error" ||
      stripeConfirmAlipay?.error ||
      (subscribeCheck?.data?.status === "error" && !subscriptionCheck)
    ) {
      setError("root.stripe", {
        type: "stripe",
        message:
          intent?.data?.message ||
          stripeConfirmAlipay?.error?.message ||
          subscribeCheck?.data?.message,
      });
    } else {
      clearErrors("root.stripe");
    }
  }, [intent, stripeConfirmAlipay, subscribeCheck, setError, clearErrors]);

  return {
    isLoading,
    isSubmitting,
    formState,
    handleSubmit,
    onSubmitAlipay,
    isEnabledRecaptchaV2,
  };
};
