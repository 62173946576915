import { baseFetch } from "@helium10/re-core";

import { userSystem } from "../../../core/links";

// register
export interface IUserRegistration {
  name: string;
  email: string;
  password: string;
  phone?: number;
  agree: boolean;
}
export interface IUserRegistrationResponse {
  data: {
    results?: string;
    code?: number;
    message?: string;
    name?: string;
    status?: number;
  } | null;
}

export const userRegistration = async (params: IUserRegistration) => {
  return baseFetch<IUserRegistration, IUserRegistrationResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: false,
    url: userSystem.signup,
    params: params,
  });
};
