import { baseFetch, getCurrentAccountId, getUrlWithSearchParams } from "@helium10/re-core";

import { getHeaderHelper } from "@/core/helpers/getHeader.helper";
import { customerAdtomicLink } from "@/core/link/apiLink";
import type { IRejected } from "@/core/rejectReaction";
import type {
  BILLING_TERMS_ACCEPTANCE_STATES_TYPE,
  ICustomerAccessLevelsType,
} from "@/store/account/customerStore";

export const getAdtomicCustomer = () =>
  baseFetch<void, IAdtomicCustomerResponse>({
    url: getUrlWithSearchParams(
      customerAdtomicLink,
      { accountId: getCurrentAccountId() },
      { hasAccountId: false },
    ),
    method: "GET",
    withCredentials: true,
    headers: getHeaderHelper(),
    useSessionAccountId: true,
  });

export type IAdtomicCustomerResponse = {
  data: IAdtomicCustomer;
} & IRejected;

export interface IAdtomicCustomerProperties {
  isReactAvailable: boolean;
  isReactEnabled: boolean;
  isReactDisableAlertShown: boolean;
  isReactAvailableAlertShown: boolean;
  isDaypartingTooltipShown?: boolean;

  isReactCampaignsAvailable: boolean;
  isReactCampaignsEnabled: boolean;
  isReactCampaignsWelcomeShown: boolean;
  isReactCampaignsTooltipShown: boolean;

  isReactOnly: boolean | null;
  isReactSuggestionsAvailable: boolean;
  isReactSuggestionsEnabled: boolean;
  isReactSuggestionsWelcomeShown: boolean;
  isReactSuggestionsTooltipShown: boolean;

  isReactBuilderAvailable: boolean;
  isReactBuilderEnabled: boolean;
  isReactBuilderWelcomeShown: boolean;
  isReactBuilderAlertShown: boolean;

  isBulkActionsAvailable: boolean;

  isSchedulesAvailable: boolean;

  isOnboardingAdManagerBuilderTooltipShown: boolean;
  isOnboardingAdManagerRuleTooltipShown: boolean;
  isOnboardingBuilderCreationTooltipShown: boolean;
  isOnboardingRuleCreationTooltipShown: boolean;
  isOnboardingBannerShown: boolean;
  showQuickActionsWarning: boolean;

  isPortfolioManageable: boolean;

  isAdtomicOnboardingEnabled: boolean;
}

export interface IAdtomicCustomer {
  advanced_features: {
    access_level: ICustomerAccessLevelsType;
    tester: boolean;
    properties: IAdtomicCustomerProperties;
  };
  access_level: ICustomerAccessLevelsType;
  tester: boolean;
  created_at: string;
  email: string;
  id: number;
  last_selected_profile_id: string;
  last_visit_at: number;
  store_name: string;
  updated_at: string;
  username: string;
  refund_available: boolean;
  billing_terms_acceptance_state: BILLING_TERMS_ACCEPTANCE_STATES_TYPE;
  billing_terms_acceptance_time: string;
  amazon_advertising_experience_track_from: string;
  hasPacvueProfiles: boolean;
  tokenInfo: {
    amazonAvailable: boolean;
    googleAvailable: boolean;
    walmartAvailable: boolean;
  };
}
