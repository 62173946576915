import { Skeleton } from "@helium10/re-ui-components";
import styled from "styled-components";

import { Container } from "../../Styles";

export const SkeletonTitle = () => {
  return (
    <Header>
      <Logo />
    </Header>
  );
};

const Header = styled(Container)`
  gap: 26px;
  margin-bottom: 12px;
`;

const Logo = styled(Skeleton)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  height: 100px;
  width: 100px;
`;
