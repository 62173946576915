import type { Helium10Plans } from "@helium10/re-core";
import { baseFetch } from "@helium10/re-core";

import { gtmLink } from "@/core/link/apiLink";

export const getGTMInfo = () =>
  baseFetch<void, { results: IGTMResponse }>({
    url: gtmLink,
    withCredentials: true,
    method: "GET",
  });

export interface IGTMResponse {
  academy: string;
  adsToken: number;
  alerts_monitoring: string;
  analytics_interest: string;
  chromeExtensionLastSeenAt: number;
  coupon: string;
  createdAt: number;
  joinDate: number;
  email: string;
  emailAddress: string;
  firstName: string;
  name: string;
  followupEnabledAt: string;
  freedomTicket: boolean;
  freedomTicketQuickStart: string;
  freedomTicketXtra: string;
  intercomHash: string;
  keyword_research_interest: string;
  lastLogin: string;
  lastName: string;
  learning_interest: string;
  listing_optimization_interest: string;
  marketTrackerEnabledAt: string;
  marketing_interest: string;
  monthlySales: string;
  mwsTokenCN: number;
  mwsTokenEU: number;
  mwsTokenFE: number;
  mwsTokenNA: number;
  operations_interest: string;
  paused: boolean;
  phone_number: string;
  plan: Helium10Plans;
  subscriptionLevel: Helium10Plans;
  portalsEnabledAt: boolean;
  ppcEnabledAt: string;
  product_research_interest: string;
  profitsEnabledAt: string;
  profitsStartedAt: string;
  redstone: string;
  redstone_id: string;
  selling_product: string;
  subscriptionAge: number;
  userId: string;
  wmTokenNA: number;
}
