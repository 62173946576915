import { declareAction, declareAtom } from "@helium10/re-core";

const initialState = {
  missingPPC: false,
  profileReadOnlyOwner: false,
  profileReadOnlyGuest: false,
};

export const profileNotify = {
  guest: {
    open: declareAction("readOnlyNotifyGuestOpen"),
    close: declareAction("readOnlyNotifyGuestClose"),
  },
  owner: {
    open: declareAction("readOnlyNotifyOwnerOpen"),
    close: declareAction("readOnlyNotifyOwnerClose"),
  },
  missing: {
    open: declareAction("missingDataNotifyOpen"),
    close: declareAction("missingDataNotifyClose"),
  },
};

export const notificationsAtom = declareAtom("notificationsAtom", initialState, (on) => [
  on(profileNotify.missing.open, (state) => {
    return { ...state, missingPPC: true };
  }),
  on(profileNotify.missing.close, (state) => {
    return { ...state, missingPPC: false };
  }),
  on(profileNotify.owner.open, (state) => {
    return { ...state, profileReadOnlyOwner: true };
  }),
  on(profileNotify.owner.close, (state) => {
    return { ...state, profileReadOnlyOwner: false };
  }),
  on(profileNotify.guest.open, (state) => {
    return { ...state, profileReadOnlyGuest: true };
  }),
  on(profileNotify.guest.close, (state) => {
    return { ...state, profileReadOnlyGuest: false };
  }),
]);
