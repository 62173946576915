import { toastNotification } from "@helium10/re-ui-components";

import { FromType } from "../index";
import { sendEmailCode, verifyEmailCode } from "../requests";

export const sendChangeEmailCode = async ({
  fromType,
}: {
  fromType: FromType;
}): Promise<string | undefined> => {
  if (fromType !== "settings") return;
  try {
    const res = await sendEmailCode();
    if (res.code !== 200) {
      toastNotification.error("Failed to send email.", { autoClose: false });
    }
    return res.data.code;
  } catch (error) {
    console.log(error);
    toastNotification.error("Failed to send email.", { autoClose: false });
  }
};
type VerifyEmailProps = {
  handleChangeEmail: () => void;
  data: { code: string };
  code: string | undefined;
  setDisabled: (disabled: boolean) => void;
};
export const verifyEmail = async ({
  handleChangeEmail,
  data,
  code,
  setDisabled,
}: VerifyEmailProps) => {
  try {
    const res = await verifyEmailCode({ code: code || "", validateCode: data.code });
    if (res.code === 250) {
      toastNotification.error("Incorrect 6-digit verification code.", { autoClose: false });
      setDisabled(false);
    } else if (res.code === 240) {
      toastNotification.error("The code is incorrect or the time has expired.", {
        autoClose: false,
      });
      setDisabled(false);
    } else {
      handleChangeEmail();
    }
  } catch (error) {
    console.log(error);
  }
};
