import type { Helium10Plans } from "@helium10/re-core";
import {
  captchaTokenV2Controller,
  getCaptchaV3,
  getUrlWithSearchParams,
  leitenPrimitive,
  leitenRecord,
  leitenRequest,
} from "@helium10/re-core";
import type { Stripe, StripeCardNumberElement } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { create } from "zustand";

import type { IPaymentMethods } from "../../../../common/types/IPaymentMethods";
import {
  INITIAL_PLAN,
  MAX_SUBSCRIPTION_CHECK_ATTEMPTS,
  ON_SUCCESS_OR_FAILURE_DELAY,
  SUBSCRIPTION_CHECK_ATTEMPTS_TIMEOUT,
} from "../consts/keys";
import { apiConfirmation } from "../consts/links";
import type { IData } from "../requests/getData";
import { getData } from "../requests/getData";
import type { IIntent, IIntentResponse } from "../requests/getIntent";
import { getIntent } from "../requests/getIntent";
import type { IStripeConfirmAlipayResponse } from "../requests/stripe/stripeConfirmAlipay";
import { stripeConfirmAlipay } from "../requests/stripe/stripeConfirmAlipay";
import type { IStripeConfirmIntentResponse } from "../requests/stripe/stripeConfirmIntent";
import { stripeConfirmIntent } from "../requests/stripe/stripeConfirmIntent";
import type { IStripeRetrieveIntentResponse } from "../requests/stripe/stripeRetrieveIntent";
import { stripeRetrieveIntent } from "../requests/stripe/stripeRetrieveIntent";
import type { ISubscribeCheckRequest, ISubscribeCheckResponse } from "../requests/subscribeCheck";
import { subscribeCheck } from "../requests/subscribeCheck";
import type {
  ISubscribeProcessParams,
  ISubscribeProcessResponse,
} from "../requests/subscribeProcess";
import { subscribeProcess } from "../requests/subscribeProcess";
import type { IUpgradeProcessParams, IUpgradeProcessResponse } from "../requests/upgradeProcess";
import { upgradeProcess } from "../requests/upgradeProcess";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IStripe = Stripe | null | any;

export interface IModalError {
  status: "success" | "error";
  message?: string;
  messageType?: string;
  invoiceUrl?: string;
}

export interface IInitialData {
  offerId?: string;
  coupon?: string;
  location?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items?: string[];
  utmCampaign?: string;
  eventSource?: string;
  module?: string;
  reload?: boolean;
  downgrade?: boolean;
  upgrade?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ICheckoutModalStore {
  initialData: IInitialData;
  isComplete: boolean;
  isEditing: boolean;
  stripe: IStripe;
  data: IData | null;
  captcha?: string | null;
  paymentMode: IPaymentMethods;
  intent: IIntentResponse | null;
  stripeConfirmAlipay: IStripeConfirmAlipayResponse | null;
  subscribeCheck: ISubscribeCheckResponse | null;
  stripeConfirmIntent: IStripeConfirmIntentResponse | null;
  stripeRetrieveIntent: IStripeRetrieveIntentResponse | null;
  subscribeProcess: ISubscribeProcessResponse | null;
  upgradeProcess: IUpgradeProcessResponse | null;
  modalError: null | IModalError;
  form: {
    captchaTokenV3?: string;
    email?: string;
    name?: string;
    coupon?: string;
    plan?: keyof Helium10Plans;
    country?: string;
    street?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
}

export let subscriptionCheck: ReturnType<typeof setInterval> | undefined;

export const clearSubscriptionCheck = (): void => {
  if (subscriptionCheck) {
    clearInterval(subscriptionCheck);
    subscriptionCheck = undefined;
  }
};

export const useCheckoutModalStore = create<ICheckoutModalStore>(() => ({
  initialData: {
    offerId: INITIAL_PLAN,
    reload: true,
  },
  modalError: null,
  isComplete: false,
  isEditing: false,
  paymentMode: "card",
  captcha: null,
  stripe: null,
  data: null,
  intent: null,
  stripeConfirmAlipay: null,
  subscribeCheck: null,
  stripeConfirmIntent: null,
  stripeRetrieveIntent: null,
  subscribeProcess: null,
  upgradeProcess: null,
  form: {},
}));

export const completeController = leitenPrimitive(useCheckoutModalStore, "isComplete");
export const paymentEditingController = leitenPrimitive(useCheckoutModalStore, "isEditing");
export const paymentModeController = leitenPrimitive(useCheckoutModalStore, "paymentMode");
export const captchaController = leitenPrimitive(useCheckoutModalStore, "captcha");

export const initialDataController = leitenRecord(useCheckoutModalStore, "initialData");
export const modalErrorController = leitenRecord(useCheckoutModalStore, "modalError");
export const stripeController = leitenRecord(useCheckoutModalStore, "stripe");
export const formController = leitenRecord(useCheckoutModalStore, "form");

export const dataController = leitenRequest(
  useCheckoutModalStore,
  "data",
  (_: void, extraArgument) => {
    const { initialData } = useCheckoutModalStore.getState();
    const { offerId, coupon, items } = initialData;

    return getData({ offer: offerId, coupon, items }, extraArgument?.signal);
  },
  {
    fulfilled: async ({ result }) => {
      const name = result?.name;
      const email = result?.email;
      const coupon = result?.coupon;
      const plan = result?.plan?.key;
      const country = result?.address?.countryIsoCode || result?.address?.currentCountry;
      const street = result?.address?.street;
      const city = result?.address?.city;
      const state = result?.address?.state;
      const zip = result?.address?.zipCode;
      const paymentMethod = result?.paymentMethods?.[0];
      const stripePublicKey = result?.stripeData?.publicKey;

      paymentMethod && paymentModeController.set(paymentMethod);
      formController.patch({ name, email, coupon, plan, country, street, city, state, zip });

      if (stripePublicKey) {
        const stripe = await loadStripe(stripePublicKey);
        stripe && stripeController.set(stripe);
      }
    },
  },
);

export const intentController = leitenRequest(
  useCheckoutModalStore,
  "intent",
  async (
    { captchaTokenV2, intent }: { captchaTokenV2?: string | null; intent: IIntent },
    extraArgument,
  ) => {
    const { isEditing, data, paymentMode } = useCheckoutModalStore.getState();
    const isCardAvailable = data?.card?.brand && !isEditing;
    const name = data?.name;
    const email = data?.email;
    const coupon = data?.coupon;
    const plan = data?.plan?.key;
    const amount = data?.amount;

    if (isCardAvailable) return;

    return getIntent(
      {
        ...(captchaTokenV2 ? { captchaTokenV2 } : { captchaTokenV3: await getCaptchaV3() }),
        method: paymentMode,
        name,
        email,
        coupon,
        plan,
        amount,
        intent,
      },
      extraArgument?.signal,
    );
  },
  {
    fulfilled: ({ result }) => {
      result?.data?.status === "captcha" && captchaController.set(result?.data?.url);

      handleError({
        status: "error",
        message: result?.data?.message,
        messageType: result?.data?.messageType,
        invoiceUrl: result?.data?.invoiceUrl,
      });
    },
    rejected: (response) => {
      const error = {
        status: "error" as const,
        message: response?.fetchError?.result?.data?.message,
        messageType: response?.fetchError?.result?.data?.messageType,
        invoiceUrl: response?.fetchError?.result?.data?.invoiceUrl,
      };
      intentController?.set({
        data: {
          ...error,
        },
      });

      handleError(error);
    },
    resolved: () => {
      captchaTokenV2Controller.clear();
    },
  },
);

export const stripeConfirmAlipayController = leitenRequest(
  useCheckoutModalStore,
  "stripeConfirmAlipay",
  () => {
    const { stripe, intent } = useCheckoutModalStore.getState();
    const clientSecret = intent?.data?.paymentIntent?.client_secret || "";

    return stripeConfirmAlipay({
      stripe,
      clientSecret,
      data: {
        return_url: getUrlWithSearchParams(apiConfirmation, {}),
      },
    });
  },
  {
    fulfilled: async ({ result }) => {
      let subscriptionCheckAttempts = 0;

      if (!result?.error && subscriptionCheck === undefined) {
        subscriptionCheck = setInterval(async () => {
          subscriptionCheckAttempts++;

          const { form } = useCheckoutModalStore.getState();
          const isLastAttempt = subscriptionCheckAttempts === MAX_SUBSCRIPTION_CHECK_ATTEMPTS;

          await subscribeCheckController?.action({ ...form, isLastAttempt });
        }, SUBSCRIPTION_CHECK_ATTEMPTS_TIMEOUT);
      }

      const redirectUrl = result?.paymentIntent?.next_action?.alipay_handle_redirect?.url;
      redirectUrl && window.open(redirectUrl, "_blank");
    },
  },
);

export const subscribeCheckController = leitenRequest(
  useCheckoutModalStore,
  "subscribeCheck",
  (params: ISubscribeCheckRequest, extraArgument) => {
    return subscribeCheck(params, extraArgument?.signal);
  },
  {
    fulfilled: ({ result, payload }) => {
      const isSuccess = result?.data?.status === "success";
      const isLastAttempt = payload?.isLastAttempt;

      if (isSuccess) completeController.set(true);
      if (isSuccess || isLastAttempt) {
        clearInterval(subscriptionCheck);
        subscriptionCheck = undefined;
      }

      handleError({
        status: "error",
        message: result?.data?.message,
        messageType: result?.data?.messageType,
        invoiceUrl: result?.data?.invoiceUrl,
      });
    },
    rejected: (response) => {
      const error = {
        status: "error" as const,
        message: response?.fetchError?.result?.data?.message,
        messageType: response?.fetchError?.result?.data?.messageType,
        invoiceUrl: response?.fetchError?.result?.data?.invoiceUrl,
      };

      subscribeCheckController.set({
        data: { ...error },
      });
      handleError(error);

      if (response?.payload?.isLastAttempt) {
        clearInterval(subscriptionCheck);
        subscriptionCheck = undefined;
      }
    },
  },
);

export const stripeConfirmIntentController = leitenRequest(
  useCheckoutModalStore,
  "stripeConfirmIntent",
  async ({ card, cardName }: { card: StripeCardNumberElement; cardName: string }) => {
    const { stripe, data, intent } = useCheckoutModalStore.getState();
    const clientSecret = intent?.data?.setupIntent?.client_secret || "";

    return await stripeConfirmIntent({
      stripe,
      clientSecret,
      data: {
        payment_method: {
          card,
          billing_details: {
            name: cardName,
            email: data?.email,
          },
        },
      },
    });
  },
  {
    fulfilled: ({ result }) => {
      const { stripe, intent } = useCheckoutModalStore.getState();
      const clientSecret = intent?.data?.setupIntent?.client_secret || "";

      return !result?.error && stripeRetrieveIntentController?.action({ stripe, clientSecret });
    },
  },
);

export const stripeRetrieveIntentController = leitenRequest(
  useCheckoutModalStore,
  "stripeRetrieveIntent",
  stripeRetrieveIntent,
  {
    fulfilled: async ({ result }) => {
      const setupIntent = result?.setupIntent;

      if (setupIntent && !result?.error) {
        const { form } = useCheckoutModalStore.getState();

        subscribeProcessController?.action({ ...form, setupIntent });
      }
    },
  },
);

export const subscribeProcessController = leitenRequest(
  useCheckoutModalStore,
  "subscribeProcess",
  async (params: ISubscribeProcessParams, extraArgument) => {
    const { initialData, data } = useCheckoutModalStore.getState();
    const { items } = initialData;

    return subscribeProcess(
      {
        ...params,
        type: data?.type,
        coupon: data?.coupon,
        period: data?.plan?.interval,
        plan: data?.plan?.key,
        captchaTokenV3: await getCaptchaV3(),
        items,
      },
      extraArgument?.signal,
    );
  },
  {
    fulfilled: ({ result }) => {
      const isSuccess = result?.data?.status === "success";
      isSuccess && completeController.set(true);

      handleError({
        status: "error",
        message: result?.data?.message,
        messageType: result?.data?.messageType,
        invoiceUrl: result?.data?.invoiceUrl,
      });

      setTimeout(() => {
        isSuccess
          ? useCheckoutModalStore.getState()?.initialData?.onSuccess?.()
          : useCheckoutModalStore.getState()?.initialData?.onFailure?.();
      }, ON_SUCCESS_OR_FAILURE_DELAY);
    },
    rejected: (response) => {
      const error = {
        status: "error" as const,
        message: response?.fetchError?.result?.data?.message,
        messageType: response?.fetchError?.result?.data?.messageType,
        invoiceUrl: response?.fetchError?.result?.data?.invoiceUrl,
      };

      subscribeProcessController?.set({ data: error });

      handleError(error);

      setTimeout(() => {
        useCheckoutModalStore.getState()?.initialData?.onFailure?.();
      }, ON_SUCCESS_OR_FAILURE_DELAY);
    },
  },
);

export const upgradeProcessController = leitenRequest(
  useCheckoutModalStore,
  "upgradeProcess",
  async (params: IUpgradeProcessParams, extraArgument) => {
    const { initialData, data } = useCheckoutModalStore.getState();
    const { utmCampaign, eventSource, module, items } = initialData;

    return upgradeProcess(
      {
        captchaTokenV3: await getCaptchaV3(),
        downgrade: initialData?.downgrade,
        upgrade: initialData?.upgrade,
        coupon: data?.coupon,
        period: data?.plan?.interval,
        plan: data?.plan?.key,
        utm_campaign: utmCampaign,
        eventSource,
        module,
        items,
        ...params,
      },
      extraArgument?.signal,
    );
  },
  {
    fulfilled: ({ result }) => {
      const isSuccess = result?.data?.status === "success";
      isSuccess && completeController.set(true);

      handleError({
        status: "error",
        message: result?.data?.message,
        messageType: result?.data?.messageType,
        invoiceUrl: result?.data?.invoiceUrl,
      });

      setTimeout(() => {
        isSuccess
          ? useCheckoutModalStore.getState()?.initialData?.onSuccess?.()
          : useCheckoutModalStore.getState()?.initialData?.onFailure?.();
      }, ON_SUCCESS_OR_FAILURE_DELAY);
    },
    rejected: (response) => {
      const error = {
        status: "error" as const,
        message: response?.fetchError?.result?.data?.message,
        messageType: response?.fetchError?.result?.data?.messageType,
        invoiceUrl: response?.fetchError?.result?.data?.invoiceUrl,
      };

      upgradeProcessController?.set({ data: error });

      handleError(error);

      setTimeout(() => {
        useCheckoutModalStore.getState()?.initialData?.onFailure?.();
      }, ON_SUCCESS_OR_FAILURE_DELAY);
    },
  },
);

const handleError = (error: IModalError) => {
  const redirectUrl = error?.invoiceUrl;
  redirectUrl && window.location.assign(redirectUrl);
  error?.messageType === "modal" && modalErrorController.set(error);
};
