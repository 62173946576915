import { baseFetch } from "@helium10/re-core";

import { getHeaderHelper } from "@/core/helpers/getHeader.helper";
import { segmentLink } from "@/core/link/apiLink";

import type { RuleType } from "../campaign/getCampaignTitles";

export const sendSegmentEvent = async (event: SegmentEvent) => {
  return baseFetch<SegmentEvent, void>({
    method: "POST",
    withCredentials: true,
    headers: getHeaderHelper(),
    params: event,
    url: segmentLink,
    useSessionAccountId: true,
  });
};

export type ITab = "Portfolio" | "Campaign" | "Ad Group" | "Target" | "Search Term" | "Product";

export type IProperties = {
  satisfied?: "Yes" | "No";
  Tab?: ITab;
  ruleType?: RuleType;
  ruleName?: string;
};

export interface SegmentEvent {
  event_name: string;
  profile_id?: string;
  account_plan?: string;
  account_id?: string;
  user_id?: number;
  properties?: IProperties;
}
