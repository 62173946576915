import type { Helium10Plans } from "@helium10/re-core";
import { baseFetch, getUrlWithSearchParams } from "@helium10/re-core";

import { apiSubscribeCheck } from "../consts/links";

export interface ISubscribeCheckRequest {
  isLastAttempt?: boolean;
  token?: string;
  email?: string;
  password?: string;
  plan?: keyof Helium10Plans | string;
}

export interface ISubscribeCheckResponse {
  data: {
    status: "success" | "error";
    paymentMethod?: string;
    message?: string;
    messageType?: string;
    invoiceUrl?: string;
    token?: string;
  };
}

export const subscribeCheck = async (
  params: ISubscribeCheckRequest,
  signal?: AbortSignal,
): Promise<ISubscribeCheckResponse> => {
  return await baseFetch<ISubscribeCheckRequest, ISubscribeCheckResponse>({
    url: getUrlWithSearchParams(apiSubscribeCheck, {}),
    withCredentials: true,
    method: "POST",
    params: {
      email: params?.email ?? "",
      password: params?.password ?? "",
      plan: params?.plan ?? "",
      token: params?.token ?? "",
    },
    signal,
  });
};
