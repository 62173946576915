import { baseFetch } from "@helium10/re-core";

import { getHeaderHelper } from "@/core/helpers/getHeader.helper";
import { accountPermissionLink, accountsLink } from "@/core/link/apiLink";

export const getAccounts = (accountId?: string) =>
  baseFetch<void, IUserReponse>({
    method: "GET",
    url: accountsLink,
    accountId,
    withCredentials: true,
    headers: getHeaderHelper(),
  });

export interface IAccount {
  accountId: string;
  accountName: string;
  role: number;
  permissions: string[];
  active?: boolean;
}

export interface IUserAccount {
  userId: number;
  clientId: number;
  name: string;
  isDefaultAccount: boolean;
  isSuperAdmin: number;
}

export interface IUserReponse {
  data: IUserAccount[];
}

export const getAccountPermission = (userId: string) =>
  baseFetch<void, IPermissionReponse>({
    method: "GET",
    url: accountPermissionLink + `${userId}`,
    withCredentials: true,
    headers: getHeaderHelper(),
  });

export interface IPermissionReponse {
  data: string[];
}
