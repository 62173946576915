import styled from "styled-components";

import { Text } from "../../../checkout/Styles";
import { useCheckoutModalStore } from "../store/useCheckoutModalStore";

export const Disclaimer = () => {
  const [type, specifics] = useCheckoutModalStore((state) => [
    state.data?.type,
    state.data?.specifics,
  ]);

  const isWithCard = ["subscribeModal", "subscribeModalCard"].includes(type as string);
  const consumerTermAndConditionLink = `<a href=${specifics?.agreement?.termsAndConditionsUrl} target="_blank">${specifics?.agreement?.termsAndConditionsText}</a>`;
  const consumerPrivacyPolicyLink = `<a href=${specifics?.agreement?.privacyPolicyUrl} target="_blank">${specifics?.agreement?.privacyPolicyText}</a>`;
  const disclaimer =
    specifics?.agreement?.text
      ?.replace("{termsAndConditionsText}", consumerTermAndConditionLink)
      ?.replace("{privacyPolicyText}", consumerPrivacyPolicyLink) || "";

  return (
    <Container $isWithCard={isWithCard}>
      <StyledText $isWithCard={isWithCard} dangerouslySetInnerHTML={{ __html: disclaimer }} />
    </Container>
  );
};

const Container = styled.div<{ $isWithCard?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  order: ${(p) => (p.$isWithCard ? 3 : 2)};
`;

const StyledText = styled(Text)<{ $isWithCard?: boolean }>`
  max-width: 466px;
  text-align: center;
  font-size: ${(p) => (p.$isWithCard ? "12px" : "16px")};
  margin: ${(p) => (p.$isWithCard ? p.theme.spacing.sp16 : p.theme.spacing.sp24)} 0 0;

  a {
    color: ${(p) => p.theme.systemColors.blue600};

    &:hover,
    &:focus,
    &:visited {
      color: ${(p) => p.theme.systemColors.blue600};
    }
  }
`;
