import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faLockKeyhole } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { useCheckoutModalStore } from "../store/useCheckoutModalStore";

export const SecurePayment = () => {
  const security = useCheckoutModalStore((state) => state.data?.specifics?.security);

  return (
    <Container>
      <LockIcon />
      {security}
    </Container>
  );
};

const Container = styled.div`
  order: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  user-select: none;
  text-align: center;
  color: ${(p) => p.theme.systemColors.grey600};
  border-top: 1px solid ${(p) => p.theme.systemColors.grey200};
  padding: ${(p) => `${p.theme.spacing.sp12} 0 0`};
  margin: ${(p) => `${p.theme.spacing.sp16} 0 0`};
`;

const LockIcon = styled(FontAwesomeIcon).attrs({ icon: faLockKeyhole })<{ icon?: IconProp }>`
  font-size: 20px;
  margin-right: ${(p) => p.theme.spacing.sp6};
`;
