import {
  IFeatureCreator,
  leitenFeature,
  leitenModal,
  leitenPrimitive,
  leitenRecord,
  leitenRequest,
} from "@helium10/re-core";
import { toastNotification } from "@helium10/re-ui-components";
import { create } from "zustand";

import { useTranslationStore } from "../../../i18n/useTranslation";
import {
  changeAddress,
  changeDefaultAccount,
  forceStatus,
  ForceStatusResponse,
  getAccounts,
  getProfileInfo,
  getUserInfo,
  IAccountsResponse,
  IAChangeDefaultAccountResponse,
  IChangeResponse,
  IProfileInfoResponse,
  IUserInfoRealTimeResponse,
  IUserInfoResponse,
  loginRecords,
  LoginRecordsResponse,
  receiveSwag,
  sendUserActiveEmails,
  userInfoRealTime,
} from "../requests";

export interface IAccessManagement {
  pageSize: number;
  pageIndex: number;
}

interface IState {
  userInfoResponse: IUserInfoResponse;
  accountsResponse: IAccountsResponse;
  profileInfoResponse: IProfileInfoResponse;
  receiveSwagResponse: IChangeResponse;
  changeAddress: IChangeResponse;
  isReceive: { value: boolean; needUpdate: boolean };
  profileModal: null;
  userActiveEmails: IChangeResponse | null;
  isEmailValidated: IUserInfoRealTimeResponse;
  forceStatus: ForceStatusResponse;
  loginRecords: LoginRecordsResponse;
  getUserListParams: IAccessManagement;
  changeDefaultAccount: IAChangeDefaultAccountResponse | null;
}

export const useDefault = () => {
  return {
    pageSize: 10,
    pageIndex: 1,
  };
};

export type IAccessManagementFeature = IFeatureCreator<IAccessManagement>;

export const [useUserSettingsStore, UserSettingsProvider] = leitenFeature(
  ({ useProps }: IAccessManagementFeature) => {
    const props = useProps.getState();
    const useStore = create<IState>(() => ({
      userInfoResponse: {
        data: null,
      },
      accountsResponse: {
        data: null,
      },
      profileInfoResponse: {
        results: null,
      },
      receiveSwagResponse: {},
      changeAddress: {},
      isReceive: {
        value: false,
        needUpdate: false,
      },
      profileModal: null,
      userActiveEmails: null,
      isEmailValidated: {
        code: 0,
        data: null,
      },
      forceStatus: {
        data: null,
      },
      loginRecords: {
        data: null,
      },
      getUserListParams: {
        pageIndex: props.pageIndex,
        pageSize: props.pageSize,
      },
      changeDefaultAccount: null,
    }));
    const useUserListParamsController = leitenPrimitive(useStore, "getUserListParams");
    const getUserListParamsSelector = (state: IState) => {
      return { ...state.getUserListParams };
    };
    const isReceiveController = leitenRecord(useStore, "isReceive");
    const isEmailValidatedController = leitenRequest(
      useStore,
      "isEmailValidated",
      userInfoRealTime,
    );
    const useUserInfoParamsController = leitenRequest(useStore, "userInfoResponse", getUserInfo);
    const useAccountsParamsController = leitenRequest(
      useStore,
      "accountsResponse",
      getAccounts,
      {},
    );
    const useProfileInfoParamsController = leitenRequest(
      useStore,
      "profileInfoResponse",
      getProfileInfo,
      {
        fulfilled: (res) => {
          isReceiveController.patch({
            value: res.result.results?.isReceive ?? false,
          });
        },
      },
    );
    const useReceiveSwagParamsController = leitenRequest(
      useStore,
      "receiveSwagResponse",
      receiveSwag,
      {
        resolved: (res) => {
          const translate = useTranslationStore.getState().translation;
          toastNotification.success(translate?.t("userSettings.done"), { autoClose: false });
          isReceiveController.set({
            value: res.payload,
            needUpdate: false,
          });
        },
      },
    );
    const useChangeAddressParamsController = leitenRequest(
      useStore,
      "changeAddress",
      changeAddress,
      {
        fulfilled: (res) => {
          console.log(res);
          const { value, needUpdate } = isReceiveController.get();
          if (needUpdate) {
            useReceiveSwagParamsController.action(!value);
          }
          useProfileInfoParamsController.action({});
          useProfileModalController.close();
        },
        rejected: (res) => {
          console.log(res);
          toastNotification.error(res.error, {
            autoClose: false,
          });
        },
      },
    );
    const useSendUserActiveEmailsController = leitenRequest(
      useStore,
      "userActiveEmails",
      sendUserActiveEmails,
      {
        fulfilled: () => {
          const translate = useTranslationStore.getState().translation;
          toastNotification.success(translate?.t("userSettings.activeEmails"), {
            autoClose: false,
          });
        },
        rejected() {
          const translate = useTranslationStore.getState().translation;
          toastNotification.error(translate?.t("userSettings.failedEmail"));
        },
      },
    );

    const useForceStatusParamsController = leitenRequest(useStore, "forceStatus", forceStatus, {
      fulfilled: () => {},
    });

    const useLoginRecordsParamsController = leitenRequest(useStore, "loginRecords", loginRecords, {
      fulfilled: ({ payload }) => {
        useUserListParamsController.set({
          pageSize: payload.page.pageSize,
          pageIndex: payload.page.pageIndex,
        });
      },
    });

    const useChangeDefaultAccountController = leitenRequest(
      useStore,
      "changeDefaultAccount",
      changeDefaultAccount,
    );

    const useProfileModalController = leitenModal(useStore, "profileModal");

    const userInfoSelector = (state: IState) => {
      return state.userInfoResponse;
    };
    // const useInfo = useStore(userInfoSelector);

    const accountsSelector = (state: IState) => {
      return state.accountsResponse;
    };

    const profileInfoSelector = (state: IState) => {
      return state.profileInfoResponse;
    };

    const isReceiveSelector = (state: IState) => {
      return state.isReceive;
    };

    const isEmailValidatedSelector = (state: IState) => {
      return state.isEmailValidated.data;
    };

    const forceStatusSelector = (state: IState) => {
      return state.forceStatus;
    };

    const loginRecordsSelector = (state: IState) => {
      return state.loginRecords;
    };

    return {
      useStore,
      useProps,
      useUserInfoParamsController,
      userInfoSelector,
      useAccountsParamsController,
      accountsSelector,
      useProfileInfoParamsController,
      profileInfoSelector,
      useReceiveSwagParamsController,
      useChangeAddressParamsController,
      isReceiveController,
      isReceiveSelector,
      useProfileModalController,
      useSendUserActiveEmailsController,
      isEmailValidatedController,
      isEmailValidatedSelector,
      useForceStatusParamsController,
      forceStatusSelector,
      loginRecordsSelector,
      useLoginRecordsParamsController,
      useUserListParamsController,
      getUserListParamsSelector,
      useChangeDefaultAccountController,
    };
  },
);
