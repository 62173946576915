import { DialogModal, NewButton } from "@helium10/re-ui-components";
import { memo, useEffect, useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";

import { useTranslation } from "../../../../i18n/useTranslation";
import { useAccessManagementStore } from "../../store";
import { CheckTree } from "./components/CheckTree";
import { InputItem } from "./components/InputItem";
import { useFeatureNodes, useFeatureTree, useUserInfoParams } from "./hooks";

interface IFormState {
  name: string;
  email: string;
  checkboxes: string[];
}

export const UserInfoModal = memo(() => {
  const {
    useUserInfoModal,
    useStore,
    permConfigDialogInputSelector,
    useEditUserInfoController,
    useAddNewUserController,
    getUserFeaturesByIdSelector,
    useGetUserFeaturesByIdController,
  } = useAccessManagementStore();
  const [isOpen] = useUserInfoModal();
  const featureData = useFeatureTree();
  const basicUserInfo = useStore(permConfigDialogInputSelector);
  const isEdit = basicUserInfo !== null;
  const methods = useForm<IFormState>();
  const UserInfoController = useEditUserInfoController();
  const enableFeatures = useStore(getUserFeaturesByIdSelector);
  const { formItems } = useUserInfoParams();
  const userFeaturesController = useGetUserFeaturesByIdController();
  const { t } = useTranslation();

  const cb = useFeatureNodes();
  useEffect(() => {
    if (isOpen) {
      if (isEdit) {
        useGetUserFeaturesByIdController.action({ userId: basicUserInfo["id"] });
      }
    }
  }, [isOpen, isEdit]);

  useEffect(() => {
    if (isOpen) {
      const defaults = isEdit
        ? {
            name: basicUserInfo["name"],
            email: basicUserInfo["email"],
            checkboxes: enableFeatures
              .filter((feature) => feature.permissionType === 2)
              .map((feature) => feature.menuId),
          }
        : { name: "", email: "", checkboxes: [] };
      methods.reset(defaults);
    }
  }, [isOpen, isEdit, methods, enableFeatures]);

  const onSubmit = async () => {
    await methods.trigger();
    const handleSubmit = methods.handleSubmit(async (e) => {
      if (isEdit) {
        useEditUserInfoController.action({
          userName: e.name,
          email: e.email,
          userId: basicUserInfo["id"],
          features: cb(e.checkboxes),
        });
      } else {
        useAddNewUserController.action({
          userName: e.name,
          email: e.email,
          features: cb(e.checkboxes),
        });
      }
    });
    handleSubmit();
  };

  const footer = useMemo(() => {
    return (
      <>
        <Between>
          <NewButton buttonType={"cancel"} onClick={useUserInfoModal.close} size={"200"}>
            {t("accessManagement.button.cancel")}
          </NewButton>
          <NewButton
            loading={UserInfoController.status === "loading"}
            onClick={onSubmit}
            buttonType={"primary"}
            size={"200"}
          >
            {t("accessManagement.button.confirm")}
          </NewButton>
        </Between>
      </>
    );
  }, [UserInfoController, useUserInfoModal, onSubmit, t]);

  return (
    <DialogModal
      size="400"
      title={t("accessManagement.modal.userPermission.title")}
      onClose={useUserInfoModal.close}
      footer={footer}
      isOpen={isOpen}
    >
      <FormProvider {...methods}>
        <FilterGrid>
          <InputItem {...formItems[0]} disabled={isEdit} />
          <InputItem {...formItems[1]} disabled={isEdit} />
        </FilterGrid>
        <Header2>{t("accessManagement.modal.userPermission.featureTitle")}</Header2>
        <Notice>{t("accessManagement.modal.userPermission.featuresSubTitle")}</Notice>
        <Controller
          name={"checkboxes"}
          control={methods.control}
          render={({ field }) => (
            <CheckTree
              checkIds={field.value || []}
              data={featureData}
              onChange={field.onChange}
              isLoading={userFeaturesController.status === "loading"}
            />
          )}
        />
      </FormProvider>
    </DialogModal>
  );
});

const Header2 = styled.div`
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: #2b3948;
  margin-bottom: 4px;
`;

const Notice = styled.div`
  color: #485e75;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`;

const Between = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FilterGrid = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing.sp24};
  align-items: flex-start;
  grid-template-columns: 1fr;
  grid-template-columns: repeat(8, 1fr);
  margin-bottom: ${({ theme }) => theme.spacing.sp24};
`;
