import { datadogRum } from "@datadog/browser-rum";
import type { Reaction, RejectedPayload } from "@helium10/re-core";
import { toastNotification, usePageStatus } from "@helium10/re-ui-components";

import { useTranslationStore } from "@/i18n/translationStore";
import { handleExpiredTokenAction } from "@/store/account/credentialsStore";

export interface IRejected {
  status?: number;
  name?: string;
  details?: string[];
  message?: string;
}

export const rejectReaction: Reaction<RejectedPayload<unknown, IRejected>> = (
  payload,
  { getState, dispatch, ...rest },
) => {
  const translate = useTranslationStore.getState().translation;
  // const router = getState(routerAtom);
  switch (payload.fetchError?.responseStatus) {
    case 401:
      handleExpiredTokenAction(401, {
        getState,
        dispatch,
        ...rest,
      });
      break;
    case 500:
      toastNotification.error(translate?.t("errors.internalServer"));
      break;
    case 504:
      toastNotification.error(translate?.t("errors.timeout"));
      break;
    case 404:
      // router.history?.push(`/panel`);
      break;
    case 0:
      // toastNotification.error(translate?.t("errors.network"));
      break;
    default:
      payload.fetchError?.result
        ? showError(payload.fetchError?.result, translate?.t("errors.unknownError"))
        : toastNotification.error(translate?.t("errors.unknownError"));
      break;
  }
  usePageStatus.getState().setStatus("loaded");
  if (process.env.NODE_ENV !== "development" && payload.fetchError?.responseStatus !== 401) {
    datadogRum.addError(payload, payload.fetchError);
  }
};

export const showError = (error: IRejected, defaultText?: string) => {
  let body = defaultText || "";
  if (error.message) body = error.message;
  else if (error.details) body = error.details.join("\n");
  else if (error.name) body = error.name;
  toastNotification.error(body);
};
