import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCircleCheck } from "@fortawesome/pro-duotone-svg-icons";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputErrorMessage, NewButton, NewInput } from "@helium10/re-ui-components";
import { memo, useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { useRedirect } from "@/core/hooks/useRedirect";

import { appRoutes } from "../../../core/links";
import { useTranslation } from "../../../i18n/useTranslation";
import walmartlogoSrc from "../../../static/icons/walmartlogo.svg";
import { ResetPasswordProvider, useResetPasswordStore } from "../store/index";

interface IResetPassword {
  password: string;
  confirmPassword: string;
}

export const SetPasswordForm = memo(() => {
  const { getPath, redirect } = useRedirect();
  const value = {
    password: "",
  };

  return (
    <ResetPasswordProvider value={{ ...value, getPath, redirect }}>
      <Context />
    </ResetPasswordProvider>
  );
});

export const Context = memo(() => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IResetPassword>();

  const { getPath, redirect } = useRedirect();

  const query = new URLSearchParams(useLocation().search);
  const secretKey = query.get("key") || "";

  const { useResetPasswordController } = useResetPasswordStore();
  const controller = useResetPasswordController();

  const handleRouteChange = useCallback(() => {
    redirect(getPath(appRoutes.signIn));
  }, [redirect, getPath]);

  const JumpToSignIn = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    handleRouteChange();
  };

  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    mixture: false,
    notEmail: false,
  });

  const onSubmit: SubmitHandler<IResetPassword> = (data) => {
    useResetPasswordController.action({ password: data.password, secretKey: secretKey });
  };

  const password = watch("password", "");
  const email = "test";

  useEffect(() => {
    setPasswordValidation({
      length: password.length >= 8,
      mixture:
        /[A-Za-z]/.test(password) && /[0-9]/.test(password) && /[^A-Za-z0-9\s]/.test(password),
      notEmail: email.indexOf(password) === -1,
    });
  }, [password, email]);

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <SignUpFormWrapperTitle>{t("setPassword.title")}</SignUpFormWrapperTitle>
      <ContainerLogo href="https://www.helium10.com/">
        <img src={walmartlogoSrc} alt="Helium 10" />
      </ContainerLogo>
      <FormGroup>
        <SignUpFormLabel htmlFor="password">{t("setPassword.newPassword")}</SignUpFormLabel>
        <NewInput
          id="password"
          type="password"
          size={"400"}
          placeholder={t("setPassword.passwordPlaceholder")}
          {...register("password", { required: true, minLength: 8 })}
        />
        {errors.password && <InputErrorMessage>{t("common.passwordCheck")}</InputErrorMessage>}
      </FormGroup>
      <PasswordValidation>
        <PasswordValidationItem>
          {passwordValidation.length ? <CircleCheckIcon /> : <TimesCircleIcon />}
          {t("common.passwordCheck2")}
        </PasswordValidationItem>
        <PasswordValidationItem>
          {passwordValidation.mixture ? <CircleCheckIcon /> : <TimesCircleIcon />}
          {t("common.passwordCheck3")}
        </PasswordValidationItem>
        <PasswordValidationItem>
          {passwordValidation.notEmail ? <CircleCheckIcon /> : <TimesCircleIcon />}
          {t("common.passwordCheck4")}
        </PasswordValidationItem>
      </PasswordValidation>
      <FormGroup>
        <SignUpFormLabel htmlFor="confirmPassword">
          {t("setPassword.passwordAgain")}
        </SignUpFormLabel>
        <NewInput
          id="confirmPassword"
          type="password"
          size={"400"}
          placeholder={t("setPassword.passwordAgainPlaceholder")}
          {...register("confirmPassword", {
            required: t("common.passwordCheck"),
            validate: (value) => value === password || t("setPassword.passwordAgainCheck"),
          })}
        />
        {errors.confirmPassword && (
          <InputErrorMessage>{String(errors.confirmPassword.message)}</InputErrorMessage>
        )}
      </FormGroup>
      <NewButtonWrapper
        size="400"
        buttonType={"primary"}
        type="submit"
        loading={controller.status === "loading"}
      >
        <NewButtonText>{t("setPassword.title")}</NewButtonText>
      </NewButtonWrapper>
      <NewButtonWrapper1 size="400" buttonType={"secondary"} onClick={JumpToSignIn}>
        <NewButtonText>{t("common.login")}</NewButtonText>
      </NewButtonWrapper1>
    </FormWrapper>
  );
});

const FormWrapper = styled.form`
  width: 410px;
  height: 835px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.sp20};
`;

const ContainerLogo = styled.a`
  width: 200px;
  margin-bottom: 24px;

  & > img {
    display: block;
    width: 100%;
  }
`;

const SignUpFormWrapperTitle = styled.h3`
  /* font-family: "Neuton", serif; */
  /* font-size: 44px; */
  font-size: 38px;
  font-weight: 400;
  letter-spacing: 2px;
  text-align: center;
  color: #003873;
  margin: 0px auto 24px;
  white-space: nowrap;
`;

const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const SignUpFormLabel = styled.label`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.375;
  color: #646464;
  margin-bottom: 8px;
  display: inline-block;
`;

const PasswordValidation = styled.ul`
  width: 100%;
  margin-top: 0;
  margin-bottom: 1rem;
  list-style-type: none;
  text-align: left;
  padding-left: 0;
  font-size: 14px;
  color: #6a6c6f;
  display: block;
`;

const PasswordValidationItem = styled.li`
  display: list-item;
  text-align: -webkit-match-parent;
  unicode-bidi: isolate;
`;

const TimesCircleIcon = styled(FontAwesomeIcon).attrs({ icon: faTimesCircle })<{ icon?: IconProp }>`
  color: #dae0e9;
  font-size: 16px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const CircleCheckIcon = styled(FontAwesomeIcon).attrs({ icon: faCircleCheck })<{ icon?: IconProp }>`
  color: #0bcf97;
  font-size: 16px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const NewButtonWrapper = styled(NewButton)`
  width: 410px;
  margin-top: 16px;
  color: #ede8e4;
`;

const NewButtonWrapper1 = styled(NewButtonWrapper)`
  color: #003873;

  &:hover {
    color: #ede8e4;
    background-color: #0081ff;
  }
`;

const NewButtonText = styled.span`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.375;
`;
