import { getBearerToken, getCookie } from "@helium10/re-core";
import { memo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { useLoginAction } from "../../core/hooks/useLoginAction";
import { useRedirect } from "../../core/hooks/useRedirect";
import { appRoutes } from "../../core/links";
import logoSrc from "../../static/icons/2-colors-light-bg.svg";
import { SignInFormWrapper } from "./components/SignInFormWrapper";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const SignIn = memo(() => {
  const query = useQuery();
  const { redirect, getPath } = useRedirect();
  const to = query.get("to") || "";
  useLoginAction({ to });

  useEffect(() => {
    const accountId = getCookie("accountId") || "";
    getBearerToken(accountId).then((res) => {
      if (res) {
        redirect(getPath(appRoutes.home), { accountId });
      }
    });
  }, [redirect, getPath]);

  return (
    <>
      <Header>
        <ContainerLogo href="https://www.helium10.com/">
          <img src={logoSrc} alt="Helium 10" />
        </ContainerLogo>
      </Header>
      <Container>
        <Wrapper>
          <SignInFormWrapper />
        </Wrapper>
      </Container>
    </>
  );
});

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 73px);
  min-height: 835px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 410px;
  height: 835px;
  display: flex;
`;

const Header = styled.div`
  width: 100%;
  height: 33px;
  margin-top: 40px;
  padding: 0 0 0 60px;
`;

const ContainerLogo = styled.a`
  width: 200px;
  height: 33px;
  display: block;

  & > img {
    display: block;
    width: 200px;
  }
`;
