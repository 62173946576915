type ITfn = (value: string, params?: Record<string, string | number>) => string;

export const translateErrors = (t: ITfn, message?: string) => {
  switch (message) {
    case "Your card number is incomplete.":
      return t("checkout.errors.cardNumberIncomplete");
    case "Your card number is invalid.":
      return t("checkout.errors.cardNumberInvalid");
    case "Your card's expiration date is incomplete.":
      return t("checkout.errors.cardExpirationIncomplete");
    case "Your card's expiration year is in the past.":
      return t("checkout.errors.cardExpirationInPast");
    case "Your card's security code is incomplete.":
      return t("checkout.errors.cardSecurityCodeIncomplete");
    case "Payment Method is not available":
      return t("checkout.errors.paymentMethodNotAvailable");
    case "Invalid amount":
      return t("checkout.errors.invalidAmount");
    case "Invalid coupon":
      return t("checkout.errors.invalidCoupon");
    case "Invalid token":
      return t("checkout.errors.invalidToken");
    case "Insecure request":
      return t("checkout.errors.insecureRequest");
    case "Already subscribed.":
      return t("checkout.errors.alreadySubscribed");
    case "Can not upgrade to this plan.":
      return t("checkout.errors.cannotUpgradeToPlan");
    case "Can not upgrade from Annual plan.":
      return t("checkout.errors.cannotUpgradeFromAnnual");
    case "Can not attach a new payment method":
      return t("checkout.errors.cannotAttachNewPaymentMethod");
    case "Can not create account profile.":
      return t("checkout.errors.cannotCreateAccountProfile");
    case "Subscription not found":
      return t("checkout.errors.subscriptionNotFound");
    case "Saving of Processing Subscription failed":
      return t("checkout.errors.subscriptionSavingOrProcessingFailed");
    case "Payment failed. Try again, or choose a different payment method.":
      return t("checkout.errors.paymentFailedTryAgainOrChooseDifferentMethod");
    case "Your card was declined. Your request was in live mode, but used a known test card.":
      return t("checkout.errors.testCardLiveMode");
    case "Something went wrong. Please try again later.":
      return t("checkout.errors.unknownErrorTryLater");
    case "An error occurred. Please try again later.":
      return t("checkout.errors.errorTryLater");
    case "Unknown error":
      return t("checkout.errors.unknownError");
    default:
      return message;
  }
};
