import { cssDisplay400Bold, NewButton } from "@helium10/re-ui-components";
import { memo, useCallback, useMemo } from "react";
import styled from "styled-components";

import { useRedirect } from "@/core/hooks/useRedirect";

import { appRoutes } from "../../core/links";
import { useTranslation } from "../../i18n/useTranslation";
import helium10logo from "../../static/icons/helium10logo.svg";
import { DemoBand } from "./components/Band";
import { DemoDashBoardProvider, useDemoDashBoard } from "./store";

const Content = () => {
  const { t } = useTranslation();
  const { getPath, redirect } = useRedirect();

  const { infoSelector, editController, useStore } = useDemoDashBoard();
  const useInfo = useStore(infoSelector);

  const handleRouteChange = useCallback(() => {
    redirect(getPath(appRoutes.demoDetailPage));
  }, [redirect, getPath]);

  const handleAgeChange = useCallback(() => {
    editController.patch({
      age: useInfo.age + 1,
    });
  }, [editController, useInfo]);

  return (
    <>
      <DemoBand />
      <Wrapper>
        <H1>
          <Logo />
          {t("demo.hello")} {useInfo.name}
        </H1>
        <Age>{useInfo.age}</Age>
      </Wrapper>
      <Wrapper>
        <NewButton onClick={handleRouteChange}>jump to Demo Detail</NewButton>
        <NewButton onClick={handleAgeChange}>age + 1</NewButton>
      </Wrapper>
    </>
  );
};

export const DemoDashBoard = memo(() => {
  const initValue = useMemo(
    () => ({
      name: "John",
    }),
    [],
  );

  return (
    <>
      <DemoDashBoardProvider value={initValue}>
        <Content />
      </DemoDashBoardProvider>
    </>
  );
});

const Age = styled.div``;

const Wrapper = styled.main`
  padding: ${({ theme }) => `${theme.spacing.sp24} ${theme.spacing.sp48}`};
`;

const H1 = styled.h2`
  ${cssDisplay400Bold}
  margin-bottom: ${({ theme }) => theme.spacing.sp24};
`;

const Logo = styled.img.attrs({ src: helium10logo })`
  margin-right: ${({ theme }) => theme.spacing.sp8};
  width: ${({ theme }) => theme.spacing.sp24};
`;
