import { getCurrentAccountId } from "@helium10/re-core";
import queryString from "query-string";
import { useCallback } from "react";
import type { Params } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useLink } from "./useLink";

export const useRedirect = () => {
  const navigate = useNavigate();
  const { appendAccountId } = useLink();

  const getPath = useCallback(
    <S extends string>(
      url: string,
      query?: Record<string, string | number>,
      params?: Params<S>,
    ) => {
      const path = queryString.parseUrl(url).url;
      return queryString.stringifyUrl({
        url: appendAccountId(path, params),
        query: {
          accountId: getCurrentAccountId(),
          ...query,
        },
      });
    },
    [appendAccountId],
  );

  const redirect = useCallback(
    <S extends string>(
      url: string,
      query?: Record<string, string | number>,
      params?: Params<S>,
      force?: boolean,
    ) => {
      const path = getPath(url, query, params);
      navigate(path, { replace: force });
    },
    [getPath, navigate],
  );

  return { redirect, getPath };
};
