import { baseFetch } from "@helium10/re-core";

import { wickUserAPI } from "../links";

// getAvailableAccounts
export interface IGetWickAvailableAccountsResponse {
  data: {
    name: string;
    userId: number;
    clientId: number;
    isSuperAdmin: number;
    isDefaultAccount: boolean;
    mail: string;
  }[];
}

export const getWickAvailableAccounts = async () => {
  return baseFetch<void, IGetWickAvailableAccountsResponse>({
    method: "GET",
    withCredentials: true,
    useSessionAccountId: true,
    url: wickUserAPI.getAvailableAccounts,
  });
};
