import { CORE_BEARER_TOKEN } from "@helium10/re-core";
import { InputErrorMessage, NewButton, NewInput } from "@helium10/re-ui-components";
import { CORE_REFRESH_TOKEN } from "@walmart10/container/helpers/refreshToken";
import { memo, useCallback } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";

import { useRedirect } from "@/core/hooks/useRedirect";

import {
  accountIdSelector,
  useAccountStore,
  useCallbacksStore,
} from "../../../features/src/components/newMenu/store/callbacksStore";
import { ApiAccounts } from "../../../features/src/requests/accounts";
import { useLoginAction } from "../../core/hooks/useLoginAction";
import { appRoutes } from "../../core/links";
import { useTranslation } from "../../i18n/useTranslation";
import logoSrc from "../../static/icons/2-colors-light-bg.svg";
import walmartlogoSrc from "../../static/icons/walmartlogo.svg";
import { UpdateUserEmailProvider, useUpdateUserEmailStore } from "./store";

type FormValues = { email: string; password: string; email2: string };

export const ChangeEmail = memo(() => {
  useLoginAction();
  const accountId = useAccountStore(accountIdSelector);
  const callbacks = useCallbacksStore((state) => state.callbacks);
  const handleSignOut = () => {
    const logoutToken = useCallbacksStore.getState().callbacks?.logoutToken;
    if (callbacks.onLogout) {
      callbacks.onLogout?.(accountId);
    } else {
      ApiAccounts.signOut(logoutToken)?.finally(() => {
        localStorage.removeItem(CORE_REFRESH_TOKEN);
        localStorage.removeItem(CORE_BEARER_TOKEN);
        window.location.replace(appRoutes.signIn);
      });
    }
  };
  return (
    <UpdateUserEmailProvider value={{ handleSignOut }}>
      <ContainerLogo href="https://www.helium10.com/">
        <img src={logoSrc} alt="Helium 10" />
      </ContainerLogo>
      <Container>
        <Wrapper>
          <ChangeFormWrapper />
        </Wrapper>
      </Container>
    </UpdateUserEmailProvider>
  );
});

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 104px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
`;

const ChangeFormWrapper = memo(() => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>();

  const { useUpdateUserEmailController } = useUpdateUserEmailStore();
  const updateUserEmailController = useUpdateUserEmailController();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    useUpdateUserEmailController.action({
      password: data.password,
      mail: data.email,
      resetAssociatedUserEmail: true,
    });
  };
  const { redirect, getPath } = useRedirect();
  const handleSettings = useCallback(() => {
    redirect(getPath(appRoutes.settings));
  }, [redirect, getPath]);

  const email = watch("email", "");

  const { t } = useTranslation();

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <SignUpFormWrapperTitle>{t("changeEmail.title")}</SignUpFormWrapperTitle>
      <ContainerWalmartLogo href="https://www.helium10.com/">
        <img src={walmartlogoSrc} alt="Helium 10" />
      </ContainerWalmartLogo>
      <FormGroup>
        <SignUpFormLabel htmlFor="password">{t("userSettings.password")}</SignUpFormLabel>
        <NewInput
          id="password"
          type="password"
          size={"400"}
          placeholder={t("signUp.passwordPlaceholder")}
          {...register("password", {
            required: t("changeEmail.required1"),
          })}
        />
        {errors.password && (
          <InputErrorMessage>{String(errors.password?.message)}</InputErrorMessage>
        )}
      </FormGroup>
      <FormGroup>
        <SignUpFormLabel htmlFor="email">{t("changeEmail.newEmail")}</SignUpFormLabel>
        <NewInput
          id="email"
          size={"400"}
          placeholder={t("changeEmail.placeholder2")}
          {...register("email", {
            required: t("changeEmail.required2"),
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i,
              message: t("changeEmail.emailMessage"),
            },
          })}
        />
        {errors.email && <InputErrorMessage>{String(errors.email?.message)}</InputErrorMessage>}
      </FormGroup>
      <FormGroup>
        <SignUpFormLabel htmlFor="email2">{t("changeEmail.emailAgain")}</SignUpFormLabel>
        <NewInput
          id="email2"
          size={"400"}
          placeholder={t("changeEmail.placeholder3")}
          {...register("email2", {
            required: t("changeEmail.required3"),
            validate: (value) => value === email || t("changeEmail.repeatedExactly"),
          })}
        />
        {errors["email2"] && (
          <InputErrorMessage>{String(errors.email2?.message)}</InputErrorMessage>
        )}
      </FormGroup>
      <NewButtonWrapper
        size="400"
        buttonType={"primary"}
        type="submit"
        loading={updateUserEmailController.status === "loading"}
      >
        {t("changeEmail.changeEmail")}
      </NewButtonWrapper>
      <NewButtonWrapper
        size="400"
        buttonType={"secondary"}
        type="button"
        onClick={handleSettings}
        disabled={updateUserEmailController.status === "loading"}
      >
        {t("userSettings.cancel")}
      </NewButtonWrapper>
    </FormWrapper>
  );
});

const FormWrapper = styled.form`
  width: 410px;
  height: 835px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.sp20};
`;

const ContainerLogo = styled.a`
  width: 200px;
  height: 33px;
  margin-top: 40px;
  padding: 0 0 0 60px;

  & > img {
    display: block;
    width: 200px;
  }
`;

const ContainerWalmartLogo = styled.a`
  width: 200px;
  margin-bottom: 24px;

  & > img {
    display: block;
    width: 100%;
  }
`;

const SignUpFormWrapperTitle = styled.h3`
  /* font-family: "Neuton", serif; */
  /* font-size: 44px; */
  font-size: 38px;
  font-weight: 400;
  letter-spacing: 2px;
  text-align: center;
  color: #003873;
  margin: 0px auto 24px;
  white-space: nowrap;
`;

const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const SignUpFormLabel = styled.label`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.375;
  color: #646464;
  margin-bottom: 8px;
  display: inline-block;
`;

const NewButtonWrapper = styled(NewButton)`
  width: 410px;
  text-transform: uppercase;
  margin-top: 35px;
  &:nth-of-type(2) {
    margin-top: 15px;
  }
`;
