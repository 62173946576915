import type { IUseTranslation, SupportLanguagesType } from "@helium10/re-core";
import { leitenPrimitive, TranslateProvider } from "@helium10/re-core";
import LocalizedStrings from "localized-strings";
import type { PropsWithChildren } from "react";
import { createContext, useContext, useEffect } from "react";
import { create } from "zustand";

import de from "./translates/de.json";
import en from "./translates/en.json";
import es from "./translates/es.json";
import it from "./translates/it.json";
import ja from "./translates/ja.json";
import zh from "./translates/zh_CN.json";

const strings = new LocalizedStrings(
  { en, de, es, it, zh, ja } as unknown as Record<string, ILanguage>,
  {
    logsEnabled: false,
  },
);
type ILanguage = typeof en;

export type ITOnlyValue = IUseTranslation<ILanguage>["t"];

export const UserSystemTranslateProvider = ({
  children,
  forceLang,
}: PropsWithChildren<{ forceLang?: SupportLanguagesType }>) => {
  return (
    <TranslateProvider strings={strings} TranslateContext={TranslateContext} forceLang={forceLang}>
      <TranslationInitializer />
      {children}
    </TranslateProvider>
  );
};

export const useTranslationInitialization = () => {
  const translation = useTranslation();

  useEffect(() => {
    translationController.set(translation);
  }, [translation]);
};

const TranslateContext = createContext<IUseTranslation<ILanguage> | null>(null);

export const useTranslation = (): IUseTranslation<ILanguage> => {
  const context = useContext(TranslateContext);
  if (!context) {
    throw new Error("[Translation] The provider is not defined");
  }
  return context;
};

const TranslationInitializer = () => {
  useTranslationInitialization();
  return null;
};

type ITranslationState = ReturnType<typeof useTranslation>;

export const useTranslationStore = create<{ translation: ITranslationState | null }>(() => ({
  translation: null,
}));
export const translationController = leitenPrimitive(useTranslationStore, "translation");
