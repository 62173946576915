import { DialogModal, NewButton } from "@helium10/re-ui-components";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { useTranslation } from "../../../i18n/useTranslation";
import { changeAvatar } from "../requests";
import { IconModalProps } from "./Common";

const Footer = styled.div`
  text-align: right;
`;

export const IconModal = ({ isOpen, onClose, userIcon, setIconName, iconList }: IconModalProps) => {
  const [loading, setLoading] = useState(false);
  const [innerIcon, setInnerIcon] = useState(userIcon ?? "");
  useEffect(() => {
    setInnerIcon(userIcon ?? "");
  }, [userIcon]);
  function imgClick(icon: { path: string; name: string }) {
    setInnerIcon(icon.name);
  }
  async function iconChange() {
    setLoading(true);
    try {
      await changeAvatar(innerIcon);
      onClose?.();
      setIconName(innerIcon);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (!isOpen) {
      setInnerIcon(userIcon ?? "");
    }
  }, [isOpen]);

  const { t } = useTranslation();

  return (
    <DialogModal
      isOpen={isOpen}
      onClose={onClose}
      title={t("userSettings.selectIcon")}
      footer={
        <Footer>
          <NewButton disabled={loading} buttonType="secondary" onClick={onClose} size="200">
            {t("userSettings.cancel")}
          </NewButton>
          <NewButton loading={loading} buttonType="primary" onClick={iconChange} size="200">
            {t("userSettings.save")}
          </NewButton>
        </Footer>
      }
    >
      <IconImgBox>
        {iconList.map((i, index) => {
          return (
            <IconImg
              active={i.name === innerIcon}
              src={i.path}
              key={index}
              onClick={() => imgClick(i)}
            ></IconImg>
          );
        })}
      </IconImgBox>
    </DialogModal>
  );
};

const IconImgBox = styled.div`
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const IconImg = styled.img<{ active: boolean }>`
  height: 75px;
  width: 75px;
  margin: 8px 15px;
  border-radius: 50%;
  outline: 2px solid;
  outline-color: ${({ active }) => (active ? "#0081ff" : "transparent")};
  outline-offset: 2px;
`;
