import { baseFetch } from "@helium10/re-core";

import { userSystem } from "../../../core/links";

export interface ISendMFAEmail {
  email: string;
}
export interface ISendMFAEmailResponse {
  data: {
    results?: string;
    code?: number;
    message?: string;
    name?: string;
    status?: number;
  } | null;
}

export interface IVerifyLoginParams {
  email: string;
  password: string;
  rememberMe: 1 | 0;
  code: string;
  validateCode: string;
}

export interface IVerifyLoginAgainResult {
  code: 0;
  message: string;
  name: string;
  status: 210;
}

export interface IVerifyLoginSuccessResult {
  code: 200;
  accessToken: string;
  refreshToken: string;
  tokenType: string;
  expiresIn: number;
}

export interface IVerifyLoginFailedResult {
  code: 200;
  message: string;
}

export interface IUserVerifyLoginResponse {
  results: IVerifyLoginAgainResult | IVerifyLoginSuccessResult | IVerifyLoginFailedResult;
}

export const sendMFAEmail = async (params: ISendMFAEmail) => {
  return baseFetch<ISendMFAEmail, ISendMFAEmailResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.sendMFAEmail,
    params: params,
  });
};

export const userVerifyLogin = async (params: IVerifyLoginParams) => {
  return baseFetch<IVerifyLoginParams, IUserVerifyLoginResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: false,
    url: userSystem.userVerifyLogin,
    params: params,
  });
};

type SendEmailCodeResponse = {
  code: number;
  data: {
    code: string;
  };
};
export const sendEmailCode = async () => {
  return baseFetch<undefined, SendEmailCodeResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.sendEmailCode,
  });
};
type VerifyEmailCodeParams = {
  code: string;
  validateCode: string;
};
type VerifyEmailCodeRsoponse = {
  code: number;
  message: string;
};
export const verifyEmailCode = async (params: VerifyEmailCodeParams) => {
  return baseFetch<VerifyEmailCodeParams, VerifyEmailCodeRsoponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.verifyEmailCode,
    params,
  });
};
