import type { ReactElement } from "react";
import { cloneElement } from "react";
import styled from "styled-components";

import { useTranslation } from "../../../i18n/useTranslation";
import type { IUseAlipay } from "../Checkout";
import { Footer, Form, SmallTitle } from "../Styles";
import { ErrorMessages } from "./ErrorMessages";

interface IPaymentAlipayProps {
  recaptchaV2?: ReactElement;
  disclaimer?: ReactElement;
  actions: ReactElement;
  useAlipay?: () => IUseAlipay;
}

export const PaymentAlipay = ({
  recaptchaV2,
  disclaimer,
  actions,
  useAlipay,
}: IPaymentAlipayProps) => {
  const { t } = useTranslation();
  const info = useAlipay?.();

  return (
    <>
      <SmallTitle>{t("checkout.alipay.title")}</SmallTitle>
      <Form onSubmit={info?.handleSubmit(info?.onSubmitAlipay)}>
        <Description>{t("checkout.alipay.description")}</Description>
        {info?.formState?.errors?.root?.stripe && (
          <ErrorMessages type="alipay" message={info?.formState?.errors?.root?.stripe?.message} />
        )}
        <Footer>
          {disclaimer}
          {recaptchaV2 && info?.isEnabledRecaptchaV2 && (
            <CaptchaWrapper>{recaptchaV2}</CaptchaWrapper>
          )}
          {cloneElement(actions, {
            isLoading: info?.isLoading,
            isSubmitting: info?.isSubmitting,
          })}
        </Footer>
      </Form>
    </>
  );
};

const Description = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 1.44;
  color: ${(p) => p.theme.systemColors.grey600};
  margin: ${(p) => `${p.theme.spacing.sp16} 0`};
`;
const CaptchaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: ${(p) => p.theme.spacing.sp16};
`;
