import { cssDisplay400Bold } from "@helium10/re-ui-components";
import { memo } from "react";
import styled from "styled-components";

import { useMyTitle } from "../hooks";

export const DemoBand = memo(() => {
  const context = useMyTitle();

  return <MyH1>{context}</MyH1>;
});

const MyH1 = styled.h1`
  ${cssDisplay400Bold}
  margin-top: ${({ theme }) => theme.spacing.sp24};
  margin-bottom: ${({ theme }) => theme.spacing.sp24};
`;
