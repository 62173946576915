import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BaseSingleSelect,
  Country,
  DialogModal,
  ICountryCode,
  InputErrorMessage,
  NewButton,
  NewInput,
  Popover,
} from "@helium10/re-ui-components";
import { ChangeEventHandler, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { useTranslation } from "../../../i18n/useTranslation";
import { useUserSettingsStore } from "../store";
import { AddressInfoType, iconSize, IProfileModalProps } from "./Common";

library.add(fas);

const FromRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  > div {
    flex: 1 0 auto;
    padding-right: 15px;
    padding-left: 15px;
  }
`;

const Footer = styled.div`
  text-align: right;
`;

type OptionalFormDateType = {
  [K in keyof AddressInfoType]?: AddressInfoType[K];
};
export const ProfileModal = ({ isOpen, onClose, profile }: IProfileModalProps) => {
  const { useChangeAddressParamsController } = useUserSettingsStore();
  const controller = useChangeAddressParamsController();
  const submitRef = useRef<HTMLButtonElement>(null);
  const getDefaultValue = () => {
    return {
      nation: profile.results?.addressInfo.nation || "",
      phone: profile.results?.addressInfo.phone || "",
      post: profile.results?.addressInfo.post || "",
      name: profile.results?.addressInfo.name || "",
      address: profile.results?.addressInfo.address || "",
      district: profile.results?.addressInfo.district || "",
      province: profile.results?.addressInfo.province || "",
    };
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: getDefaultValue(),
  });
  const saveChange = () => {
    submitRef.current?.click();
  };
  const onSubmit = async () => {
    try {
      useChangeAddressParamsController.action({
        name: formDate.name,
        address: formDate.address,
        city: formDate.district,
        state: formDate.province,
        zip: formDate.post,
        country: formDate.nation,
        phone: formDate.phone,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const [formDate, setFormDate] = useState<AddressInfoType>({
    nation: "",
    phone: "",
    post: "",
    name: "",
    address: "",
    district: "",
    province: "",
  });

  useEffect(() => {
    if (!isOpen) return;
    const data = getDefaultValue();
    setFormDate(data);
    reset(data);
  }, [profile, isOpen]);

  const setFormValue = ({
    nation,
    phone,
    post,
    name,
    address,
    district,
    province,
  }: OptionalFormDateType) => {
    const state = {
      ...formDate,
    };
    if (typeof nation !== "undefined") {
      state.nation = nation + "";
    }
    if (typeof phone !== "undefined") {
      state.phone = phone || "";
    }
    if (typeof post !== "undefined") {
      state.post = post || "";
    }
    if (typeof name !== "undefined") {
      state.name = name || "";
    }
    if (typeof address !== "undefined") {
      state.address = address || "";
    }
    if (typeof district !== "undefined") {
      state.district = district || "";
    }
    if (typeof province !== "undefined") {
      state.province = province || "";
    }
    setFormDate(state);
  };
  const { t } = useTranslation();
  return (
    <DialogModal
      isOpen={isOpen}
      onClose={onClose}
      title={t("userSettings.addressPhone")}
      footer={
        <Footer>
          <NewButton
            disabled={controller.status === "loading"}
            buttonType="secondary"
            onClick={onClose}
            size="200"
          >
            {t("userSettings.cancel")}
          </NewButton>
          <NewButton
            loading={controller.status === "loading"}
            buttonType="primary"
            onClick={saveChange}
            size="200"
            type="submit"
          >
            {t("userSettings.saveChanges")}
          </NewButton>
        </Footer>
      }
    >
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <FromRow>
          <FromRowItem>
            <SignUpFormLabel htmlFor="name">
              {t("userSettings.companyName")}
              <Star>*</Star>
            </SignUpFormLabel>
            <NewInput
              id="name"
              type="input"
              size={iconSize}
              {...register("name", { required: true })}
              onInput={(e) => setFormValue({ name: e.currentTarget.value })}
            ></NewInput>
            {errors["name"] && (
              <InputErrorMessage>{t("userSettings.companyNameCheck")}</InputErrorMessage>
            )}
          </FromRowItem>
        </FromRow>
        <FromRow>
          <FromRowItem>
            <SignUpFormLabel htmlFor="address">
              {t("userSettings.streetAddress")}
              <Star>*</Star>
            </SignUpFormLabel>
            <NewInput
              id="address"
              type="input"
              size={iconSize}
              {...register("address", { required: true })}
              onInput={(e) => setFormValue({ address: e.currentTarget.value })}
            ></NewInput>
            {errors["address"] && (
              <InputErrorMessage>{t("userSettings.streetAddressCheck")}</InputErrorMessage>
            )}
          </FromRowItem>
        </FromRow>
        <FromRow>
          <FromRowItem isHalf>
            <SignUpFormLabel htmlFor="district">
              {t("userSettings.cityDistrict")}
              <Star>*</Star>
            </SignUpFormLabel>
            <NewInput
              id="district"
              type="input"
              size={iconSize}
              {...register("district", { required: true })}
              onInput={(e) => setFormValue({ district: e.currentTarget.value })}
            ></NewInput>
            {errors["district"] && (
              <InputErrorMessage>{t("userSettings.cityDistrictCheck")}</InputErrorMessage>
            )}
          </FromRowItem>
          <FromRowItem isHalf>
            <SignUpFormLabel htmlFor="province">
              {t("userSettings.stateProvince")}
              <Star>*</Star>
            </SignUpFormLabel>
            <NewInput
              id="province"
              type="input"
              size={iconSize}
              {...register("province", { required: true })}
              onInput={(e) => setFormValue({ province: e.currentTarget.value })}
            ></NewInput>
            {errors.province && (
              <InputErrorMessage>{t("userSettings.stateProvinceCheck")}</InputErrorMessage>
            )}
          </FromRowItem>
        </FromRow>
        <FromRow>
          <FromRowItem isHalf>
            <SignUpFormLabel htmlFor="post">
              {t("userSettings.postalCode")}
              <Star>*</Star>
            </SignUpFormLabel>
            <NewInput
              id="post"
              type="input"
              size={iconSize}
              {...register("post", { required: true })}
              onInput={(e) => setFormValue({ post: e.currentTarget.value })}
            ></NewInput>
            {errors.post && (
              <InputErrorMessage>{t("userSettings.postalCodeCheck")}</InputErrorMessage>
            )}
          </FromRowItem>
          <FromRowItem isHalf>
            <SignUpFormLabel htmlFor="country">
              {t("userSettings.sessionTable.country")}
              <Star>*</Star>
            </SignUpFormLabel>
            <CountrySelectlist
              value={formDate.nation}
              options={countryOptions}
              dropdownAutoWidth={true}
              onChange={(value) => setFormValue({ nation: value.value + "" })}
            ></CountrySelectlist>
          </FromRowItem>
        </FromRow>
        <FromRow>
          <PhoneNumberInput
            value={formDate.phone}
            onInput={(phone) => setFormValue({ phone })}
            t={t}
          ></PhoneNumberInput>
        </FromRow>
        <button type="submit" hidden ref={submitRef}></button>
      </FormWrapper>
    </DialogModal>
  );
};

const Star = styled.span`
  color: #c91309;
  margin-left: 4px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
`;

const FormWrapper = styled.form``;

const FromRowItem = styled.div<{ isHalf?: boolean }>`
  flex: ${(props) => (props.isHalf ? "0 1 50% !important" : "")};
`;

const SignUpFormLabel = styled.label`
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  color: #485e75;
  margin-bottom: 4px;
  display: inline-block;
`;
const CountrySelectlist = styled(BaseSingleSelect)`
  height: 40px;
  > div {
    height: 40px;
  }
`;

type AccountOptionsType = {
  label: string;
  value: string;
};

const PhoneNumberInput = ({
  value,
  onInput,
  t,
}: {
  value?: string;
  onInput?: (value: string) => void;
  t: (value: string) => string;
}) => {
  const [country, setCountry] = useState({
    value: "CN",
    label: getPhonePrefix("CN"),
  });
  const [phoneNumber, setPhoneNumber] = useState(getPhonePrefix(country.value));
  useEffect(() => {
    if (value) {
      for (const key in countryToPhonePrefix) {
        if (value.startsWith(countryToPhonePrefix[key])) {
          setCountry({
            value: key,
            label: getPhonePrefix(key),
          });
          break;
        }
      }
      setPhoneNumber(value);
    } else {
      setCountry({
        value: "CN",
        label: getPhonePrefix("CN"),
      });
      setPhoneNumber(getPhonePrefix("CN"));
    }
  }, [value]);
  const [isOpen, setIsOpen] = useState(false);
  const accountOptions = countries.reduce((arr: AccountOptionsType[], key: string) => {
    arr.push({
      label: key,
      value: key,
    });
    return arr;
  }, []);
  const selectChange = (option: AccountOptionsType) => {
    const oldValue = getPhonePrefix(country.value);
    let phone = phoneNumber;
    if (oldValue && phoneNumber.startsWith(oldValue)) {
      phone = phoneNumber.slice(oldValue.length);
    }
    setCountry(option);
    setPhoneNumber(getPhonePrefix(option.value) + phone);
  };
  const RenderOption = (option: AccountOptionsType) => {
    return (
      <CustomOption key={option.value} onClick={() => selectChange(option)}>
        <Country code={option.value as ICountryCode} label={true} />
        <span>{getPhonePrefix(option.value)}</span>
      </CustomOption>
    );
  };

  const phoneNumberChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const phone = event.target.value;
    setPhoneNumber(phone);
    onInput?.(phone);
  };
  return (
    <CountrySelectBox>
      <PhoneInput
        label={t("userSettings.phoneNumber")}
        size={iconSize}
        value={phoneNumber}
        onChange={phoneNumberChange}
      />
      <Popover
        open={isOpen}
        maxWidth={415}
        minWidth={415}
        onOpenChange={setIsOpen}
        content={
          <>
            {accountOptions.map((option) => {
              return (
                <Popover.Item key={option.value} onClick={() => selectChange(option)}>
                  {RenderOption(option)}
                </Popover.Item>
              );
            })}
          </>
        }
      >
        <CountryInput open={isOpen}>
          <Country code={country.value as ICountryCode} label={true} />
          <FontAwesomeIcon height={5} width={5} icon={["fas", "play"]} />
        </CountryInput>
      </Popover>
    </CountrySelectBox>
  );
};

const PhoneInput = styled(NewInput)`
  > div:nth-child(2) > input {
    padding-left: 54px;
  }
`;

const CountrySelectBox = styled.div`
  position: relative;
`;

const CountryInput = styled.div<{ open: boolean }>`
  position: absolute;
  display: inline-flex;
  align-items: center;
  height: 38px;
  padding: 0 6px 0 8px;
  width: 46px;
  left: 16px;
  top: 23px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  + div {
    > div {
      max-height: 200px;
      overflow: auto;
    }
  }
  > div {
    height: 14px;
    width: 20px;
    > div:nth-child(1) {
      height: 100%;
      width: 100%;
    }
    > div:nth-child(2) {
      display: none;
    }
  }
  > svg {
    margin-left: 6px;
    transform: ${({ open }) => (open ? "rotateZ(-90deg)" : "rotateZ(90deg)")};
  }
`;

const CustomOption = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0px !important;
  line-height: 21px;
  > div {
    display: inline-flex;
    margin-right: 6px;
  }
`;

const countries = [
  "CN",
  "US",
  "AF",
  "AX",
  "AL",
  "DZ",
  "AS",
  "AD",
  "AO",
  "AI",
  "AQ",
  "AG",
  "AR",
  "AM",
  "AN",
  "AW",
  "AU",
  "AT",
  "AZ",
  "BS",
  "BH",
  "BD",
  "BB",
  "BY",
  "BE",
  "BZ",
  "BJ",
  "BM",
  "BT",
  "BO",
  "BQ",
  "BA",
  "BW",
  "BV",
  "BR",
  "IO",
  "BN",
  "BG",
  "BF",
  "BI",
  "CV",
  "KH",
  "CM",
  "CA",
  "KY",
  "CF",
  "TD",
  "CL",
  "CX",
  "CC",
  "CO",
  "KM",
  "CG",
  "CD",
  "CK",
  "CR",
  "CI",
  "HR",
  "CU",
  "CW",
  "CY",
  "CZ",
  "DK",
  "DJ",
  "DM",
  "DO",
  "EC",
  "EG",
  "SV",
  "GQ",
  "ER",
  "EE",
  "SZ",
  "ET",
  "FK",
  "FO",
  "FJ",
  "FI",
  "FR",
  "GF",
  "PF",
  "TF",
  "GA",
  "GM",
  "GE",
  "DE",
  "GH",
  "GI",
  "GR",
  "GL",
  "GD",
  "GP",
  "GU",
  "GT",
  "GG",
  "GN",
  "GW",
  "GY",
  "HT",
  "HM",
  "VA",
  "HN",
  "HK",
  "HU",
  "IS",
  "IN",
  "ID",
  "IR",
  "IQ",
  "IE",
  "IM",
  "IL",
  "IT",
  "JM",
  "JP",
  "JE",
  "JO",
  "KZ",
  "KE",
  "KI",
  "KP",
  "KR",
  "KW",
  "KG",
  "LA",
  "LV",
  "LB",
  "LS",
  "LR",
  "LY",
  "LI",
  "LT",
  "LU",
  "MO",
  "MG",
  "MW",
  "MY",
  "MV",
  "ML",
  "MT",
  "MH",
  "MQ",
  "MR",
  "MU",
  "YT",
  "MX",
  "FM",
  "MD",
  "MC",
  "MN",
  "ME",
  "MS",
  "MA",
  "MZ",
  "MM",
  "NA",
  "NR",
  "NP",
  "NL",
  "NC",
  "NZ",
  "NI",
  "NE",
  "NG",
  "NU",
  "NF",
  "MK",
  "MP",
  "NO",
  "OM",
  "PK",
  "PW",
  "PS",
  "PA",
  "PG",
  "PY",
  "PE",
  "PH",
  "PN",
  "PL",
  "PT",
  "PR",
  "QA",
  "RE",
  "RO",
  "RU",
  "RW",
  "BL",
  "SH",
  "KN",
  "LC",
  "MF",
  "PM",
  "VC",
  "WS",
  "SM",
  "ST",
  "SA",
  "SN",
  "RS",
  "SC",
  "SL",
  "SG",
  "SX",
  "SK",
  "SI",
  "SB",
  "SO",
  "ZA",
  "GS",
  "SS",
  "ES",
  "LK",
  "SD",
  "SR",
  "SJ",
  "SE",
  "CH",
  "SY",
  "TW",
  "TJ",
  "TZ",
  "TH",
  "TL",
  "TG",
  "TK",
  "TO",
  "TP",
  "TT",
  "TN",
  "TR",
  "TM",
  "TC",
  "TV",
  "UG",
  "UA",
  "AE",
  "GB",
  "UK",
  "UM",
  "UY",
  "UZ",
  "VU",
  "VE",
  "VN",
  "VG",
  "VI",
  "WF",
  "EH",
  "YE",
  "ZM",
  "ZR",
  "ZW",
];
const countryMap: { [key: string]: string } = {
  AF: "Afghanistan",
  AX: "Åland-Inseln",
  AL: "Albanien",
  DZ: "Algerien",
  AS: "Amerikanischen Samoa-Inseln",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarktis",
  AG: "Antigua und Barbuda",
  AR: "Argentinien",
  AM: "Armenien",
  AW: "Aruba",
  AU: "Australien",
  AT: "Österreich",
  AZ: "Aserbaidschan",
  BS: "Bahamas",
  BH: "Bahrein",
  BD: "Bangladesch",
  BB: "Barbados",
  BY: "Weißrussland",
  BE: "Belgien",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermudas",
  BT: "Bhutan",
  BO: "Bolivien",
  BQ: "Karibische Niederlande",
  BA: "Bosnien und Herzegowina",
  BW: "Botswana",
  BV: "Bouvet-Insel",
  BR: "Brasilien",
  IO: "Britisches Territorium des Indischen Ozeans",
  BN: "Brunei Darussalam",
  BG: "Bulgarien",
  BF: "Burkina Faso",
  BI: "Burundi",
  CV: "Cabo Verde",
  KH: "Kambodscha",
  CM: "Kamerun",
  CA: "Kanada",
  KY: "Cayman Inseln",
  CF: "Zentralafrikanische Republik",
  TD: "Tschad",
  CL: "Chile",
  CN: "China",
  CX: "Weihnachtsinsel",
  CC: "Kokosinseln (Keelinginseln)",
  CO: "Kolumbien",
  KM: "Komoren",
  CG: "Kongo",
  CD: "Kongo",
  CK: "Cookinseln",
  CR: "Costa Rica",
  CI: "Elfenbeinküste",
  HR: "Kroatien",
  CU: "Kuba",
  CW: "Curacao",
  CY: "Zypern",
  CZ: "Tschechien",
  DK: "Dänemark",
  DJ: "Dschibuti",
  DM: "Dominika",
  DO: "Dominikanische Republik",
  EC: "Ecuador",
  EG: "Ägypten",
  SV: "El Salvador",
  GQ: "Äquatorialguinea",
  ER: "Eritrea",
  EE: "Estland",
  SZ: "Eswatini",
  ET: "Äthiopien",
  FK: "Falklandinseln (Malvinas)",
  FO: "Färöer Inseln",
  FJ: "Fidschi",
  FI: "Finnland",
  FR: "Frankreich",
  GF: "Französisch-Guayana",
  PF: "Französisch Polynesien",
  TF: "Südfranzösische Territorien",
  GA: "Gabun",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Deutschland",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Griechenland",
  GL: "Grönland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard-Insel und McDonald-Inseln",
  VA: "Heiliger Stuhl",
  HN: "Honduras",
  HK: "Hongkong",
  HU: "Ungarn",
  IS: "Island",
  IN: "Indien",
  ID: "Indonesien",
  IR: "Iran",
  IQ: "Irak",
  IE: "Irland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italien",
  JM: "Jamaika",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordanien",
  KZ: "Kasachstan",
  KE: "Kenia",
  KI: "Kiribati",
  KP: "Korea",
  KR: "Korea, Republik von",
  KW: "Kuwait",
  KG: "Kirgistan",
  LA: "Demokratische Volksrepublik Laos",
  LV: "Lettland",
  LB: "Libanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libyen",
  LI: "Liechtenstein",
  LT: "Litauen",
  LU: "Luxemburg",
  MO: "Macau",
  MG: "Madagaskar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Malediven",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshallinseln",
  MQ: "Martinique",
  MR: "Mauretanien",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexiko",
  FM: "Mikronesien",
  MD: "Moldau, Republik",
  MC: "Monaco",
  MN: "Mongolei",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Marokko",
  MZ: "Mosambik",
  MM: "Burma",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Niederlande",
  NC: "Neu-Kaledonien",
  NZ: "Neuseeland",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolkinsel",
  MK: "Nordmazedonien",
  MP: "Nördliche Marianneninseln",
  NO: "Norwegen",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palästina, Staat",
  PA: "Panama",
  PG: "Papua Neu-Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippinen",
  PN: "Pitcairn",
  PL: "Polen",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Katar",
  RE: "Réunion",
  RO: "Rumänien",
  RU: "Russische Föderation",
  RW: "Ruanda",
  BL: "Sankt Barthélemy",
  SH: "St. Helena, Himmelfahrt und Tristan da Cunha",
  KN: "St. Kitts und Nevis",
  LC: "St. Lucia",
  MF: "Saint Martin (französischer Teil)",
  PM: "St. Pierre und Miquelon",
  VC: "St. Vincent und die Grenadinen",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome und Principe",
  SA: "Saudi-Arabien",
  SN: "Senegal",
  RS: "Serbien",
  SC: "Seychellen",
  SL: "Sierra Leone",
  SG: "Singapur",
  SX: "Sint Maarten (niederländischer Teil)",
  SK: "Slowakei",
  SI: "Slowenien",
  SB: "Salomon-Inseln",
  SO: "Somalia",
  ZA: "Südafrika",
  GS: "Süd-Georgien und die südlichen Sandwich-Inseln",
  SS: "Südsudan",
  ES: "Spanien",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Surinam",
  SJ: "Spitzbergen und Jan Mayen",
  SE: "Schweden",
  CH: "Schweiz",
  SY: "Syrische Arabische Republik",
  TW: "Taiwan, Provinz Chinas",
  TJ: "Tadschikistan",
  TZ: "Tansania",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad und Tobago",
  TN: "Tunesien",
  TR: "Türkei",
  TM: "Turkmenistan",
  TC: "Turks- und Caicosinseln",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "Vereinigte Arabische Emirate",
  GB: "Vereinigtes Königreich Großbritannien und Nordirland",
  US: "Vereinigte Staaten von Amerika",
  UM: "Kleinere abgelegene Inseln der Vereinigten Staaten",
  UY: "Uruguay",
  UZ: "Usbekistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Vietnam",
  VG: "Jungferninseln",
  VI: "Jungferninseln",
  WF: "Wallis und Futuna",
  EH: "Westsahara",
  YE: "Jemen",
  ZM: "Sambia",
  ZW: "Zimbabwe",
  AN: "Niederländische Antillen",
  TP: "Osttimor",
  UK: "Großbritannien",
  ZR: "Zaire",
};
const countryOptions = Object.keys(countryMap).map((key) => {
  return {
    label: countryMap[key],
    value: key,
  };
});
const countryToPhonePrefix: { [key: string | number]: string } = {
  AF: "+93",
  AX: "+358",
  AL: "+355",
  DZ: "+213",
  AS: "+1-684",
  AD: "+376",
  AO: "+244",
  AI: "+1-264",
  AQ: "+672",
  AG: "+1-268",
  AR: "+54",
  AM: "+374",
  AN: "+599",
  AW: "+297",
  AU: "+61",
  AT: "+43",
  AZ: "+994",
  BS: "+1-242",
  BH: "+973",
  BD: "+880",
  BB: "+1-246",
  BY: "+375",
  BE: "+32",
  BZ: "+501",
  BJ: "+229",
  BM: "+1-441",
  BT: "+975",
  BO: "+591",
  BQ: "+599",
  BA: "+387",
  BW: "+267",
  BV: "+55",
  BR: "+55",
  IO: "+246",
  BN: "+673",
  BG: "+359",
  BF: "+226",
  BI: "+257",
  CV: "+238",
  KH: "+855",
  CM: "+237",
  CA: "+1",
  KY: "+1-345",
  CF: "+236",
  TD: "+235",
  CL: "+56",
  CN: "+86",
  CX: "+61",
  CC: "+61",
  CO: "+57",
  KM: "+269",
  CG: "+242",
  CD: "+243",
  CK: "+682",
  CR: "+506",
  CI: "+225",
  HR: "+385",
  CU: "+53",
  CW: "+599",
  CY: "+357",
  CZ: "+420",
  DK: "+45",
  DJ: "+253",
  DM: "+1-767",
  DO: "+1-809",
  EC: "+593",
  EG: "+20",
  SV: "+503",
  GQ: "+240",
  ER: "+291",
  EE: "+372",
  SZ: "+268",
  ET: "+251",
  FK: "+500",
  FO: "+298",
  FJ: "+679",
  FI: "+358",
  FR: "+33",
  GF: "+594",
  PF: "+689",
  TF: "+262",
  GA: "+241",
  GM: "+220",
  GE: "+995",
  DE: "+49",
  GH: "+233",
  GI: "+350",
  GR: "+30",
  GL: "+299",
  GD: "+1-473",
  GP: "+590",
  GU: "+1-671",
  GT: "+502",
  GG: "+44-1481",
  GN: "+224",
  GW: "+245",
  GY: "+592",
  HT: "+509",
  HM: "+672",
  VA: "+39",
  HN: "+504",
  HK: "+852",
  HU: "+36",
  IS: "+354",
  IN: "+91",
  ID: "+62",
  IR: "+98",
  IQ: "+964",
  IE: "+353",
  IM: "+44-1624",
  IL: "+972",
  IT: "+39",
  JM: "+1-876",
  JP: "+81",
  JE: "+44-1534",
  JO: "+962",
  KZ: "+7",
  KE: "+254",
  KI: "+686",
  KP: "+850",
  KR: "+82",
  KW: "+965",
  KG: "+996",
  LA: "+856",
  LV: "+371",
  LB: "+961",
  LS: "+266",
  LR: "+231",
  LY: "+218",
  LI: "+423",
  LT: "+370",
  LU: "+352",
  MO: "+853",
  MG: "+261",
  MW: "+265",
  MY: "+60",
  MV: "+960",
  ML: "+223",
  MT: "+356",
  MH: "+692",
  MQ: "+596",
  MR: "+222",
  MU: "+230",
  YT: "+262",
  MX: "+52",
  FM: "+691",
  MD: "+373",
  MC: "+377",
  MN: "+976",
  ME: "+382",
  MS: "+1-664",
  MA: "+212",
  MZ: "+258",
  MM: "+95",
  NA: "+264",
  NR: "+674",
  NP: "+977",
  NL: "+31",
  NC: "+687",
  NZ: "+64",
  NI: "+505",
  NE: "+227",
  NG: "+234",
  NU: "+683",
  NF: "+672",
  MK: "+389",
  MP: "+1-670",
  NO: "+47",
  OM: "+968",
  PK: "+92",
  PW: "+680",
  PS: "+970",
  PA: "+507",
  PG: "+675",
  PY: "+595",
  PE: "+51",
  PH: "+63",
  PN: "+64",
  PL: "+48",
  PT: "+351",
  PR: "+1-787",
  QA: "+974",
  RE: "+262",
  RO: "+40",
  RU: "+7",
  RW: "+250",
  BL: "+590",
  SH: "+290",
  KN: "+1-869",
  LC: "+1-758",
  MF: "+590",
  PM: "+508",
  VC: "+1-784",
  WS: "+685",
  SM: "+378",
  ST: "+239",
  SA: "+966",
  SN: "+221",
  RS: "+381",
  SC: "+248",
  SL: "+232",
  SG: "+65",
  SX: "+1-721",
  SK: "+421",
  SI: "+386",
  SB: "+677",
  SO: "+252",
  ZA: "+27",
  GS: "+500",
  SS: "+211",
  ES: "+34",
  LK: "+94",
  SD: "+249",
  SR: "+597",
  SJ: "+47",
  SE: "+46",
  CH: "+41",
  SY: "+963",
  TW: "+886",
  TJ: "+992",
  TZ: "+255",
  TH: "+66",
  TL: "+670",
  TG: "+228",
  TK: "+690",
  TO: "+676",
  TP: "+670",
  TT: "+1-868",
  TN: "+216",
  TR: "+90",
  TM: "+993",
  TC: "+1-649",
  TV: "+688",
  UG: "+256",
  UA: "+380",
  AE: "+971",
  GB: "+44",
  US: "+1",
  UK: "+44",
  UM: "+1",
  UY: "+598",
  UZ: "+998",
  VU: "+678",
  VE: "+58",
  VN: "+84",
  VG: "+1-284",
  VI: "+1-340",
  WF: "+681",
  EH: "+212",
  YE: "+967",
  ZM: "+260",
  ZR: "+243",
  ZW: "+263",
};

function getPhonePrefix(countryCode: string | number): string {
  return countryToPhonePrefix[countryCode] || "Unknown country code";
}
