import { JSX } from "react";
import styled from "styled-components";

export const Content = ({ children }: { children: JSX.Element[] }) => {
  return <MyBox>{children}</MyBox>;
};

const MyBox = styled.div`
  padding: 25px 15px 0px;
  min-width: 320px;
  background: #f1f3f6;
`;
