import { IFeatureCreator, leitenFeature, leitenRequest } from "@helium10/re-core";
import { toastNotification } from "@helium10/re-ui-components";
import { create } from "zustand";

import { IUpdateUserEmailResponse, updateUserEmail } from "../../userSettings/requests";

interface IState {
  updateUserEmailResponse: IUpdateUserEmailResponse;
}

export type ICodeVerificationFeature = IFeatureCreator<{
  handleSignOut: () => void;
}>;

export const [useUpdateUserEmailStore, UpdateUserEmailProvider] = leitenFeature(
  ({ useProps }: ICodeVerificationFeature) => {
    const { handleSignOut } = useProps.getState();
    const useStore = create<IState>(() => ({
      updateUserEmailResponse: {
        code: 0,
        msg: "",
      },
    }));
    const useUpdateUserEmailController = leitenRequest(
      useStore,
      "updateUserEmailResponse",
      updateUserEmail,
      {
        fulfilled: (res) => {
          if (res.result.code === 200) {
            handleSignOut();
          } else {
            toastNotification.error(res.result.msg, { autoClose: false });
          }
        },
        rejected: (res) => {
          const error = res.error || res.fetchError?.result?.msg;
          toastNotification.error(error, { autoClose: false });
        },
      },
    );

    return {
      useStore,
      useProps,
      useUpdateUserEmailController,
    };
  },
);
