import { DialogModal, NewButton } from "@helium10/re-ui-components";
import { memo } from "react";
import styled from "styled-components";

import { useTranslation } from "../../../i18n/useTranslation";
import { useAccessManagementStore } from "../store";

export const AccountConfirmModal = memo(() => {
  const { useAccountConfirmModal } = useAccessManagementStore();
  const [isOpen] = useAccountConfirmModal();
  const { t } = useTranslation();

  return (
    <DialogModal
      title={t("accessManagement.modal.conform.title")}
      onClose={useAccountConfirmModal.close}
      onClickOutside={() => false}
      onClickEscape={() => false}
      maxWidth={"504px"}
      footer={
        <Footer>
          <NewButton buttonType="primary" size={"200"} onClick={useAccountConfirmModal.close}>
            {t("accessManagement.button.ok")}
          </NewButton>
        </Footer>
      }
      isOpen={isOpen}
    >
      {t("accessManagement.modal.conform.content")}
    </DialogModal>
  );
});

const Footer = styled.div`
  display: flex;
  justify-content: center;
`;
