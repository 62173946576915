import type { ConfirmCardSetupData, SetupIntent, StripeError } from "@stripe/stripe-js";

import type { IStripe } from "../../store/useCheckoutModalStore";

export interface IStripeConfirmIntentRequest {
  stripe: IStripe;
  clientSecret: string;
  data?: ConfirmCardSetupData;
}

export interface IStripeConfirmIntentResponse {
  setupIntent?: SetupIntent;
  error?: StripeError;
}

export const stripeConfirmIntent = ({
  stripe,
  clientSecret,
  data,
}: IStripeConfirmIntentRequest): Promise<IStripeConfirmIntentResponse> => {
  return stripe?.confirmCardSetup(clientSecret, data);
};
