import { isMainAppDevOrCi, membersLink, transformHelium10Domain } from "@helium10/re-core";

import { isDev } from "../helpers/utils";

const helium = transformHelium10Domain("https://www.helium10.com");

export const netDomain = isDev()
  ? ""
  : transformHelium10Domain(
      isMainAppDevOrCi ? window.location.origin : process.env.REACT_APP_WALMART_DOMAIN || "/",
    );

export const domain = isDev() ? "" : membersLink;
export const customersLink = domain + "/api/v1/customers/me";
export const defaultAvatarLink = membersLink + "/images/avatars/profile-default.svg";
export const defaultNotificationImageLink =
  membersLink + "/images/new-logo/helium-apple-logo-new.png";

export const searchBarAutocompleteLink = domain + "/site/get-search-bar-autocomplete";
export const searchBarChosenResultLink = domain + "/site/search-bar-chosen-result";

export const supportLink = helium + "/support";
export const contactSupportLink = transformHelium10Domain(
  "https://kb.helium10.com/hc/en-us/requests/new",
);
export const facebookGroupLink = "https://www.facebook.com/groups/Helium10Users/";
export const mainMenuLink = domain + "/site/menu";

export const profileSubscribeLink = domain + "/subscribe";
export const profileAccountsLink = domain + "/user-accounts";
export const profileTokensLink = domain + "/profile/tokens";
export const profileNotificationsLink = domain + "/account/notifications";
export const profileSignoutLink = domain + "/user/signout";
export const spaProfileSignoutLink = membersLink + "/api/v1/site/logout";

export const extendedMenuLink = customersLink + "/extended-menu";

export const notificationsAlertsLink = domain + "/alerts";

export const paymentDataLink = domain + "/api/v1/customers/subscription/current-data";

export const subscribeLink = domain + "/subscribe";
export const eliteLink = "https://members.helium10elite.com";

export const getFlagLink = (code: string) => {
  return transformHelium10Domain(
    "https://adtomic.helium10.com/assets/images/flags/" + code.toUpperCase() + ".svg",
  );
};

export const profilesGroupedList = "/v1/profiles/grouped-list";

export const createSetupIntentUrl = subscribeLink + "/create-setup-intent";
export const changeCardV3Url = subscribeLink + "/change-card-v3";

export const commonEventsLink = domain + "/common-events/track-tool-link-click";

export const segmentTrackLink = domain + "/api/v1/customers/segment-track";

export const privacyPolicyLink = transformHelium10Domain(helium + "/privacy-policy");
export const termsAndConditionsLink = transformHelium10Domain(helium + "/terms-and-conditions");
export const reviewsLink = "https://www.g2.com/products/helium-10/reviews#reviews";
export const sitemapLink = membersLink + "/sitemap";

export const walmartLink = helium + "/walmart";

export const customerSuccessWebinarsLink = transformHelium10Domain(
  "https://pages.helium10.com/customer-success-webinars",
);

export const marketplacesWidgetLink = "/v2/widgets/all-marketplaces";
export const summaryCardWidgetLink = "/v2/widgets/summary-card";
export const productPerformanceLink = "/v2/widgets/product-performance";
export const favoritesLink = "/v2/widgets/favorites";
export const heatMapsExternalWidget = "/v2/widgets/heat-map-external";

export const productDataLink = domain + "/api/v1/product/data";
export const topOrganicProductsLink = domain + "/api/v1/product/organic-top";

const WickWalmartBase = process.env.REACT_APP_MEMBERS_DOMAIN;

export const wickUserAPI = {
  switchAccount: WickWalmartBase + "/api/v1/users/switch-account",
  loginout: WickWalmartBase + "/api/v1/users/logout",
};
