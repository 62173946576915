import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
} from "@stripe/react-stripe-js";
import { useMemo } from "react";
import styled, { css } from "styled-components";

import { Label, StyledInputErrorMessage } from "../Styles";
import { getStripeOptions } from "../utils/getStripeOptions";

interface ILabeledStripe {
  id: string;
  type: "cardNumber" | "cardExpiry" | "cardCvc";
  placeholder?: string;
  label: string;
  error?: string;
  disabled?: boolean;
  onBlur: () => void;
  onChange: () => void;
}

export const LabeledStripe = ({
  id,
  type,
  placeholder,
  label,
  error,
  disabled,
  onBlur,
  onChange,
}: ILabeledStripe) => {
  const stripeOptions = useMemo(
    () => getStripeOptions({ id, error, disabled, placeholder, onChange, onBlur }),
    [id, error, disabled, placeholder, onChange, onBlur],
  );
  const elements = useElements();
  const { [type]: stripeNode } = {
    cardNumber: (
      <StyledCardNumber
        {...stripeOptions}
        $disabled={disabled}
        onReady={(e) => {
          e.focus();
        }}
        onChange={(e) => {
          stripeOptions?.onChange(e);
          if (!e.error && e.complete) {
            elements?.getElement(CardExpiryElement)?.focus();
          }
        }}
      />
    ),
    cardExpiry: (
      <StyledCardExpiry
        {...stripeOptions}
        $disabled={disabled}
        onChange={(e) => {
          stripeOptions?.onChange(e);
          if (!e.error && e.complete) {
            elements?.getElement(CardCvcElement)?.focus();
          }
        }}
      />
    ),
    cardCvc: <StyledCardCvc {...stripeOptions} $disabled={disabled} />,
  };

  return (
    <Container>
      <Label htmlFor={id}>{label}</Label>
      {stripeNode}
      {error && <StyledInputErrorMessage>{error}</StyledInputErrorMessage>}
    </Container>
  );
};

const Container = styled.div`
  font-size: 0;
  flex: 1;
`;

const errorCss = css`
  border-color: #e74c3c;
  background: ${(p) => p.theme.systemColors.red100};

  &::placeholder {
    color: ${(p) => p.theme.systemColors.red400};
    opacity: 0;
  }
`;

const outlineBorderCSS = css`
  outline: 2px solid transparent;
  outline-offset: -2px;
`;

const outlineBorderActiveBlueCSS = css`
  outline-color: ${({ theme }) => theme.systemColors.blue600};
`;

const outlineBorderActiveRedCSS = css`
  outline-color: ${({ theme }) => theme.systemColors.red400};
`;

const styledStripeElement = css<{ $disabled?: boolean; $error?: boolean }>`
  ${outlineBorderCSS};
  width: 100%;
  height: 40px;
  display: grid;
  align-items: center;
  border-radius: 6px;
  border: 1px solid ${(p) => p.theme.systemColors.grey400};
  background-color: ${(p) => p.theme.systemColors.white};
  background-clip: padding-box;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  padding: 10px 12px;
  margin: 6px 0 0;

  ${(p) =>
    p?.$disabled &&
    css`
      background: ${p.theme.systemColors.grey200};
      border-color: ${p.theme.systemColors.grey200};
      box-shadow: none;
    `}
  &.invalid {
    ${errorCss}
  }

  &.focus {
    ${outlineBorderActiveBlueCSS};
    background-color: #fff;
    border-color: #8fb5fe;
    box-shadow: none;

    ${(p) =>
      p.$error &&
      css`
        ${outlineBorderActiveRedCSS};
        ${errorCss};
      `};
  }

  ${(p) =>
    p.$error &&
    css`
      ${errorCss};
    `};
`;

const StyledCardNumber = styled(CardNumberElement)<{ $disabled?: boolean; $error?: boolean }>`
  ${styledStripeElement};
`;

const StyledCardExpiry = styled(CardExpiryElement)<{ $disabled?: boolean; $error?: boolean }>`
  ${styledStripeElement};
`;

const StyledCardCvc = styled(CardCvcElement)<{ $disabled?: boolean; $error?: boolean }>`
  ${styledStripeElement};
`;
