//TODO: add the stripe type of events
type IStripeEvent = unknown;
interface IGetStripeOptions {
  id: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  onBlur: () => void;
  onChange: (e: IStripeEvent) => void;
}

export const getStripeOptions = ({
  id,
  placeholder,
  disabled,
  error,
  onBlur,
  onChange,
}: IGetStripeOptions) => ({
  $error: !!error,
  options: {
    classes: {
      focus: "focus",
      empty: "empty",
      invalid: "invalid",
    },
    style: {
      base: {
        fontSize: "16px",
        color: "#485e75",
        fontFamily: "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif",
        fontSmoothing: "antialiased",
        ":disabled": {
          backgroundColor: "#d9e0e8",
        },
        "::placeholder": {
          color: error ? "#c91309" : "#a6b7c9",
        },
      },
    },
    placeholder,
    disabled,
  },
  id,
  onBlur,
  onChange,
});
