import { baseFetch, getCurrentAccountId } from "@helium10/re-core";

import { getHeaderHelper } from "@/core/helpers/getHeader.helper";
import { updateSubscriptionsLink } from "@/core/link/apiLink";

import type { IGroupProfilesResponse } from "./getSubscriptions";

export const updateSubscriptions = async (profileSelections: Record<string, boolean>) => {
  const params: IProfileSubscriptionUpdateBody = {
    external_account_id: getCurrentAccountId() || "",
    profile_selections: Object.keys(profileSelections).map((profile_id) => {
      return { profile_id, action: profileSelections[profile_id] ? "SUBSCRIBED" : "CANCELLED" };
    }),
  };
  return baseFetch<IProfileSubscriptionUpdateBody, IGroupProfilesResponse>({
    method: "POST",
    withCredentials: true,
    headers: getHeaderHelper(),
    params,
    url: updateSubscriptionsLink,
    useSessionAccountId: true,
  });
};

export interface IProfileSubscriptionUpdateBody {
  external_account_id: string;
  profile_selections: IProfileSelections[];
}

export interface IProfileSelections {
  profile_id: string;
  action: "SUBSCRIBED" | "CANCELLED";
}
