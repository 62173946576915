import { faPen, faPlusCircle, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DataTableType,
  FilterChangesType,
  IDataTable,
  ITableColumn,
  ITableFilter,
  NewButton,
  Tooltip,
} from "@helium10/re-ui-components";
import { useWickAccountsStore } from "@walmart10/container/core/store/wickAccountsStore";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { useTranslation } from "../../../i18n/useTranslation";
import { useAccessManagementStore } from "../store";

export const useDefault = () => {
  return {
    pageSize: 5,
    pageIndex: 1,
  };
};

const emailSuffixWhiteList = ["pacvue.com", "helium10.com"];
const isWhiteList = (email: string = "") => {
  return emailSuffixWhiteList.some((suffix) => email.endsWith(suffix));
};

export const useTableProps = (
  list: {
    id: string;
    name: string;
    email: string;
    accesibleFeaturesList: string[];
  }[],
) => {
  const { useUserInfoModal, useDeleteConfirmModal, useUserListController } =
    useAccessManagementStore();
  const { t } = useTranslation();

  const allAccounts = useWickAccountsStore((s) => s.availableAccounts);
  const adminAccount = allAccounts.find((account) => account.isSuperAdmin);

  const handleDisplayModal = useCallback(() => {
    // add new user
    useUserInfoModal.open();
  }, [useUserInfoModal]);

  const renderFeatureCellFunc = useCallback(
    (data: DataTableType<IDataTable>) => {
      return (
        <FeatureCell>
          <TextContainer>{(data.accesibleFeaturesList || []).join(", ")}</TextContainer>
          <div>
            {t("accessManagement.table.cell.features", {
              number: (data.accesibleFeaturesList || []).length,
            })}
            <ViewAll
              onClick={() => {
                useUserInfoModal.open({ id: data.userId, email: data.mail, name: data.name });
              }}
            >
              {t("accessManagement.table.cell.viewAll")}
            </ViewAll>
          </div>
        </FeatureCell>
      );
    },
    [t],
  );

  const renderRightActionCellFunc = useCallback(
    (data: DataTableType<IDataTable>) => {
      return (
        <ButtonGroups>
          <NewButton
            buttonType="icon"
            icon={
              <FontAwesomeIcon
                icon={faPen}
                onClick={() => {
                  useUserInfoModal.open({ id: data.userId, email: data.mail, name: data.name });
                }}
              />
            }
          />
          <NewButton
            buttonType="icon"
            onClick={() => {
              useDeleteConfirmModal.open({ id: data.userId });
            }}
            icon={<FontAwesomeIcon icon={faTrashCan} />}
          />
        </ButtonGroups>
      );
    },
    [useDeleteConfirmModal, useUserInfoModal],
  );

  const [limit, setLimit] = useState(2);

  useEffect(() => {
    if (adminAccount && isWhiteList(adminAccount.mail)) {
      setLimit(Number.MAX_SAFE_INTEGER);
    }
  }, [adminAccount?.clientId]);

  const leftActions = useCallback(() => {
    return (
      <NewButton
        disabled={list.length >= limit}
        icon={<FontAwesomeIcon icon={faPlusCircle} />}
        onClick={handleDisplayModal}
        buttonType="primary"
      >
        {t("accessManagement.button.addNewUser")}
      </NewButton>
    );
  }, [handleDisplayModal, list, limit]);

  const selected = useMemo(() => {
    return {
      haveCheckbox: false,
    };
  }, []);

  const columns: ITableColumn<IDataTable, string>[] = useMemo(() => {
    return [
      {
        field: "_",
        title: "",
        render: renderRightActionCellFunc,
        width: 96,
      },
      {
        field: "name",
        title: t("accessManagement.table.columns.name"),
        minWidth: 220,
        maxWidth: 220,
        render: (record: DataTableType<IDataTable>) => {
          return (
            <Tooltip maxWidth={1500} content={record.name}>
              <Name>{record.name}</Name>
            </Tooltip>
          );
        },
      },
      {
        field: "mail",
        title: t("accessManagement.table.columns.email"),
        minWidth: 356,
        maxWidth: 356,
        render: (record: DataTableType<IDataTable>) => {
          return (
            <Tooltip maxWidth={1500} content={record.mail}>
              <Nowrap>{record.mail}</Nowrap>
            </Tooltip>
          );
        },
      },
      {
        field: "accesibleFeaturesList",
        title: t("accessManagement.table.columns.features"),
        minWidth: 400,
        render: renderFeatureCellFunc,
      },
    ];
  }, [renderFeatureCellFunc, renderRightActionCellFunc, t]);

  const dimensions = useMemo(() => {
    return {
      minHeight: 498,
    };
  }, []);

  const behavior = useMemo(() => {
    return {
      itemsPerPage: [5, 10, 20],
      noFooter: true,
    };
  }, []);

  const filter = useMemo(() => {
    return {
      haveInitial: true,
      defaultValue: {
        count: behavior.itemsPerPage[0],
        page: 1,
        search: "",
        sortField: "",
        sortDirection: "ASC",
      } as ITableFilter,
    };
  }, [behavior]);

  const onFilter = useCallback(
    (filter: ITableFilter<IDataTable>, _changeType: FilterChangesType) => {
      useUserListController.action({
        page: {
          pageIndex: filter.page,
          pageSize: filter.count,
        },
      });
    },
    [useUserListController],
  );

  return {
    leftActions,
    selected,
    columns,
    dimensions,
    behavior,
    filter,
    onFilter,
  };
};

const FeatureCell = styled.div``;

const TextContainer = styled.div`
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-decoration: none;
  font-size: 14px;
  line-height: 18px;
  font-family: Nunito Sans;
`;

const ViewAll = styled.span`
  color: #0a6fe8;
  padding-left: 8px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  cursor: pointer;
`;

const ButtonGroups = styled.div`
  display: flex;
`;

const Nowrap = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Name = styled(Nowrap)`
  font-weight: 700;
`;
