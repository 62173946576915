import { baseFetch } from "@helium10/re-core";

import { userSystem } from "../../../core/links";

//getUserList
export interface IGetUserList {
  page: {
    pageSize: number;
    pageIndex: number;
  };
}

export interface IGetUserListResponse {
  data: {
    list: { id: string; name: string; email: string; accesibleFeaturesList: string[] }[];
    totalCount: number;
  };
}

//deleteUserAccount
export interface IDeleteUser {
  userId: string;
}

export interface IDeleteUserResponse {
  data: boolean;
  msg?: string;
}

//getUserFeaturesById
export interface IGetFeaturesById {
  userId: number;
}

export interface IGetUserFeaturesResponse {
  data: {
    menuId: string;
    id: number;
    productLine: string;
    roleId: string;
    permissionType: 0 | 2;
  }[];
}

//saveUserInfo
export interface ISaveUserInfo {
  userName: string;
  email: string;
  userId: number;
  features: {
    menuId: string;
    permissionType: 0 | 2;
  }[];
}

export interface ISaveUserInfoResponse {
  data: null;
  msg?: string;
}

// getAllMenus
export interface IGetAllMenusResponse {
  data: {
    menuId: number;
    i18N: string;
    children: { i18N: string; menuId: number }[] | null;
  }[];
}

// addNewUser
export interface IAddNewUser {
  userName: string;
  email: string;
  features: {
    menuId: string;
    permissionType: 0 | 2;
  }[];
}

export interface IAddNewUserResponse {
  data: {
    newInviteUser: boolean;
  };
  msg?: string;
}

export const getUserList = async (params: IGetUserList) => {
  return baseFetch<IGetUserList, IGetUserListResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.userAccountList,
    params: params,
  });
};

export const deleteUserAccount = async (params: IDeleteUser) => {
  const { userId } = params;
  return baseFetch<IDeleteUser, IDeleteUserResponse>({
    method: "DELETE",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.deleteUserAccount.replace(/:userId/g, userId),
  });
};

export const getUserFeaturesById = async (params: IGetFeaturesById) => {
  const { userId } = params;
  return baseFetch<IGetFeaturesById, IGetUserFeaturesResponse>({
    method: "GET",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.getUserInfoById.replace(/:userId/g, String(userId)),
  });
};

export const saveUserInfo = async (params: ISaveUserInfo) => {
  return baseFetch<ISaveUserInfo, ISaveUserInfoResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.saveUserInfo,
    params,
  });
};

export const getAllMenus = async () => {
  return baseFetch<void, IGetAllMenusResponse>({
    method: "GET",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.getAllMenus,
  });
};

export const addNewUser = async (params: IAddNewUser) => {
  return baseFetch<IAddNewUser, IAddNewUserResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: true,
    url: userSystem.addNewUser,
    params,
  });
};
