// add your request here
import { baseFetch } from "@helium10/re-core";

import { userSystem } from "../links";

//usersLogin
export interface IUserLoginParams {
  email: string;
  password: string;
  rememberMe: 1 | 0;
}

export interface ISignAgainResult {
  code: 210;
  type: string;
  verifyCode: string;
}

export interface ISignSuccessResult {
  code: 200;
  accessToken: string;
  refreshToken: string;
  tokenType: string;
  expiresIn: number;
}

export interface ISignFailedResult {
  code: 200;
  message: string;
}

export interface IUserLoginResponse {
  results: ISignAgainResult | ISignSuccessResult | ISignFailedResult;
}

export const usersLogin = async (params: IUserLoginParams) => {
  return baseFetch<IUserLoginParams, IUserLoginResponse>({
    method: "POST",
    withCredentials: true,
    useSessionAccountId: false,
    url: userSystem.userLogin,
    params: params,
  });
};
