import {
  InputErrorMessage,
  NewButton,
  NewInput,
  toastNotification,
} from "@helium10/re-ui-components";
import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { useRedirect } from "@/core/hooks/useRedirect";

import { useLoginAction } from "../../core/hooks/useLoginAction";
import { appRoutes } from "../../core/links";
import { useTranslation } from "../../i18n/useTranslation";
import logoSrc from "../../static/icons/2-colors-light-bg.svg";
import walmartlogoSrc from "../../static/icons/walmartlogo.svg";
import { disableForce, forceApp, forceEmail } from "../userSettings/requests";

type FromType = "tfd";
enum FORCE_TYPE {
  TFD = "tfd",
  TFE = "tfe",
  TFA = "tfa",
}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const ConfirmMethod = memo(() => {
  useLoginAction();
  const query = useQuery();
  const fromType = (query.get("from") as FromType) ?? "tfd";
  return (
    <>
      <ContainerLogo href="https://www.helium10.com/">
        <img src={logoSrc} alt="Helium 10" />
      </ContainerLogo>
      <Container>
        <Wrapper>
          <ChangeFormWrapper fromType={fromType} />
        </Wrapper>
      </Container>
    </>
  );
});

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 104px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
`;

const ChangeFormWrapper = memo(({ fromType }: { fromType: FromType }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    mixture: false,
    notEmail: false,
  });
  const { getPath, redirect } = useRedirect();

  const handleSettings = useCallback(() => {
    redirect(getPath(appRoutes.settings));
  }, [redirect, getPath]);
  const handleAuthenticator = useCallback(
    (param: string) => {
      redirect(getPath(appRoutes.authenticator), { p: param });
    },
    [redirect, getPath],
  );
  const handleCodeVerification = useCallback(
    (param: string) => {
      redirect(getPath(appRoutes.codeVerification), { p: param, from: "factoremail" });
    },
    [redirect, getPath],
  );
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data: any) => {
    if (fromType === FORCE_TYPE.TFD) {
      setLoading(true);
      try {
        const res = await disableForce(data.password);
        if (res.code === 200) {
          handleSettings();
        } else {
          toastNotification.error(t("confirmMethod.wrongPassword"), { autoClose: false });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else if (fromType === FORCE_TYPE.TFA) {
      setLoading(true);
      try {
        const res = await forceApp(data.password);
        if (res.code === 200) {
          const string = JSON.stringify(res.data);
          const param = encodeURIComponent(string);
          handleAuthenticator(param);
        } else {
          toastNotification.error(t("confirmMethod.wrongPassword"), { autoClose: false });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else if (fromType === FORCE_TYPE.TFE) {
      setLoading(true);
      try {
        const res = await forceEmail(data.password);
        if (res.code === 200) {
          const param = encodeURIComponent(res.data?.code || "");
          handleCodeVerification(param);
        } else {
          toastNotification.error(t("confirmMethod.wrongPassword"), { autoClose: false });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };
  const onCancel = () => {
    if (fromType === FORCE_TYPE.TFD) {
      handleSettings();
    } else if (fromType === FORCE_TYPE.TFA) {
      handleSettings();
    } else if (fromType === FORCE_TYPE.TFE) {
      handleSettings();
    }
  };

  const password = watch("password", "");
  const email = watch("email", "");

  useEffect(() => {
    setPasswordValidation({
      length: password.length >= 8,
      mixture:
        /[A-Za-z]/.test(password) && /[0-9]/.test(password) && /[^A-Za-z0-9\s]/.test(password),
      notEmail: email.indexOf(password) === -1,
    });
  }, [password, email]);

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <SignUpFormWrapperTitle>{t("confirmMethod.title")}</SignUpFormWrapperTitle>
      <ContainerWalmartLogo href="https://www.helium10.com/">
        <img src={walmartlogoSrc} alt="Helium 10" />
      </ContainerWalmartLogo>
      <FormGroup>
        <SignUpFormLabel htmlFor="password">{t("userSettings.password")}</SignUpFormLabel>
        <NewInput
          id="password"
          type="password"
          size={"400"}
          placeholder={t("confirmMethod.placeholder1")}
          {...register("password", { required: true })}
        />
        {errors.password && <InputErrorMessage>{t("common.passwordCheck")}</InputErrorMessage>}
      </FormGroup>
      <NewButtonWrapper size="400" buttonType={"primary"} type="submit" loading={loading}>
        {t("userSettings.confirm")}
      </NewButtonWrapper>
      <NewButtonWrapper
        size="400"
        buttonType={"secondary"}
        type="button"
        onClick={onCancel}
        disabled={loading}
      >
        {t("userSettings.cancel")}
      </NewButtonWrapper>
    </FormWrapper>
  );
});

const FormWrapper = styled.form`
  width: 410px;
  height: 835px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.sp20};
`;

const ContainerLogo = styled.a`
  width: 200px;
  height: 33px;
  margin-top: 40px;
  padding: 0 0 0 60px;

  & > img {
    display: block;
    width: 200px;
  }
`;

const ContainerWalmartLogo = styled.a`
  width: 200px;
  margin-bottom: 24px;

  & > img {
    display: block;
    width: 100%;
  }
`;

const SignUpFormWrapperTitle = styled.h3`
  /* font-family: "Neuton", serif; */
  /* font-size: 44px; */
  font-size: 38px;
  font-weight: 400;
  letter-spacing: 2px;
  text-align: center;
  color: #003873;
  margin: 0px auto 24px;
  white-space: nowrap;
`;

const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const SignUpFormLabel = styled.label`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.375;
  color: #646464;
  margin-bottom: 8px;
  display: inline-block;
`;

const NewButtonWrapper = styled(NewButton)`
  width: 410px;
  text-transform: uppercase;
  margin-top: 35px;
  &:nth-of-type(2) {
    margin-top: 15px;
  }
`;
