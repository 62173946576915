import { memo } from "react";
import styled from "styled-components";

import logoSrc from "../../static/icons/2-colors-light-bg.svg";
import { SetPasswordForm } from "./components/SetPasswordForm";

export const SetPassword = memo(() => {
  return (
    <>
      <ContainerLogo href="https://www.helium10.com/">
        <img src={logoSrc} alt="Helium 10" />
      </ContainerLogo>
      <Container>
        <Wrapper>
          <SetPasswordForm />
        </Wrapper>
      </Container>
    </>
  );
});

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 73px);
  min-height: 835px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 410px;
  height: 835px;
  display: flex;
`;

const ContainerLogo = styled.a`
  width: 200px;
  height: 33px;
  margin-top: 40px;
  padding: 0 0 0 60px;

  & > img {
    display: block;
    width: 200px;
  }
`;
