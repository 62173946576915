import { getCookie } from "@helium10/re-core";
import { toastNotification, ToastTitle } from "@helium10/re-ui-components";
import {
  ISignAgainResult,
  ISignFailedResult,
  ISignSuccessResult,
} from "@walmart10/container/core/requests/usersLogin";
import {
  userloginController,
  userWickUserLoginStore,
} from "@walmart10/container/core/store/loginStore";
import { setRefreshTokenToStorage } from "@walmart10/container/helpers/refreshToken";
import { useEffect } from "react";

import { appRoutes } from "../links";
import { useRedirect } from "./useRedirect";
export const useLoginAction = (props?: { to?: string }) => {
  const { status, payload } = userloginController();
  const { getPath, redirect } = useRedirect();
  const { userLogin } = userWickUserLoginStore();
  console.log(payload, userLogin);
  useEffect(() => {
    if (status === "loaded" && userLogin) {
      if (userLogin.results.code === 210) {
        const results = userLogin.results as ISignAgainResult;
        if (results.type === "email") {
          redirect(getPath(appRoutes.codeVerification), {
            from: "signemail",
            u: encodeURIComponent(
              JSON.stringify({
                e: payload?.email,
                p: payload?.password,
                c: results.verifyCode,
                r: payload?.rememberMe,
              }),
            ),
          });
        } else if (results.type === "mfa") {
          redirect(getPath(appRoutes.codeVerification), {
            from: "signinapp",
            u: encodeURIComponent(
              JSON.stringify({
                e: payload?.email,
                p: payload?.password,
                c: results.verifyCode,
                r: payload?.rememberMe,
              }),
            ),
          });
        }
      } else if (userLogin.results.code === 200) {
        if ((userLogin.results as { message?: string })?.message) {
          const results = userLogin.results as ISignFailedResult;
          toastNotification.error(<ToastTitle>{results.message}</ToastTitle>);
        } else {
          const results = userLogin.results as ISignSuccessResult;
          setRefreshTokenToStorage(results.refreshToken);
          const accountId = getCookie("accountId");
          if (props?.to === "settings") {
            const url = getPath(appRoutes.settings, { accountId: accountId || "" });
            window.location.assign(url);
          } else {
            const url = getPath(appRoutes.home, { accountId: accountId || "" });
            window.location.assign(url);
          }
        }
      }
    }
  }, [status, userLogin]);
};
